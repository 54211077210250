.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.textOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*<editor-fold desc="Обнуление стилей">*/
* {
  outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #313131;
  font-family: 'Rubik', sans-serif;
  margin: 10px 0;
  font-weight: 300;
}
h1 {
  line-height: 48px;
  font-size: 36px;
}
h2 {
  line-height: 36px;
  font-size: 24px;
}
h3 {
  line-height: 30px;
  font-size: 21px;
}
h4 {
  line-height: 22px;
  font-size: 18px;
}
h5 {
  font-size: 16px;
  font-size: 14px;
}
.dn {
  display: none;
}
.db {
  display: block;
}
.light_op_text {
  color: rgba(255, 255, 255, 0.5);
}
blockquote {
  border-left: 5px solid #2cabe3 !important;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
p {
  line-height: 1.6;
}
b {
  font-weight: 500;
}
a:hover {
  outline: 0;
  text-decoration: none;
}
a:active {
  outline: 0;
  text-decoration: none;
}
a:focus {
  outline: 0;
  text-decoration: none;
}
.clear {
  clear: both;
}
.font-12 {
  font-size: 12px;
}
hr {
  border-color: rgba(120, 130, 140, 0.13);
}
label {
  margin-bottom: 0;
}
/*</editor-fold>*/
/*<editor-fold desc="Утильные классы">*/
.b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}
.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}
.b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.b-none {
  border: 0px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-45 {
  padding: 45px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-l-45 {
  padding-left: 45px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-r-45 {
  padding-right: 45px !important;
}
.p-r-50 {
  padding-right: 50px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-t-1 {
  padding-top: 1px !important;
}
.p-t-2 {
  padding-top: 2px !important;
}
.p-t-3 {
  padding-top: 3px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.m-0 {
  margin: 0 !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-l0 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-r-45 {
  margin-right: 45px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-3 {
  margin-top: 3px;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-45 {
  margin-top: 45px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-45 {
  margin-bottom: 45px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.f-w-400 {
  font-weight: 400;
}
.f-w-500 {
  font-weight: 500;
}
.f-w-600 {
  font-weight: 600;
}
.b-s-n {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/*</editor-fold>*/
/*<editor-fold desc="Body">*/
html {
  /*Если мало контента - видно белый body*/
  background-color: #edf1f5;
}
.clearfix:after {
  /*Используется для того что бы зачистить границы.*/
  content: '';
  display: block;
  clear: both;
}
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 0;
}
/*</editor-fold>*/
/*<editor-fold desc="Content">*/
#page-name-div {
  float: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  height: 37px;
  width: 220px;
  vertical-align: center;
  padding-left: 24px;
  padding-top: 23px;
}
/*</editor-fold>*/
/*<editor-fold desc="еще хрень какая-то">*/
#page-wrapper {
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0px 0 0px 60px;
    min-height: 100%;
  }
  .navbar-default {
    position: relative;
    width: 100%;
    top: 0px;
  }
  .navbar-static-top {
    padding-left: 60px;
  }
  .fix-header .navbar-static-top {
    position: fixed;
  }
  .fix-header #page-wrapper {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .top-left-part {
    width: 60px;
  }
  #page-wrapper {
    margin: 0px;
  }
  .fix-header .navbar-static-top {
    position: fixed;
    top: 0px;
    width: 100%;
  }
  .fix-header #page-wrapper {
    margin-top: 60px;
  }
}
/*</editor-fold>*/
/*<editor-fold desc="Подсказки">*/
.tooltip2 {
  display: inline;
  position: relative;
  opacity: 1;
  /*z-index: 5;*/
}
.tooltip2_btn {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}
.tooltip2_btn:hover {
  color: #000;
}
.tooltip2:hover:after {
  background: #e33351;
  bottom: 30px;
  color: #fff;
  content: attr(data-tip);
  left: 100px;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 250px;
}
.tooltip2-stat {
  display: inline;
  position: relative;
}
.tooltip2-stat:hover:after {
  background: #e33351;
  bottom: 30px;
  color: #fff;
  content: attr(data-tip);
  padding: 5px 15px;
  position: absolute;
  font-size: 12px;
  z-index: 98;
  width: 250px;
}
.tooltip-status-right,
.tooltip-status-left {
  display: inline;
  position: relative;
}
.tooltip-status-right:hover:after,
.tooltip-status-left:hover:after {
  background: #e33351;
  bottom: 55px;
  color: #fff;
  content: attr(data-tip);
  padding: 5px 15px;
  position: absolute;
  font-size: 12px;
  z-index: 98;
  width: 170px;
}
.tooltip-status-right:hover:after {
  left: calc(100% - 170px);
}
.tooltip2-flex {
  display: flex;
  position: relative;
}
.tooltip2-flex:hover:after {
  background: #333;
  background: #e33351;
  bottom: 40px;
  color: #fff;
  content: attr(data-tip);
  left: 100px;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 250px;
}
.tooltip2-flex-left {
  display: flex;
  position: relative;
}
.tooltip2-flex-left:hover:after {
  background: #333;
  background: #e33351;
  bottom: 40px;
  color: #fff;
  content: attr(data-tip);
  right: 100px;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 250px;
}
/*</editor-fold>*/
/*<editor-fold desc="Notify">*/
.notify-info {
  color: #fff;
  background-color: #4CAF50;
  padding: 15px;
  max-width: 500px;
}
.notify-error {
  color: #fff;
  background-color: #e33351;
  padding: 15px;
  max-width: 500px;
}
.notify-close-button {
  font-size: 21px;
  opacity: 0.5;
  border: 0;
  margin-left: 10px;
  background: 0 0;
  color: white;
}
.notify-message {
  margin-right: 10px;
}
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
/*</editor-fold>*/
/*<editor-fold desc="Button">*/
.next-button {
  background-color: transparent;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}
.next-button:hover {
  background-color: #ebebeb;
}
.button-green {
  color: #4CAF50;
  border: #4CAF50 2px solid;
}
.button-yellow {
  color: #b9b900;
  border: #b9b900 2px solid;
}
.button-blue {
  color: #008CBA;
  border: #008CBA 2px solid;
}
.button-red {
  color: #ba0b02;
  border: #ba0b02 2px solid;
}
/*</editor-fold>*/
/*<editor-fold desc="Webra buttons">*/
.webra-button {
  font-family: inherit;
  background-image: none;
  user-select: none;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  height: 40px;
  line-height: 38px;
  font-size: 17px;
  color: #fff;
  border: none;
  padding: 0 25px;
  font-weight: 500;
}
.webra-button > span {
  margin-left: 5px;
}
.webra-button-add {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}
.webra-button-blue {
  background-color: #2e8ae0;
}
.webra-button-green {
  background-color: #4CAF50;
}
/*</editor-fold>*/
/*<editor-fold desc="lib">*/
.next-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1021;
  /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3);
  /* Black w/ opacity */
  align-items: flex-start;
  justify-content: center;
  padding-top: 100px;
}
.next-modal .next-modal-body {
  background-color: #fefefe;
  border-radius: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
}
.next-modal .next-modal-close {
  color: #d6d6d6;
  float: right;
  font-size: 25px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 7px;
}
.next-modal .next-modal-close:hover,
.next-modal .next-modal-close:focus {
  color: #ababab;
  text-decoration: none;
  cursor: pointer;
}
.next-modal .next-modal-header {
  padding: 2px 16px;
  color: #38769b;
  position: relative;
}
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.next-modal-ask {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
.next-modal-ask-body {
  background-color: #fefefe;
  border-radius: 2px;
  margin: 300px auto;
  border: 1px solid #888;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  padding: 20px;
}
.next-modal-ask-close {
  color: #ffffff;
  float: right;
  font-size: 44px;
  font-weight: bold;
  position: relative;
  top: -40px;
  left: 45px;
}
.next-modal-ask-close:hover,
.next-modal-ask-close:focus {
  color: #38769b;
  text-decoration: none;
  cursor: pointer;
}
.next-modal-ask-header {
  padding: 2px 16px;
  color: #38769b;
}
/*</editor-fold>*/
/*<editor-fold desc="Checkbox">*/
.next-checkbox {
  position: relative;
  margin: 0 10px;
  border-radius: 20px;
  background: #999;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  width: 36px;
  height: 20px;
}
.next-checkbox::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: calc((100% - 4px) / 2);
  height: calc(100% - 4px);
  border-radius: 50%;
  background: #eee;
  transition: all 0.1s linear;
}
.next-checkbox:checked {
  background: #4CAF50;
}
.next-checkbox:checked::after {
  transform: translateX(100%);
}
.next-checkbox::-ms-check {
  border: none;
}
@supports (-ms-ime-align: auto) {
  .next-checkbox {
    border: 2px solid #c3c3c3;
    background: none;
    border-radius: 0;
    width: 20px;
  }
  .next-checkbox:checked {
    background: none;
    border: 2px solid #10ae00;
  }
}
.cb-in-div {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}
.cb-in-div .next-checkbox {
  margin-top: 0;
}
.cb-in-div label {
  margin-left: 10px;
  margin-bottom: 0;
}
/*</editor-fold>*/
/*<editor-fold desc="Elements">*/
.next-input {
  border: 1px solid #a5a6a2;
  border-radius: 2px;
  padding: 5px 5px;
  background-image: -webkit-linear-gradient(#fdfdfd, #fafafa 90%, #f5f5f5);
}
.next-select {
  -moz-appearance: button;
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-image: -webkit-linear-gradient(#fdfdfd, #fafafa 90%, #f5f5f5);
  border: 1px solid #AAA;
  color: #555;
  overflow: hidden;
  padding: 5px 5px;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  height: 37px;
}
.next-select option {
  cursor: pointer;
}
.primary-rounded-content-block {
  background-color: #fff;
  padding: 15px;
  padding-right: 20px;
  color: #12171d;
  border-top: #d8d8d8 1px solid;
}
.container-with-header-self {
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 7px -2px #000000;
  box-shadow: 0px 0px 7px -2px #000000;
}
.shadow {
  -webkit-box-shadow: 0px 0px 7px -2px #000000 !important;
  box-shadow: 0px 0px 7px -2px #000000 !important;
}
.big-header {
  background-color: #637689;
  color: white;
  font-weight: 500;
  font-size: 15px;
  padding: 19px 0 19px 32px;
}
.big-header > * {
  float: left;
}
.small-block-header {
  background-color: #dfe6ed;
  font-size: 15px;
  color: #000000;
  padding: 8px;
  padding-left: 15px;
  line-height: 25px;
  font-weight: 400;
}
.small-block-content {
  padding: 10px;
  border: 2px solid #dfe6ed;
  border-top: none;
}
/*всем дочерним элементам отступ сверху*/
.small-block-content > * {
  margin-top: 7px;
}
/*но кроме самого первого*/
.small-block-content > *:first-child {
  margin-top: 0;
}
.next-white-select {
  border: none;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  /*padding-right: 10px;*/
}
.button-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-with-icon img {
  margin-right: 8px;
}
.select-proxima-default button {
  background-color: #fff !important;
  box-shadow: none;
  background-image: -webkit-linear-gradient(90deg, #f2f6f9 0%, #ffffff 31%);
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 100%;
  padding: 0px;
  padding-left: 10px;
}
.select-proxima-default .bootstrap-select {
  width: 100% !important;
}
.select-proxima-default span {
  line-height: 25px !important;
}
.proxima-tooltip-fix {
  height: 16px !important;
}
.proxima-tooltip-fix .tooltip_text {
  width: 300px !important;
}
.tooltip-width-400 .tooltip_text {
  width: 400px !important;
}
.tooltip-width-500 .tooltip_text {
  width: 500px !important;
}
.tooltip-lines-3 .tooltip_text {
  top: -20px !important;
}
.tooltip-lines-4 .tooltip_text {
  top: -27px !important;
}
/*<editor-fold desc="чекбокс квадратный">*/
.next-checkbox-square .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #eee;
  transition: 0.3s ease-in-out;
}
.next-checkbox-square {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
.modalTooltip {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
  line-height: 1;
  font-size: 13px;
  font-weight: bold;
  color: #8d8d8d;
  top: 50%;
  margin-left: 5px;
}
.modalTooltip:before {
  font-family: 'themify';
  content: "\e718";
}
.next-checkbox-square input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.next-checkbox-square input:checked ~ .checkmark {
  background-color: #06b000;
  border: none;
  transition: 0.3s ease-in-out;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.next-checkbox-square input:checked ~ .checkmark:after {
  display: block;
}
.next-checkbox-square .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: 0.3s ease-in-out;
}
/*</editor-fold>*/
/*</editor-fold>*/
.next-text-area {
  outline: none;
  -moz-appearance: none;
  border: 1px solid #5f6a99;
  border-radius: 3px;
  padding: 10px;
}
.textarea_default {
  background-color: #fff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 221px;
  resize: none;
  padding-top: 20px;
  font-size: 16px;
  padding-left: 20px;
}
@media (max-width: 767px) {
  .textarea_default {
    height: 120px;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .textarea_default {
    font-size: 12px;
    padding-top: 12px;
    padding-left: 12px;
  }
}
.link_default {
  font-size: 15px;
  color: #2e8ae0;
  text-decoration: underline;
}
.link_default:hover {
  color: #2e8ae0;
  text-decoration: none;
}
/*<editor-fold desc="Input field и multi-select button">*/
.webra-input-field {
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  width: 100%;
  background-color: #fff;
}
.dropdown-multi-button .btn-default {
  border: none !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 15px;
  font-weight: 500;
  width: 100%;
}
.dropdown-single-button .btn-default {
  border: none !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  font-weight: 500;
  width: 100%;
}
.dropdown-single-button .bootstrap-select {
  width: 100% !important;
  height: 100% !important;
}
.dropdown-single-button button {
  width: 100% !important;
  height: 100% !important;
}
.dropdown-single-button .dropdown-menu {
  min-width: 100% !important;
}
.dropdown-single-button button {
  padding-right: 25px !important;
}
/*</editor-fold>*/
/*<editor-fold desc="Input + -">*/
.input-plus-minus {
  border: 1px solid #c0cbd5;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  height: 30px;
  min-width: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  width: 80px;
}
.input-plus-minus input {
  width: 35px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #30323e;
  text-align: center !important;
}
.input-plus-minus span {
  cursor: pointer;
  color: #9babba;
}
/*</editor-fold>*/
/*<editor-fold desc="Особые свойства">*/
.disabled {
  opacity: 0.15;
  position: relative;
  cursor: default !important;
}
.vertical-align-items-flex {
  display: flex;
  align-items: center;
}
.vertical-align-items {
  position: relative;
}
.vertical-align-items > * {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vertical-align-in-relative {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/*</editor-fold>*/
/*<editor-fold desc="pseudo button">*/
.pseudo-button {
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  cursor: pointer;
  color: #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pseudo-button.disabled {
  cursor: not-allowed !important;
}
.pseudo-button > span {
  position: relative;
  line-height: 20px;
}
.pseudo-button > span:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -25px;
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.pseudo-button-remove > span:before {
  background: url("../img/svg/trash.svg") no-repeat center;
}
.pseudo-button-refresh > span:before {
  background: url("../img/svg/refresh.svg") no-repeat center;
}
.pseudo-button-channels > span:before {
  background: url("../img/svg/channels.svg") no-repeat center;
}
.pseudo-button-channels-blue > span:before {
  background: url("../img/svg/channels_blue.svg") no-repeat center;
}
.pseudo-button-off > span:before {
  background: url("../img/svg/off2_red.svg") no-repeat center;
}
.pseudo-button-special > span:before {
  background: url("../img/svg/special.svg") no-repeat center;
}
.pseudo-button-on > span:before {
  background: url("../img/svg/on2_green.svg") no-repeat center;
}
.pseudo-button-ok > span:before {
  background: url("../img/svg/check_mark2.svg") no-repeat center;
}
.pseudo-button-add > span:before {
  background: url("../img/svg/green_plus.svg") no-repeat center;
}
.pseudo-button-copy > span:before {
  background: url("../img/svg/copy_blue.svg") no-repeat center;
}
.pseudo-button-documentation > span:before {
  background: url("../img/svg/blue_documents.svg") no-repeat center;
}
.pseudo-button-add-blue > span:before {
  background: url("../img/svg/plus_blue.svg") no-repeat center;
}
.pseudo-button-save > span:before {
  background: url("../img/svg/check_mark.svg") no-repeat center;
}
.pseudo-button-save2 > span:before {
  background: url("../img/svg/save.svg") no-repeat center;
}
.pseudo-button-save-diskette > span:before {
  background: url("../img/svg/save-discette.svg") no-repeat center;
}
.pseudo-button-play2 > span:before {
  background: url("../img/svg/play-green.svg") no-repeat center;
}
.pseudo-button-statistic > span:before {
  background: url("../img/svg/statistic-green.svg") no-repeat center;
}
.pseudo-button-note > span:before {
  background: url("../img/svg/blue_note.svg") no-repeat center;
}
.pseudo-button-note-disabled > span:before {
  background: url("../img/svg/gray_note.svg") no-repeat center;
}
.pseudo-button-settings > span:before {
  background: url("../img/svg/green_settings.svg") no-repeat center;
}
.pseudo-button-support > span:before {
  background: url("../img/svg/settings_blue.svg") no-repeat center;
}
.pseudo-button-support-white > span:before {
  background: url("../img/svg/settings_white.svg") no-repeat center;
}
.pseudo-button-support-test > span:before {
  background: url("../img/svg/settings_white.svg") no-repeat center;
}
.pseudo-button-cancel > span:before {
  background: url("../img/svg/off_yellow.svg") no-repeat center;
}
.pseudo-button-download > span:before {
  background: url("../img/svg/download-green.svg") no-repeat center;
}
.pseudo-button-play > span:before {
  background: url("../img/svg/play-red.svg") no-repeat center;
}
.pseudo-button-list > span:before {
  background: url("../img/svg/list_blue.svg") no-repeat center;
}
.pseudo-button-testing > span:before {
  background: url("../img/svg/testing-yellow.svg") no-repeat center;
}
.pseudo-button-lease > span:before {
  background: url("../img/svg/key.svg") no-repeat center;
}
.pseudo-button-buy > span:before {
  background: url("../img/svg/buy.svg") no-repeat center;
}
.pseudo-button-edit > span:before {
  background: url("../img/svg/edit_blue.svg") no-repeat center;
}
.pseudo-button-edit2 > span:before {
  background: url("../img/svg/edit_blue2.svg") no-repeat center;
}
.pseudo-button-call1-redirect > span:before {
  background: url("../img/svg/call1-redirect.svg") no-repeat center;
}
.pseudo-button-call1-in > span:before {
  background: url("../img/svg/call1-in.svg") no-repeat center;
}
.pseudo-button-call1-out > span:before {
  background: url("../img/svg/call1-out.svg") no-repeat center;
}
.pseudo-button-dial-pad > span:before {
  background: url("../img/svg/dial_pad.svg") no-repeat center;
}
.pseudo-button-audio-library > span:before {
  background: url("../img/svg/library-audio-music.svg") no-repeat center;
}
.pseudo-button-user-work-resume > span:before {
  background: url("../img/svg/user_work_resume.svg") no-repeat center;
}
.pseudo-button-user-work-pause > span:before {
  background: url("../img/svg/user_work_pause.svg") no-repeat center;
}
.pseudo-button-info > span:before {
  background: url("../img/svg/blue_info.svg") no-repeat center;
}
.pseudo-button-disable-pro > span:before {
  background: url("../img/svg/pro-mode-red.svg") no-repeat center;
}
.pseudo-button-list > span:before {
  background: url("../img/svg/list_blue.svg") no-repeat center;
}
/*</editor-fold>*/
/*<editor-fold desc="Circle loading bar">*/
.lds-default {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-default div {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #38769b;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
/*</editor-fold>*/
.nextel-checkbox {
  display: inline-block;
  position: relative;
}
.nextel-checkbox span {
  font-size: 14px;
  color: #30323e;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding-left: 28px;
}
.nextel-checkbox span:before {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #e6eaef;
  position: absolute;
  top: -2px;
  left: 0;
  border-radius: 1px;
  transition: 0.3s ease-in-out;
}
.nextel-checkbox span:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 8px;
  background: url(../img/checkbox_after.svg) no-repeat center;
  top: 4px;
  left: 5px;
  opacity: 1;
}
.nextel-checkbox input {
  display: none;
}
.nextel-checkbox input:checked + span:before {
  background-color: #4caf50;
  border-color: #4caf50;
}
.nextel-radio {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nextel-radio span {
  font-size: 14px;
  color: #30323e;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding-left: 28px;
}
.nextel-radio span:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  width: 20px;
  height: 20px;
  border: 1px solid #c0cbd5;
  transition: 0.3s ease-in-out;
}
.nextel-radio span:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  left: 6px;
  top: 6px;
  background-color: #fff;
  border-radius: 50%;
}
.nextel-radio input {
  display: none;
}
.nextel-radio input:checked + span:before {
  background-color: #4caf50;
  border-color: #4caf50;
}
.nextel-radio.red input:checked + span:before {
  background-color: #ec402f;
  border-color: #ec402f;
}
.nextel-autocomplete-div {
  position: relative;
  cursor: pointer;
}
.nextel-autocomplete-div .nextel-autocomplete-list {
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 40px;
  left: 0;
  border: #d1d1d1 1px solid;
  display: none;
}
.nextel-autocomplete-div .nextel-autocomplete-list-item {
  padding: 10px 16px;
  height: 40px;
  font-weight: 500;
}
.nextel-autocomplete-div .nextel-autocomplete-list-item:hover {
  background-color: #efeef3;
}
.nextel-autocomplete-div .autocomplete-active,
.nextel-autocomplete-div .autocomplete-active:hover {
  background-color: #308bec;
  color: #fff;
}
.source-filters-div-for-filters-buttons > div {
  margin-top: 10px;
}
.source-filters-div-for-filters .webra-input-field {
  border: 1px solid #d1d1d1;
}
.source-filters-2-px-bordered-block {
  border: 2px solid #e0e0e0;
  padding: 10px;
  margin-top: 10px;
}
.source-filters-geo-filters-type-select {
  display: flex;
  padding-top: 5px;
  margin-left: 50px;
}
.source-filters-geo-filter-one-line {
  display: flex;
  margin-top: 20px;
}
.source-filters-span-field-description {
  font-weight: 500;
  line-height: 40px;
}
.source-filters-geo-input-div {
  position: relative;
  width: 250px;
  margin-right: 20px;
}
.source-filters-geo-span-name {
  color: #2a8be1;
  line-height: 13px !important;
  position: absolute;
  font-size: 13px;
  top: -13px;
}
.source-filters-url-filters {
  margin-bottom: 25px;
}
.source-filters-one-url-filter-line > div {
  margin-top: 5px;
  margin-bottom: 15px;
}
.source-filters-one-url-filter-in-line {
  display: flex;
  margin-bottom: 10px;
}
.source-filters-one-url-filter-in-line span {
  line-height: 40px;
  font-weight: 500;
}
.source-filters-condition-description {
  margin-left: 10px;
}
.billing-compare h2 {
  line-height: 20px;
  padding-top: 10px;
}
.balance-comparing .button {
  height: 47px;
  line-height: 47px;
  padding: 0 25px;
  font-weight: 500;
  color: #36af38;
  border: 2px solid #36af38;
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.balance-comparing .button svg {
  margin-right: 10px;
  position: relative;
  top: -3px;
}
.balance-comparing .button svg path {
  transition: all 0.5s ease;
}
.balance-comparing .button:hover {
  background-color: #36af38;
  color: #fff;
}
.balance-comparing .button:hover svg path {
  fill: #fff;
}
.balance-comparing .adminChangeButton {
  display: none;
  font-weight: 500;
  color: #0089ff;
  margin-top: 10px;
}
.balance-comparing .adminChangeButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
.billing-compare-one-line {
  display: flex;
  margin-top: 5px;
  padding: 5px;
  border-radius: 3px;
}
.billing-compare-left,
.billing-compare-right {
  flex-basis: 900px;
  display: flex;
  height: 40px;
}
.billing-compare-left {
  text-align: right;
}
.billing-compare-center {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.billing-compare-service-count-difference {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}
.billing-compare-service-count {
  width: 50px;
  font-weight: 500;
  font-size: 30px;
  color: #cfcfcf;
}
.billing-compare-service-count-stop {
  text-align: center;
  color: #9b9b9b;
  font-size: 12px;
}
.billing-compare-service-name {
  font-weight: 500;
}
.billing-compare-left .billing-compare-service-price {
  margin-left: 10px;
}
.billing-compare-service-price {
  color: #00AEEF;
  font-weight: 400;
}
.billing-compare-service-discount {
  font-size: 12px;
  color: #17c200;
  margin-left: 10px;
  font-weight: 400;
}
.billing-compare-service-max-count {
  text-align: center;
  color: #9b9b9b;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
}
.billing-hide-arrow svg {
  display: none;
}
.tariffs-not-enough-services,
.balance-cost-summarize {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tariffs-not-enough-services .balance-cost-summarize-table-line:nth-child(odd),
.balance-cost-summarize .balance-cost-summarize-table-line:nth-child(odd) {
  background-color: #fafafa;
}
.balance-cost-summarize-table-line {
  display: flex;
  padding: 10px;
  background-color: #e8e8e8;
  font-weight: 500;
}
.balance-cost-summarize-table-line > div:first-child {
  width: 320px;
  padding-right: 15px;
}
.balance-cost-summarize-table-line > div:last-child {
  width: 170px;
}
.billing-compare-right-only {
  display: flex;
}
.billing-compare-right-only div,
.billing-compare-right-only button {
  text-align: left !important;
}
.billing-compare-right-only .billing-compare-left {
  display: none;
}
.billing-compare-right-only .billing-compare-center {
  display: none;
}
.billing-compare-right-only .billing-compare {
  width: 50%;
}
.billing-compare-right-only .balance-cost-summarize {
  width: 50%;
  justify-content: center;
}
.balance-change-tariff-details {
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.balance-change-tariff-description {
  font-weight: 500;
  margin-bottom: 20px;
}
.billing-prolong-tariff-view .billing-compare-can-turn-on-list {
  display: none;
}
.billing-prolong-tariff-view .billing-compare-out-lease-list {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #2e8ae0;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
.proxima-tooltip {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-family: 'simple-line-icons';
  line-height: 1;
  font-size: 15px;
  font-weight: bold;
  color: #8d8d8d;
}
.proxima-tooltip.tooltip-small {
  font-size: 10px;
  top: -7px;
  left: -1px;
  color: #cfcfcf;
}
.proxima-tooltip:before {
  font-family: 'themify';
  content: "\e718";
}
.proxima-tooltip:hover {
  z-index: 1;
}
.proxima-tooltip:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}
.proxima-tooltip .tooltip_text {
  font-family: Rubik, sans-serif !important;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s ease-out;
  display: inline-block;
  width: 215px;
  height: auto;
  background-color: #f03f3c;
  color: #fff;
  font-size: 15px;
  padding: 9px 15px;
  position: absolute;
  font-weight: initial;
  text-transform: initial;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
}
.proxima-tooltip .tooltip_text:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}
.proxima-tooltip.tooltip-in-field {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.proxima-tooltip.tooltip-right .tooltip_text {
  left: 200%;
  transform: translate(0, -50%);
  top: 8px;
}
.proxima-tooltip.tooltip-right .tooltip_text:before {
  top: 50%;
  transform: translateY(-50%);
  left: -7px;
  border-width: 6px 7px 6px 0px;
  border-color: transparent #f03f3c transparent transparent;
}
.proxima-tooltip.tooltip-right.tooltip-small .tooltip_text {
  top: 5px;
}
.proxima-tooltip.tooltip-left .tooltip_text {
  right: 200%;
  transform: translate(0, -50%);
  top: 8px;
}
.proxima-tooltip.tooltip-left .tooltip_text:before {
  top: 50%;
  transform: translateY(-50%);
  right: -7px;
  border-width: 6px 0px 6px 7px;
  border-color: transparent transparent transparent #f03f3c;
}
.proxima-tooltip.tooltip-left.tooltip-small .tooltip_text {
  top: 5px;
}
.proxima-tooltip.tooltip-top .tooltip_text {
  bottom: 200%;
  transform: translate(-50%, 0);
  left: 8px;
}
.proxima-tooltip.tooltip-top .tooltip_text:before {
  left: 50%;
  transform: translateX(-50%);
  bottom: -7px;
  border-width: 7px 6px 0px 6px;
  border-color: #f03f3c transparent transparent transparent;
}
.proxima-tooltip.tooltip-top.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-bottom .tooltip_text {
  top: 200%;
  transform: translate(-50%, 0);
  left: 8px;
}
.proxima-tooltip.tooltip-bottom .tooltip_text:before {
  left: 50%;
  transform: translateX(-50%);
  top: -7px;
  border-width: 0px 6px 7px 6px;
  border-color: transparent transparent #f03f3c transparent;
}
.proxima-tooltip.tooltip-bottom.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-right-top .tooltip_text {
  transform: translate(-5%, 0);
  bottom: 200%;
}
.proxima-tooltip.tooltip-right-top .tooltip_text:before {
  left: 5%;
  transform: translateX(-50%);
  bottom: -7px;
  border-width: 7px 6px 0px 6px;
  border-color: #f03f3c transparent transparent transparent;
}
.proxima-tooltip.tooltip-right-top.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-left-top .tooltip_text {
  transform: translate(-95%, 0);
  bottom: 200%;
}
.proxima-tooltip.tooltip-left-top .tooltip_text:before {
  left: 95%;
  transform: translateX(-50%);
  bottom: -7px;
  border-width: 7px 6px 0px 6px;
  border-color: #f03f3c transparent transparent transparent;
}
.proxima-tooltip.tooltip-left-top.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-right-bottom .tooltip_text {
  transform: translate(-5%, 0);
  top: 200%;
}
.proxima-tooltip.tooltip-right-bottom .tooltip_text:before {
  left: 5%;
  transform: translateX(-50%);
  top: -7px;
  border-width: 0px 6px 7px 6px;
  border-color: transparent transparent #f03f3c transparent;
}
.proxima-tooltip.tooltip-right-bottom.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-left-bottom .tooltip_text {
  transform: translate(-95%, 0);
  top: 200%;
}
.proxima-tooltip.tooltip-left-bottom .tooltip_text:before {
  left: 95%;
  transform: translateX(-50%);
  top: -7px;
  border-width: 0px 6px 7px 6px;
  border-color: transparent transparent #f03f3c transparent;
}
.proxima-tooltip.tooltip-left-bottom.tooltip-small .tooltip_text {
  left: 5px;
}
.proxima-tooltip.tooltip-500 .tooltip_text {
  width: 500px;
}
.proxima-tooltip.tooltip-400 .tooltip_text {
  width: 400px;
}
.proxima-tooltip.tooltip-300 .tooltip_text {
  width: 300px;
}
.proxima-tooltip.tooltip-200 .tooltip_text {
  width: 200px;
}
.default_tooltips {
  position: relative;
  display: inline-block;
  right: -5px;
  top: -5px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .default_tooltips {
    top: 0;
  }
}
.default_tooltips.default_tooltips_white img,
.default_tooltips.help_box_white img {
  filter: brightness(200%);
}
.default_tooltips.default_tooltips_white .tooltip_text {
  background-color: #fff;
  border: 2px solid #f03f3c;
  color: #f03f3c;
  top: -22px;
}
.default_tooltips.default_tooltips_white .tooltip_text:before {
  border-color: transparent #fff transparent transparent;
}
.default_tooltips .tooltip_text {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: inline-block;
  width: 215px;
  height: auto;
  background-color: #f03f3c;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 9px 15px;
  position: absolute;
  left: 200%;
  top: -13px;
  z-index: 2;
  font-weight: initial;
  text-transform: initial;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1199px) {
  .default_tooltips .tooltip_text {
    display: none !important;
  }
}
.default_tooltips .tooltip_text:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 7px 6px 0;
  border-color: transparent #f03f3c transparent transparent;
}
.default_tooltips:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}
.input_default {
  box-shadow: inset 0.5px 0.87px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
}
@media (max-width: 576px) {
  .input_default {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }
}
.section-default {
  position: relative;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 30px 40px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}
@media (max-width: 576px) {
  .section-default {
    padding: 20px;
  }
}
.section-default__title {
  font-size: 21px;
  line-height: 1;
  font-weight: 500;
  padding-bottom: 23px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ecf0f4;
  text-transform: inherit;
}
@media (max-width: 767px) {
  .section-default__title {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .section-default__title {
    font-size: 16px;
    padding-bottom: 15px;
  }
}
.white-sector {
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 7px -2px #7f7f7f !important;
  box-shadow: 0px 0px 7px -2px #7F7F7F !important;
}
.white-sector textarea,
.white-sector [type="text"],
.white-sector [type="password"] {
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 12px;
}
.white-sector .horizontal-content {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.white-sector .vertical-content {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}
.white-sector .proxima-tooltip {
  margin-left: 10px;
  margin-top: 2px;
}
.white-sector [type="text"],
.white-sector [type="password"] {
  width: 100%;
}
.white-sector .sector-header-div {
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
  padding-bottom: 20px;
}
.white-sector .div-with-input {
  margin-right: 30px;
  width: 350px;
}
.white-sector .sector-field-description {
  font-weight: 500;
  margin-bottom: 10px;
}
.white-sector .next-checkbox-square {
  padding-left: 40px;
}
.white-sector .two-column-div {
  font-weight: 500;
  display: flex;
  margin-bottom: 10px;
}
.white-sector .two-column-div > div {
  display: flex;
  align-items: center;
}
.gradientSelect {
  background-color: #fff !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: -moz-linear-gradient(90deg, #f2f6f9 0%, #ffffff 31%);
  background-image: -webkit-linear-gradient(90deg, #f2f6f9 0%, #ffffff 31%);
  background-image: -ms-linear-gradient(90deg, #f2f6f9 0%, #ffffff 31%);
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  width: 100%;
  outline: none !important;
}
.gradientSelect:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.standardPaginationTable .paginationTableHeader {
  background-color: #657689;
  font-weight: 500;
  color: #fff;
}
.standardPaginationTable .paginationTableShowCounters {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px;
}
.standardPaginationTable .paginationTableShowCount,
.standardPaginationTable .paginationTableAllCount {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.standardPaginationTable .paginationTableShowMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.standardPaginationTable .paginationTableShowMoreCount {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.border2px {
  border: 2px solid #e8e8e8;
}
th {
  color: #666666;
  font-weight: 500;
}
.no-hover {
  pointer-events: none;
}
.anychart-credits {
  display: none;
}
.defaultStylePaginationTable .tableHeader {
  background-color: #657689;
  font-weight: 500;
  color: #fff;
}
.defaultStylePaginationTable .tableHeader .tableRow {
  border: none;
}
.defaultStylePaginationTable .tableRow {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
  font-weight: 500;
}
.defaultStylePaginationTable .tableCol {
  padding: 15px 8px;
  flex-basis: 100px;
}
.defaultStylePaginationTable .showedRecordsLine {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ea;
  line-height: 19px;
  position: relative;
}
.defaultStylePaginationTable .showedRecordsLine .refreshIcon {
  margin-left: 10px;
  height: 15px;
}
.defaultStylePaginationTable .showedRecordsLine .refreshIcon path {
  fill: #696969;
}
.defaultStylePaginationTable .showedRecordsLine .refreshButton .refreshIcon {
  height: 40px;
}
.defaultStylePaginationTable .showedRecordsLine .refreshButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 300ms linear 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 500;
  cursor: pointer;
  background-color: #657689;
  color: #fff;
}
.defaultStylePaginationTable .showedRecordsLine .refreshButton:hover {
  display: flex;
  opacity: 0.9;
}
.defaultStylePaginationTable .paginationTableShowMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ea;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.defaultStylePaginationTable .paginationTableShowCount,
.defaultStylePaginationTable .paginationTableAllCount,
.defaultStylePaginationTable .paginationTableShowMoreCount {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.defaultStylePaginationTable .paginationTableBody > div:nth-child(even) {
  background-color: #fafdff;
}
.defaultStylePaginationTable .paginationTableBody > div:nth-child(odd) {
  background-color: #f7fafc;
}
.defaultStylePaginationTable .paginationTableBody .paginationTableItem {
  display: flex;
  position: relative;
  font-weight: 400;
  border-bottom: 1px solid #e4e7ea;
}
.defaultStylePaginationTable .paginationTableBody .paginationTableItem:hover {
  background-color: #f5f5f5;
}
@-webkit-keyframes flashTable {
  from,
  100%,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes flashTable {
  from,
  100%,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.animateFlashPaginationTable {
  -webkit-animation-name: flashTable;
  animation-name: flashTable;
}
.api-block .small-block-content {
  height: 110px;
}
.api-key__input {
  width: 332px;
}
@media (max-width: 767px) {
  .api-key__input {
    width: 100%;
  }
}
.d__flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.api-transactions-webhook-url,
.api-key-field,
.webhook-token-field {
  height: 50px !important;
}
.api-transactions-webhook-url {
  width: 600px;
}
.api-key-field,
.webhook-token-field {
  width: 380px;
}
@media (max-width: 800px) {
  .api-key-field,
  .webhook-token-field {
    width: 100%;
  }
}
.api-button-save {
  margin-bottom: 20px;
}
.generate-webhook-url-div > div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.generate-webhook-url-div > div .bootstrap-select > .dropdown-toggle {
  width: 280px;
}
.generate-webhook-url-div > div > div:first-child {
  width: 100px;
}
.generate-webhook-url-div > div .generate-webhook-url-input {
  height: 50px !important;
}
.generate-webhook-url-div .generate-webhook-url-buttons-div {
  width: 380px;
}
.generate-webhook-url-div .generate-webhook-url-buttons-div .generate-webhook-copy-url-button {
  margin-left: auto;
}
.leadvertexIntegrationInstructionDiv,
.api-trigger-action-instruction-div {
  position: absolute;
  right: 40px;
  margin-bottom: 10px;
}
@media (max-width: 1100px) {
  .leadvertexIntegrationInstructionDiv,
  .api-trigger-action-instruction-div {
    position: static;
  }
}
.leadvertexIntegrationInstructionDiv {
  top: 40px;
}
.api-trigger-action-block-template {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .api-trigger-action-block-template {
    flex-direction: column;
  }
  .api-trigger-action-block-template .api-trigger-action-event-div {
    margin-bottom: 5px;
  }
}
.api-trigger-action-block-template .api-trigger-action-event-div {
  display: flex;
  width: 350px;
}
.apiTriggerActionBlockWithOuterPhoneSelectTemplate {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .apiTriggerActionBlockWithOuterPhoneSelectTemplate {
    flex-direction: column;
  }
  .apiTriggerActionBlockWithOuterPhoneSelectTemplate .api-trigger-action-event-div {
    margin-bottom: 5px;
  }
}
.apiTriggerActionBlockWithOuterPhoneSelectTemplate .api-trigger-action-event-div {
  display: flex;
  width: 350px;
}
.api-trigger-action-template {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.api-trigger-action-template .api-trigger-action-select {
  width: 500px;
}
.api-trigger-action-template .api-trigger-action-select .btn-default,
.api-trigger-action-template .api-trigger-action-select .btn-default.disabled {
  width: 500px;
}
@media (max-width: 650px) {
  .api-trigger-action-template .api-trigger-action-select {
    width: 360px;
  }
  .api-trigger-action-template .api-trigger-action-select .btn-default,
  .api-trigger-action-template .api-trigger-action-select .btn-default.disabled {
    width: 360px;
  }
}
.smsReceivingEventsHeader {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: 500;
  border-bottom: 1px solid #ecf0f4;
  margin-bottom: 20px;
}
.leadvertexIntegrationTemplate {
  margin-bottom: 20px;
}
.leadvertexIntegrationTemplate .leadvertexFieldDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.leadvertexIntegrationTemplate .leadvertexFieldDiv > div:first-child {
  width: 150px;
}
.leadvertexIntegrationTemplate .leadvertexFieldDiv input {
  width: 600px;
}
.leadvertexIntegrationTemplate .leadvertexWarningDiv {
  color: darkred;
}
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexStatusSelect,
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexTriggerActionSelect {
  width: 300px;
}
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexStatusSelect .btn-default,
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexTriggerActionSelect .btn-default,
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexStatusSelect .btn-default.disabled,
.leadvertexIntegrationTemplate .leadvertexStatusAndActionTemplate .leadvertexTriggerActionSelect .btn-default.disabled {
  width: 300px;
}
.tracking-not-connected {
  background-color: #bfbfbf;
}
.tracking-off .tracking-connected-icon,
.tracking-not-connected .tracking-connected-icon {
  display: none;
}
.tracking-off .tracking-off-icon {
  display: block !important;
}
.tracking-site-container {
  background-color: #fff;
}
.tracking-site-container .dropdown-single-button {
  height: 100%;
}
.tracking-site-container button {
  height: 40px;
}
.tracking-site-container .button_default {
  line-height: 40px;
}
.tracking-site-collapsible-div .webra-input-field {
  border: 1px solid #d1d1d1;
}
.tracking-one-unit-block {
  border-top: 1px solid #a0caf9;
  border-bottom: 1px solid #a0caf9;
  margin-bottom: 25px;
  margin-top: 10px;
}
.tracking-one-unit-block {
  padding: 15px;
}
/*настройка источника*/
.tracking-unit-source-info-div {
  display: flex;
  padding-bottom: 10px;
}
.tracking-unit-source-settings-div {
  padding-left: 180px;
}
.tracking-geo-span-desc {
  color: #7e7e7e;
  line-height: 40px;
  margin-left: 15px;
}
.tracking-unit-one-binding-number-title {
  display: flex;
  margin: 20px 0;
  margin-bottom: 10px;
}
.tracking-1-px-bord-top {
  border-top: 1px solid #d7d7d7;
}
.tracking-unit-one-binding-number-settings {
  padding-left: 180px;
}
.tracking-unit-one-binding-number-how-to-find {
  display: flex;
}
.tracking-span-main-action-description {
  font-weight: 500;
  line-height: 40px;
  color: #2a8be1;
}
.tracking-left-border {
  position: absolute;
  width: 5px;
  background-color: #2a8be1;
  height: 100%;
  left: 0;
  top: 0;
}
.tracking-invisible-border {
  position: absolute;
  width: 5px;
  background-color: #fff;
  /*background-color: #f461ef;*/
  height: 100%;
  left: -15px;
  top: 0;
  z-index: 1;
}
.tracking-yellow-border {
  position: absolute;
  width: 5px;
  background-color: #36af38;
  height: 100%;
  left: 160px;
  top: 0;
  z-index: 1;
}
.tracking-div-for-hide-elements > div {
  margin-top: 10px;
}
.tracking-div-for-black-filter {
  width: 100%;
}
.tracking-site-container .input-daterange-timepicker {
  border: 1px solid #000000;
}
.white-box {
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.white-box .box-title {
  margin: 0px 0px 12px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
}
.tracking-show-numbers-statistic li {
  list-style-type: none;
}
.tracking-show-numbers-statistic ul {
  padding-left: 10px;
}
.bitrix-connection-fields-div > div {
  margin-right: 15px;
}
.bitrix-field-name {
  font-weight: 500;
  margin-bottom: 5px;
}
.bitrix-operator-phone-input,
.bitrix-domain {
  height: 50px !important;
}
.bitrix-responsible-user-schedule > div > div {
  float: left;
}
.bitrix-responsible-user-schedule > div > div:first-child {
  width: 150px;
}
.bitrix-responsible-user-schedule > div > div:last-child {
  width: 350px;
  height: 27px;
}
.bitrix-responsible-user-schedule > div {
  margin: 15px;
}
.bitrix-settings-general > div > div:first-child {
  width: 350px;
}
.bitrix-settings-general > div > div:last-child {
  width: 200px;
}
.bitrix-settings-general > div {
  margin: 15px;
}
.bitrix-settings-general > div:last-child {
  margin-bottom: 0;
}
.bitrix-operator-location-item > div {
  width: 200px;
  float: left;
}
.bitrix-operator-location-item > div:last-child {
  margin-left: 15px;
}
.bitrix-operator-location-item > div {
  width: auto;
  float: none;
}
.bitrix-operator-location-item > div:last-child {
  margin-left: auto;
}
@media (max-width: 991px) {
  .connection-settings__item {
    margin-bottom: 15px;
  }
}
.connection-settings__button {
  margin-top: 25px;
}
@media (max-width: 767px) {
  .connection-settings__button .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.bitrix-settings__item {
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .bitrix-settings__item {
    display: block;
  }
}
@media (max-width: 991px) {
  .bitrix-settings__item .nextel-radio {
    margin-top: 10px;
  }
}
.bitrix-settings__item div:first-child {
  width: 300px;
}
.bitrix-settings__item .nextel-radio {
  width: 200px;
}
.bitrix-responsible-use-outers-cb-div,
.bitrix-responsible-use-periods-cb-div {
  margin-bottom: 10px;
}
.bitrix-responsible-outers-responsible-div {
  margin-bottom: 30px;
}
.bitrix-responsible-schedule-time-range-div {
  display: flex;
  position: relative;
}
.bitrix-responsible-schedule-time-range-div span {
  line-height: 40px;
  display: block;
  margin-right: 5px;
  margin-left: 5px;
}
.bitrix-responsible-schedule-one-day-div,
.bitrix-responsible-schedule-one-period-div,
.bitrix-responsible-schedule-one-outer-div,
.bitrix-custom-fields-for-sites-item {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.bitrix-responsible-schedule-one-outer-div {
  display: flex;
  margin: 10px 0;
}
.bitrix-responsible-schedule-one-outer-div > div {
  margin: 10px;
}
.bitrix-responsible-schedule-one-outer-div .bitrix-outer-data-div {
  width: 200px;
  margin: 15px 10px 10px 20px;
}
.bitrix-responsible-schedule-one-outer-div .bitrix-responsible-schedule-outer-phone {
  font-weight: 500;
  font-size: 16px;
}
.bitrix-responsible-schedule-one-outer-div .bitrix-responsible-schedule-outer-phone-alias {
  font-size: 12px;
  color: #969696;
}
.bitrix-responsible-schedule-one-responsible-operators-block {
  display: flex;
}
@media (max-width: 1000px) {
  .bitrix-responsible-schedule-one-responsible-operators-block {
    flex-direction: column;
  }
}
.bitrix-responsible-schedule-one-responsible-operators-block .bitrix-responsible-operators-list-div > div:last-child {
  margin: 0;
}
.bitrix-responsible-schedule-one-responsible-operators-block .bitrix-add-responsible-to-list-button {
  padding-top: 6px;
}
.bitrix-responsible-schedule-one-day-div {
  padding: 10px;
  margin: 10px 0;
}
.bitrix-responsible-schedule-one-day-div > div {
  margin: 10px 0;
}
.bitrix-responsible-schedule-one-day-div .bitrix-responsible-schedule-day-name {
  padding-top: 6px;
}
.bitrix-responsible-schedule-one-day-div .bitrix-responsible-schedule-day-main-div {
  display: flex;
  margin: 10px;
}
.bitrix-responsible-schedule-one-day-div .bitrix-responsible-schedule-day-main-div > div {
  margin: 0 5px;
}
.bitrix-responsible-schedule-one-day-div .bitrix-responsible-schedule-day-main-div .bitrix-responsible-schedule-day-name {
  margin-right: 15px;
}
.bitrix-responsible-schedule-one-day-div .bitrix-responsible-schedule-day-name {
  font-weight: 500;
  font-size: 25px;
}
.bitrix-responsible-schedule-one-period-div {
  display: flex;
  background: #f4f7f9;
  padding: 5px 10px;
  margin: 10px;
}
.bitrix-responsible-schedule-one-period-div > div {
  margin: 5px;
}
.bitrix-responsible-schedule-one-period-div .bitrix-responsible-schedule-period-remove-button,
.bitrix-responsible-schedule-one-period-div .bitrix-responsible-schedule-time-range-div,
.bitrix-responsible-schedule-one-period-div .bitrix-responsible-operator-remove-button {
  padding-top: 6px;
}
.bitrix-responsible-schedule-one-period-div .webra-input-field {
  height: 40px;
  border: 1px solid #d4d4d4 !important;
}
@media (max-width: 1300px) {
  .bitrix-responsible-schedule-one-period-div {
    flex-direction: column;
  }
}
.bitrix-responsible-schedule-one-responsible-div {
  display: flex;
  margin-bottom: 5px;
}
.bitrix-responsible-operator-remove-button {
  padding-top: 6px;
}
.bitrix-day {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .bitrix-day {
    justify-content: flex-start;
  }
}
.bitrix-day__title {
  margin-right: 15px;
  font-weight: 500;
}
.bitrix-day__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 320px);
  grid-gap: 15px;
}
@media (max-width: 991px) {
  .bitrix-day__wrapper {
    grid-template-columns: repeat(1, 320px);
  }
}
.bitrix-day .select-default {
  width: 250px;
}
@media (max-width: 767px) {
  .bitrix-day .select-default {
    width: 180px;
  }
}
.inner-numbers__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 450px);
  grid-column-gap: 150px;
  grid-row-gap: 15px;
}
@media (max-width: 1440px) {
  .inner-numbers__wrapper {
    grid-template-columns: repeat(2, 400px);
  }
}
@media (max-width: 991px) {
  .inner-numbers__wrapper {
    grid-template-columns: repeat(1, 400px);
  }
}
@media (max-width: 767px) {
  .inner-numbers__wrapper {
    grid-template-columns: repeat(1, 100%);
  }
}
.inner-numbers__wrapper .input_default {
  width: 250px;
}
@media (max-width: 767px) {
  .inner-numbers__wrapper .input_default {
    width: 100%;
  }
}
.inner-numbers__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .inner-numbers__item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}
.inner-numbers__item span {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .inner-numbers__item span {
    display: flex;
    margin-bottom: 5px;
  }
}
.pipelines-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .pipelines-item {
    flex-direction: column;
    align-items: flex-start;
  }
}
.pipelines-item__title {
  width: 200px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .pipelines-item__title {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.pipelines-item .select-default {
  width: 250px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .pipelines-item .select-default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.bitrixDivForDealFields,
.bitrixDivForContactFields,
.bitrixDivForLeadFields {
  display: flex;
  flex-wrap: wrap;
}
.bitrixDivForFieldsForSites > div {
  margin: 5px;
}
.bitrixOneField {
  width: 400px;
  padding: 5px;
}
.bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #00AEEF;
}
.bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.bitrixOneField .bitrixFieldSettingDiv {
  height: 50px;
}
.bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.select-default .btn-default,
.select-default .btn-default.disabled {
  background-color: #fff !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: -moz-linear-gradient(90deg, #f2f6f9 0, #fff 31%);
  background-image: -webkit-linear-gradient(90deg, #f2f6f9 0, #fff 31%);
  background-image: -ms-linear-gradient(90deg, #f2f6f9 0, #fff 31%);
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
}
@media (max-width: 576px) {
  .select-default .btn-default,
  .select-default .btn-default.disabled {
    height: 40px;
    font-size: 13px;
  }
}
.select-default .btn-default.disabled:focus,
.select-default .btn-default:focus {
  background-color: #fff !important;
  outline: 0 !important;
}
.select-default .bootstrap-select.form-control {
  height: 50px;
}
.select-default .form-group {
  margin-bottom: 0 !important;
}
@media (max-width: 576px) {
  .select-default .bootstrap-select.form-control {
    height: 40px;
  }
}
.selected_text {
  font-size: 15px;
  color: #ec3b41;
  font-weight: 500;
  padding: 0 3px;
}
@media (max-width: 576px) {
  .selected_text {
    font-size: 13px;
  }
}
.d__flex-inline {
  display: inline-flex !important;
}
.d__flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bitrixSupportGetJsonControlsDiv {
  display: flex;
}
.bitrixSupportGetJsonControlsDiv > div {
  margin-right: 10px;
}
.bitrixSupportGetJsonControlsDiv .bitrixSupportIdInput,
.bitrixSupportGetJsonControlsDiv .bitrixSupportEntityTypeSelect {
  height: 50px;
  width: 250px;
}
.bitrixSupportEntityJsonDiv {
  margin-top: 10px;
  padding: 10px;
}
.ga-site-container {
  margin-bottom: 20px;
}
.ga-site-container .ga-site-settings-div {
  line-height: 30px;
}
.ga-site-container .ga-site-labels > div > div:first-child {
  width: 150px;
}
.ga-site-container .ga-site-labels > div > div:nth-child(2) {
  width: 300px;
}
.ga-site-container .ga-site-labels > div > div:nth-child(3) {
  width: 300px;
}
.ga-site-container .ga-site-labels {
  border: none;
}
.ga-site-container .ga-site-labels .next-input {
  width: 280px;
}
.ga-site-container .ga-site-labels > div > div {
  height: 55px;
  margin-right: 20px;
}
.ga-site-container .out-line-static-tracking-save-button {
  line-height: 45px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.static-tracking-phone {
  -webkit-box-shadow: 0px 0px 7px -2px #7e7e7e;
  box-shadow: 0px 0px 7px -2px #7e7e7e;
  padding: 10px;
}
.static-tracking-phone .webra-input-field {
  border: 2px solid #e8e8e8;
}
.static-tracking-phone .static-tracking-description {
  position: absolute;
  left: 8px;
  top: 8px;
  font-size: 13px;
  font-weight: 500;
}
.static-tracking-phone .ga-inputs-div {
  flex-grow: 1;
  position: relative;
}
.static-tracking-phone .ga-inputs-div > div {
  margin-right: 15px;
}
.static-tracking-phone .ga-inputs-div > div:last-child {
  margin-right: 0px;
}
.static-tracking-phone .ga-inputs-div .firstParametersRow {
  display: flex;
}
.static-tracking-phone .ga-inputs-div .firstParametersRow > div {
  width: 180px;
  margin-right: 15px;
  margin-bottom: 7px;
  margin-top: 7px;
}
.static-tracking-phone .ga-inputs-div .secondParametersRow {
  margin-top: 15px;
}
.static-tracking-phone .ga-inputs-div .static-tracking-event-parameter {
  margin-right: 15px;
}
.static-tracking-phone .ga-inputs-div .out-line-static-tracking-save-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 75px;
  height: 45px;
  line-height: 45px;
}
.static-tracking-phone .ga-inputs-div .input-placeholder {
  color: #65b2ff;
  font-size: 12px;
  position: absolute;
  margin-top: -14px;
  margin-left: 5px;
}
.ga-static-tracking-header,
.ga-dynamic-tracking-header {
  background-color: #fff;
  margin-bottom: 20px;
  text-align: center;
}
.ga-static-tracking-header .ga-bottom-red-line,
.ga-dynamic-tracking-header .ga-bottom-red-line {
  background-color: #e33351;
  height: 4px;
}
.ga-4-static-tracking-phone.disable-block {
  pointer-events: none;
  opacity: 0.4;
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
.click2call-site-container {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.click2call-site-container button {
  height: 40px;
}
.click2call-site-container .webra-input-field {
  height: 40px;
  border: 1px solid #d4d4d4 !important;
}
.click2call-site-container .button_default {
  line-height: 40px;
}
.click2call-site-not-connected .click2call-site-connected-status,
.click2call-site-not-connected .click2call-site-pro-activated-status,
.click2call-site-not-connected .click2call-site-pro-left-status,
.click2call-site-not-connected .click2call-site-disabled-status {
  display: none !important;
}
.click2call-site-not-connected .big-header {
  background-color: #bfbfbf;
}
.click2call-schedule-time-range-div {
  display: flex;
  position: relative;
}
.click2call-schedule-time-range-div span {
  line-height: 40px;
  display: block;
  margin-right: 5px;
  margin-left: 5px;
}
.input-time2 {
  position: relative;
  width: 90px;
}
.input-time2:before {
  content: '';
  position: absolute;
  top: 12px;
  right: 11px;
  width: 16px;
  height: 16px;
  z-index: 9;
  background: url(../img/svg/clock.svg) #fff;
  -webkit-background-size: contain;
  background-size: contain;
}
.click2call-schedule-div {
  display: flex;
  flex-wrap: wrap;
}
.click2call-schedule-one-day {
  margin-right: 20px;
  background: #ffffff;
  padding: 10px;
  padding-top: 0px;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 220px;
}
.click2call-span-regular-line {
  line-height: 40px;
  font-weight: 500;
}
.click2call-auto-blocked-ip {
  position: relative;
  display: flex;
  width: 150px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 7px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.click2call-visual-settings-div-for-button-positioning {
  height: 600px;
  position: relative;
  overflow: hidden;
}
.click2call-visual-settings-div-for-button-positioning * {
  zoom: 1 !important;
}
.click2call-visual-settings-div-for-button-positioning #nextel-click-to-call-button-template {
  display: inline-block;
  cursor: move;
}
.click2call-visual-settings-div-for-button-positioning #nextel-click-to-call-button-template .nextel-click-to-call-desktop-button-background-div {
  position: unset;
}
.click2call-visual-settings-div-for-button-positioning .click2call-visual-settings-div-for-button-positioning-img {
  position: absolute;
  width: 1000px;
  height: 600px;
}
.click2call-visual-settings-desktop-examples .nextel-click-to-call-desktop-button-background-div {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
}
.click2call-visual-settings-desktop-examples > div > div {
  margin-top: 25px;
  margin-left: 15px;
}
@media (max-width: 1370px) {
  .click2call-visual-settings-desktop-examples {
    zoom: 0.9;
  }
}
@media (max-width: 1700px) {
  .click2call-visual-settings-desktop-examples .click2call-visual-settings-footer-examples .nextel-click-to-call-div-for-logo {
    left: -90px !important;
  }
}
@media (max-width: 1570px) {
  .click2call-visual-settings-desktop-examples .click2call-visual-settings-footer-examples .nextel-click-to-call-div-for-logo {
    left: -50px !important;
  }
}
.click2call-visual-settings-desktop-examples .nextel-click-to-call-footer-div {
  position: relative;
}
.click2call-visual-settings-desktop-examples .nextel-click-to-call-sidebar-div {
  position: absolute;
}
.click2call-visual-settings-desktop-examples .nextel-click-to-call-popup-div {
  position: relative;
  transform: unset;
  left: 0;
}
.click2call-visual-settings-sidebar-examples {
  display: flex;
  flex-wrap: wrap;
}
.click2call-visual-settings-sidebar-examples > div {
  height: 800px;
  width: 420px;
  position: relative;
}
.click2call-visual-settings-popup-examples {
  display: flex;
  flex-wrap: wrap;
}
.click2call-visual-settings-popup-examples > div {
  margin-right: 20px;
  margin-bottom: 20px;
}
.click2call-visual-settings-parameters {
  border-right: 1px solid #cfcfcf;
  width: 320px;
}
@media (max-width: 1370px) {
  .click2call-visual-settings-parameters {
    width: 250px !important;
  }
  .click2call-visual-settings-parameters .button_default {
    font-size: 14px;
  }
}
.click2call-visual-settings-parameters .click2call-visual-settings-color-picker-button {
  width: 40px;
  height: 40px;
  border: 1px solid #cfcfcf;
}
.click2call-visual-settings-parameters .click2call-visual-settings-one-color {
  display: flex;
  margin-bottom: 20px;
}
.click2call-visual-settings-parameters .click2call-visual-settings-one-color span {
  font-weight: 500;
}
.click2call-visual-settings-parameters .click2call-visual-settings-one-color > div:nth-child(1) {
  margin-right: 10px;
}
.click2call-visual-settings-parameters .click2call-visual-settings-one-color > div:nth-child(2) span {
  display: inline-flex;
  align-items: center;
  height: 100%;
}
.click2call-visual-settings-field-desc {
  margin-bottom: 10px;
}
.click2call-visual-settings-field-desc > span {
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
}
.click2call-visual-settings-section {
  padding: 5px 10px 10px 20px;
  border-bottom: 1px solid #cfcfcf;
  position: relative;
  margin-bottom: 1px;
}
.click2call-visual-settings-text > div:first-child {
  margin-top: 10px;
}
.click2call-visual-settings-text > div:first-child > span {
  font-weight: 500;
}
.click2call-textarea-resizable {
  resize: none;
  overflow: hidden;
  min-height: 75px;
  height: 75px;
  max-height: 200px;
  width: 100%;
  font-weight: 500;
  background-color: #fff;
  font-size: 16px;
  color: #535353;
  padding: 5px 5px 5px 10px;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  border: 2px solid #c0cbd5;
  border-radius: 2px;
}
.click2call-visual-tabs > .tabs-style-bar > nav {
  padding-right: 53px;
  height: 48px;
  border: none;
}
.click2call-visual-tabs > .tabs-style-bar > nav ul,
.click2call-visual-tabs > .tabs-style-bar > nav a {
  height: 48px;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab {
  position: relative;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab a {
  padding: 5px 65px 5px 20px;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab .click2call-tab-remove-button {
  position: absolute;
  top: 14px;
  right: 10px;
  display: none;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab .click2call-tab-edit-button {
  position: absolute;
  top: 14px;
  right: 40px;
  display: none;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab .click2call-tab-change-name {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px !important;
  vertical-align: middle;
  color: #686868;
  font-weight: 600 !important;
  height: 35px;
  text-align: center;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab-name {
  font-weight: 500 !important;
  font-family: Rubik, sans-serif !important;
}
.click2call-visual-tabs > .tabs-style-bar > nav li a:hover {
  background-color: #e9e8f1;
  color: #000 !important;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current:hover a {
  background-color: #e9e8f1;
  color: #fff !important;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current a {
  color: #fff;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current a:after {
  content: none;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current .click2call-tab-edit-button,
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current .click2call-tab-remove-button {
  display: block;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current.hide-buttons .click2call-tab-edit-button,
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current.hide-buttons .click2call-tab-remove-button {
  display: none;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-tab-list li {
  cursor: pointer !important;
}
.click2call-visual-tabs > .tabs-style-bar > nav .tab-current a {
  background: #308bec !important;
  cursor: default !important;
  margin: 0 !important;
}
.click2call-visual-tabs > .tabs-style-bar > nav .click2call-add-visual-setup {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background: #308bec;
  padding: 8px;
  cursor: pointer;
}
.click2call-setup-filters {
  padding: 20px 20px 20px;
  display: none;
}
.source-filters-div-for-filters-buttons > div {
  width: 420px;
  position: relative;
}
.source-filters-div-for-filters-buttons > div .proxima-tooltip {
  color: #fff;
}
.source-filters-div-for-filters-buttons button {
  width: 100%;
  display: flex;
  justify-content: left;
}
@media (max-width: 1370px) {
  .source-filters-div-for-filters-buttons > div {
    width: 350px;
  }
  .source-filters-div-for-filters-buttons button {
    font-size: 14px;
  }
}
.click2call-premium-only,
.click2call-pro-only {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 10;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
  cursor: pointer;
  display: none;
}
.click2call-premium-only .fa-unlock,
.click2call-pro-only .fa-unlock {
  display: none;
}
.click2call-premium-only:hover .fa-lock,
.click2call-pro-only:hover .fa-lock {
  display: none;
}
.click2call-premium-only:hover .fa-unlock,
.click2call-pro-only:hover .fa-unlock {
  display: inline-block;
}
.click2call-premium-only div,
.click2call-pro-only div {
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
@media (max-width: 1370px) {
  .click2call-premium-only div,
  .click2call-pro-only div {
    font-size: 20px;
  }
}
.click2call-premium-only span,
.click2call-pro-only span {
  margin-left: 10px;
}
.click2call-not-PRO .click2call-pro-only {
  display: block;
}
.click2call-not-PRO .click2call-visual-settings-div-for-mobile-mockup-first #nextel-click-to-call-button-template,
.click2call-not-PRO .click2call-visual-settings-div-for-button-positioning #nextel-click-to-call-button-template {
  cursor: not-allowed !important;
}
.click2call-not-PRO .click2call-visual-settings-pro-position-tip {
  display: block;
}
.click2call-not-PRO .click2call-visual-settings-div-for-mobile-mockup-first .click2call-visual-settings-pro-position-tip {
  left: 10px;
  right: 10px;
  bottom: 10px;
}
.click2call-not-PREMIUM .click2call-premium-only {
  display: block;
}
.click2call-examples-sticky-header {
  padding: 0;
  position: sticky;
  top: 65px;
  width: 100%;
}
.click2call-device-select-tabs .click2call-device-select-tab {
  cursor: pointer;
  font-weight: 500 !important;
}
.click2call-device-select-tabs .click2call-device-select-tab :hover {
  color: #38769b !important;
}
.click2call-device-select-tabs .click2call-device-select-tab a {
  padding: 10px !important;
}
.click2call-device-select-tabs .click2call-device-select-tab path {
  fill: #5d5d5d;
}
.click2call-device-select-tabs .click2call-device-select-tab span,
.click2call-device-select-tabs .click2call-device-select-tab svg {
  vertical-align: middle !important;
}
.click2call-device-select-tabs .click2call-device-select-tab svg {
  margin-right: 10px;
}
.click2call-device-select-tabs .click2call-device-select-tab a::after {
  background: #38769b !important;
}
.click2call-device-select-tabs .tab-current {
  cursor: default;
}
.click2call-device-select-tabs .tab-current a {
  color: #5d5d5d !important;
}
.click2call-device-select-tabs nav {
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
}
.click2call-visual-settings-mobile-position-setup-div {
  position: absolute;
  background-color: #fff;
  top: 104px;
  left: 14px;
  width: 335px;
  height: 593px;
}
.click2call-visual-settings-div-for-mobile-mockup-first,
.click2call-visual-settings-div-for-mobile-mockup-second,
.click2call-visual-settings-div-for-mobile-mockup-third {
  position: relative;
  overflow: hidden;
  content: '';
  width: 100%;
  height: 100%;
}
.click2call-visual-settings-div-for-mobile-mockup-first *,
.click2call-visual-settings-div-for-mobile-mockup-second *,
.click2call-visual-settings-div-for-mobile-mockup-third * {
  zoom: 1 !important;
}
.click2call-visual-settings-div-for-mobile-mockup-first #nextel-click-to-call-button-template,
.click2call-visual-settings-div-for-mobile-mockup-second #nextel-click-to-call-button-template,
.click2call-visual-settings-div-for-mobile-mockup-third #nextel-click-to-call-button-template {
  display: inline-block;
}
.click2call-visual-settings-div-for-mobile-mockup-first #nextel-click-to-call-button-template .nextel-click-to-call-mobile-button-background-div,
.click2call-visual-settings-div-for-mobile-mockup-second #nextel-click-to-call-button-template .nextel-click-to-call-mobile-button-background-div,
.click2call-visual-settings-div-for-mobile-mockup-third #nextel-click-to-call-button-template .nextel-click-to-call-mobile-button-background-div {
  position: unset;
  display: inline-flex;
}
.click2call-visual-settings-div-for-mobile-mockup-first .click2call-visual-settings-mobile-site-img,
.click2call-visual-settings-div-for-mobile-mockup-second .click2call-visual-settings-mobile-site-img,
.click2call-visual-settings-div-for-mobile-mockup-third .click2call-visual-settings-mobile-site-img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.click2call-visual-settings-div-for-mobile-mockup-first #nextel-click-to-call-button-template {
  z-index: 1;
  cursor: move;
}
.click2call-visual-settings-div-for-mobile-mockup-second .nextel-click-to-call-mobile-popup,
.click2call-visual-settings-div-for-mobile-mockup-third .nextel-click-to-call-mobile-popup {
  position: absolute;
}
.click2call-visual-settings-div-for-mobile-mockup-second .nextel-click-to-call-full-screen-shadow,
.click2call-visual-settings-div-for-mobile-mockup-third .nextel-click-to-call-full-screen-shadow {
  display: block !important;
  position: absolute !important;
}
.click2call-visual-settings-div-for-mobile-mockup-second .nextel-click-to-call-mobile-popup,
.click2call-visual-settings-div-for-mobile-mockup-third .nextel-click-to-call-mobile-popup {
  display: flex !important;
}
.click2call-visual-settings-mobile-examples {
  padding: 10px;
  display: none;
  flex-wrap: wrap;
}
.click2call-visual-settings-mobile-examples > div {
  margin-left: 15px;
  margin-top: 15px;
}
.click2call-visual-settings-div-for-mobile-animations > div > div span,
.click2call-visual-settings-animation-chain > div > div span {
  font-weight: 500;
}
.click2call-visual-settings-animation-chain {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 15px;
  padding-right: 0;
}
.click2call-visual-settings-one-animation {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  min-width: 270px;
}
.click2call-visual-settings-div-for-desktop-animation-chain {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1570px) {
  .click2call-visual-settings-div-for-desktop-animation-chain {
    flex-direction: row;
  }
}
.click2call-show-mode-button-select span {
  font-weight: 500;
  font-size: 13px;
}
.click2call-visual-settings-pro-position-tip {
  position: absolute;
  bottom: 50px;
  left: 300px;
  background-color: #fff;
  padding: 10px;
  z-index: 1;
  border-radius: 20px;
  display: none;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 1370px) {
  .click2call-visual-settings-section .click2call-visual-settings-one-animation {
    min-width: 225px;
  }
  .click2call-visual-settings-section .click2call-visual-settings-add-mobile-animation-button {
    width: 210px !important;
  }
}
.click2call-border-with-description {
  border: 1px solid #a1a1a1;
  padding-top: 15px;
  padding-left: 10px;
  position: relative;
}
.click2call-border-with-description .click2call-border-description {
  position: absolute;
  top: -10px;
  left: 10px;
  color: #8C8C8C;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 13px;
}
.click2call-one-sip {
  margin-top: 10px;
}
.click2call-call-to-sip-div .click2call-one-sip:first-child {
  margin-top: 0;
}
.unavailable-background {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.7);
  z-index: 1001;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
  cursor: pointer;
  display: block !important;
}
.unavailable-background div {
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
@media (max-width: 1370px) {
  .unavailable-background div {
    font-size: 20px;
  }
}
.unavailable-background span {
  margin-left: 10px;
}
.tab-current .tub_current_link svg path {
  fill: #fff;
}
.click2call-main-tabs > nav {
  border: none;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.click2call-main-tabs > nav ul li:first-child a {
  border-left: none;
}
.click2call-main-tabs > nav ul li:last-child a {
  border-right: none;
}
.click2call-main-tabs > nav ul li a {
  cursor: pointer;
  transition: all 0.5s ease !important;
}
.click2call-main-tabs > nav ul li a:hover {
  background-color: #fafafa;
}
.click2call-main-tabs > nav ul li a:after {
  background: linear-gradient(90deg, #cd3f4e 0%, #4471b3 100%);
}
.click2call-main-tabs > nav ul li a span {
  font-weight: 500;
  font-size: 20px !important;
}
.click2call-main-tabs .click2call-main-tabs-sections section {
  display: unset;
}
.tabs-style-underline nav {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.tabs-style-underline nav a {
  padding: 20px 0;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  color: #313131;
}
.tabs-style-underline nav li:last-child a {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}
.tabs-style-underline nav li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #fb9678;
  content: '';
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translate3d(0, 150%, 0);
  transform: translate3d(0, 150%, 0);
}
.tabs-style-underline nav li.tab-current a::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.click2call-visual-settings-desktop-examples .nextel-click-to-call-full-screen-shadow {
  display: none !important;
}
.click2call-site-container .irs--modern .irs-single,
.click2call-site-container .irs--modern .irs-min,
.click2call-site-container .irs--modern .irs-max {
  top: 5px;
  padding: 2px;
}
.auth-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  background-color: #ecf0f4;
}
@media (max-width: 767px) {
  .auth-wrapper {
    min-height: 100vh;
    height: auto;
    flex-direction: column;
    padding-bottom: 30px;
  }
}
.auth-wrapper * {
  font-family: 'Rubik', sans-serif;
  outline: none !important;
  color: #313131;
  font-weight: 400;
}
.auth-wrapper .bigCircle,
.auth-wrapper .smallCircle {
  display: none;
  position: fixed;
  opacity: 0.1;
}
.mfa-form .mfa-nav-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 20px;
}
.mfa-form .mfa-nav-buttons svg {
  transition: filter 0.2s ease-in-out;
}
.mfa-form .mfa-nav-buttons svg:hover {
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.4));
  elevation: above;
  cursor: pointer;
}
.mfa-form .email-method {
  display: flex;
  flex-direction: column;
}
.mfa-form .email-method .resendCodeButton {
  color: #e02e43;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 15px;
}
.mfa-form .email-method .resendCodeButton .disabled {
  pointer-events: none;
  color: #808080;
}
.auth-form-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  -webkit-background-size: contain, 100% 100%;
  background-size: contain, 100% 100%;
}
.auth-form-section .authLogo {
  width: 120px;
  height: 150px;
  margin-bottom: 20px;
}
.auth-form-section .passwordContainer {
  position: relative;
}
.auth-form-section .passwordContainer .showPasswordLogo {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: inline-block;
  width: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: #535353;
}
.auth-form-section .button {
  height: 50px;
  line-height: 48px;
  font-size: 17px;
  color: #fff;
  border: none;
  padding: 0 25px;
  font-weight: 500;
  background-color: #e02e43;
  width: 100%;
  text-transform: uppercase;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.auth-form-section .form {
  display: none;
  background-color: #fff;
  width: 520px;
  padding: 40px;
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .auth-form-section .form {
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .auth-form-section .form {
    width: 90%;
  }
}
.auth-form-section .form .header-text {
  font-size: 30px;
  text-transform: initial;
  font-weight: 400;
  margin: 0 0 30px 0;
}
.auth-form-section .form .registrationPhoneInput,
.auth-form-section .form .textInput,
.auth-form-section .form input[type="email"],
.auth-form-section .form .password,
.auth-form-section .form .repeat-password {
  width: 100%;
  padding-left: 23px;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
}
@media (max-height: 720px) {
  .auth-form-section .form .registrationPhoneInput,
  .auth-form-section .form .textInput,
  .auth-form-section .form input[type="email"],
  .auth-form-section .form .password,
  .auth-form-section .form .repeat-password {
    height: 40px;
  }
}
.auth-form-section .form .registrationPhoneInput,
.auth-form-section .form .textInput,
.auth-form-section .form input[type="email"] {
  margin-bottom: 20px;
}
@media (max-height: 720px) {
  .auth-form-section .form .registrationPhoneInput,
  .auth-form-section .form .textInput,
  .auth-form-section .form input[type="email"] {
    margin-bottom: 10px;
  }
}
.auth-form-section .form .registrationPhoneInput {
  padding-left: 60px;
}
.auth-form-section .form .iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}
.auth-form-section .form .footer {
  background-color: #fbfcfd;
}
.auth-form-section .form .footer .question {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.auth-form-section .form .footer .question a {
  color: #e02e43;
  font-weight: 500;
  font-size: 15px;
  text-decoration: underline;
  position: relative;
  display: inline-flex;
}
.auth-form-section .form .footer .question b {
  color: #e02e43;
  font-weight: 500;
}
.auth-form-section .form .footer .register-question a {
  padding-left: 62px;
}
.auth-form-section .form .footer .register.question {
  display: flex;
  justify-content: space-between;
}
.auth-form-section .form .wrong-login-or-password {
  text-align: center;
  padding-bottom: 10px;
}
.auth-form-section .form .wrong-login-or-password span {
  color: red;
  display: none;
}
.auth-form-section .form .wrong-login-or-password p {
  color: red;
}
.auth-form-section .form .wrong-code {
  text-align: center;
  padding-bottom: 10px;
}
.auth-form-section .form .wrong-code span {
  color: red;
  display: none;
}
.auth-form-section .form .wrong-code p {
  color: red;
}
.auth-form-section .form.wrong-auth-data input[type="text"],
.auth-form-section .form.wrong-auth-data input[type="password"] {
  box-shadow: inset 0 0 4px 0 #dd0700;
  border: 1px solid #dd0700;
}
.auth-form-section .form.wrong-auth-data .wrong-login-or-password span {
  display: unset;
}
.auth-form-section .form.wrong-auth-data .wrong-code span {
  display: unset;
}
.auth-form-section .form .forgot-pass {
  font-size: 14px;
  color: #3c3c3c;
  line-height: 1.2;
  transition: border-bottom-color 0.5s ease;
  text-decoration: none;
}
.auth-form-section .form .nextel-checkbox span:after {
  top: 4px;
  left: 6px;
  height: 10px;
  width: 12px;
}
.auth-form-section .form.document {
  max-height: 90%;
  overflow-y: scroll;
  width: 80%;
}
.auth-form-section .form.document .auth-form__title {
  font-size: 30px;
  text-transform: initial;
  font-weight: 400;
  margin: 0 0 30px 0;
}
.auth-form-section .form.document h3 {
  line-height: 30px;
  font-size: 21px;
  color: #313131;
  font-family: 'Rubik', sans-serif;
  margin: 10px 0;
  font-weight: 300;
}
.auth-form-section .form.document p {
  font-family: 'Rubik', sans-serif;
  color: #313131;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 0 10px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.auth-form-section .form.document a {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
}
.auth-form-section .form.document ol,
.auth-form-section .form.document ul {
  margin-top: 0;
  margin-bottom: 10px;
}
.auth-form-section .form.document ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.auth-form-section .form.document li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.auth-form-section .form input:-webkit-autofill,
.auth-form-section .form input:-webkit-autofill:hover,
.auth-form-section .form input:-webkit-autofill:focus,
.auth-form-section .form input:-webkit-autofill,
.auth-form-section .form textarea:-webkit-autofill,
.auth-form-section .form textarea:-webkit-autofill:hover,
.auth-form-section .form textarea:-webkit-autofill:focus,
.auth-form-section .form select:-webkit-autofill,
.auth-form-section .form select:-webkit-autofill:hover,
.auth-form-section .form select:-webkit-autofill:focus {
  border: 1px solid #e02e43;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
.auth-form-section .div-for-project-list {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 300px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
}
@media (max-width: 1366px) {
  .auth-form-section .div-for-project-list {
    padding-left: 100px;
    padding-right: 50px;
  }
}
@media (max-width: 1199px) {
  .auth-form-section .div-for-project-list {
    margin-top: 10%;
    height: 87%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media (max-width: 991px) {
  .auth-form-section .div-for-project-list {
    margin-top: 10%;
    height: 87%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.auth-form-section .div-for-project-list .projects-one-project {
  display: flex;
  background-color: #ffffff;
  padding: 25px;
  margin-bottom: 20px;
  margin-top: 20px;
  max-height: 90px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 3px;
  margin-left: 30px;
  box-shadow: 5px 5px 15px -3px rgba(0, 0, 0, 0.57);
  justify-content: space-between;
  position: relative;
  width: 700px;
}
.auth-form-section .div-for-project-list .projects-one-project:hover {
  margin-left: 0;
  margin-right: 30px;
}
.auth-form-section .div-for-project-list .projects-one-project-name {
  font-size: 35px;
  color: #30323e;
}
.auth-form-section .div-for-project-list .projects-one-project-role-desc {
  font-weight: 500;
  font-size: 20px;
}
.auth-form-section .div-for-project-list .projects-one-project-role {
  font-weight: 600;
  color: #c143d8;
  padding-left: 5px;
}
.auth-form-section .register-form input[type="text"].wrong,
.auth-form-section .register-form input[type="password"].wrong,
.auth-form-section .register-form .registrationPhoneInput.wrong,
.auth-form-section .register-form .checkmark.wrong {
  box-shadow: inset 0 0 4px 0 #dd0700;
  border: 1px solid #dd0700;
  position: relative;
}
.auth-form-section .register-form .checkmark {
  position: absolute !important;
}
.auth-form-section .register-form .enter-accepting-rules-div .next-checkbox-square .checkmark:after {
  left: 6px;
  top: 2px;
}
.auth-form-section .register-form .useGeneratedPasswordButton {
  cursor: pointer;
  color: #e02e43;
  font-weight: 500;
}
.auth-form-section .register-form .useGeneratedPasswordButton:hover {
  text-decoration: underline;
}
.auth-form-section .we-are-calling-img {
  display: none;
  justify-content: center;
  position: relative;
}
.auth-form-section .we-are-calling-img .textOnImage {
  width: 290px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.confirm-recover-pass-form .useGeneratedPasswordButton,
.register-form .useGeneratedPasswordButton {
  cursor: pointer;
  color: #e02e43;
  font-weight: 500;
}
.confirm-recover-pass-form .useGeneratedPasswordButton:hover,
.register-form .useGeneratedPasswordButton:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .auth-wrapper {
    padding-bottom: 0;
  }
  .auth-wrapper .form {
    width: 100%;
    padding: 20px;
  }
  .auth-wrapper .form.document {
    width: 100%;
    padding: 15px;
  }
  .auth-wrapper .form .repeat-password {
    padding-left: 7px !important;
  }
  .auth-wrapper .form .header-text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .auth-wrapper .form input[type="text"] {
    height: 45px;
    margin-bottom: 15px;
  }
  .auth-wrapper .form input[type="password"] {
    height: 45px;
  }
  .auth-wrapper .form .footer {
    background-color: #fff;
  }
  .auth-wrapper .enter-rules-div {
    flex-direction: column;
  }
  .auth-wrapper .register.question {
    display: flex;
    flex-direction: column;
  }
  .auth-wrapper .register.question span {
    margin-right: 0 !important;
  }
}
@media (max-height: 720px) {
  .auth-wrapper .form {
    padding: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .auth-wrapper .enterPasswordDesc {
    margin-bottom: 5px !important;
  }
}
@media (max-height: 600px) {
  .auth-wrapper .authLogo {
    display: none;
  }
}
.terms-of-use-form .nextel-radio span:after {
  left: 7px;
  top: 7px;
}
.terms-of-use-form .termsOfUseFormLangSelectRadio {
  margin-left: auto;
}
.terms-of-use-form-ukr,
.terms-of-use-form-rus {
  text-align: justify;
}
.terms-of-use-form-ukr ul,
.terms-of-use-form-rus ul {
  margin-left: 40px;
}
.auth-wrapper > .footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.auth-wrapper > .footer .form_auth_middle_link {
  color: #337ab7;
  text-decoration: none;
}
.auth-wrapper > .footer .form_auth_middle_link:hover,
.auth-wrapper > .footer .form_auth_middle_link:focus {
  color: #23527c;
  text-decoration: underline;
}
@media (max-height: 700px) {
  .auth-wrapper > .footer {
    display: none;
  }
}
.enterPageLangSelection {
  position: fixed;
  top: 0;
  left: 50%;
  display: flex;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  transform: translateX(-50%);
  z-index: 1;
}
.enterPageLangSelection .oneLang {
  border-right: #7F7F7F 1px solid;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.enterPageLangSelection .oneLang:last-child {
  border-right: none;
}
.enterPageLangSelection .oneLang.current {
  font-weight: 500;
  cursor: default;
}
.account-we-are-calling-img {
  display: none;
  justify-content: center;
  position: relative;
}
.account-we-are-calling-img .textOnImage {
  width: 290px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.projects-action-item {
  margin-bottom: 20px;
}
.projects-action-item .projects-action-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f03f3c;
  padding: 20px;
  cursor: pointer;
}
.projects-action-item .projects-action-title .projects-action-title-text {
  font-size: 21px;
  color: #fff;
  position: relative;
  line-height: 1.4;
}
.projects-action-item .webra-input-field {
  border: 1px solid #d1d1d1;
}
.projects-action-item .tariff-select {
  width: 100% !important;
  height: 100%;
}
.projects-action-item .projects-action-settings {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 50px;
  position: relative;
  display: none;
}
.projects-action-item .projects-action-settings .join-button,
.projects-action-item .projects-action-settings .create-button,
.projects-action-item .projects-action-settings .cancel-button {
  width: 150px;
  height: 40px;
  position: absolute;
  bottom: 15px;
}
.projects-action-item .projects-action-settings .new-project-description {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  font-weight: 400;
}
.projects-one-project {
  display: flex;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
  align-items: center;
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  cursor: default;
  position: relative;
}
.projects-one-project.can-be-choosen:hover {
  box-shadow: 12px 12px 25px -3px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.projects-one-project .red-line {
  background-color: red;
  width: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.projects-one-project .projects-one-project-name-div {
  width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.projects-one-project .projects-one-project-name {
  font-size: 30px;
  color: #30323e;
  margin-bottom: 25px;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.projects-one-project .projects-one-project-role-div {
  width: 200px;
}
.projects-one-project .projects-one-project-role,
.projects-one-project .projects-one-project-tariff,
.projects-one-project .projects-one-project-pay-to {
  font-weight: 600;
  color: #c143d8;
  padding-left: 5px;
}
.projects-one-project .projects-one-project-tariff-div {
  width: 250px;
}
.projects-one-project .projects-one-project-pay-to-div {
  width: 300px;
}
.projects-one-project .projects-remove-project-button-div,
.projects-one-project .projects-left-project-button-div {
  margin-left: auto;
  width: 100px;
}
@media (max-width: 1366px) {
  .projects-one-project .projects-one-project-role-div *,
  .projects-one-project .projects-one-project-pay-to-div *,
  .projects-one-project .projects-one-project-tariff-div * {
    display: block;
  }
}
@media (max-width: 1199px) {
  .projects-one-project .projects-one-project-name-div {
    width: 220px;
  }
  .projects-one-project .projects-one-project-name {
    font-size: 20px;
  }
}
.tariffMasterWrap {
  position: relative;
}
.tariffMasterWrap .masterPrevButton {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.tariffMasterWrap .masterNextButton {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.projectsTariffMaster {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.masterBlock {
  height: 450px !important;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.masterBlock.active {
  display: flex;
}
.masterBlock .masterQuestion {
  height: 50px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
  color: #c93c5f;
  text-align: center;
}
.projectsDirectionWrap {
  position: relative;
}
.projectsDirectionWrap .masterQuestion {
  position: absolute;
  top: 20px;
}
.projectsDirection {
  display: flex;
  justify-content: space-between;
  width: 900px;
}
.projectsDirection .service {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projectsDirection .service .serviceName {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
.projectsDirection .service .projectsDirectionImg {
  width: 200px;
  height: 200px;
  border-radius: 15px;
  box-shadow: 3px 2px 8px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.1s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectsDirection .service .projectsDirectionImg .tariffService {
  position: absolute;
  transition: all 0.1s ease;
}
.projectsDirection .service .projectsDirectionImg .tariffService.directionChoosen {
  width: 135px;
  height: 135px;
  transition: all 0.2s ease;
}
.projectsDirection .service .projectsDirectionImg .tariffService.directionChoosen path {
  transition: all 0.2s ease;
  fill: #f03f3c;
}
.projectsDirection .service .projectsDirectionImg .tariffsIcon {
  width: 85%;
}
.projectsDirection .service:hover .projectsDirectionImg {
  box-shadow: 5px 5px 10px 8px rgba(0, 0, 0, 0.05);
}
.projectsDirection .service:hover .projectsDirectionImg .tariffService {
  width: 115px;
  height: 115px;
}
.projectsDirection .service:hover .projectsDirectionImg .tariffService.directionChoosen {
  width: 135px;
  height: 135px;
}
.projectsNumbersCountDiv,
.projectsUsersCountDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectsNumbersCountDiv .numbersCountRadio,
.projectsUsersCountDiv .numbersCountRadio,
.projectsNumbersCountDiv .usersCountRadio,
.projectsUsersCountDiv .usersCountRadio {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.projectsNumbersCountDiv .numbersCountRadio .radioAndField,
.projectsUsersCountDiv .numbersCountRadio .radioAndField,
.projectsNumbersCountDiv .usersCountRadio .radioAndField,
.projectsUsersCountDiv .usersCountRadio .radioAndField {
  display: flex;
  align-items: center;
  height: 50px;
}
.projectsNumbersCountDiv .numbersCountRadio .radioAndField label,
.projectsUsersCountDiv .numbersCountRadio .radioAndField label,
.projectsNumbersCountDiv .usersCountRadio .radioAndField label,
.projectsUsersCountDiv .usersCountRadio .radioAndField label {
  width: 140px;
}
.projectsNumbersCountDiv .numbersCountRadio .radioAndField label span,
.projectsUsersCountDiv .numbersCountRadio .radioAndField label span,
.projectsNumbersCountDiv .usersCountRadio .radioAndField label span,
.projectsUsersCountDiv .usersCountRadio .radioAndField label span {
  font-weight: 500;
}
.projectsNumbersCountDiv .numbersCountRadio .radioAndField [type="text"],
.projectsUsersCountDiv .numbersCountRadio .radioAndField [type="text"],
.projectsNumbersCountDiv .usersCountRadio .radioAndField [type="text"],
.projectsUsersCountDiv .usersCountRadio .radioAndField [type="text"] {
  width: 150px;
}
.projectsSitesCountDiv {
  display: flex;
  justify-content: center;
}
.projectsSitesCountDiv .siteAskWrap {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 20px;
}
.projectsSitesCountDiv .siteAskWrap span {
  margin-right: 20px;
  font-weight: 500;
}
.projectsSitesCountDiv .siteAskWrap input {
  width: 100px;
}
.projectsTrackingDirectionDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projectsTrackingDirectionDiv button {
  margin-top: 10px;
  border: 5px;
}
.projectsTrackingDirectionDiv .trackingDirectionRadio {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.projectsTrackingDirectionDiv .trackingDirectionRadio .radioAndField {
  display: flex;
  align-items: center;
  height: 50px;
}
.projectsTrackingDirectionDiv .trackingDirectionRadio .radioAndField label span {
  font-weight: 500;
}
.projectsTrackingDiv {
  width: 80%;
}
@media (min-width: 1400px) {
  .projectsTrackingDiv {
    width: 65%;
  }
}
@media (min-width: 1800px) {
  .projectsTrackingDiv {
    width: 50%;
  }
}
.projectsTrackingDiv .trackingQuestionWithInput {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.projectsTrackingDiv .trackingQuestionWithInput > div:first-child {
  font-weight: 500;
  width: 70%;
}
.projectsTrackingDiv .trackingQuestionWithInput > div:first-child p {
  line-height: 1;
}
.projectsTrackingDiv .trackingQuestionWithInput > div:last-child {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.projectsTrackingDiv .trackingQuestionWithInput > div:last-child input {
  width: 150px;
}
.projectsMasterEndWrap .projectsResultDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projectsMasterEndWrap .tariffResultNameDiv {
  display: flex;
  line-height: 25px !important;
  justify-content: center;
}
.projectsMasterEndWrap .tariffResultNameDiv > div {
  display: flex;
  align-items: center;
}
.projectsMasterEndWrap .resultTariffDesc {
  font-weight: 500;
  font-size: 20px;
  margin-right: 20px;
}
.projectsMasterEndWrap .resultTariffName {
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  color: #5d5d5d;
}
.tariffResultServicesDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tariffResultServicesDiv .tariffResultServicesHeader {
  text-align: center;
  margin-top: 30px;
  color: #c93c5f;
  font-size: 13px;
  font-weight: 500;
}
.tariffResultServicesDiv .divForServices .oneService {
  display: flex;
  line-height: 25px;
  height: 50px;
}
.tariffResultServicesDiv .divForServices .oneService .oneServiceCount {
  color: #5d5d5d;
  font-size: 25px;
  font-weight: 500;
  width: 75px;
  position: relative;
}
.tariffResultServicesDiv .divForServices .oneService .oneServiceCount:before {
  content: '+';
  transform: rotate(45deg);
  position: absolute;
  top: -4px;
  right: 3px;
  font-weight: 300;
  font-size: 30px;
  color: #5d5d5d;
  line-height: 1;
}
.tariffResultServicesDiv .divForServices .oneService .oneServiceName {
  color: #262626;
  font-size: 20px;
  margin-left: 30px;
}
.projectsGeneralQuestions .projectsDivWithInput,
.projectsMasterCreateProjectWrap .projectsDivWithInput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.projectsGeneralQuestions .projectsDivWithInput > div:first-child,
.projectsMasterCreateProjectWrap .projectsDivWithInput > div:first-child {
  margin-left: 30px;
  width: 170px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  height: 43px;
}
.projectsGeneralQuestions .projectsDivWithInput > div:first-child .proxima-tooltip,
.projectsMasterCreateProjectWrap .projectsDivWithInput > div:first-child .proxima-tooltip {
  margin-left: 10px;
}
.projectsGeneralQuestions .projectsDivWithInput > div:last-child,
.projectsMasterCreateProjectWrap .projectsDivWithInput > div:last-child {
  width: 300px;
  font-weight: 500;
  font-size: 17px;
  height: 43px;
}
.projects-join-div .projects-action-title {
  background-color: #bb9390;
}
.new-project-name {
  font-size: 21px;
  padding: 5px 10px;
}
.new-project-promo {
  font-weight: 400;
}
.autodial-task-template,
.create-new-autodial-div {
  margin-bottom: 20px;
}
.autodial-main-div {
  position: relative;
}
.autodial-sector-header {
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
  font-size: 18px;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
.showMoreAutodialsButton {
  cursor: pointer;
}
.showMoreAutodialsButton,
.currentAutodialsCountDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 32px;
  margin-bottom: 20px;
  color: #12171d;
  border: #d8d8d8 1px solid;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.showMoreAutodialsButton > span,
.currentAutodialsCountDiv > span {
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
}
.autodial-primary-rounded-content-block {
  background-color: #fff;
  padding: 15px 32px;
  color: #12171d;
  border-top: #d8d8d8 1px solid;
}
.autodial-header {
  display: flex;
  align-items: center;
}
.autodial-header > div {
  margin: 0 5px;
}
.autodial-header div:first-child {
  margin-left: 0;
}
.autodial-header span {
  vertical-align: middle;
}
.autodial-header .autodial-progress-bar-div {
  width: 200px;
  height: 25px;
  background-color: #B0C4DE;
}
.autodial-header .autodial-progress-bar-div .autodial-progress-bar {
  height: 25px;
  box-shadow: none;
  text-align: center;
  padding-top: 2px;
}
.autodial-header .autodial-alert-icon-div {
  color: #f03f3c;
  font-size: 18px;
}
.autodial-header .autodial-collapse-button-div {
  margin-left: auto;
  margin-right: 32px;
}
.autodial-actions-div {
  display: flex;
  flex-wrap: wrap;
}
.autodial-actions-div .autodialShowDialCyclesStatistics,
.autodial-actions-div #autodial-button-clear-dial-cache,
.autodial-actions-div #autodial-button-clear-calls-history,
.autodial-actions-div .autodial-cyber-p-div {
  color: #0089ff;
}
.autodial-actions-div > div {
  margin-right: 10px;
  margin-bottom: 3px;
  margin-top: 3px;
  width: 230px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .autodialAddNumbersFromDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .autodialAddNumbersFromDiv > div:first-child {
  margin-right: 50px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 1100px) {
  .autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv {
    flex-direction: column-reverse;
  }
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv {
  margin-right: 100px;
  width: 400px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .autodialFileInputDiv {
  width: 400px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv > div > div:first-child {
  width: 140px;
  margin-right: 10px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakePhonesCountText,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeTooManyPhonesWarning,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeDateDiv {
  height: 40px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeTooManyPhonesWarning {
  color: darkred;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterOccupationsSelect,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterOperatorsSelect,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterCountrySelect,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterCityInput {
  width: 250px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterOccupationsSelect .bootstrap-select,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterOperatorsSelect .bootstrap-select,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterCountrySelect .bootstrap-select,
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterCityInput .bootstrap-select {
  width: 246px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .addNumbersInputsDiv .dataLakeFiltersDiv .dataLakeFilterCountrySelect {
  height: 54px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .highPriorityDiv {
  display: flex;
  margin-bottom: 10px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .autodial-number-duplicates-check-boxes-div > div {
  display: flex;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .autodial-number-duplicates-check-boxes-div label {
  margin-bottom: 5px;
}
.autodial-add-numbers-main-div .autodial-numbers-block-main-div .addNumbersAndRefreshDuplicatesDiv .autodial-numbers-block-buttons-div {
  margin-top: 15px;
  display: flex;
}
.autodial-all-data-div .dropdown-single-button,
.autodial-all-data-div .webra-input-field {
  height: 45px;
}
.autodial-all-data-div > div > div:last-child {
  margin-bottom: 10px;
}
.autodial-all-data-div .open-page-button {
  height: 45px;
  line-height: 45px;
  margin-left: 10px;
  width: 250px;
}
.autodial-all-data-div .autodialRemoveAutodialCallsHistoryAfterDaysBlock {
  display: flex;
  align-items: center;
}
.autodial-all-data-div .autodialRemoveAutodialCallsHistoryAfterDaysBlock > div {
  margin-right: 10px;
}
.autodial-all-data-div .autodialMiaSelect,
.autodial-all-data-div .autodial-type-select,
.autodial-all-data-div .autodial-group-select {
  width: 320px;
}
.autodial-all-data-div .autodial-guaranteed-dial-operators-alert-div {
  margin-top: 10px;
  font-weight: 500;
  color: indianred;
}
.autodial-all-data-div .autodial-trigger-action-block-template {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .autodial-all-data-div .autodial-trigger-action-block-template {
    flex-direction: column;
  }
  .autodial-all-data-div .autodial-trigger-action-block-template .autodia-trigger-action-event-div {
    margin-bottom: 5px;
  }
}
.autodial-all-data-div .autodial-trigger-action-block-template .autodia-trigger-action-event-div {
  display: flex;
  width: 500px;
  margin-top: 7px;
}
.autodial-all-data-div .autodial-scheduler-div .autodial-scheduler-dates {
  display: flex;
  align-items: center;
  margin: 10px 5px;
  font-weight: 500;
}
.autodial-all-data-div .autodial-scheduler-div .autodial-scheduler-dates > span {
  margin-right: 10px;
}
.autodial-all-data-div .autodial-scheduler-div .autodial-scheduler-dates > input {
  margin-right: 5px;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate {
  display: flex;
  align-items: center;
  height: 35px;
  margin: 10px;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate > div:first-child {
  width: 70px;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv {
  display: flex;
  align-items: center;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .rangeDelimiter {
  margin: 0 10px;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeFrom,
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeTo {
  display: flex;
  align-items: center;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeFrom .timeDelimiter,
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeTo .timeDelimiter {
  margin: 0 2px;
}
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeFrom .hourSelect,
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeTo .hourSelect,
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeFrom .minuteSelect,
.autodial-all-data-div .autodial-scheduler-div .autodialOneDayTimeScheduleTemplate .timeRangeDiv .timeTo .minuteSelect {
  width: 60px;
  height: 35px;
}
.autodial-all-data-div .autodial-repeat-call-settings > div {
  display: flex;
}
.autodial-all-data-div .autodial-repeat-call-settings > div > div:last-child {
  margin-left: 10px;
}
.autodial-all-data-div .autodial-repeat-call-settings > div:not(:last-child) {
  align-items: center;
  height: 50px;
}
.autodial-all-data-div .autodial-repeat-call-settings > div:last-child {
  margin-top: 10px;
}
.autodial-all-data-div .autodial-repeat-call-settings > div:last-child > div:last-child > div {
  margin-bottom: 5px;
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div {
  display: flex;
}
@media (max-width: 800px) {
  .autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div {
    flex-direction: column;
  }
  .autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child {
    margin-bottom: 15px;
  }
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div #autodial-button-save {
  margin-left: auto;
  margin-top: auto;
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div {
  display: flex;
  align-items: center;
  height: 50px;
  margin: 3px;
}
@media (max-width: 1000px) {
  .autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div {
    flex-direction: column;
    height: 90px;
    align-items: normal;
  }
  .autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div > div {
    margin: 0;
  }
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div > div {
  margin: 5px;
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div > div:first-child {
  width: 310px;
}
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div .ion-slider-div,
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div .dropdown-single-button,
.autodial-all-data-div .autodial-block-settings .autodial-settings-and-save-button-div > div:first-child > div .webra-input-field {
  width: 250px;
}
.autodialRemoveNumbersArea,
#autodial-add-calls-area {
  resize: vertical;
  padding: 10px;
}
.autodialRemoveNumbersPopupBodyTemplate {
  padding: 10px 25px;
}
.autodialRemoveNumbersPopupBodyTemplate > div {
  margin-bottom: 10px;
}
.autodialRemoveNumbersPopupBodyTemplate > div:last-child {
  display: flex;
  justify-content: center;
  margin: 20px 0 15px;
}
.autodialRemoveNumbersPopupBodyTemplate .autodialRemoveNumbersRadio {
  display: flex;
  margin-bottom: 20px;
}
.autodialRemoveNumbersPopupBodyTemplate .autodial-remove-calls-popup-states-div {
  margin-top: 25px;
}
.autodialRemoveNumbersPopupBodyTemplate .autodial-remove-calls-popup-states-div > div {
  margin-bottom: 10px;
}
.autodialRemoveAutodialCallsHistoryPopupTemplate {
  margin: 10px 25px;
}
.autodialRemoveAutodialCallsHistoryPopupTemplate > div:first-child {
  display: flex;
  align-items: center;
  height: 50px;
}
.autodialRemoveAutodialCallsHistoryPopupTemplate > div:last-child {
  display: flex;
  justify-content: center;
  margin: 20px 0 15px;
}
.autodial-refresh-popup-template > div > div {
  margin: 10px 25px;
}
.autodial-refresh-popup-template > div:last-child {
  display: flex;
  justify-content: center;
  margin: 20px 0 15px;
}
.id-text-color {
  color: darkgray;
}
.autodial-id {
  position: absolute;
  top: 30px;
  right: 20px;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 12px;
}
.autodial-trigger-action-template {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.autodial-trigger-action-template .autodial-trigger-action-select {
  width: 500px;
}
@media (max-width: 650px) {
  .autodial-trigger-action-template .autodial-trigger-action-select {
    width: 360px;
  }
}
.divForAutodialListenAudioTriggerActionsHeader {
  margin-top: 20px;
  font-size: 14px;
}
.divForAutodialListenAudioTriggerActionsHeader > div {
  display: flex;
  align-items: center;
  width: 500px;
}
.divForAutodialListenAudioTriggerActionsHeader > div > div:first-child {
  margin-right: 5px;
}
.divForAutodialListenAudioTriggerActionsHeader > div > div:last-child {
  margin-left: auto;
}
.autodialListenAudioTriggerActionsDataTemplate {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .autodialListenAudioTriggerActionsDataTemplate {
    flex-direction: column;
  }
  .autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv {
    margin-bottom: 5px;
  }
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv {
  display: flex;
  align-items: center;
  width: 500px;
  height: 45px;
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv .autodialListenAudioTextAndInputsDiv {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv .autodialListenAudioTextAndInputsDiv input {
  width: 60px;
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv .autodialListenAudioTextAndInputsDiv > div {
  margin-right: 10px;
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv .autodialListenAudioRemoveButtonDiv {
  margin-left: auto;
}
.autodialListenAudioTriggerActionsDataTemplate .autodialListenAudioMainDiv .autodialListenAudioAddTriggerActionButtonDiv {
  margin-left: 5px;
}
.autodialDialCyclesStatisticsBlock {
  padding-left: 20px;
}
.autodialDialCyclesStatisticsBlock .autodialDialCyclesStatisticsButtonsDiv > div {
  margin-bottom: 5px;
}
.autodialDialCyclesStatisticsBlock .autodialInProgressStatRowTemplate {
  margin-bottom: 3px;
  display: flex;
}
.autodialDialCyclesStatisticsBlock .autodialInProgressStatRowTemplate .statTitle {
  width: 600px;
  margin-right: 10px;
}
.autodialDialCyclesStatisticsBlock .AutodialDialCyclesStatisticsDiv {
  margin-top: 10px;
}
.autodialDialCyclesStatisticsBlock .AutodialDialCyclesStatisticsDiv .autodialCyclesStatRow:nth-child(odd) {
  background: #e8f4f8;
}
.autodialDialCyclesStatisticsBlock .AutodialDialCyclesStatisticsDiv .autodialCyclesStatRow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.autodialDialCyclesStatisticsBlock .AutodialDialCyclesStatisticsDiv .autodialCyclesStatRow .autodialCyclesStatCell:first-child {
  width: 600px;
  margin-right: 10px;
}
.autodialDialCyclesStatisticsBlock .AutodialDialCyclesStatisticsDiv .autodialCyclesStatRow .autodialCyclesStatCell:not(:first-child) {
  width: 150px;
}
.out-phone,
.containerForNewUnioAddingWrap {
  background-color: #fff;
}
.out-phone .supportOption,
.containerForNewUnioAddingWrap .supportOption {
  color: #005fff;
}
.out-phone .supportOption .next-checkbox-square .checkmark,
.containerForNewUnioAddingWrap .supportOption .next-checkbox-square .checkmark {
  border-color: #c5e2ff;
}
.out-phone .supportOption .next-checkbox-square input:checked ~ .checkmark,
.containerForNewUnioAddingWrap .supportOption .next-checkbox-square input:checked ~ .checkmark {
  background-color: #005fff;
}
.container-with-header-content .out-phone:nth-child(even),
.out-phone-in_site .out-phone:nth-child(even) {
  background-color: #fbfbfb;
}
.out-phone .dropdown-single-button,
.out-phone .webra-input-field {
  border: 2px solid #e8e8e8;
}
.out-number-lease {
  position: absolute;
  display: flex;
  left: 15px;
  top: 0;
  font-size: 13px;
  color: #2a8be1;
}
.out-number-lease .autodialOnlyIcons {
  display: none;
  margin-left: 10px;
  gap: 5px;
}
.out-number-lease .autodialOnlyIcons path {
  fill: #2a8be1;
}
.out-phone-work-text-status {
  font-size: 12px;
  color: #42a915;
  position: absolute;
  left: 28px;
  bottom: 8px;
}
.out-site-scenario-select-div {
  width: 200px;
  float: left;
}
.wrapperWithPhoneSettings {
  position: relative;
  padding-bottom: 5px;
  padding-left: 10px;
}
.wrapperWithPhoneSettings .unioCbs {
  display: flex;
  flex-wrap: wrap;
}
.wrapperWithPhoneSettings .unioCbs .oneOuterSetting {
  margin-right: 10px;
  margin-bottom: 10px;
}
.wrapperWithPhoneSettings label {
  margin-bottom: 0;
}
.wrapperWithPhoneSettings .out-own-number-remove-button {
  position: absolute;
  bottom: 15px;
  right: 20px;
  width: 150px;
}
.wrapperWithPhoneSettings .editSettingsSection {
  display: flex;
  padding: 0 20px 0;
}
.wrapperWithPhoneSettings .editSettingsSection .oneUserParameter {
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
}
.wrapperWithPhoneSettings .announcesSettings {
  position: relative;
  padding-top: 5px;
}
.wrapperWithPhoneSettings .announcesSettings .announceHeader {
  display: flex;
  align-items: center;
}
.wrapperWithPhoneSettings .announcesSettings .announceHeader .pauseDescr {
  margin-left: 22px;
  color: #2a8be1;
  font-size: 13px;
}
.wrapperWithPhoneSettings .announcesSettings .announceHeader .announcePauseDropdown {
  border: none;
}
.wrapperWithPhoneSettings .announcesSettings .field-description {
  color: #2a8be1;
  font-size: 13px;
}
.wrapperWithPhoneSettings .announcesSettings .addAnnounceButton {
  margin-left: 15px;
  cursor: pointer;
}
.wrapperWithPhoneSettings .announcesSettings .divForAnnounces {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}
.wrapperWithPhoneSettings .announcesSettings .divForAnnounces .oneAnnounce {
  display: flex;
}
.wrapperWithPhoneSettings .announcesSettings .divForAnnounces .oneAnnounce .audioDropDown {
  width: 300px;
  border: 2px solid #c3c3c3;
}
.wrapperWithPhoneSettings .announcesSettings .divForAnnounces .oneAnnounce .directionsDiv {
  margin-left: 10px;
  flex-grow: 1;
  padding-right: 10px;
  max-width: 1240px;
}
.wrapperWithPhoneSettings .announcesSettings .divForAnnounces .oneAnnounce .directionsInput {
  border: 2px solid #c3c3c3;
}
.wrapperWithPhoneSettings .announcesSettings .saveAnnouncesButton {
  width: 115px;
  height: 34px;
  margin-right: 15px;
  margin-bottom: 5px;
}
.wrapperWithPhoneSettings .announcesSettings .announcesAndButtons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.wrapperWithPhoneSettings .supportSection {
  display: flex;
  align-items: center;
  padding: 0px 20px 10px;
}
.wrapperWithPhoneSettings .supportSection > div {
  margin-top: 10px;
}
.wrapperWithPhoneSettings .supportSection > div:first-child {
  margin-top: 0px;
}
.wrapperWithPhoneSettings .supportSection .show-as-redirected-own-div {
  width: 490px;
}
.wrapperWithPhoneSettings .supportSection .connectToDirtySwapButton {
  width: 250px;
  height: 25px;
  line-height: 1 !important;
}
.wrapperWithPhoneSettings .editPhoneControlButtons {
  display: flex;
  justify-content: flex-end;
}
.wrapperWithPhoneSettings .editPhoneControlButtons button {
  width: 115px;
  margin-top: 0;
  margin-right: 15px;
}
.containerForNewUnioAddingWrap .webra-input-field,
.wrapperWithPhoneSettings .webra-input-field,
.containerForNewUnioAddingWrap .dropdown-single-button,
.wrapperWithPhoneSettings .dropdown-single-button {
  border: 2px solid #e8e8e8;
}
.containerForNewUnioAddingWrap {
  background-color: #fff;
}
.containerForNewUnioAddingWrap .addingNewPhoneButtonsDiv {
  display: flex;
  margin-top: 15px;
}
.containerForNewUnioAddingWrap .addingNewPhoneButtonsDiv button {
  margin-left: 10px;
  margin-top: 0;
  width: 200px;
}
.containerForNewUnioAddingWrap .addingNewPhoneButtonsDiv button:first-child {
  margin-left: 0;
}
.out-phone {
  /*номер*/
  /*линии*/
  /*метка*/
  /*сценарий*/
}
.out-phone .out-phone-info {
  position: relative;
  height: 90px;
  display: flex;
  justify-content: space-between;
}
.out-phone .out-phone-info .leftSide {
  display: flex;
}
.out-phone .out-phone-info .rightSide {
  display: flex;
  align-items: center;
  width: 130px;
  min-width: 130px;
}
.out-phone .out-phone-info .oneNumberSettingsButton {
  outline: none;
}
.out-phone .out-phone-info .oneNumberSettingsButton:focus {
  outline: none;
}
.out-phone .out-phone-info .out-span-number {
  font-weight: 500;
  font-size: 30px;
}
.out-phone .out-number-col {
  display: flex;
  align-items: center;
  padding: 5px;
  word-wrap: break-spaces;
  position: relative;
}
.out-phone .descriptionInputDiv .employeeDescription {
  display: none;
}
.out-phone .out-number-col:nth-child(1) {
  width: 300px;
  min-width: 300px;
  color: #4b4b4b;
}
.out-phone .out-number-col:nth-child(2) {
  width: 110px;
  min-width: 110px;
}
.out-phone .out-number-col:nth-child(3) {
  width: 230px;
  min-width: 230px;
}
.out-phone .out-number-col:nth-child(3) .out-input-description {
  width: 210px;
}
.out-phone .out-number-col:nth-child(4) {
  width: 250px;
  min-width: 250px;
}
.out-phone .out-number-col:nth-child(4) .out-input-description {
  width: 240px;
}
.out-phone .outerPhoneEmployeeDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneCommentaryDiv {
  color: #005fff;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneTagsDiv {
  display: flex;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneTagsDiv .oneTag {
  display: flex;
  font-weight: 500;
  margin-right: 15px;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneTagsDiv .oneTag svg {
  padding-top: 3px;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneTagsDiv .tagAddutton {
  padding-top: 3px;
  cursor: pointer;
  margin-right: 10px;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneTagsDiv .tagRemoveButton {
  cursor: pointer;
}
.out-phone .outerPhoneEmployeeDiv .outerPhoneCommentaryEditButton {
  padding-left: 1px;
  cursor: pointer;
}
.out-phone .out-number-scenario-link-to-edit {
  position: absolute;
  right: 10px;
  top: -1px;
  font-weight: 500;
  color: #2a8be1;
  cursor: pointer;
  font-size: 13px;
}
.out-phone .out-number-scenario-link-to-edit a {
  text-decoration: underline;
}
.out-phone .out-number-col .field-description {
  color: #2a8be1;
  font-size: 13px;
  position: absolute;
  top: -1px;
}
.out-site {
  -webkit-box-shadow: 0px 0px 7px -2px #000000;
  box-shadow: 0px 0px 7px -2px #000000;
  margin-bottom: 15px;
}
.out-site .big-header {
  padding: 15px;
}
.out-site .big-header .out-site-scenario-select-div button {
  padding: 5px;
}
.out-site .big-header .dropdown-single-button {
  padding: 0px;
  width: 240px;
  height: 40px;
}
.out-site-name {
  float: left;
  width: 500px;
  line-height: 40px;
  margin-left: 20px;
  height: 100%;
}
.out-scenario-select button {
  padding: 10px;
}
.leaseRequestDiv .editorColumn,
.containerForNewUnioAddingWrap .editorColumn {
  max-width: 360px;
  margin-right: 20px;
}
.leaseRequestDiv .editorColumn {
  max-width: unset;
}
.leaseRequestDiv .leaseRequestBody {
  display: flex;
  min-height: 385px;
}
.leaseRequestDiv .leaseRequestBody .locationSelectSection {
  width: 20%;
}
.leaseRequestDiv .leaseRequestBody .tariffAndMultis {
  width: 80%;
  display: flex;
}
.leaseRequestDiv .tariffWrap,
.leaseRequestDiv .multiWrap {
  width: 50%;
  padding: 10px;
  padding-top: 0;
}
.leaseRequestDiv .divForTariffsChoice,
.leaseRequestDiv .divForMultisChoice {
  padding: 0px 15px 15px;
  border: 2px solid #e8e8e8;
}
.leaseRequestDiv .divForTariffsChoice .tariffOrMultiItem,
.leaseRequestDiv .divForMultisChoice .tariffOrMultiItem {
  margin-top: 15px;
}
.leaseRequestDiv .field-description {
  color: #2a8be1;
  font-size: 13px;
  font-weight: 500;
}
.leaseRequestDiv .nextStepItem {
  border: 2px solid #3f3dc9 !important;
}
.leaseRequestDiv .progressStep {
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 16px;
  line-height: 25px;
  color: #e8e8e8;
}
.leaseRequestDiv .progressStep i {
  font-size: 25px;
}
.leaseRequestDiv .progressStep .fa-check-square {
  display: none;
}
.leaseRequestDiv .progressStep.completed {
  color: #00c907;
}
.leaseRequestDiv .progressStep.completed .fa-check-square {
  display: block;
}
.leaseRequestDiv .progressStep.completed .fa-square {
  display: none;
}
.leaseRequestDiv .progressStep.nextStep {
  color: #3f3dc9;
}
.leaseRequestDiv .progressStep .stepNumber {
  margin-left: 10px;
  min-width: 50px;
}
@media (max-width: 1900px) {
  .leaseRequestDiv .progressStep {
    font-size: 14px;
    line-height: 25px;
  }
  .leaseRequestDiv .progressStep i {
    font-size: 20px;
  }
  .leaseRequestDiv .progressStep .stepNumber {
    margin-left: 5px;
    min-width: 35px;
  }
}
@media (max-width: 1550px) {
  .leaseRequestDiv .progressStep {
    font-size: 12px;
    line-height: 25px;
  }
  .leaseRequestDiv .progressStep i {
    font-size: 17px;
  }
  .leaseRequestDiv .progressStep .stepNumber {
    margin-left: 5px;
    min-width: 30px;
  }
}
.tariffOrMultiItem {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  min-height: 50px;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tariffOrMultiItem:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}
.tariffOrMultiItem.selected {
  box-shadow: 0 0 12px 0 #000000;
}
.tariffOrMultiItem .body {
  display: flex;
  align-items: center;
  height: 50px;
}
.tariffOrMultiItem .body .itemName {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}
.tariffOrMultiItem .linesCountDiv {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  background-color: #2f323e;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tariffOrMultiItem .linesCountDiv .linesCount {
  font-size: 24px;
  font-weight: 500;
  line-height: 25px;
}
.tariffOrMultiItem .linesCountDiv .linesDesc {
  font-size: 11px;
  font-weight: 400;
  line-height: 10px;
}
.tariffOrMultiItem .priceDiv {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tariffOrMultiItem .priceDiv .priceSum {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}
.tariffOrMultiItem .priceDiv .priceCurrency {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  margin-right: 5px;
}
.tariffOrMultiItem .priceDiv .priceDesc {
  font-size: 11px;
  font-weight: 400;
  line-height: 10px;
}
.tariffOrMultiItem .body {
  padding-left: 60px;
  padding-right: 60px;
}
.orderNumberDiv .body {
  padding: 10px;
}
.fullTariffInfo,
.orderNumberDiv {
  border: 2px solid #e8e8e8;
  margin-right: 10px;
  padding: 10px;
  margin-top: 10px;
}
.fullTariffInfo .header,
.orderNumberDiv .header {
  display: flex;
  height: 30px;
  align-items: center;
  font-size: 25px;
  padding: 10px;
}
.outLinesNewLeaseBilling > div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.outLinesNewLeaseFielDesc {
  font-weight: 500;
}
.fullTariffInfo {
  position: relative;
}
.fullTariffInfo .divWithTariffConnectButtons {
  display: flex;
  justify-content: space-between;
}
.fullTariffInfo .divWithTariffConnectButtons button {
  width: 250px;
  margin-top: 0;
}
.fullTariffInfo .header .tariffText {
  font-size: 25px;
}
.fullTariffInfo .header .tariffName {
  color: #e33351;
  font-size: 25px;
  margin-left: 20px;
  font-weight: 500;
}
.fullTariffInfo .header .headerDesc {
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
}
.fullTariffInfo .body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.fullTariffInfo .body .bodySection {
  padding: 10px;
}
@media (min-width: 600px) {
  .fullTariffInfo .body .bodySection {
    width: 100%;
  }
}
@media (min-width: 1300px) {
  .fullTariffInfo .body .bodySection {
    width: 50%;
  }
}
@media (min-width: 1900px) {
  .fullTariffInfo .body .bodySection {
    width: 33.33%;
  }
}
.fullTariffInfo .body > div:first-child {
  margin-left: 0;
}
.fullTariffInfo .tariffConditions {
  display: flex;
}
.fullTariffInfo .autodialOnlyWarning {
  display: none;
  padding: 10px;
  color: red;
  font-weight: 500;
}
.fullTariffInfo .onePrepaidMinutes,
.fullTariffInfo .oneSummaryRow {
  display: flex;
  background-color: #fff;
  padding: 5px;
}
.fullTariffInfo .onePrepaidMinutes:nth-child(odd),
.fullTariffInfo .oneSummaryRow:nth-child(odd) {
  background-color: #f5f5f5;
}
.fullTariffInfo .onePrepaidMinutes .desc {
  width: 70px;
}
.fullTariffInfo .onePrepaidMinutes .minutes {
  font-weight: 500;
  width: 60px;
}
.fullTariffInfo .oneSummaryRow .desc {
  width: 150px;
  font-weight: 500;
}
.fullTariffInfo .cantWorkWithoutMulti {
  padding: 10px;
  color: red;
  font-weight: 500;
}
.orderNumberDiv {
  margin-top: 10px;
}
.orderNumberDiv .availableDescription {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 17px;
  color: #2a8be1;
}
.orderNumberDiv .divForAvailablePhones {
  display: flex;
  flex-wrap: wrap;
}
.orderNumberDiv .divForAvailablePhones .oneAvailableNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  padding: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  transition: all 0.3s ease;
  cursor: pointer;
}
.orderNumberDiv .divForAvailablePhones .oneAvailableNumber:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.orderNumberDiv .divForAvailablePhones .oneAvailableNumber .number {
  font-size: 25px;
}
.orderNumberDiv .wantAnother {
  text-decoration: underline;
  cursor: pointer;
}
.orderNumberDiv .requestAnotherWrap {
  padding-top: 15px;
}
.orderNumberDiv .requestAnotherWrap .requestDescription {
  color: #2a8be1;
  font-size: 17px;
  margin-bottom: 10px;
}
.orderNumberDiv .requestAnotherWrap .requestAnotherDiv {
  display: flex;
  height: 34px;
}
.orderNumberDiv .requestAnotherWrap .requestAnotherDiv button {
  width: 250px;
}
.orderNumberDiv .requestAnotherWrap .requestAnotherDiv input {
  height: 34px;
}
.orderNumberDiv .requestAnotherWrap .requestAnotherDiv .indexSelect {
  width: 400px;
  margin-right: 20px;
  margin-left: 20px;
}
.divForRequestedLeases .oneLeaseRequest {
  display: flex;
  padding: 10px;
  padding-left: 0;
  font-size: 17px;
  justify-content: space-between;
}
.divForRequestedLeases .oneLeaseRequest .date {
  font-weight: 500;
  width: 200px;
}
.divForRequestedLeases .oneLeaseRequest .forTrackingDescription {
  width: 170px;
  color: #00AEEF;
}
.noLeasesByCurrentTariffLocation {
  padding: 15px;
  color: red;
}
.out-scenario-select {
  background-color: #fff;
}
.outNumberLinesInfoDiv {
  display: flex;
  height: 45px;
  font-weight: 500;
}
.outNumberLinesInfoDiv .outNumberLinesVerticalDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 19px;
}
.outNumberLinesInfoDiv .outNumberLinesVerticalDiv.coloredLine div:first-child {
  fill: #00AEEF;
  color: #00AEEF;
}
.outNumberLinesInfoDiv .outNumberLinesVerticalDiv.coloredLine div:last-child {
  fill: #3CC400;
  color: #3CC400;
}
.outNumberSummaryLinesDiv {
  display: flex;
  height: 45px;
  margin-left: 5px;
  align-items: center;
  color: #7F7F7F;
}
.out-number-lines-desc {
  position: absolute;
  left: 5px;
  top: 0;
  font-size: 13px;
  color: #2a8be1;
}
.outPhoneLinesTooltip {
  display: none;
  top: 0 !important;
}
.container-with-header-content .out-phone:first-child .outPhoneLinesTooltip {
  display: inline-block;
}
.addDirtySwapModal {
  padding: 20px;
  font-weight: 500;
}
.addDirtySwapModal .divForMultis .tariffOrMultiItem {
  margin-bottom: 10px;
}
.addDirtySwapModal .connectDirtySwapButton {
  height: 40px;
}
.addTagsModal .tagsAutocompleteField {
  position: relative;
}
.addTagsModal .tagsAutocompleteField .webra-input-field {
  height: 100%;
}
.addTagsModal .tagsAutocompleteField .nextel-autocomplete-list {
  top: 50px;
  border: none;
}
.addTagsModal .tagsAutocompleteField .nextel-autocomplete-list .nextel-autocomplete-list-item {
  border-left: #d1d1d1 1px solid;
  border-right: #d1d1d1 1px solid;
}
.addTagsModal .tagsAutocompleteField .nextel-autocomplete-list .nextel-autocomplete-list-item:first-child {
  border-top: #d1d1d1 1px solid;
}
.addTagsModal .tagsAutocompleteField .nextel-autocomplete-list .nextel-autocomplete-list-item:last-child {
  border-bottom: #d1d1d1 1px solid;
}
.addTagsModal .tagsAutocompleteField .ephonesTagConditionSelectDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4px;
  right: 4px;
}
.addTagsModal .tagsAutocompleteField .ephonesTagConditionSelectDiv .nextel-radio span {
  font-size: 12px;
  padding-left: 18px;
  color: #7F7F7F;
}
.addTagsModal .tagsAutocompleteField .ephonesTagConditionSelectDiv .nextel-radio span:before {
  width: 16px;
  height: 16px;
}
.addTagsModal .tagsAutocompleteField .ephonesTagConditionSelectDiv .nextel-radio span:after {
  width: 10px;
  height: 10px;
  left: 3px;
  top: 3px;
}
.commentEditModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.commentEditModal .commentInput {
  width: 450px;
  height: 150px;
}
.commentEditModal .addCommentButton {
  margin-top: 20px;
}
.outer-settings-controls {
  display: flex;
  position: relative;
}
.outer-settings-controls .column {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}
.outer-settings-controls .oneOuterSetting {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 300px;
  max-width: 300px;
}
.wrapperWithPhoneSettings .out-number-callback-div {
  display: flex;
  padding-right: 10px;
}
.wrapperWithPhoneSettings .out-number-callback-div .out-callback-audio-select {
  width: 296px;
  max-width: 200px;
  height: 30px;
  line-height: 10px;
}
.wrapperWithPhoneSettings .out-number-callback-div .out-callback-audio-select .dropdown-toggle {
  padding: 0;
}
.wrapperWithPhoneSettings .out-number-callback-div .dropdown-single-button {
  border: none;
}
.wrapperWithPhoneSettings .missedCallsHandlingDiv {
  display: flex;
}
.wrapperWithPhoneSettings .missedCallsHandlingDiv .modalTooltip {
  position: static;
}
.wrapperWithPhoneSettings .missedCallsHandlingDiv .missedCallsTriggerActionsSelect {
  width: 296px;
  max-width: 200px;
  height: 30px;
  line-height: 10px;
  margin-right: 30px;
}
.wrapperWithPhoneSettings .missedCallsHandlingDiv .missedCallsTriggerActionsSelect .dropdown-toggle {
  padding: 0 16px 0 0;
}
.wrapperWithPhoneSettings .missedCallsHandlingDiv .dropdown-multi-button {
  border: none;
}
.outLinesEntityEditor .params {
  display: flex;
  flex-wrap: wrap;
}
.outLinesEntityEditor .oneParameter,
.containerForNewUnioAdding .oneParameter {
  width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.outLinesEntityEditor .oneParameter:last-child,
.containerForNewUnioAdding .oneParameter:last-child {
  margin-bottom: 0;
}
.outLinesEntityEditor .oneParameter .dropdown-single-button,
.containerForNewUnioAdding .oneParameter .dropdown-single-button,
.outLinesEntityEditor .oneParameter input[type="text"],
.containerForNewUnioAdding .oneParameter input[type="text"] {
  border: 2px solid #c3c3c3;
  box-shadow: none;
  height: 45px !important;
}
.outLinesEntityEditor .oneParameter .field-description,
.containerForNewUnioAdding .oneParameter .field-description {
  color: #2a8be1;
  font-size: 13px;
}
.telephony-lease-header .sector-header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.telephony-lease-header .sector-header-div .outLinesCounters {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.telephony-lease-header,
.leaseRequestDiv {
  padding: 15px;
}
.telephony-lease-header .sector-header-div,
.leaseRequestDiv .sector-header-div {
  padding-bottom: 10px;
}
.ivr-template .ivr-settings-id-block {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 12px;
  display: none;
}
.ivr-template .webra-input-field,
.ivr-template .dropdown-single-button {
  height: 37px;
}
.ivr-template .webra-input-field,
.ivr-template .dropdown-single-button span {
  font-weight: 400;
}
.ivr-template .ivr-main-settings-div > div {
  display: flex;
  align-items: center;
}
.ivr-template .ivr-main-settings-div > div .webra-input-field,
.ivr-template .ivr-main-settings-div > div .dropdown-single-button {
  width: 250px;
}
.ivr-template .ivr-main-settings-div > div > div:first-child {
  width: 200px;
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div {
  display: flex;
}
@media (max-width: 1200px) {
  .ivr-template .ivr-action-template .ivr-action-settings-main-div {
    flex-direction: column;
  }
  .ivr-template .ivr-action-template .ivr-action-settings-main-div > div {
    margin-top: 10px;
  }
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div > div {
  margin-right: 10px;
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div .ivr-action-buttons-div {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div .ivr-action-buttons-div > div {
  width: 165px;
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div .ivr-action-title-span {
  color: #38769b;
  font-size: 14px;
}
.ivr-template .ivr-action-template .ivr-action-settings-main-div .choice-key-select {
  float: left;
  font-size: 38px;
  color: darkgrey;
  border: 1px solid #aaa;
  border-radius: 0;
  cursor: pointer;
  margin-top: 2px;
  width: 47px;
  height: 55px;
}
@-moz-document url-prefix() {
  .ivr-template .ivr-action-template .ivr-action-settings-main-div .choice-key-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #aaa;
    border-radius: 0;
    background: url(../img/mozilla-dropdown-arrow.png) right / 20px no-repeat #fff;
    padding-right: 20px;
  }
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div {
  margin-top: 10px;
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div {
  display: flex;
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div .ivr-action-trigger-actions-div {
  margin-left: 10px;
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div .ivr-action-trigger-actions-div > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div .ivr-action-trigger-actions-div > div .ivr-action-trigger-action-select {
  width: 500px;
}
@media (max-width: 900px) {
  .ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div .ivr-action-trigger-actions-div > div .ivr-action-trigger-action-select {
    width: 300px;
  }
}
.ivr-template .ivr-action-template .ivr-action-trigger-actions-main-div .ivr-action-add-triggers-div .ivr-action-trigger-actions-div > div > div {
  margin-left: 5px;
}
.ivr-template .generalTriggerActionsDivTemplate {
  display: flex;
  margin-left: 10px;
}
.ivr-template .generalTriggerActionsDivTemplate .divForTriggerActions > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.ivr-template .generalTriggerActionsDivTemplate .divForTriggerActions > div .ivr-action-trigger-action-select {
  width: 500px;
}
@media (max-width: 900px) {
  .ivr-template .generalTriggerActionsDivTemplate .divForTriggerActions > div .ivr-action-trigger-action-select {
    width: 300px;
  }
}
.ivr-template .generalTriggerActionsDivTemplate .divForTriggerActions > div > div {
  margin-left: 5px;
}
.audio-div-for-download-button label {
  cursor: pointer;
}
.audio-audio:hover {
  background-color: #f5f5f5;
}
.fileuploader {
  padding: 2px;
}
.fileuploader:hover {
  padding: 2px;
  background-color: #d3d7db;
}
.fileuploader > div {
  border: 4px dotted #bfbfe3;
  padding-left: 20px;
  padding-top: 8px;
  height: 52px;
}
.fileuploader span {
  font-size: 20px;
  color: #8181a0;
}
.fileuploader label span {
  line-height: 30px;
  border: 2px #8181a0 solid;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}
.audio-audio-player {
  min-height: 70px !important;
}
.audio-audio {
  position: relative;
}
.audio-audio .audioIdDiv {
  position: absolute;
  color: darkgrey;
  top: 2px;
  right: 4px;
}
.audio-add-from-library-popup-template {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audio-add-from-library-popup-template .audioAddFromLibraryTypeSelect {
  width: 400px;
  height: 50px;
}
.audio-add-from-library-popup-template .addFromLibraryButtonDiv {
  margin: 20px 0;
}
.stat-op-table thead th {
  background-color: #637689;
  color: white;
  font-weight: 500;
  font-size: 15px;
  padding: 15px 8px;
  text-align: left;
}
.stat-op-table > td {
  height: 60px;
}
.stat-op-table td {
  padding: 10px;
  height: 50px;
}
#stat-op-tab-list li {
  cursor: pointer !important;
}
.tabs-style-bar .clearfix {
  flex-wrap: wrap;
}
.tabs-style-bar .clearfix > div {
  margin-right: 10px;
  margin-bottom: 10px;
}
.tabs-style-bar .clearfix > div .input-daterange-timepicker {
  background-color: #fff;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  font-weight: 300;
}
.tabs-style-bar .clearfix .stat-operator-filters-date,
.tabs-style-bar .clearfix .dropdown-multi-button,
.tabs-style-bar .clearfix .webra-input-field {
  height: 50px;
}
.tabs-style-bar .clearfix .dropdown-multi-button .dropdown-menu.open,
.tabs-style-bar .clearfix .webra-input-field {
  width: 220px !important;
  min-width: 50% !important;
}
.tabs-style-bar .tab-current a {
  background: #308bec !important;
  cursor: default !important;
  margin: 0 !important;
}
.stat-op-table-header {
  cursor: pointer;
}
.stat-op-main-div .stat-operator-filters-date {
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 10px;
  cursor: default;
  width: 280px;
}
.stat-op-main-div .stat-operator-filters-date .input-daterange-timepicker {
  border: none;
  width: 190px;
}
.stat-op-main-div .statFilters .filtersRow {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stat-op-main-div .statFilters .filtersRow > div {
  padding-left: 10px;
}
.stat-op-main-div table {
  font-weight: 400;
}
.stat-op-main-div table tr:nth-child(even) {
  background-color: #f7fafc;
}
.stat-op-main-div table tr:nth-child(odd) {
  background-color: #ebeef0;
}
.stat-op-main-div table th {
  height: 72px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.stat-op-main-div table tr:first-child {
  font-weight: 600;
}
.opStatCallsCountDiv {
  position: absolute;
  top: 5px;
  left: 30px;
  color: #4e4e4e;
}
.balance-div-for-quick-actions {
  display: flex;
  gap: 10%;
}
@media (max-width: 1280px) {
  .balance-div-for-quick-actions {
    flex-direction: column;
  }
}
.balance-operations-export {
  margin-left: 20px;
  background-color: #fff;
  height: 50px;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
}
.balance-operations-export .pseudo-button {
  color: #0089ff;
}
.balance-card {
  height: 175px;
  width: 45%;
  background-color: #fff;
  margin: 0 30px 30px;
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1280px) {
  .balance-card {
    margin: 0 0px 30px;
    width: 100%;
  }
}
.balance-card .balance-card-how-much-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.balance-card .balance-card-how-much-div .howMuchHint {
  margin-right: 10px;
  font-size: 14px;
  color: #777777;
}
.balance-card-how-much-div-show {
  opacity: 1 !important;
}
.balance-div-for-quick-actions .balance-card:first-child {
  margin-left: 0;
  margin-right: 0;
}
.balance-div-for-quick-actions .balance-card:last-child {
  margin-right: 0;
  margin-left: 0;
}
.balance-card-current-balance-container {
  padding: 0 60px 0 30px;
  height: 160px;
  width: 45%;
}
.balance-card-title {
  white-space: nowrap;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  border-bottom: 1px solid #4a8df1;
}
.balance-card-warning {
  flex-direction: column;
  padding-left: 30px;
  gap: 10px;
  min-width: 420px;
}
.balance-card-warning .balance-card-title {
  font-weight: 500;
  font-size: 16px;
  color: #f03f3c;
  margin-bottom: 10px;
  line-height: 36px;
}
.balance-card-warning .balance-card-header span {
  font-size: 14px;
  line-height: 21px;
  color: #30323e;
  margin-bottom: 6px;
}
.balance-card-warning .warning-card-credit-button,
.balance-card-warning .warning-card-tariff-prolong-button {
  height: 40px;
  width: 40%;
  line-height: 47px;
  font-weight: 500;
  color: #d51515;
  border: 1px solid #d51515;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.balance-card-warning .warning-card-credit-button:hover,
.balance-card-warning .warning-card-tariff-prolong-button:hover {
  background-color: #d51515;
  color: #fff;
}
.balance-card-current-balance,
.balance-card-current-bonus-bal,
.balance-card-currency {
  font-size: 16px;
}
.balance-card-topup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  padding-right: 10px;
}
.balance-card-topup .pay-input-box {
  display: flex;
  align-items: center;
  width: 40%;
  background: #fff;
  border: 1px solid #a0a0a0;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
  height: 40px;
}
.balance-card-topup .pay-input-box .balance-card-pay-input {
  height: 50px;
  width: 100%;
  color: #777777;
  font-size: 20px;
  border: none;
  outline: none;
}
.balance-card-topup .pay-input-box .balance-card-currency {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}
.balance-card-topup .creditExplanation {
  font-size: 12px;
  color: #5B6D82;
}
.balance-card-topup .hideCreditRequestViewButton {
  margin-top: 10px;
  margin-bottom: 15px;
  width: 70px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #4a8df1;
}
.balance-card-topup .hideCreditRequestViewButton svg {
  cursor: pointer;
}
.balance-card-tariff {
  display: none;
}
.balanceAskForCreditMoneyModalBody .button,
.balance-card-topup .button {
  height: 40px;
  width: 40%;
  margin-left: 30px;
  line-height: 47px;
  padding: 0 25px;
  font-weight: 500;
  color: #d51515;
  border: 1px solid #d51515;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.balanceAskForCreditMoneyModalBody .button:hover,
.balance-card-topup .button:hover {
  background-color: #d51515;
  color: #fff;
}
.balance-invoices-and-transactions {
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
  margin-top: 20px !important;
}
.balance-transactions-table .tableCol:nth-child(1) {
  flex-basis: 25px;
}
.balance-transactions-table .tableCol:nth-child(2) {
  min-width: 180px;
}
.balance-transactions-table .tableCol:nth-child(3) {
  flex-basis: 5000px;
  min-width: 172px;
}
.balance-transactions-table .tableCol:nth-child(4) {
  min-width: 130px;
}
.balance-transactions-table .tableCol:nth-child(5) {
  min-width: 140px;
}
.balance-transactions-table .tableCol:nth-child(6) {
  min-width: 120px;
}
.balance-invoices-table .tableCol:nth-child(1) {
  flex-basis: 10px;
}
.balance-invoices-table .tableCol:nth-child(2) {
  min-width: 70px;
}
.balance-invoices-table .tableCol:nth-child(3) {
  min-width: 170px;
}
.balance-invoices-table .tableCol:nth-child(4) {
  flex-basis: 5000px;
}
.balance-invoices-table .tableCol:nth-child(5) {
  min-width: 130px;
}
.balance-invoices-table .tableCol:nth-child(6) {
  min-width: 223px;
}
.balance-invoices-table .tableCol:nth-child(7) {
  min-width: 150px;
}
#balance-invoices-calls,
#balance-transactions-calls {
  background-color: #fff;
}
#balance-invoices-calls .balance-invoices-table-one-invoice:nth-child(even),
#balance-transactions-calls .balance-transactions-table-one-transaction:nth-child(even) {
  background-color: #f7fafc;
}
.balance-sections-wrap section.content-current {
  display: block;
}
.balance-sections-wrap section {
  display: none;
  margin: 0 auto;
  min-height: 150px;
}
@media (max-width: 1600px) {
  .balance-sections-wrap section .unitalkDatePicker .dateDesc {
    display: none;
  }
}
.balance-comparing {
  background-color: #fff;
  padding: 20px;
}
.balance-transactions-act-download {
  background-color: #fff;
  height: 50px;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
}
.balanceFiltersLeftInvisibleDiv {
  display: flex;
  width: 320px;
}
@media (max-width: 1480px) {
  .balance-card-warning .balance-card-topup {
    padding: 15px 15px;
  }
  .balance-card-warning .balance-card-topup .button_default {
    padding: 0 20px;
  }
}
@media (max-width: 1440px) {
  .balanceFiltersLeftInvisibleDiv {
    display: none;
  }
}
.balance-type {
  font-size: 16px;
  font-weight: 500;
}
.creditMoneyWarning {
  margin-left: 7px;
  font-size: 11px;
  color: red;
}
.creditMoneyWarning .balance-card-currency {
  font-size: 11px;
  color: red;
}
.balanceCardCreditRequestWarning {
  margin-left: 7px;
  font-size: 11px;
  color: red;
}
#balance-outers-section .tablesorter .thead {
  background-color: #657689;
  font-weight: 500;
  color: #fff;
}
#balance-outers-section .subTabs {
  margin-top: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
}
#balance-outers-section .subTabs .oneSubTab {
  background-color: #fff;
  width: calc((100% / 6) - 4px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #7F7F7F 1px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#balance-outers-section .subTabs .oneSubTab:hover {
  color: #ff5656;
}
#balance-outers-section .subTabs .oneSubTab.active {
  cursor: default;
  position: relative;
  color: #fff;
  background: #657689;
}
#balance-outers-section .oneSubSectionHeader {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  gap: 4px;
  height: 50px;
}
#balance-outers-section .oneSubSectionHeader > div {
  width: calc((100% / 6) - 4px);
}
#balance-outers-section .oneSubSectionHeader .bootstrap-select {
  width: 100%;
}
#balance-report-section {
  padding: 15px;
}
#balance-report-section .setParentProjectButton {
  cursor: pointer;
  color: #1b75bc;
}
#balance-report-section .setParentProjectButton:hover {
  text-decoration: underline;
}
#balance-report-section .promoInviterProjectInfo {
  padding: 5px;
  color: #0089ff;
}
#balance-report-section .financialRootProjectDiv {
  color: #0089ff;
}
#balance-report-section .promoInviterData {
  color: #1b75bc;
  font-weight: 500;
}
/*************
  Blue Theme
 *************/
/* overall */
.tablesorter-nextel {
  width: 100%;
  background-color: #fff;
  text-align: left;
}
.tablesorter-nextel th,
.tablesorter-nextel thead td {
  color: #fff;
  background-color: #657689;
  font-weight: 500;
  padding: 15px 8px;
  font-size: 14px;
  line-height: 1.42857143;
}
.tablesorter-nextel tbody td,
.tablesorter-nextel tfoot th,
.tablesorter-nextel tfoot td {
  padding: 15px 8px;
}
.tablesorter-nextel th:first-child,
.tablesorter-nextel thead td:first-child,
.tablesorter-nextel tbody td:first-child,
.tablesorter-nextel tfoot th:first-child,
.tablesorter-nextel tfoot td:first-child {
  padding-left: 25px;
}
.tablesorter-nextel th:last-child,
.tablesorter-nextel thead td:last-child,
.tablesorter-nextel tbody td:last-child,
.tablesorter-nextel tfoot th:last-child,
.tablesorter-nextel tfoot td:last-child {
  padding-right: 25px;
}
.tablesorter-nextel .header,
.tablesorter-nextel .tablesorter-header {
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  white-space: normal;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDIwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQgMTNsLTIuNS0yLjVMNyAxNWwtNC41LTQuNUwwIDEzbDcgN3pNMTQgNy41TDExLjUgMTAgNyA1LjUgMi41IDEwIDAgNy41bDctN3oiLz48L3N2Zz4=);
}
.tablesorter-nextel .headerSortUp,
.tablesorter-nextel .tablesorter-headerSortUp,
.tablesorter-nextel .tablesorter-headerAsc {
  background-color: #7b8fa5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQgOS41TDExLjUgMTIgNyA3LjUgMi41IDEyIDAgOS41bDctN3oiLz48L3N2Zz4=);
}
.tablesorter-nextel .headerSortDown,
.tablesorter-nextel .tablesorter-headerSortDown,
.tablesorter-nextel .tablesorter-headerDesc {
  background-color: #77889b;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQgNWwtMi41LTIuNS00LjUgNC41LTQuNS00LjVMMCA1IDcgMTJ6Ii8+PC9zdmc+);
}
.tablesorter-nextel tbody tr {
  border-top: 1px solid #e4e7ea;
}
.tablesorter-nextel tbody tr:nth-child(odd) {
  background-color: #fafdff;
}
.tablesorter-nextel tbody tr:nth-child(even) {
  background-color: #f7fafc;
}
.balance-card-balance .balanceCardCreditMoneyRequestDiv {
  display: flex;
  align-items: center;
  width: 140px;
  transition: all 0.3s ease;
  color: #4a8df1;
  align-self: flex-end;
  cursor: pointer;
}
.balance-card-balance .balanceCardCreditMoneyRequestDiv h2 {
  margin-top: 0;
  font-weight: normal;
  color: #4a8df1;
  font-size: 16px;
}
.balance-card-balance .balanceCardCreditMoneyRequestDiv:hover {
  text-decoration: underline;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv,
.balanceAskForCreditMoneyModalBody .moneyDateDiv {
  display: flex;
  align-items: center;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv .moneyCreditDateSelect,
.balanceAskForCreditMoneyModalBody .moneyDateDiv .moneyCreditDateSelect {
  font-weight: 500;
  padding: 4px;
  padding-left: 11px;
  padding-top: 7px;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv {
  margin-bottom: 15px;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv .moneyAmountMaxDiv {
  color: #a8a7a7;
  font-size: 13.5px;
  margin-left: 15px;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv .moneyAmountInputDiv {
  position: relative;
}
.balanceAskForCreditMoneyModalBody .moneyAmountDiv .moneyAmountInputDiv .moneyAmountCurrency {
  position: absolute;
  top: 8px;
  right: 3px;
  color: #a8a7a7;
  font-size: 15px;
  font-weight: 400;
}
.balanceAskForCreditMoneyModalBody .dropdown-menu {
  padding: 0 !important;
}
.root-selector-div-desc {
  display: flex;
}
.balanceMonthSelectorDiv {
  position: relative;
}
.balanceMonthSelectorDiv .balanceMonthSelector {
  top: 50px;
  left: -86px;
  background: #fff;
  z-index: 1;
}
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearSelector {
  display: flex;
  justify-content: center;
}
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearMinusButton,
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearPlusButton,
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYear {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearMinusButton,
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearPlusButton {
  padding: 20px;
  font-size: 35px;
  cursor: pointer;
}
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearMinusButton:hover,
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYearPlusButton:hover {
  background: #f7f7f7;
}
.balanceMonthSelectorDiv .balanceMonthSelector .balanceYear {
  font-size: 20px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.balanceMonthSelectorDiv .balanceMonthSelector .monthsRow {
  display: flex;
}
.balanceMonthSelectorDiv .balanceMonthSelector .monthsRow .month {
  height: 60px;
  width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.balanceMonthSelectorDiv .balanceMonthSelector .monthsRow .month:hover {
  background: #f7f7f7;
}
.balanceMonthSelectorDiv .balanceMonthSelector .monthsRow .month.selected {
  background: #308bec !important;
  color: #fff;
}
.oneOwnersProject {
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
  padding: 10px;
  padding-left: 20px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.oneOwnersProject:hover {
  box-shadow: 3px 3px 22px -3px rgba(0, 0, 0, 0.6);
}
.acts-table-section {
  display: flex;
}
.acts-table-section .nameTableRow {
  text-align: left;
  padding-left: 5px;
}
.acts-table-section .countTableRow {
  text-align: right;
  padding-right: 5px;
}
.acts-table-section .sumTableRow {
  text-align: right;
  padding-right: 5px;
}
.acts-table-section .tableRow {
  border-left: 1px solid;
  border-bottom: 1px solid;
}
.balance-reports-date {
  width: 260px;
  background-color: #fff;
}
.balance-reports-date > div:first-child {
  float: left;
  line-height: 30px;
  font-weight: 500;
  padding: 10px;
  cursor: default;
}
.balance-reports-date > div:last-child {
  float: left;
  width: 180px;
  height: 100%;
}
.incomeOutcomeButton,
.summarizedSpendItemsByCategoryButton,
.showPromoInfoButton {
  margin-top: 5px;
  background: #fff;
  height: 50px;
  padding: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  cursor: pointer;
}
.showPromoInfoButton {
  color: #0089ff;
}
.summarizedSpendItemsByCategoryButton {
  margin-top: 10px;
}
.tableCell {
  background: #fff;
}
.divForIncomeAndOutcomeTable {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable {
  background: #fff;
  border: 1px solid;
  width: 915px;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable .income-outcome-table-section {
  border-bottom: 1px solid;
  display: flex;
  background: #eee;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable .income-outcome-table-section > div {
  width: 175px;
  border-right: 1px solid;
  text-align: right;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable .income-outcome-table-section > div:first-child {
  width: 220px;
  text-align: left;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable .income-outcome-table-section > div:last-child {
  border-right: none;
}
.divForIncomeAndOutcomeTable .incomeOutcomeTable .income-outcome-table-section.tableHeader > div {
  text-align: center;
}
.divForSummarizingTable {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.divForSummarizingTable .summarizingTransactionsTable {
  background: #fff;
  border: 1px solid;
  width: 915px;
}
.divForSummarizingTable .summarizingTransactionsTable .tableRow {
  border-bottom: 1px solid;
}
.divForSummarizingTable .summarizingTransactionsTable .tableRow > div:first-child {
  width: 600px;
  border-right: 1px solid;
}
.divForSummarizingTable .summarizingTransactionsTable .tableRow > div:nth-child(2) {
  width: 165px;
  border-right: 1px solid;
}
.divForSummarizingTable .summarizingTransactionsTable .tableRow > div:last-child {
  width: 150px;
}
.divForShowPromoInfo {
  margin: 10px;
  color: #1b75bc;
  font-weight: 500;
}
#stat-op-tab-list li a {
  margin: 0;
}
#stat-op-tab-list li {
  margin: 0 2px;
}
.paginationTableItem .payDiv {
  display: flex;
  gap: 3px;
}
.paginationTableItem .payDiv .balanceInvoiceCloseButton,
.paginationTableItem .payDiv .balanceInvoicePayDevEnvButton {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 3px;
}
.my-tariff-main-div {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1650px) {
  .my-tariff-main-div {
    flex-direction: column;
  }
}
.my-tariff-main-div > div {
  width: 50%;
  padding: 20px;
}
@media (max-width: 1650px) {
  .my-tariff-main-div > div {
    width: 100%;
  }
}
.my-tariff-main-div .include-and-extra-div-wrap,
.my-tariff-main-div .order-extra-div,
.my-tariff-main-div .discountDiv {
  min-width: 700px;
}
.my-tariff-main-div .include-and-extra-div-wrap .tariff-title,
.my-tariff-main-div .order-extra-div .tariff-title,
.my-tariff-main-div .discountDiv .tariff-title {
  font-size: 30px;
  color: #30323e;
}
.my-tariff-main-div .order-extra-div {
  padding: 20px 40px;
}
.my-tariff-main-div .nextTariffWarning {
  display: none;
  margin-top: 5px;
  align-items: center;
}
.my-tariff-main-div .nextTariffWarning .nextTariffName {
  font-weight: 500;
  color: #c143d8;
  margin-left: 10px;
}
.my-tariff-main-div .nextTariffWarning .cancelTariffChangeButton {
  color: #3c99ec;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  text-decoration: underline;
}
.my-tariff-main-div .nextTariffWarning .changeTariffPenaltyDiv {
  display: none;
  margin-left: 10px;
  align-items: center;
}
.my-tariff-main-div .discountsSection {
  display: none;
  flex-grow: 1;
  min-width: 700px;
}
.my-tariff-main-div .discountsSection .discountDiv {
  padding: 30px 40px;
  background-color: #fff;
  width: 100%;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
  gap: 5px;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount .name {
  min-width: 300px;
  max-width: 300px;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount .start,
.my-tariff-main-div .discountsSection .discountsList .oneDiscount .end {
  min-width: 120px;
  max-width: 120px;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount .percent {
  min-width: 80px;
  max-width: 80px;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount input {
  height: 40px;
}
.my-tariff-main-div .discountsSection .discountsList .oneDiscount .target {
  min-width: 400px;
  max-width: 400px;
  height: 40px;
  display: flex;
  flex-grow: 1;
}
.include-and-extra-div-wrap {
  background-color: #FFF;
}
.include-and-extra-div-wrap .tariff-name {
  font-weight: 600;
  color: #c143d8;
  font-size: 22px;
  margin-left: 20px;
  line-height: 30px;
  padding-top: 5px;
}
.include-and-extra-div-wrap .include-and-extra-div .editTariffPeriodButton {
  font-weight: 500;
  color: #3c99ec;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  text-decoration: underline;
}
.include-and-extra-div-wrap .include-and-extra-div .editTariffPeriodButton:hover {
  text-decoration: underline;
}
.include-and-extra-div-wrap .include-and-extra-div .forceProlongButton {
  font-weight: 500;
  color: #3c99ec;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  text-decoration: underline;
}
.include-and-extra-div-wrap .include-and-extra-div .forceProlongButton:hover {
  text-decoration: underline;
}
.include-and-extra-div {
  position: relative;
}
.include-and-extra-div .tariff-activate-button-div {
  position: absolute;
  right: 40px;
  top: 40px;
}
.order-extra-div {
  background-color: #FFF;
}
.tariff-one-included-service {
  display: flex;
  padding: 15px;
  background-color: #fafbfc;
}
.tariff-one-included-service .first-line {
  font-weight: 400;
  color: #6c6c6c;
  font-size: 14px;
}
.tariff-one-included-service:nth-child(even) {
  background-color: #f3f4f5;
}
.tariff-one-included-service-name {
  width: 60%;
  font-size: 15px;
  color: #000000;
}
.tariff-one-included-service-count-div {
  width: 40%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.tariff-one-included-service-count-div .tariff-one-included-service-price-without-discount-div {
  position: relative;
}
.tariff-one-included-service-count-div .tariff-one-included-service-price-without-discount-div .tariff-one-included-service-price-without-discount {
  text-decoration: line-through;
  color: #b1b1b1;
  position: absolute;
  top: -12px;
  font-size: 12px;
}
.include-and-extra-div {
  border-bottom: 1px solid #ebedee;
}
.tariffSummaryCostDiv {
  font-size: 18px;
  color: #30323e;
}
.tariffSummaryCostDiv .tariffWithoutDiscountDiv {
  font-size: 16px;
  line-height: 16px;
  color: #b1b1b1;
  text-decoration: line-through;
}
.tariffSummaryCostDiv .tariffWithDiscountDiv {
  line-height: 22px;
}
.tariff-red-cost {
  font-weight: 500;
  color: #f03f3c;
  font-size: 22px;
}
.tariff-extra-services-div {
  background-color: #fbfcfd;
}
.tariff-extra-services-div .tariff-extra-title {
  font-size: 20px;
  color: #30323e;
  padding: 20px 40px 0;
}
.tariff-extra-services-warning-div {
  padding: 20px 40px;
  display: none;
}
.tariff-extra-services-warning {
  border: 3px solid #f15250;
  padding: 15px 20px;
  position: relative;
}
.tariff-extra-services-warning .tariff-warning-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -13px;
  background-color: #fbfcfd;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #f03f3c;
  margin-bottom: 5px;
}
.tariff-extra-services-warning .tariff-warning-desc {
  font-size: 15px;
  color: #5b5b5b;
  padding-top: 10px;
}
.tariff-extra-services-warning .tariff-warning-next-price-div {
  font-weight: 500;
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 8px;
}
.tariff-extra-services-warning .tariff-period-end {
  font-weight: 500;
}
.tariff-total-price-div {
  padding: 30px 40px 30px;
  font-size: 18px;
  color: #30323e;
  border-top: 1px solid #ebedee;
  position: relative;
}
.tariff-cost-desc {
  padding-left: 8px;
  font-size: 12px;
  color: #b1b1b1;
  font-weight: 400;
  bottom: 15px;
  left: 100px;
}
.tariff-service-counter {
  border: 1px solid #c0cbd5;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  height: 40px;
  width: 75px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #9babba;
}
.tariff-service-counter .counter-red {
  color: #f03f3c;
}
.tariff-service-counter > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tariff-service-counter .counter-minus,
.tariff-service-counter .counter-plus {
  cursor: pointer;
  width: 20px;
}
.tariff-one-service-cost,
.tariff-one-service-total-cost {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  position: relative;
}
.tariff-one-service-cost .tariff-service-without-discount-div,
.tariff-one-service-total-cost .tariff-service-without-discount-div,
.tariff-one-service-cost .tariff-service-without-discount-total-div,
.tariff-one-service-total-cost .tariff-service-without-discount-total-div {
  position: absolute;
  font-size: 13px;
  color: #b1b1b1;
  text-decoration: line-through;
  top: -12px;
  right: 0;
}
.tariff-one-service {
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 10px;
}
.tariff-one-service .tariff-one-service-counter {
  width: 90px;
}
.tariff-one-service .lease-label {
  background-color: #ff9800;
  font-weight: 500;
  border-radius: 60px;
  margin-right: 5px;
  padding: 3px 5px;
  letter-spacing: 0.05em;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 10px;
  display: inline;
  height: 16px;
}
.tariff-one-service .tariff-one-extra-service-count-desc {
  font-size: 13px;
  font-weight: 500;
  margin-right: 4px;
}
.tariff-one-service .tariff-one-extra-service-auto-manage-desc {
  font-size: 13px;
  font-weight: 500;
}
.tariff-one-service .tariff-one-extra-service-auto-manage-desc .modalTooltip {
  top: 9%;
}
.tariff-one-service .tariff-one-service-info-div {
  font-size: 16px;
  color: #171717;
}
.tariff-one-service .tariff-one-service-info-div .tariff-one-service-info {
  display: flex;
  align-items: center;
}
.tariff-one-service .tariff-one-service-max-count-div,
.tariff-one-service .tariff-one-service-activate-price-div {
  font-size: 13px;
  color: #787878;
}
.tariff-one-service .tariff-one-service-wrap {
  margin-left: auto;
  display: flex;
}
.tariff-one-service .tariff-one-service-wrap .cost-inactive > span {
  color: #b9b9b9;
}
.tariff-one-service .tariff-one-service-wrap .tariff-one-service-cost {
  min-width: 70px;
  font-size: 11px;
  text-align: right;
}
.tariff-one-service .tariff-one-service-wrap .tariff-one-service-cost .tariff-service-cost,
.tariff-one-service .tariff-one-service-wrap .tariff-one-service-cost .tariff-project-currency {
  line-height: 22px;
  color: #b1b1b1;
}
.tariff-one-service .tariff-one-service-wrap .tariff-one-service-cost .tariff-service-without-discount-div {
  font-size: 11px;
  color: #cbcbcb;
}
.tariff-one-service .tariff-one-service-wrap .tariff-one-service-total-cost {
  min-width: 100px;
}
.tariff-connect-extra-connecting-div {
  position: relative;
  border: 3px solid #2e8ae0;
  padding: 15px 20px;
  font-weight: 400;
  line-height: 25px;
  display: none;
}
.tariff-connect-extra-connecting-div .tariff-extra-connect-buttons {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
.tariff-connect-extra-connecting-div .tariff-extra-connect-buttons .tariff-extra-connect-button {
  margin-bottom: 10px;
}
.tariff-connect-extra-connecting-div .tariff-extra-connect-buttons .btn {
  padding: 0 10px;
  height: 45px;
  line-height: 45px;
}
.tariff-connect-extra-connecting-div .tariff-services-connect-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 18px;
  top: -13px;
  background-color: #fff;
  color: #2e8ae0;
  padding-left: 20px;
  padding-right: 20px;
}
.tariff-connect-extra-connecting-div .tariff-connect-extra-no-money-warning {
  color: #f03f3c;
  font-weight: 500;
}
.tariff-connect-extra-connecting-div .tariff-connect-extra-money-text {
  font-weight: 500;
}
.tariff-connect-extra-connecting-div .addServicesForFree {
  color: #3c99ec;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}
.oneLeasePacket {
  border: 1px solid #fff;
  padding-bottom: 15px;
  position: relative;
}
.oneLeasePacket .sideLine {
  position: absolute;
  top: 0;
  bottom: 10px;
  left: -15px;
  background-color: #c143d8;
  width: 4px;
  transition: all 0.6s ease;
  opacity: 0;
}
.oneLeasePacket .header {
  display: flex;
}
.oneLeasePacket .header .numberAndLocation {
  width: 250px;
}
.oneLeasePacket .header .numberAndLocation .numberAndStop {
  display: flex;
}
.oneLeasePacket .header .numberAndLocation .number {
  padding-top: 0.5px;
  font-weight: 500;
  font-size: 19px;
  line-height: 19px;
  color: #c143d8;
  width: 180px;
}
.oneLeasePacket .header .numberAndLocation .stopState {
  position: relative;
}
.oneLeasePacket .header .numberAndLocation .stopState .stopIcon {
  height: 20px;
}
.oneLeasePacket .header .numberAndLocation .stopState .stopDescWrap {
  z-index: 1;
  position: absolute;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  top: -10px;
  left: -10px;
  display: none;
  width: 230px;
}
.oneLeasePacket .header .numberAndLocation .stopState .stopDescWrap:hover {
  display: block;
}
.oneLeasePacket .header .numberAndLocation .stopState .stopDescWrap .changeTariffButton,
.oneLeasePacket .header .numberAndLocation .stopState .stopDescWrap .addSpecialConditionsButton,
.oneLeasePacket .header .numberAndLocation .stopState .stopDescWrap .showTariffButton {
  color: #005fff;
}
.oneLeasePacket .header .numberAndLocation .stopState:hover .stopDescWrap {
  display: block;
}
.oneLeasePacket .header .numberAndLocation .stopState .iconAndDescription {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.oneLeasePacket .header .numberAndLocation .stopState .iconAndDescription .stateDesc {
  margin-left: 10px;
  font-weight: 500;
}
.oneLeasePacket .header .numberAndLocation .stopState .pseudo-button {
  font-weight: 500;
  margin-left: 29px;
}
.oneLeasePacket .header .numberAndLocation .stopState .pseudo-button > span:before {
  left: -28px;
}
.oneLeasePacket .header .stopState .greenIcon {
  display: block;
}
.oneLeasePacket .header .stopState .redIcon {
  display: none;
}
.oneLeasePacket .header.showActions .stopDescWrap {
  display: block !important;
}
.oneLeasePacket .header.stopped .number {
  color: #9b9b9b;
}
.oneLeasePacket .header.stopped .stopState .greenIcon {
  display: none;
}
.oneLeasePacket .header.stopped .stopState .redIcon {
  display: block;
}
.oneLeasePacket .header .tariffAndMulti .tariff,
.oneLeasePacket .header .tariffAndMulti .multi {
  display: flex;
}
.oneLeasePacket .header .tariffAndMulti .tariff .desc,
.oneLeasePacket .header .tariffAndMulti .multi .desc {
  font-weight: 500;
  width: 100px;
}
.oneLeasePacket .header .price {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.oneLeasePacket .info .divForMinutesLimits {
  width: 100%;
}
.oneLeasePacket .info .phoneProgressBar {
  width: 100%;
  font-weight: 500;
  height: 1px;
  margin-bottom: 1px;
  transition: all 0.3s ease;
}
.oneLeasePacket .info .phoneProgressBar .progressInfo {
  display: flex;
  justify-content: space-between;
  height: 1px;
  transition: all 0.3s ease;
}
.oneLeasePacket .info .phoneProgressBar .indexes,
.oneLeasePacket .info .phoneProgressBar .description {
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
  opacity: 0;
}
.oneLeasePacket .info .phoneProgressBar .progressWrap {
  transition: all 0.3s ease;
  -webkit-box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.13);
  box-shadow: none !important;
  height: 1px;
  border-radius: 0px;
  margin-bottom: 0px;
  overflow: hidden;
}
.oneLeasePacket .info .phoneProgressBar .progressWrap .progressBar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  float: left;
  height: 100%;
}
.oneLeasePacket .infoMetricDescription {
  padding-top: 0px;
  transition: all 0.3s ease;
  opacity: 0;
  max-height: 0px;
}
.oneLeasePacket .divForMinutesLimits {
  margin-bottom: 3px;
}
.oneLeasePacket:hover .sideLine {
  opacity: 1;
}
.oneLeasePacket:hover .infoMetricDescription {
  padding-top: 10px;
  opacity: 1;
  max-height: unset;
}
.oneLeasePacket:hover .oneCallPrice {
  height: 15px;
  border-bottom: 2px solid rgba(255, 0, 32, 0.3);
}
.oneLeasePacket:hover .oneCallPrice .indexes,
.oneLeasePacket:hover .oneCallPrice .description {
  opacity: 1;
}
.oneLeasePacket:hover .divForMinutesLimits .phoneProgressBar {
  height: 20px;
}
.oneLeasePacket:hover .divForMinutesLimits .phoneProgressBar .indexes,
.oneLeasePacket:hover .divForMinutesLimits .phoneProgressBar .description {
  opacity: 1;
}
.oneLeasePacket:hover .divForMinutesLimits .phoneProgressBar .progressWrap {
  height: 2px;
}
.oneLeasePacket:hover .divForMinutesLimits .phoneProgressBar .progressInfo {
  height: 12px;
}
.oneLeasePacket .changeNumberDiv .listOfNumbers {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}
.oneLeasePacket .changeNumberDiv .listOfNumbers .oneNumberToChange {
  cursor: pointer;
  border: #cfcfcf 1px solid;
  margin: 3px;
  padding: 3px;
  font-weight: 500;
  width: 115px;
}
.oneLeasePacket .changeNumberDiv .listOfNumbers .oneNumberToChange:hover {
  color: #00AEEF;
  border: #00AEEF 1px solid;
}
.oneLeasePacket .projectCurrency {
  font-weight: 500;
}
.oneLeasePacket .showCallPricesButton {
  font-size: 11px;
  display: inline-block;
}
.oneLeasePacket .showCallPricesButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.oneLeasePacket .showSpecialConditionsDiv {
  color: #0069ff;
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
}
.oneLeasePacket .showSpecialConditionsDiv:hover {
  cursor: pointer;
  text-decoration: underline;
}
.hideSectionDescription .infoMetricDescription {
  display: none;
}
.sliderWrap {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #ebedee;
  position: relative;
}
.sliderWrap .sliderHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
}
.sliderWrap .sliderHeader .sliderHeaderLeft {
  font-size: 20px;
}
.sliderWrap .sliderHeader .sliderHeaderRight {
  width: 220px;
  display: flex;
  align-items: center;
}
.sliderWrap .sliderBody {
  padding-bottom: 20px;
}
.sliderWrap .sliderToggleButton {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 40px;
}
.changeMultiList .tariffOrMultiItem,
.changeTariffList .tariffOrMultiItem {
  margin-top: 10px;
}
.audioRecordsProgress,
.separateRecordsProgress {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #000000;
  background-color: #f3f4f5;
  padding: 15px;
}
.audioRecordsProgress .barHeader,
.separateRecordsProgress .barHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
}
.audioRecordsProgress .barBody,
.separateRecordsProgress .barBody {
  width: 100%;
  height: 4px;
  position: relative;
  background-color: #fff;
}
.audioRecordsProgress .barBody .barProgress,
.separateRecordsProgress .barBody .barProgress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #4caf50;
}
.audioRecordsProgress .barFooter,
.separateRecordsProgress .barFooter {
  margin-top: 5px;
}
.ttsAndSttDiv > div {
  margin-top: 10px;
}
.speechRecognitionPrices,
.ttsPrices {
  padding: 15px;
  background-color: #f3f4f5;
}
.speechRecognitionPrices .divForTtsPrices,
.ttsPrices .divForTtsPrices {
  display: flex;
  flex-wrap: wrap;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice,
.ttsPrices .divForTtsPrices .oneTtsPrice {
  display: flex;
  width: 50%;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice .oneTtsType,
.ttsPrices .divForTtsPrices .oneTtsPrice .oneTtsType {
  width: 140px;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice .currencySign,
.ttsPrices .divForTtsPrices .oneTtsPrice .currencySign {
  width: 13px;
  display: flex;
  justify-content: end;
  margin-right: 5px;
  font-weight: 500;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice .fullCost,
.ttsPrices .divForTtsPrices .oneTtsPrice .fullCost {
  display: flex;
  font-weight: 500;
  position: relative;
  padding-right: 5px;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice .discountCost,
.ttsPrices .divForTtsPrices .oneTtsPrice .discountCost {
  display: flex;
  color: #000000;
  font-weight: 500;
  margin-left: 10px;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice .horizontalLine,
.ttsPrices .divForTtsPrices .oneTtsPrice .horizontalLine {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
  height: 1px;
  background-color: #8a8a8a;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice.discountAvailable .fullCost,
.ttsPrices .divForTtsPrices .oneTtsPrice.discountAvailable .fullCost {
  color: #8a8a8a;
}
.speechRecognitionPrices .divForTtsPrices .oneTtsPrice.discountAvailable .horizontalLine,
.ttsPrices .divForTtsPrices .oneTtsPrice.discountAvailable .horizontalLine {
  display: block;
}
.periodEditorModal {
  padding: 15px;
}
.periodEditorModal .periodRow {
  display: flex;
  gap: 5px;
}
.periodEditorModal .periodRow .descriptionPropertyDiv {
  display: flex;
  position: relative;
}
.periodEditorModal .periodRow .descriptionPropertyDiv .inputPlaceholder {
  position: absolute;
  top: 8px;
  right: 6px;
}
.periodEditorModal .periodRow > * {
  margin-top: 5px;
}
.periodEditorModal .periodRow .buttonDiv {
  display: flex;
  align-items: end;
}
.periodEditorModal .periodRow .buttonDiv .editTariffPeriodButton {
  height: 35px;
  width: 170px;
  line-height: 35px;
  font-size: 17px;
  color: #fff;
  border: none;
  padding: 0 25px;
  font-weight: 500;
  background-color: #36af38;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.periodEditorModal .periodRow .inputField {
  height: 35px;
  width: 170px;
  border: 1px solid #c0cbd5;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  color: #535353;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 12px;
}
.orders-table {
  margin-top: 20px;
}
.orders-table-col {
  padding: 15px 8px;
}
.orders-table-col:nth-child(1) {
  flex-basis: 40px;
}
.orders-table-col:nth-child(2) {
  flex-basis: 150px;
  min-width: 100px;
}
.orders-table-col:nth-child(3) {
  flex-basis: 220px;
  min-width: 220px;
}
.orders-table-col:nth-child(4) {
  flex-basis: 250px;
  min-width: 220px;
}
.ordersOneOrderLeftDate,
.ordersOneOrderCallDate {
  min-width: 87px;
}
.orders-table-col:nth-child(5) {
  display: flex;
  justify-content: center;
  flex-basis: 150px;
  min-width: 120px;
}
.orders-table-col:nth-child(6) {
  flex-basis: 300px;
  min-width: 150px;
}
.orders-table-col:nth-child(7) {
  flex-basis: 400px;
}
.orders-table-source-col {
  display: flex;
  align-items: center;
}
.orders-col-site-name-div {
  position: absolute;
  left: 20px;
  top: 10px;
  color: #308bec;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 1440px) {
  .orders-col-site-name-div {
    left: 10px;
    top: 10px;
    max-width: 140px;
  }
}
.orders-table-counters {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.orders-table-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.orders-table-row-main-info {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.orders-table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
}
.orders-table-ordered-header {
  padding: 15px;
  background-color: #dfe6ed;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
}
.orders-table-row-utm-collapsed {
  display: none;
}
.orders-table-row-utm-expanded {
  display: none;
}
.orders-table-row-utm-expanded .lastUrl {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .orders-table-row-utm-collapsed {
    display: none !important;
  }
  .orders-table-row-utm-expanded {
    display: block;
  }
  .orders-table-col:nth-child(7) {
    flex-basis: 550px;
  }
}
@media (min-width: 1800px) {
  .orders-table-col:nth-child(7) {
    flex-basis: 660px;
  }
}
.orders-table-one-call {
  background-color: #fff;
}
.orderSourceLabel {
  font-size: 12px;
  font-weight: 500;
  height: 21px;
  border: 1px solid #000;
  display: inline-flex;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}
.orderSourceLabel.orderSourceLabelForm {
  color: #ed9d12;
  border-color: #ed9d12;
}
.orderSourceLabel.orderSourceLabelPopUp {
  color: #ec30d8;
  border-color: #ec30d8;
}
.orderSourceLabel.orderSourceLabelC2c {
  color: #308bec;
  border-color: #308bec;
}
.ordersClientInfo .ordersClientName {
  font-weight: 500;
  font-size: 18px;
  color: #2e2f35;
}
.ordersClientInfo .ordersClientNumber,
.ordersClientInfo .ordersClientEmail {
  font-size: 12px;
  color: #919191;
}
.ordersClientInfo .ordersClientName,
.ordersClientInfo .ordersClientNumber {
  margin-bottom: 3px;
}
.ordersClientInfo .ordersClientEmail {
  margin-bottom: 7px;
}
.ordersClientInfo .button_wrapp {
  position: relative;
}
.ordersClientInfo .button_wrapp button {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
}
.ordersClientInfo .pseudo-button-remove {
  position: absolute;
  right: 20px;
  top: 40px;
}
.when_app-wrapper {
  display: flex;
}
.when_app-wrapper .when_app {
  display: inline-block;
  font-size: 15px;
  color: #2e2f35;
}
.when_app-wrapper .when_app .text {
  font-size: 13px;
  color: #919191;
  margin-bottom: 2px;
}
.orders-table-row-utm {
  display: flex;
  flex-wrap: wrap;
}
.orders-table-row-utm > .label {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
}
.ordersDivWithFilters .orders-filters-first-row,
.ordersDivWithFilters .orders-filters-third-row {
  display: flex;
  flex-wrap: wrap;
}
.ordersDivWithFilters .orders-filters-main-types {
  display: flex;
  background-color: #fff;
}
.ordersDivWithFilters .orders-filters-main-types > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
}
.ordersDivWithFilters .orders-filters-main-types > div.active {
  background-color: #2cabe3;
  color: #fff;
}
.ordersDivWithFilters .filterItem {
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
}
.ordersDivWithFilters .ordersFilterInput {
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  background-color: #fff;
}
.ordersDivWithFilters .orders-filters-clear-button {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ordersDivWithFilters .orders-filters-one-type {
  cursor: pointer;
}
.roistat-main-div {
  padding: 30px;
  background-color: #fff;
}
.roistat-main-div input {
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 12px;
  width: 100%;
}
.roistat-header-div {
  font-weight: 500;
  line-height: 50px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
}
.roistat-div-with-input {
  margin-right: 30px;
  width: 350px;
}
.roistat-field-description {
  font-weight: 500;
  margin-bottom: 10px;
}
.zoho-connection-fields-div > div {
  margin-right: 15px;
}
.zoho-field-name {
  font-weight: 500;
  margin-bottom: 5px;
}
.zoho-responsible-user-schedule > div > div {
  float: left;
}
.zoho-responsible-user-schedule > div > div:first-child {
  width: 200px;
}
.zoho-responsible-user-schedule > div > div:last-child {
  width: 200px;
  height: 27px;
}
.zoho-responsible-user-schedule > div {
  margin: 15px;
}
.zoho-settings-general > div > div:first-child {
  width: 300px;
}
.zoho-settings-general > div > div:last-child {
  width: 200px;
}
.zoho-settings-general > div {
  margin: 15px;
}
.zoho-settings-general > div:last-child {
  margin-bottom: 0;
}
.zoho-operator-location-item > div {
  width: 200px;
  height: 26px;
  float: left;
}
.zoho-operator-location-item > div:last-child {
  margin-left: 15px;
}
.zoho-operator-phone-select {
  width: 120px;
}
.zoho-create-for-radio-div,
.zoho-create-deal-select {
  display: flex;
}
.zoho-create-for-radio-div .nextel-radio,
.zoho-create-deal-select .nextel-radio {
  width: 200px;
}
.custom-fields-block-template {
  background-color: #fff;
}
.custom-fields-block-template .custom-fields-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.custom-fields-block-template .custom-fields-div .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.zohoChatSettingsBlock,
.zohoCallSettingsBlock {
  background-color: #fff;
}
.entitiesByPipelinesBlock {
  padding-bottom: 15px;
}
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv {
  margin-left: 15px;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate {
  margin-left: 30px;
  margin-bottom: 5px;
  background-color: #fff;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1072px) {
  .entitiesByPipelinesBlock .oneRowSelectsTemplate,
  .entitiesByPipelinesBlock .entitiesColumnsNamesDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .entitiesByPipelinesBlock .oneRowSelectsTemplate .leadsColumnNameDiv,
  .entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadsColumnNameDiv,
  .entitiesByPipelinesBlock .oneRowSelectsTemplate .dealsColumnNameDiv,
  .entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealsColumnNameDiv {
    display: none;
  }
  .entitiesByPipelinesBlock .oneRowSelectsTemplate .next-select,
  .entitiesByPipelinesBlock .entitiesColumnsNamesDiv .next-select {
    margin-bottom: 5px;
  }
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate > div:first-child,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv > div:first-child {
  width: 200px;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate .next-select,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .next-select,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .leadsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadsColumnNameDiv,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .dealsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealsColumnNameDiv {
  width: 235px;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate .leadSourceSelect,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadSourceSelect,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .leadStatusSelect,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadStatusSelect,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .dealStageSelect,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealStageSelect,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .dealPipelineSelect,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealPipelineSelect,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .leadsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadsColumnNameDiv,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .dealsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealsColumnNameDiv {
  margin-right: 10px;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate span,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv span {
  margin-right: 2px;
}
.entitiesByPipelinesBlock .oneRowSelectsTemplate .leadsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .leadsColumnNameDiv,
.entitiesByPipelinesBlock .oneRowSelectsTemplate .dealsColumnNameDiv,
.entitiesByPipelinesBlock .entitiesColumnsNamesDiv .dealsColumnNameDiv {
  text-align: center;
}
.moy-sklad-connection-fields-div > div {
  margin-right: 15px;
}
.moy-sklad-settings-general > div > div:first-child {
  width: 350px;
}
.moy-sklad-settings-general > div > div:last-child {
  width: 200px;
}
.moy-sklad-settings-general > div {
  margin: 15px;
}
.moy-sklad-settings-general > div:last-child {
  margin-bottom: 0;
}
.moy-sklad-operator-location-item > div {
  width: 200px;
  float: left;
}
.moy-sklad-operator-location-item > div:last-child {
  margin-left: 15px;
}
.inner-number {
  float: left;
  width: 145px;
  height: 100%;
}
.innerPhoneHeader {
  background-color: #fff;
  color: #12171d;
  padding: 10px 20px 10px 15px;
}
.innerPhoneHeader .sip-settings-button-div {
  margin-left: auto;
  width: 185px;
}
.userAndSipFilterDiv {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  align-items: center;
}
.userAndSipFilterDiv .dropdown-multi-button,
.userAndSipFilterDiv .webra-input-field {
  height: 50px;
}
.noRecordsFoundWarning {
  color: white;
  font-size: 50px;
  position: relative;
  text-align: center;
  margin-top: 50px;
}
.innerPhoneHeader {
  display: flex;
  align-items: center;
}
.innerPhoneHeader .prompterButtonDiv {
  cursor: pointer;
}
.innerPhoneHeader .prompterButtonDiv .proxima-tooltip {
  transition: all 0.2s linear 0.7s;
  opacity: 0;
}
.innerPhoneHeader .prompterButtonDiv .pseudo-button {
  color: #000;
}
.innerPhoneHeader .prompterButtonDiv .pseudo-button:hover {
  color: #000 !important;
}
.innerPhoneHeader .prompterButtonDiv:hover {
  background-color: #f3f3f3;
  border-radius: 2px;
  color: #000 !important;
}
.innerPhoneHeader .prompterButtonDiv:hover .proxima-tooltip {
  opacity: 1;
}
.sip-settings-controls {
  display: flex;
  position: relative;
}
.sip-settings-controls .column {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}
.sip-settings-controls .unitalkTooltipZone {
  width: 30%;
}
.sip-settings-controls .oneSipSetting {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 300px;
  max-width: 300px;
  padding-right: 15px;
}
.sip-settings-controls .oneSipSetting.supportOption {
  color: #005fff;
}
.sip-settings-controls .oneSipSetting.supportOption .next-checkbox-square .checkmark {
  border-color: #c5e2ff;
}
.sip-settings-controls .oneSipSetting.supportOption .next-checkbox-square input:checked ~ .checkmark {
  background-color: #005fff;
}
.sip-settings-help {
  margin-top: 5px;
}
.sip-settings-help .usersSipPassUncovered {
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sip-settings-help .usersSipPassUncovered:hover {
  background-color: #fff;
  z-index: 1;
  overflow: visible;
}
.innerPhoneHeader .permanentPrompterNotify {
  font-weight: 500;
  color: #7a7a7a;
}
.innerPhoneHeader > div {
  margin-right: 5px;
}
.inner-number-talked-with > span,
.user-data-role {
  color: #3288e3;
  font-weight: 500;
}
/*что бы дропдаун мог быть шире чем сама кнопка*/
.innerPhoneHeader .dropdown-single-button .dropdown-menu {
  width: unset !important;
}
/*что бы дропдаун мог быть шире чем сама кнопка*/
.user-data-inner-phone-select .dropdown-menu {
  width: unset !important;
}
.user-data > div > div {
  line-height: 35px;
}
.user-data-full-name {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.user-data-role {
  font-size: 18px;
  line-height: 30px;
}
.user-data-email {
  color: darkgray;
  line-height: 30px;
}
.user-and-phone-container {
  -webkit-box-shadow: 0px 0px 7px -2px #7e7e7e;
  box-shadow: 0px 0px 7px -2px #7e7e7e;
}
.user-and-phone-container .primary-rounded-content-block {
  border-top: none;
  margin-top: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-and-phone-container .pseudo-button {
  margin-left: 0;
}
.user-data-settings > div > div > div {
  margin-bottom: 5px;
}
.user-data-fields > div {
  margin-bottom: 5px;
}
.user-data-fields > div > div:first-child {
  width: 150px;
}
.user-data-fields > div > div:last-child {
  width: 200px;
}
.user-data-fields .userPasswordGenerateNew {
  font-weight: 500;
}
.user-data-fields .userPasswordGenerateNew:hover {
  text-decoration: underline;
  cursor: pointer;
}
.user-registration-fields > div {
  margin-bottom: 5px;
}
.user-registration-fields > div > div:last-child {
  width: 200px;
}
.user-registration-fields > div > div:first-child {
  width: 80px;
}
.user-data-check-boxes > div {
  margin-bottom: 5px;
}
input[disabled="disabled"] ~ .checkmark {
  background-color: #eaeee7 !important;
  cursor: default !important;
}
.innerLineShowPassButton,
.usersCopyPassButton,
.usersChangePassButton,
.installSipClientButton,
.usersCopyUrlButton {
  color: #b8b8b8;
}
.innerLineShowPassButton:hover,
.usersCopyPassButton:hover,
.usersChangePassButton:hover,
.installSipClientButton:hover,
.usersCopyUrlButton:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #000000;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div {
  display: flex;
  align-items: center;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div div:nth-child(odd) {
  margin-right: 5px;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div div:nth-child(even) {
  margin-right: 20px;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div .inner-host-input,
.user-and-phone-container .inner-lines-ip-auth-settings-div .inner-port-input {
  height: 32px;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div .inner-host-input {
  width: 250px;
}
.user-and-phone-container .inner-lines-ip-auth-settings-div .inner-port-input {
  width: 76px;
}
.user-and-phone-container .originateIpSettingsDiv {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.user-and-phone-container .originateIpSettingsDiv .originateIpInput {
  width: 150px;
  height: 32px;
  margin-left: 15px;
  margin-right: 20px;
}
.user-and-phone-container .originateIpSettingsDiv .originateIpSaveButton {
  margin-left: 30px;
}
.inner-number-status-drop-down {
  position: relative;
}
.inner-number-status-drop-down:hover .innerIpAndPing {
  display: block;
}
.inner-number-status-drop-down .innerIpAndPing {
  z-index: 3;
  position: absolute;
  display: none;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  top: -10px;
  left: -10px;
  width: max-content;
}
.inner-number-status-drop-down .innerIpAndPing:hover {
  display: block;
}
.inner-number-status-drop-down .innerIpAndPing .innerPhoneStatus {
  cursor: default;
}
.inner-number-status-drop-down .innerIpAndPing .ipAndPingRow {
  display: flex;
  margin-top: 5px;
}
.inner-number-status-drop-down .innerIpAndPing .ipAndPingRow .ipAndPingParam {
  width: 70px;
  font-weight: 500;
  color: #1b76c7;
}
.userChatName,
.userEmailSign {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.userChatName .userChatNameInput,
.userEmailSign .userChatNameInput,
.userChatName .userEmailSignInput,
.userEmailSign .userEmailSignInput {
  margin-left: 10px;
  width: 300px;
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  color: #535353;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
}
.usersFakeDropDown {
  height: 34px !important;
}
.usersFakeDropDown.dropdownNotInitialized {
  line-height: 1.42857143 !important;
  padding: 6px 12px !important;
}
.changePassModal {
  padding: 20px;
  padding-top: 0;
}
.changePassModal button {
  height: 35px;
}
.changePassModal button.button_default {
  line-height: 35px !important;
}
.changePassModal .newPassInput {
  height: 35px;
}
.changePassModal .newPassInputDiv {
  display: flex;
  margin-top: 10px;
}
.changePassModal .requirements {
  display: flex;
  justify-content: space-between;
}
.changePassModal .requirements div:last-child {
  width: 200px;
  font-weight: 500;
  color: #0dad00;
}
.sipLoginAndPasswordDownloadButton button {
  box-shadow: none !important;
  border: none !important;
  background-color: #fff !important;
}
.sipLoginAndPasswordDownloadXlsx,
.sipLoginAndPasswordDownloadCsv {
  text-transform: initial;
}
.loginsSection {
  border-top: none;
  margin-top: 2px;
  padding-top: 10px;
  margin-bottom: 20px;
  background-color: #FFF;
  -webkit-box-shadow: 0px 0px 7px -2px #7e7e7e;
  box-shadow: 0px 0px 7px -2px #7e7e7e;
}
.loginsSection .tableCol {
  display: flex;
  align-items: center;
}
.loginsSection .tableCol:nth-child(1) {
  flex-basis: 20px;
  min-width: 20px;
}
.loginsSection .tableCol:nth-child(2) {
  min-width: 250px;
}
.loginsSection .tableCol:nth-child(3) {
  flex-basis: 150px;
  min-width: 300px;
  max-width: 300px;
}
.loginsSection .tableCol:nth-child(4) {
  flex-basis: 100px;
  min-width: 200px;
}
.loginsSection .tableCol:nth-child(5) {
  flex-basis: 150px;
  min-width: 800px;
}
.loginsSection .loginItemRow {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.loginsSection .loginsFilterInput {
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  background-color: #fff;
}
.loginsSection .loginsDownloadButton {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
  transition: all 0.1s ease-in;
}
.loginsSection .loginsDownloadButton > span {
  font-weight: 500;
}
.loginsSection .loginsDownloadButton:hover {
  box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.2);
}
.loginsSection .filterItem {
  height: 45px;
}
.loginsSection .btn-default {
  padding: 11px;
}
.divForCodecs {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.divForCodecs .oneCodec {
  margin-bottom: 1px;
  margin-right: 10px;
  background-color: #ffffff;
  font-weight: 500;
  box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.4);
  padding-left: 25px;
  padding-right: 10px;
  cursor: move;
  width: 110px;
  height: 20px;
}
.divForCodecs .oneCodec .next-checkbox-square .checkmark {
  left: -25px;
  top: 0;
}
.inner-phone .teamsConnectionSettings {
  display: flex;
  padding-top: 10px;
}
.inner-phone .teamsConnectionSettings .oneTeamsParameter {
  width: 440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  height: 30px;
}
.inner-phone .teamsConnectionSettings .oneTeamsParameter .paramName {
  width: 150px;
  font-weight: 500;
}
.inner-phone .teamsConnectionSettings .oneTeamsParameter .paramValue {
  width: 280px;
  height: 30px;
}
.inner-phone .teamsConnectionSettings .teamsUnitalkDomainSelect,
.inner-phone .teamsConnectionSettings .teamsClientDomainInput {
  width: 100%;
  height: 30px;
}
.inner-phone .teamsConnectionSettings .dropdownNotInitialized {
  padding: 3px;
  padding-left: 8px;
}
.inner-phone .teamsConnectionSettings .dropdown-multi-button .btn-default {
  padding: 3px;
  padding-left: 8px;
  width: 275px;
}
.userAccessBlock {
  padding: 10px;
  background-color: #fff;
}
.userAccessBlock .accessPresets {
  display: flex;
  align-items: center;
  background-color: #EBF5FF;
  padding: 10px;
  padding-left: 20px;
}
.userAccessBlock .accessPresets .accessRadios label {
  margin-left: 20px;
}
.userAccessBlock .accessPresets .accessRadios label span {
  font-weight: 500;
}
.userAccessBlock .divForAccessGroups {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.userAccessBlock .permitsGroup {
  width: 380px;
}
.userAccessBlock .permitsGroup .groupName {
  display: inline-block;
  border-bottom: 1px solid #CAE0ED;
  color: #5B6D82;
  line-height: 16px;
  margin-bottom: 7px;
}
.userAccessBlock .onePermission {
  margin-bottom: 10px;
}
.userAccessBlock .onePermission .permissionHeader {
  display: flex;
  align-items: center;
  height: 20px;
}
.userAccessBlock .onePermission .permissionHeader .permissionIcon {
  width: 25px;
}
.userAccessBlock .onePermission .permissionHeader .permissionName {
  margin-left: 10px;
  font-weight: 500;
}
.userAccessBlock .oneLevel {
  margin-top: 7px;
  margin-left: 30px;
}
.userAccessBlock .oneLevel .levelName {
  font-weight: 500;
}
.userAccessBlock .oneLevel .groupsSelect {
  margin-right: 20px;
  margin-left: 30px;
}
.userAccessBlock .accessRadios {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userAccessBlock .accessRadios .masterGroupsSelect,
.userAccessBlock .accessRadios .btn-group,
.userAccessBlock .accessRadios button {
  height: 30px;
}
.userAccessBlock .accessRadios .dropdown-multi-button .btn-default {
  padding: 0;
  padding-left: 5px;
}
.userAccessBlock .accessRadios .masterGroupsSelect {
  margin-left: 10px;
}
.userChangeStatusTriggerActionsSelect .btn-default {
  padding: 6px 12px;
}
.work-schedule-main-statistic-block {
  text-align: center;
}
.work-schedule-content {
  text-align: left;
  margin-bottom: 30px;
}
.work-schedule-range-picker > * {
  margin-right: 10px;
}
.work-schedule-range-picker > input {
  height: 21px;
}
.work-schedule-range-picker {
  display: inline-block;
}
.work-schedule-user-statistic-block {
  height: 40px;
  padding-top: 5px;
}
.work-schedule-user-statistic-name {
  line-height: 40px;
}
.work-schedule-user-statistic-statistic {
  padding-top: 3px;
}
.work-schedule-stat-element {
  float: left;
  margin-top: 5px;
  cursor: default;
  height: 25px;
}
.work-schedule-stat-element-stop {
  background-color: #b2b3ab;
}
.work-schedule-stat-element-stop:hover {
  background-color: #c3c4bc;
}
.work-schedule-stat-element-pause {
  background-color: #4883e6;
}
.work-schedule-stat-element-pause:hover {
  background-color: #489cff;
}
.work-schedule-stat-element-working {
  background-color: #7cd540;
}
.work-schedule-stat-element-working:hover {
  background-color: #8ce741;
}
.work-schedule-user-statistic-work-time-counters {
  line-height: 35px;
  font-weight: 500;
  text-align: center;
}
.work-schedule-user-statistic-work-time-counters > div {
  width: 75px;
  float: left;
}
.work-schedule-user-statistic-work-time {
  color: #7cd540;
}
.work-schedule-user-statistic-paus-time {
  color: #4883e6;
}
.work-schedule-user-statistic-stop-time {
  color: #b2b3ab;
}
.work-schedule-table,
.work-schedule-table th,
.work-schedule-table td {
  border-collapse: collapse;
  padding: 15px;
  text-align: left;
  border-spacing: 5px;
}
.work-schedule-table th {
  background-color: #637689;
  color: white;
  font-weight: 500;
  font-size: 15px;
}
.work-schedule-table tr:nth-child(even) {
  background-color: #f7fafc;
}
.work-schedule-table tr:nth-child(odd) {
  background-color: #ebeef0;
}
.userCustomStatusItem .userStatusMainDiv {
  display: flex;
  align-items: center;
}
.userCustomStatusItem .userStatusMainDiv .statusTypeDiv {
  width: 140px;
  font-weight: 500;
}
.userCustomStatusItem .userStatusMainDiv .userStatusButtonsDiv {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.userCustomStatusItem .userStatusMainDiv .userStatusButtonsDiv .statusEditButton {
  margin-right: 30px;
}
.userCustomStatusItem .userStatusMainDiv .statusNameSpan {
  color: dodgerblue;
}
.userCustomStatusItem .userStatusSettingsDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.userCustomStatusItem .userStatusSettingsDiv .divWithSettings > div {
  margin-bottom: 10px;
}
.userCustomStatusItem .userStatusSettingsDiv .userCustomStatusActivityItem {
  display: flex;
  align-items: center;
  margin-top: 5px;
  height: 35px;
}
.userCustomStatusItem .userStatusSettingsDiv .userCustomStatusActivityItem > div:first-child {
  width: 250px;
}
.userCustomStatusItem .userStatusSettingsDiv .userCustomStatusActivityItem .dropdown-multi-button .btn-default {
  padding: 6px 12px;
}
.userCustomStatusItem .userStatusSettingsDiv .statusSaveButton {
  margin-left: auto;
  margin-top: auto;
}
.mainContainerForGroups .sectionDescription {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: red solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.mainContainerForGroups .sectionDescription .sectionName {
  font-size: 25px;
  color: #000000;
}
.mainContainerForGroups .sectionDescription .pseudo-button-add {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}
.mainContainerForGroups .parameter {
  display: flex;
  height: 50px;
  margin-bottom: 10px;
}
.mainContainerForGroups .parameter .name {
  display: flex;
  align-items: center;
  width: 200px;
  font-weight: 500;
  color: #2f2f2f;
  height: 100%;
}
.mainContainerForGroups .parameter .value {
  width: 350px;
  height: 100%;
}
.mainContainerForGroups .addingNewSipsDiv {
  display: flex;
  align-items: center;
}
.mainContainerForGroups .addingNewSipsDiv > div {
  font-weight: 500;
  color: #2f2f2f;
}
.mainContainerForGroups .addingNewSipsDiv .dropdown-single-button {
  margin-left: 20px;
  width: 300px;
}
.mainContainerForGroups .addingNewGsmDiv {
  display: flex;
  align-items: center;
  height: 50px;
}
.mainContainerForGroups .mirandaGroup .header-collapsible-div {
  background-color: #fff;
}
.mainContainerForGroups .mirandaGroup .mgSettings {
  padding: 15px 20px 0 15px;
}
.mainContainerForGroups .mirandaGroup .mgDivForUsers {
  margin: 20px;
}
.mainContainerForGroups .mirandaGroup .mgAddingNewOperatorsDiv {
  display: flex;
  align-items: center;
  margin: 20px;
}
.mainContainerForGroups .mirandaGroup .mgBottomButtons {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
}
.mainContainerForGroups .mirandaGroup .mgOneUserLine {
  display: flex;
  justify-content: space-between;
  background-color: #f4f7f9;
  padding: 10px 20px 7px 20px;
  border-radius: 5px;
  margin-bottom: 6px;
  align-items: center;
}
.mainContainerForGroups .mirandaGroup .mgOneUserLine .userName {
  font-weight: 500;
}
.mainContainerForGroups .mirandaGroup .mgOneUserLine .mgRemoveUserButton {
  font-weight: 500;
}
.pg-line {
  background-color: #f4f7f9;
  padding: 10px 20px 7px 10px;
  border-radius: 5px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.pg-line .fa-star {
  color: #dfe6ed;
  cursor: pointer;
  font-size: 20px;
}
.pg-line .fas {
  color: #e33351;
  cursor: pointer;
}
.pg-line .lineName {
  display: flex;
}
.pg-line .lineName .sipNumber {
  font-weight: 500;
  width: 60px;
}
.pg-line .lineName .userName {
  width: 300px;
}
.pg-line .lineName .gsmNumber {
  width: 250px;
  font-weight: 500;
}
.pg-line .pg-button-remove-line {
  font-weight: 500;
}
.pg-stars-container {
  padding: 5px;
}
.pg-group-description {
  color: #7e7e7e;
  padding: 15px;
  background-color: #fff;
  border-top: 2px solid #e0e0e0;
  display: none;
}
[pg-type=GSM] .pg-priority-column-description,
[pg-type=GSM] .pg-line-phone-select,
[pg-type=GSM] .pg-line-column-description-sip,
[pg-type=GSM] .pg-stars-container {
  display: none;
}
[pg-type=GSM] .pg-line-gsm-phone-input {
  display: block;
}
[pg-type=SIP] .pg-line-column-description-gsm,
[pg-type=SIP] .pg-line-gsm-phone-input {
  display: none;
}
.phone-group-id-block {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 12px;
  display: none;
}
.pg-xferNumber-input-div .proxima-tooltip:hover {
  z-index: 2;
}
.customtab {
  border-bottom: 2px solid #f7fafc;
}
.customtab li.active a,
.customtab li.active a:hover,
.customtab li.active a:focus {
  background: #ffffff;
  border: 0px;
  border-bottom: 2px solid #2cabe3;
  margin-bottom: -1px;
  color: #2cabe3;
}
.customtab li a,
.customtab li a:hover,
.customtab li a:focus {
  border: 0px;
}
/*custom tab2*/
.customtab2 {
  border-bottom: 1px solid #f7fafc;
  border-top: 1px solid #f7fafc;
}
.customtab2 li.active a,
.customtab2 li.active a:hover,
.customtab2 li.active a:focus {
  background: #2cabe3;
  border: 1px solid #2cabe3;
  color: #ffffff;
}
.customtab2 li a,
.customtab2 li a:hover,
.customtab2 li a:focus {
  border: 0px;
}
#page-wrapper {
  min-height: 568px;
  background: #edf1f5;
}
.nav-tabs > li > a {
  border-radius: 0px;
  color: #313131;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background: #ffffff;
}
/*<editor-fold desc="Фильтры">*/
.callHistoryFilters .webra-input-field::placeholder {
  color: #000;
}
.callHistoryFilters .callHistoryFiltersDateWrap {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-right: 5px;
  width: 405px;
}
.callHistoryFilters .callHistoryFiltersDateWrap .callHistoryFiltersDate {
  background-color: #fff;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 10px;
}
.callHistoryFilters .callHistoryFiltersDateWrap .callHistoryFiltersDate input {
  min-width: 270px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  line-height: 20px;
  padding-top: 2px;
  height: 100%;
}
.callHistoryFiltersThirdRow > *,
.callHistoryFilterItem {
  margin-right: 5px;
  float: left;
  margin-top: 5px;
  width: 200px;
}
.callHistoryFilters-second-row {
  display: inline-flex;
}
.callHistoryFiltersFirstRow .dropdown-multi-button .btn-default,
.callHistoryFiltersThirdRow .dropdown-multi-button .btn-default {
  width: 200px;
  color: #000;
}
.callHistoryFilters .dropdown-multi-button .bootstrap-select.btn-group .dropdown-menu.open {
  width: 200px !important;
  min-width: 50% !important;
}
.callHistoryMainScreen .webra-input-field {
  height: 50px;
}
.callHistoryFiltersMainTypes {
  border: none !important;
  padding: 0;
  font-weight: 500;
  display: flex;
  height: 50px;
  margin-bottom: 0;
}
.callHistoryFiltersMainTypes div {
  background-color: #fff !important;
  cursor: pointer;
  padding: 15px 11.6px !important;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callHistoryFiltersMainTypes div.active {
  background-color: #308bec !important;
  padding: 14px !important;
  color: #fff;
}
.callHistoryFiltersMainTypes {
  float: left;
}
.callHistoryFiltersCheckboxesDiv {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
}
.callHistoryFiltersClearButton:hover {
  text-decoration: underline;
}
.callHistoryFiltersDownloadButtonDiv button {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  padding: 15px 12px !important;
}
/*</editor-fold>*/
/*<editor-fold desc="Фильтр по имени контакта">*/
.history-address-book-search-div {
  position: relative;
  cursor: pointer;
}
.callHistoryFilters-contact-names-list {
  background-color: #fff;
  position: absolute;
  width: 204px;
  z-index: 1;
  top: 55px;
  left: -2px;
  border: #edf1f5 2px solid;
  border-top: none;
}
.history-address-book-search-item {
  padding: 8px 16px;
  height: 50px;
}
.history-address-book-search-item:hover {
  background-color: #efeef3;
}
.autocomplete-active,
.autocomplete-active:hover {
  background-color: #308bec;
  color: #fff;
}
/*</editor-fold>*/
/*<editor-fold desc="Настройка таблицы и ширины колонок">*/
.callHistoryTable {
  margin-top: 20px;
}
.callHistoryTable .partialSearchLimitWarning {
  background-color: #ffa700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.callHistoryTableCol {
  padding: 15px 8px;
}
.callHistoryTableCol:nth-child(1) {
  flex-basis: 75px;
}
.callHistoryTableCol:nth-child(2) {
  flex-basis: 150px;
  min-width: 100px;
}
.callHistoryTableCol:nth-child(3) {
  flex-basis: 200px;
  min-width: 172px;
}
.callHistoryTableCol:nth-child(4) {
  flex-basis: 250px;
  max-width: 200px;
}
.callHistoryTableCol:nth-child(5) {
  flex-basis: 90px;
}
.callHistoryTableCol:nth-child(6) {
  flex-basis: 110px;
}
.callHistoryTableCol:nth-child(7) {
  flex-basis: 100px;
}
.callHistoryTableCol:nth-child(8) {
  flex-basis: 100px;
}
.callHistoryTableCol:nth-child(9) {
  flex-basis: 200px;
  min-width: 200px;
}
.callHistoryTableCol:nth-child(10) {
  flex-basis: 100px;
  padding-bottom: 3px;
}
.callHistoryTableCounters {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.callHistoryTableRow {
  display: flex;
  position: relative;
}
.callHistoryTableRowMainInfo {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.callHistoryTableRowMainInfo .callPhase {
  position: absolute;
  bottom: 2px;
  left: 10px;
  color: #989498;
  font-size: 11px;
}
.callHistoryTable-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
}
/*</editor-fold>*/
.callHistoryTableOneCall {
  position: relative;
  background-color: #fff;
}
.callHistoryTableOneCall .callHistoryTableOneCallLine {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  z-index: 1;
}
/*<editor-fold desc="Звонок: иконка">*/
.callHistoryTableColIcon {
  display: flex;
  justify-content: center;
}
.callHistoryTableColIcon img {
  margin-top: 10px;
  width: 26px;
  height: 26px;
}
/*</editor-fold>*/
/*<editor-fold desc="Звонок: Кто звонил">*/
.newUserIcon:before {
  content: "\f111";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.newUserIcon {
  color: #3cc400;
  font-size: 7px;
  margin-right: 3px;
  position: absolute;
  top: 4px;
  left: -12px;
}
/*</editor-fold>*/
/*<editor-fold desc="Инструмент">*/
.buttonRecord {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 36px;
  height: 36px;
  border: 1px solid #ecf0f4;
  margin-right: 10px;
}
.call-history-audio-div-player {
  min-height: 70px !important;
}
/*</editor-fold>*/
/*<editor-fold desc="столбец аналитики">*/
.callHistoryTableRow-utm {
  display: flex;
  flex-wrap: wrap;
}
.callHistoryTableRow-utm > .label {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
}
/*</editor-fold>*/
/*<editor-fold desc="комментарии">*/
.callHistoryTableOneCall .next-input {
  background-image: none;
  background-color: #fff;
  border: #cacaca 1px solid;
}
/*</editor-fold>*/
/*<editor-fold desc="Информация">*/
.callHistoryTableRow-utm-expanded {
  display: none;
}
.callHistoryTableRow-utm-expanded .lastUrl {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.utm_wrapp {
  padding-bottom: 15px;
  position: relative;
  display: inline-block;
}
.utm_wrapp .utm_ico {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.utm_wrapp .utm_ico span {
  width: 8px;
  height: 8px;
  background-color: #e1e5ea;
  margin-right: 5px;
  border-radius: 100%;
  cursor: pointer;
}
.utm_wrapp .utm_content {
  position: absolute;
  top: 25px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 2px 3.464px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 20px 25px 10px 25px;
  z-index: 999;
  align-items: center;
  flex-wrap: wrap;
  min-width: 310px;
  display: none;
}
.utm_wrapp .utm_content:before {
  content: '';
  position: absolute;
  top: -8px;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 0 12px;
  border-color: transparent transparent transparent #ffffff;
}
.utm_wrapp .utm_content .row {
  margin-right: -15px;
  margin-left: -15px;
}
.utm_wrapp .utm_content .label-rounded {
  margin-bottom: 4px;
}
.utm_wrapp:hover .utm_content {
  display: flex;
}
@media only screen and (min-width: 1440px) {
  .callHistoryTableRow-utm-collapsed {
    display: none;
  }
  .callHistoryTableRow-utm-expanded {
    display: block;
  }
  .callHistoryTableCol:nth-child(10) {
    flex-basis: 550px;
  }
}
@media only screen and (min-width: 1800px) {
  .callHistoryTableCol:nth-child(10) {
    flex-basis: 660px;
  }
}
/*</editor-fold>*/
/*<editor-fold desc="История переадресациий">*/
.callHistoryTableRow-redirects {
  border-top: #e9e9e9 1px solid;
  color: #535353;
  padding: 5px;
}
.callHistoryTableRow-redirect-step {
  margin: 5px;
  padding: 5px;
  max-width: 400px;
}
.callHistoryTableRow-redirect-arrow {
  padding-top: 30px;
}
.redirect-item-key,
.redirect-item-value {
  display: block;
  overflow-wrap: break-word;
}
.redirectOuterSection .lineAndReason {
  margin-top: 5px;
}
.redirectOuterSection .lineAndReason .redirectLine {
  padding: 0;
  line-height: 14px;
}
.redirectOuterSection .lineAndReason .redirectReason {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
}
.callHistoryTableRow-redirect-step p:first-child {
  font-weight: 500;
}
.callHistoryTableRow-redirect-step .redirect-header {
  border-bottom: #cfcfcf 1px solid;
}
.callHistoryTableRow-redirect-step .redirect-item-key {
  font-size: 12px;
  color: #308bec;
  padding: 0;
  height: 15px;
}
.call-history-redirect-source {
  position: relative;
  font-size: 15px;
  color: #308bec;
  font-weight: 500;
  margin-right: 20px;
}
.call-history-redirect-source:after {
  content: '';
  position: absolute;
  bottom: 0px;
  right: -20px;
  width: 18px;
  height: 14px;
  background: url(../img/svg/arrow-carret-down.svg) no-repeat center right;
  -webkit-background-size: 14px;
  background-size: 14px;
  color: #7e7e7e;
}
.redirect-result {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
.redirect-result div:first-child span {
  margin-right: 10px;
}
.callHistoryTableRow-redirect-step-source {
  padding: 5px;
}
.redirect-nosc {
  border-bottom: none;
}
/*</editor-fold>*/
/*<editor-fold desc="Мигалка таблицы">*/
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*</editor-fold>*/
/*<editor-fold desc="Контекстное меню контакта">*/
.called-dd .calledFromMain,
.called-dd .called-to-main {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.called-dd .calledFromMain:hover,
.called-dd .called-to-main:hover {
  text-decoration: underline;
}
.called-dd .dropdown-menu {
  top: -9px;
  left: -21px;
  cursor: pointer;
}
.called-dd .dropdown-menu [role="menuitem"] {
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
}
.called-dd .dropdown-menu svg {
  display: inline;
}
.called-dd .dropdown-menu .dropdown-header {
  font-weight: 500;
  color: #313131;
  font-size: 14px;
  line-height: 1.42857143;
  text-decoration: underline;
}
.called-dd .dropdown-menu li {
  cursor: pointer;
}
.called-dd .dropdown-menu li [role="menuitem"] {
  padding: 9px 20px;
  cursor: pointer;
}
.called-dd .dropdown-menu li [role="menuitem"] > span {
  display: inline;
  margin-left: 5px;
}
.called-dd .dropdown-menu li > img {
  display: inline;
}
/*</editor-fold>*/
.callHistoryUpdateButtonBackground {
  background-color: #657689;
}
.callHistoryUpdateButton,
.callHistoryUpdateButtonBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 300ms linear 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 500;
  cursor: pointer;
}
.callHistoryTableHeader {
  position: relative;
}
.callHistoryTableHeader:hover .callHistoryUpdateButton,
.callHistoryTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
@-webkit-keyframes callFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    max-height: 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    max-height: 100px;
  }
}
@keyframes callFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    max-height: 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    max-height: 100px;
  }
}
.callFadeInRight {
  -webkit-animation-name: callFadeInRight;
  animation-name: callFadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes callFadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    max-height: 75px;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    max-height: 0;
  }
}
@keyframes callFadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    max-height: 75px;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    max-height: 0;
  }
}
.callFadeOutRight {
  -webkit-animation-name: callFadeOutRight;
  animation-name: callFadeOutRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.callHistoryFilters .callHistoryFilterItem {
  font-size: 14px;
}
.expandWidth .callHistoryTableCol:nth-child(9) {
  flex-basis: 250px;
  min-width: 250px;
}
.disableCallTriesCount .callHistoryTableCol:nth-child(8) {
  display: none;
}
@-webkit-keyframes flash_callhistory {
  from,
  100%,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes flash_callhistory {
  from,
  100%,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.animate_flash_callhistory {
  -webkit-animation-name: flash_callhistory;
  animation-name: flash_callhistory;
}
.priceContainer {
  margin-left: 20px;
}
.analyticsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.analyticsContainer .containerRow {
  display: flex;
  gap: 20px;
}
.analyticsContainer .containerRow .leftColumn {
  display: flex;
  flex-direction: column;
  width: 715px;
}
.analyticsContainer .loadingIndicator {
  margin-left: 45%;
  width: 5%;
}
.analyticsContainer .analyticsDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 715px;
}
.analyticsContainer .analyticsDiv .analyticsSection {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #F6F7FB;
  width: 100%;
  padding: 5px;
}
.analyticsContainer .analyticsDiv .analyticsSection .proxima-tooltip .tooltip_text::before {
  border-color: transparent transparent transparent rgba(160, 162, 171, 0.8);
}
.analyticsContainer .analyticsDiv .analyticsSection .wordHighlightShow,
.analyticsContainer .analyticsDiv .analyticsSection .wordHighlightHide {
  cursor: pointer;
}
.analyticsContainer .analyticsDiv .analyticsSection .content {
  padding: 10px;
}
.analyticsContainer .analyticsDiv .analyticsSection .points {
  display: flex;
  flex-direction: column;
}
.analyticsContainer .analyticsDiv .analyticsSection .points .phrase {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.analyticsContainer .analyticsDiv .analyticsSection .points .phrase:hover {
  color: transparent;
}
.analyticsContainer .analyticsDiv .analyticsSection .points .phrase::before {
  content: attr(data-hover-text);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  transition: color 0.3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.analyticsContainer .analyticsDiv .analyticsSection .points .phrase:hover::before {
  color: initial;
}
.analyticsContainer .analyticsDiv .wordListSection {
  display: flex;
  flex-wrap: wrap;
  width: 49.3%;
}
.analyticsContainer .analyticsDiv .wordListSection .list {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.analyticsContainer .analyticsDiv .wordListSection .list .word {
  background-color: #c6c6c6;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.analyticsContainer .analyticsDiv .wordListSection .eye {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.analyticsContainer .analyticsDiv .wordListSection:hover .eye {
  opacity: 1;
}
.analyticsContainer .analyticsDiv .analyticsDynamicContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.analyticsContainer .analyticsDiv .header {
  width: 100%;
  padding: 10px;
  font-weight: 500;
  border-bottom: 1px solid #A0BEDC;
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.analyticsContainer .playerContainer {
  display: flex;
  flex-direction: column;
}
.analyticsContainer .playerContainer .playerRow {
  background-color: #F6F7FB;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.analyticsContainer .playerContainer .playerRow .player {
  margin-left: 5px;
  width: 95%;
  display: block;
}
.analyticsContainer .playerContainer .playerRow .controlColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.analyticsContainer .playerContainer .playerRow .controlColumn .volumeControl,
.analyticsContainer .playerContainer .playerRow .controlColumn .speedControl {
  accent-color: black;
  width: 80px;
  cursor: pointer;
  margin-left: 0.1vw;
}
.analyticsContainer .playerContainer .playerRow .controlColumn .currentTimeDisplay {
  font-size: 10px;
  text-align: center;
  width: 60px;
}
.analyticsContainer .playerContainer .playerRow .controlColumn .playPauseButton {
  margin-top: 20px;
  cursor: pointer;
}
.analyticsContainer .playerContainer .playerRow .controlColumn .playButton:hover {
  fill: #f44336;
}
.analyticsContainer .playerContainer .playerRow .controlColumn .pauseButton:hover {
  fill: #f44336;
}
.analyticsContainer .playerContainer .transcriptDiv {
  position: relative;
  max-height: 720px;
  margin: 15px 0 15px 0;
  padding: 5px 0 5px 0;
  background-color: #F6F7FB;
  border-radius: 10px;
  border: 10px solid #F6F7FB;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue {
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px;
  margin-right: 5px;
  align-items: center;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText {
  cursor: pointer;
  border-radius: 10px;
  height: 100%;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .fillerWord {
  background-color: #fff2b0;
  border-radius: 5px;
  padding: 3px;
  margin: 0 3px 0 3px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .fillerWordT {
  transition: all 0.5s ease-in-out;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .stopWord {
  background-color: #ffb0b0;
  border-radius: 5px;
  padding: 3px;
  margin: 0 3px 0 3px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .stopWordT {
  transition: all 0.5s ease-in-out;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .keyword {
  background-color: #d0ffb0;
  border-radius: 5px;
  padding: 3px;
  margin: 0 3px 0 3px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .keywordT {
  transition: all 0.5s ease-in-out;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .obsceneWord {
  background-color: #ffb0b0;
  border-radius: 5px;
  padding: 3px;
  margin: 0 3px 0 3px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText .obsceneWordT {
  transition: all 0.5s ease-in-out;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText.left {
  background-color: #bfdaf1;
  max-width: 80%;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue .cueText.right {
  background-color: white;
  max-width: 80%;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue:has(.left) + .oneCue:has(.right) {
  margin-top: 15px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue:has(.left) + .oneCue:has(.left) {
  margin-top: initial;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue:has(.right) + .oneCue:has(.left) {
  margin-top: 15px;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue:has(.right) + .oneCue:has(.right) {
  margin-top: initial;
}
.analyticsContainer .playerContainer .transcriptDiv .highlightIndicator {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.15vw;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
@keyframes highlightAnimation {
  0%,
  100% {
    transform: scaleY(0.85);
  }
  50% {
    transform: scaleY(1.15);
  }
}
.analyticsContainer .playerContainer .transcriptDiv .highlightIndicator rect {
  transform-origin: center;
  animation: highlightAnimation 0.5s infinite alternate;
}
.analyticsContainer .playerContainer .transcriptDiv .highlightIndicator rect:nth-child(2n) {
  animation-delay: 0.2s;
}
.analyticsContainer .playerContainer .transcriptDiv .highlightIndicator rect:nth-child(3n) {
  animation-delay: 0.4s;
}
.analyticsContainer .playerContainer .transcriptDiv .highlightIndicator rect:nth-child(4n) {
  animation-delay: 0.6s;
}
.analyticsContainer .playerContainer .transcriptDiv .oneCue:hover {
  background-color: rgba(157, 157, 157, 0.22);
}
.analyticsContainer .playerContainer .transcriptDiv::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.analyticsContainer .playerContainer .transcriptDiv::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.analyticsContainer .playerContainer .transcriptDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.analyticsContainer .playerContainer .transcriptDiv::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
.analyticsContainer .playerContainer .transcriptDiv::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
.analyticsContainer .collapseTranscriptionButton {
  border-radius: 10px;
  background-color: #F6F7FB;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.analyticsContainer .fullTranscriptionText {
  margin-top: 10px;
  background-color: #F6F7FB;
  padding: 10px;
  border-radius: 10px;
  white-space: pre-wrap;
}
.contacts-editor {
  padding: 15px;
}
.contacts-editor .webra-input-field {
  height: 47px;
  border: 1px solid #e3e3e3;
}
.contacts-editor .contacts-editor-name-input,
.contacts-editor .contacts-editor-email-input,
.contacts-editor .contacts-editor-address-input,
.contacts-editor .contacts-editor-note-area {
  width: 400px;
}
.contacts-editor .contacts-field-description {
  color: #2a8be1;
  font-size: 13px;
}
.contacts-editor .dropdown-single-button {
  border: 1px solid #e8e8e8;
  height: 47px;
}
.contacts-editor .btn-block {
  padding: 12px 15px;
  margin-bottom: 20px;
}
.contacts-editor .contacts-edit-responsible-select {
  width: 250px;
}
.sites-main-container .icon-in-header {
  display: flex;
  align-items: center;
  margin-right: 50px;
  width: 190px;
}
.sites-main-container .icon-in-header svg {
  width: 20px;
  height: 20px;
}
.sites-main-container .icon-in-header span {
  margin-left: 10px;
  line-height: 20px;
}
.sites-collapsible-div {
  background-color: #fff;
}
.sites-one-connected-service {
  display: flex;
  padding: 20px;
  font-weight: 500;
  border-bottom: 2px solid #efefef;
}
.sites-one-connected-service button {
  width: 180px;
  display: flex;
  justify-content: center;
}
.sites-one-connected-service > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sites-one-connected-service > div:nth-child(1) {
  width: 200px;
}
.sites-one-connected-service > div:nth-child(2) {
  width: 200px;
}
.sites-one-connected-service > div:nth-child(3) {
  width: 200px;
}
.sites-name-edit-div {
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 500;
}
.sites-name-edit-div > div {
  margin-right: 15px;
}
.sites-div-with-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
.sites-div-with-buttons button {
  margin-left: 20px;
}
.sites-add-new-site-div {
  position: relative;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 30px 40px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}
.sites-add-site-title {
  font-size: 21px;
  line-height: 1;
  font-weight: 500;
  padding-bottom: 23px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ecf0f4;
  text-transform: inherit;
}
.sites-div-with-input {
  margin-right: 30px;
  width: 350px;
}
.sites-div-with-input .sites-field-description {
  font-weight: 500;
  margin-bottom: 10px;
}
.sites-div-with-input .sites-name-input {
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 12px;
  width: 100%;
}
.sites-instruction-send-div {
  padding: 20px;
  font-size: 17px;
  display: flex;
}
.sites-instruction-send-div > div {
  padding-right: 20px;
}
.sites-instruction-send-div input {
  height: 50px;
}
.tariffs-div-for-tariffs {
  display: flex;
  flex-wrap: wrap;
}
.tariffs-one-tariff-outer-wrap,
.tariffsShowHiddenWrap {
  padding: 30px;
  width: 33%;
}
@media (max-width: 1650px) {
  .tariffs-one-tariff-outer-wrap,
  .tariffsShowHiddenWrap {
    width: 50%;
  }
}
@media (max-width: 1199px) {
  .tariffs-one-tariff-outer-wrap,
  .tariffsShowHiddenWrap {
    width: 100%;
  }
}
.tariffsShowHiddenWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: hsl(209, 20%, 50%);
}
.tariffsShowHiddenWrap .showHiddenButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.tariffs-one-tariff {
  background-color: #fff;
  height: 100%;
  margin-top: 0;
  border: none;
  padding: 35px 0 0;
  position: relative;
}
.tariffs-one-tariff .pricing-header {
  text-align: center;
}
.tariffs-one-tariff .pricing-header h4 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 0 30px;
}
.tariffs-one-tariff .pricing-header h2 {
  font-size: 60px;
  color: #3c3c3c;
  font-weight: 500;
  line-height: 1;
  margin-top: 0;
}
.tariffs-one-tariff .pricing-header h2 sup {
  font-size: 28px;
  font-weight: 200;
  top: -1.2em;
  left: 5px;
}
.tariffs-one-tariff .pricing-header .tariffs-month {
  font-size: 13px;
  text-transform: uppercase;
  color: #3c3c3c;
}
.price-table-content {
  margin-bottom: 108px;
}
.tariffs-leases-list,
.tariffs-services-list {
  padding: 20px;
}
.tariffs-one-service {
  display: flex;
  margin-bottom: 13px;
}
.tariffs-one-service-count {
  min-width: 30px;
  color: #5d5d5d;
  font-size: 17px;
  font-weight: 500;
  line-height: 17px;
}
.tariffs-one-service-price {
  min-width: 100px;
  margin-left: auto;
}
.tariffs-one-service-price .tariffs-one-service-cost {
  font-weight: 500;
}
.tariffs-leases-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
}
.price-table-footer {
  height: 106px;
  border-top: 1px solid #ecf0f4;
  background-color: #fbfcfd;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.my_tariffs {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  width: 115px;
  height: 115px;
  background-color: #ff9700;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
}
.my_tariffs .myTariffMark {
  text-transform: uppercase;
  display: inline-block;
  line-height: 21px;
  transform: rotate(-45deg);
  position: relative;
  top: 13px;
  left: -20px;
}
.my_tariffs_next {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  width: 115px;
  height: 115px;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  background-color: #17d701;
  text-transform: uppercase;
}
.my_tariffs_next .myTariffMark {
  text-transform: uppercase;
  display: inline-block;
  line-height: 21px;
  transform: rotate(-45deg);
  position: relative;
  top: 16px;
  left: -17px;
}
.unitalktariffsMainDiv {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 7px -2px #7f7f7f !important;
  box-shadow: 0px 0px 7px -2px #7F7F7F !important;
}
.unitalktariffsMainDiv .columnsNameContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-weight: 500;
  text-align: left;
}
.unitalktariffsMainDiv .columnsNameContainer .tariffNameGroup {
  display: flex;
}
.unitalktariffsMainDiv .tariffHeader > div:nth-child(1),
.unitalktariffsMainDiv .columnsNameContainer > div:nth-child(1) {
  width: 330px;
}
.unitalktariffsMainDiv .tariffHeader > div:nth-child(2),
.unitalktariffsMainDiv .columnsNameContainer > div:nth-child(2) {
  width: 160px;
}
.unitalktariffsMainDiv .tariffHeader > div:nth-child(3),
.unitalktariffsMainDiv .columnsNameContainer > div:nth-child(3) {
  width: 300px;
}
.unitalktariffsMainDiv .tariffHeader > div:nth-child(4),
.unitalktariffsMainDiv .columnsNameContainer > div:nth-child(4) {
  width: 100px;
}
.unitalktariffsMainDiv .tariffHeader > div:nth-child(5),
.unitalktariffsMainDiv .columnsNameContainer > div:nth-child(5) {
  width: 85px;
}
.unitalktariffsMainDiv .namedInput {
  display: flex;
  align-items: center;
  height: 25px;
  margin-bottom: 3px;
  justify-content: space-between;
  gap: 10px;
}
.unitalktariffsMainDiv .namedInput div:last-child {
  height: 100%;
}
.unitalktariffsMainDiv .namedInput div:last-child input {
  height: 100%;
  font-weight: 400;
  padding: 3px;
  border: #ececec 2px solid;
}
.unitalktariffsMainDiv .oneTariffWithServices .header {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  padding: 5px;
}
.unitalktariffsMainDiv .oneTariffWithServices .header .headerName {
  font-weight: 500;
}
.unitalktariffsMainDiv .oneTariffWithServices.hiddenTariff .header {
  color: #b6b6b6;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody {
  padding: 5px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings {
  display: flex;
  gap: 10px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings .dataChangerDiv {
  display: flex;
  gap: 10px;
  padding-left: 5px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings .dataChangerDiv .divForPrice {
  width: 120px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings .dataChangerDiv .divForPrice .amountDiv {
  width: 75px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings .dataChangerDiv .divForName {
  width: 300px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .mainSettings .dataChangerDiv .divForName .langTranslateDiv {
  width: 265px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .galki {
  width: 210px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .galki .namedInput > div:last-child {
  width: 70px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .divForTariffAvailableServiceCheckbox {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .divForTariffAvailableServiceCheckbox .tariffAvailableServiceCheckboxDiv {
  width: 400px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .controlButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .controlButtons .availableServiceButton {
  height: 30px;
  width: 100px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .servicesInTariffDiv {
  margin-top: 5px;
}
.unitalktariffsMainDiv .oneTariffWithServices .tariffBody .servicesInTariffDiv .servicesInTariffHeader {
  height: 40px;
  font-weight: 500;
  background-color: #f7f7f1;
}
.unitalktariffsMainDiv .backgroundColorDiv:nth-child(odd) .elementColor {
  background-color: #f7f7f7;
}
.unitalktariffsMainDiv .backgroundColorDiv:nth-child(even) .elementColor {
  background-color: #f1f1f1;
}
.unitalktariffsMainDiv .servicesInTariffBody .serviceHeader {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 25px;
  justify-content: space-between;
  gap: 10px;
}
.unitalktariffsMainDiv .servicesInTariffBody .serviceHeader:hover {
  background-color: #efefef;
}
.unitalktariffsMainDiv .servicesInTariffBody .serviceHeader.opened {
  background-color: #d9d9d9;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(1) {
  width: 500px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(2) {
  width: 50px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(3) {
  width: 200px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(4) {
  width: 50px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(5) {
  width: 50px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(6) {
  width: 50px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(7) {
  width: 50px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(8) {
  width: 70px;
  margin-right: 5px;
}
.unitalktariffsMainDiv .elementPosition > div:nth-child(9) {
  width: 150px;
}
.unitalktariffsMainDiv .serviceBody {
  padding: 10px;
  border-bottom: solid #eaeaea 2px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns {
  display: flex;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns > * {
  margin-left: 10px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .paramNameWithInput {
  display: flex;
  font-weight: 500;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .paramNameWithInput > div:first-child {
  width: 135px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .paramNameWithInput input[type="text"] {
  width: 65px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .firstColumn {
  margin-top: 20px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .firstColumn > * {
  margin-top: 3px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .priceDiv .oneCurrencyPrice {
  display: flex;
  margin-top: 3px;
  border: 1px solid #bdbdbd;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .priceDiv .oneCurrencyPrice .countryFlag {
  height: 26px;
  width: 37.5px;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .priceDiv .oneCurrencyPrice .countryFlag img {
  width: 100%;
  height: 100%;
}
.unitalktariffsMainDiv .serviceBody .unitalktariffsServiceColumns .priceDiv .oneCurrencyPrice .priceInput {
  width: 70px;
  border: none;
}
.unitalktariffsMainDiv .serviceBody .actionDiv {
  padding-top: 10px;
}
.unitalktariffsMainDiv .serviceBody .actionDiv button {
  height: 25px;
  line-height: 0;
}
.unitalktariffsServiceSelectNameModal {
  width: 500px;
}
.settings-main-div .sendScheduledCallsDiv .input-time2:before {
  top: 17px;
  right: -15px;
}
.settings-main-div .add-new-notification-contact-div {
  padding-top: 20px;
  padding-bottom: 20px;
}
.settings-main-div .add-new-notification-contact-div > div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.settings-main-div .add-new-notification-contact-div .newContactTypeSelect {
  height: 50px;
  width: 250px;
  margin: 0 10px;
}
.settings-main-div .add-new-notification-contact-div .new-notification-contact-destination-input {
  width: 407px;
  margin-right: 10px;
}
.settings-main-div .add-new-notification-contact-div .telegramUsersSelector {
  width: 307px;
  margin-right: 15px;
}
.settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div {
  display: flex;
  margin-bottom: 5px;
  font-weight: 500;
}
.settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div .proxima-tooltip {
  margin-left: 0;
}
.settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div > div:first-child {
  width: 300px;
}
@media (max-width: 1024px) {
  .settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div > div:first-child {
    width: 200px;
  }
}
.settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div > div {
  width: 110px;
}
@media (max-width: 1024px) {
  .settings-main-div .notification-contacts-table-div .notification-contacts-table-header-div > div {
    width: 60px;
    overflow: hidden;
  }
}
.settings-main-div .notification-contacts-table-div .notification-contact-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child {
  display: flex;
  align-items: center;
  width: 300px;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child i {
  font-size: 25px;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child div:first-child {
  width: 40px;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child div:last-child {
  width: 260px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child div:last-child {
    width: 160px;
  }
}
@media (max-width: 1024px) {
  .settings-main-div .notification-contacts-table-div .notification-contact-item > div:first-child {
    width: 200px;
  }
}
.settings-main-div .notification-contacts-table-div .notification-contact-item > div {
  width: 110px;
}
@media (max-width: 1024px) {
  .settings-main-div .notification-contacts-table-div .notification-contact-item > div {
    width: 60px;
  }
}
.settings-main-div .notification-contacts-table-div .notification-contact-item label {
  width: 0;
  margin-left: 3px;
  margin-top: 5px;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item .langSelect {
  height: 30%;
}
.settings-main-div .notification-contacts-table-div .notification-contact-item .langSelect.disabled span.caret {
  display: none;
}
.settings-main-div .dropdown-single-button {
  width: 196px;
  height: 50px;
  margin-left: 10px;
}
.settings-main-div .financialActSender {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.settings-main-div .countrySelectContainer {
  margin-left: 10px;
  width: 400px;
}
.settings-main-div .billingDetailsInput {
  margin-top: 20px;
  width: 60%;
  height: 270px;
}
.settings-main-div .emailTextInputField {
  margin-left: 100px;
  width: 500px;
}
.settings-main-div .notification-settings-div .notification-settings-paragraph {
  margin-top: 40px;
  font-weight: 500;
}
.settings-main-div .notification-settings-div > div {
  display: flex;
  margin-bottom: 20px;
}
.settings-main-div .oneValueField {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.settings-main-div .oneValueField .fieldName {
  font-weight: 500;
  width: 230px;
}
.settings-main-div .oneValueField input {
  width: 500px;
  margin-left: 15px;
  margin-right: 15px;
}
.settings-main-div .oneValueField .dropdown-single-button {
  width: 500px;
  height: 50px;
  margin-left: 15px;
}
@media (max-width: 900px) {
  .settings-main-div .oneValueField .fieldName {
    width: 200px;
  }
  .settings-main-div .oneValueField input {
    width: 300px;
  }
  .settings-main-div .oneValueField .dropdown-single-button {
    width: 300px;
  }
}
.settings-main-div .financialFieldsWrapper {
  display: flex;
  flex-wrap: wrap;
}
.settings-main-div .listItem {
  display: flex;
  margin-bottom: 5px;
}
.settings-main-div .listItem .webra-input-field {
  height: 35px;
  width: 200px;
  margin-right: 5px;
  font-size: 14px;
}
.settings-main-div .listItem .ip {
  width: 250px;
}
.settings-main-div .removedNumberEmailDetails .projectNameOrThemeDiv {
  padding-top: 40px;
  display: flex;
  align-items: center;
}
.settings-main-div .removedNumberEmailDetails .projectNameOrThemeDiv .projectNameOrThemeInput {
  width: 400px;
  margin-left: 30px;
}
.settings-main-div .removedNumberEmailDetails .emailTextDiv {
  align-items: center;
  display: flex;
  padding-top: 10px;
}
.settings-main-div .removedNumberEmailDetails .emailTextDiv .emailTextInput {
  width: 400px;
  min-width: 400px;
  max-width: 800px;
  min-height: 50px;
  max-height: 340px;
  margin-left: 30px;
}
.settings-main-div .securitySettingsColumn {
  display: flex;
  flex-direction: column;
  min-width: 490px;
  margin-top: 15px;
  margin-right: 60px;
}
.settings-main-div .securitySettingsColumn .securitySettingsColumnHeader {
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.settings-main-div .securitySettingsColumn .securitySettingsColumnHeader .pseudo-button {
  padding-top: 5px;
}
.settings-main-div .oneUserLoggedTriggerAction {
  margin-top: 3px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 30px;
}
.settings-main-div .oneUserLoggedTriggerAction .dropdown-single-button {
  max-height: 35px;
  margin-left: 0;
  width: 455px;
}
.settings-main-div .supportOption {
  display: none !important;
}
.settings-main-div .supportOptionEnabled {
  color: #0089ff;
}
.settings-main-div .supportOptionEnabled .next-checkbox-square input:checked ~ .checkmark {
  background-color: #0089ff;
}
.in-scenario-container {
  min-width: 1300px;
}
.inScenarioRuleDragAndDropDiv {
  display: flex;
  padding: 20px;
  justify-content: center;
}
.inScenarioRulesLeftDiv {
  background-color: #f4f6f8;
  border: 3px dashed #cbd2d9;
  padding: 20px;
  min-height: 450px;
  position: relative;
  flex: 1;
}
.inScenarioRulesLeftDiv .inScenarioEmptyDropZoneDescription {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 21px;
  font-weight: 500;
  color: #647688;
}
.inScenarioRulesLeftDiv .dropdown-single-button,
.inScenarioRulesLeftDiv .webra-input-field {
  height: 50px;
}
.inScenarioRulesLeftDiv .webra-input-field {
  padding: 5px;
  padding-left: 20px;
}
.inScenarioRuleRightDivWrap {
  flex-basis: 400px;
  margin-left: 35px;
  padding: 0;
}
.inScenarioRuleRightDivWrap .inScenarioRuleRightDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.inScenarioRuleRightDivWrap .inScenarioTitle {
  font-size: 21px;
  font-weight: 500;
  line-height: 1;
  text-transform: inherit;
  margin-top: 30px;
  margin-bottom: 30px;
}
.inScenarioRuleRightDivWrap .inScenarioTitle:first-child {
  margin-top: 0;
}
.scenarioItemDrag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 18px 20px;
  border: 1px solid #d0d5da;
  cursor: pointer;
  margin-bottom: 4px;
  position: relative;
}
.scenarioItemDrag .scenarioItemIco {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scenarioItemDrag .scenarioItemText {
  font-weight: 500;
  color: #30323e;
  font-size: 15px;
  margin-left: 17px;
}
.scenarioItemDrag .inScenarioRightIco {
  position: absolute;
  right: 20px;
  top: 13px;
  fill: #C8C8C8;
}
.scenarioItemDrag .inScenarioRightIco:hover {
  fill: #707070;
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.inScenarioRuleRightDiv .scenarioItemDrop {
  display: none;
}
.inScenarioRulesLeftDiv .scenarioItemDrag {
  display: none;
}
.gu-mirror .scenarioItemDrop {
  display: none;
}
.scenarioItemDropped .scenarioItemDrop {
  display: block;
}
.scenarioItemDropped .scenarioItemDrag {
  display: none;
}
.scenarioItemDrop {
  display: block;
  margin-bottom: 50px;
}
.inScenarioDropHeader {
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(47, 68, 88, 0.05);
  border-radius: 2px;
  border: 1px solid #ecf0f4;
  border-bottom: none;
  padding: 17px 25px;
  position: relative;
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.inScenarioDropHeader .scenario-toggle {
  height: 20px;
  width: 20px;
  margin-right: 30px;
  cursor: move;
}
.inScenarioDropHeader .scenario-toggle span {
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background-color: #cbd2d9;
}
.inScenarioDropHeader .scenarioItemHeaderTitle {
  font-size: 21px;
  font-weight: 500;
  line-height: 1;
  text-transform: inherit;
}
.inScenarioDropHeader .scenarioControlButtonsDiv {
  margin-left: auto;
  display: flex;
  font-weight: 500;
}
.inScenarioDropHeader .scenarioControlButtonsDiv .pseudo-button-settings {
  margin-right: 20px;
  padding-right: 25px;
  border-right: 1px solid #d7d7d7;
}
.inScenarioDropSettingsWrap {
  display: none;
  padding: 15px 50px 40px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(47, 68, 88, 0.05);
}
.inScenarioDropSettings {
  display: flex;
  flex-wrap: wrap;
}
.inScenarioDropSettings > div {
  margin-right: 20px;
}
.inScenarioDropSettings .inScenarioDropTimeoutDiv .proxima-tooltip:hover {
  z-index: 2;
}
.scenarioItemDropped {
  position: relative;
}
.scenarioItemDropped::before {
  content: attr(content);
  position: absolute;
  bottom: -40px;
  left: 0px;
  width: 170px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 15px;
  background: #ffffff;
}
.scenarioItemDropped:after {
  content: '';
  position: absolute;
  bottom: -33px;
  left: 140px;
  width: 18px;
  height: 14px;
  background: url(../img/svg/arrow-carret-down.svg) no-repeat center right;
  -webkit-background-size: 14px;
  background-size: 14px;
}
.gu-mirror::before,
.inScenarioRulesLeftDiv .scenarioItemDropped:last-child::before {
  content: "";
}
.gu-mirror:after,
.inScenarioRulesLeftDiv .scenarioItemDropped:last-child:after {
  content: '';
  background: none;
}
.scenarioItemDrop .bootstrap-select.btn-group .dropdown-menu {
  padding-bottom: 0px;
  padding-top: 0px;
}
.inScenarioRule {
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 7px -2px #717171 !important;
}
.inScenarioButtons {
  margin-left: auto;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.inScenarioButtons .pseudo-button-remove {
  margin-left: 20px;
  padding-left: 40px;
  border-left: #c3c3c3 solid 1px;
}
.inScenarioRuleName {
  font-weight: 500;
  font-size: 18px;
}
.inScenarioRuleTime {
  color: #aaaaaa;
  font-weight: 400;
}
.inScenarioRuleHeader {
  display: flex;
  cursor: pointer;
}
.inScenarioRuleContent {
  border-top: 1px solid #e4e3e2;
  margin-top: 10px;
}
.inScenarioRuleSettings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
}
.inScenarioRuleSettings .checkmark {
  top: 7px;
}
.inScenarioRuleTimeSelect {
  display: flex;
}
.inScenarioRuleTimeSelect .inScenarioInputTime {
  position: relative;
  width: 90px;
  border: 2px solid #e8e8e8 !important;
}
.inScenarioRuleTimeSelect .input-time2 {
  border: 2px solid #e8e8e8 !important;
}
.inScenarioRuleTimeSelect .input-time2:before {
  top: 9px;
  right: 6px;
}
.inScenarioRuleTimeSelect .input-time2 input {
  padding: 3px;
  padding-left: 10px;
}
.inScenarioDaysOfWeek {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inScenarioDaysOfWeek > label {
  width: 135px;
  height: 20px;
  line-height: 35px;
}
.inScenarioDefaultRuleToolTip {
  display: none;
}
.inScenarioDefaultRule {
  position: relative;
}
.inScenarioDefaultRule .inScenarioDefaultRuleToolTip {
  display: block;
}
.inScenarioDefaultRule:before {
  content: attr(content);
  position: absolute;
  top: -50px;
  left: 0;
  width: 240px;
  padding: 5px 15px;
  font-weight: 500;
  background: #fff;
  font-size: 12px;
  box-shadow: 0px 0px 7px -2px #717171 !important;
}
.inScenarioDefaultRule:after {
  content: '';
  position: absolute;
  top: -35px;
  left: 210px;
  width: 18px;
  height: 14px;
  background: url(../img/svg/arrow-carret-down.svg) no-repeat center right;
  background-size: 14px;
}
.inScenarioDefaultRule .inScenarioRuleHeader .pseudo-button-remove {
  display: none;
}
.inScenarioDefaultRule .inScenarioRuleSettings {
  display: none;
}
.inScenarioDivForRules {
  padding: 20px;
}
.inScenarioDivForRules .inScenarioDivWithAddRuleButton {
  padding-bottom: 10px;
}
.inScenarioNameDesc {
  position: absolute;
  font-weight: 500;
  top: -5px;
  left: 15px;
  background-color: #fff;
  line-height: 13px;
  color: #5d5d5d;
}
.inScenarioVQSpecialActionAddButton .pseudo-button-add {
  margin-left: 25px;
}
.inScenarioItemNotAvailable {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 10;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
  cursor: pointer;
  display: none;
}
.inScenarioItemNotAvailable div {
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
@media (max-width: 1370px) {
  .inScenarioItemNotAvailable div {
    font-size: 20px;
  }
}
.inScenarioItemNotAvailable span {
  margin-left: 10px;
}
.inScenarioNotAvailable .inScenarioItemNotAvailable {
  display: block;
  opacity: 0;
  transition: all 0.1s ease-in;
}
.inScenarioNotAvailable:hover .inScenarioItemNotAvailable {
  opacity: 1;
}
@media (max-width: 1380px) {
  .in-scenario-container {
    min-width: 1200px;
  }
  .inScenarioDropSettingsWrap {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .inScenarioDropHeader {
    padding: 8px 20px;
  }
  .inScenarioRulesLeftDiv {
    padding: 12px;
  }
  .inScenarioVQRedirectSelect {
    width: 300px !important;
  }
  .scenarioItemDrag {
    padding: 10px 10px;
  }
  .scenarioItemDrag .inScenarioRightIco {
    right: 6px;
    top: 14px;
  }
  .scenarioItemDrag .inScenarioRightIco svg {
    width: 20px;
    height: 20px;
  }
  .scenarioItemDrag .scenarioItemText {
    margin-left: 8px;
    font-size: 14px;
  }
  .inScenarioRuleDragAndDropDiv {
    padding: 0;
    padding-top: 10px;
  }
  .inScenarioRuleRightDivWrap {
    margin-left: 10px;
    flex-basis: 320px;
  }
}
.showMoreScenariosButton,
.currentScenariosCountDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 32px;
  margin-bottom: 20px;
  color: #12171d;
  border: #d8d8d8 1px solid;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.showMoreScenariosButton > span,
.currentScenariosCountDiv > span {
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
}
.inScenarioAudioSelectWithUpload {
  width: 300px;
  display: flex;
  justify-content: space-between;
}
.inScenarioAudioSelectWithUpload .dropdown-single-button {
  width: 240px;
}
.inScenarioAudioSelectWithUpload button {
  width: 50px;
  height: 50px;
}
.inScenarioAudioSelectWithUpload button img {
  margin-right: 0;
}
.scenarioItem {
  position: relative;
}
.promo-main-div .promoDivForCodes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}
.promo-main-div .promoOnePromoCodeBlock {
  border: 1px solid #dfe2e6;
  border-radius: 2px;
  width: 390px;
  height: 390px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeBody {
  padding: 30px 25px 72px;
  height: 100%;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeBody p ps {
  font-size: 14px;
  color: #363636;
  font-weight: 500;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeBody p span {
  font-size: 15px;
  color: #6e6e6e;
  font-weight: 400;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeBody .promoCodeLink {
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeBody .promoCopyLinkButton {
  cursor: pointer;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeFooter {
  border-top: 1px solid #dfe2e6;
  padding: 9px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbfcfd;
  position: absolute;
  height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeFooter a {
  color: #000000;
  display: flex;
  align-items: center;
  line-height: 27px;
}
.promo-main-div .promoOnePromoCodeBlock .promoOnePromoCodeFooter a svg {
  margin-right: 15px;
}
.promo-main-div .promoOnePromoCodeCreateDiv,
.promo-main-div .promoOnePromoCodeCreatingDiv,
.promo-main-div .promoOnePromoCodeExisted {
  height: 100%;
  width: 100%;
  position: relative;
}
.promo-main-div .promoOnePromoCodeCreateDivWrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f03f3c;
  cursor: pointer;
}
.promo-main-div .promoOnePromoCodeCreateDivWrap .add_item {
  font-size: 21px;
  color: #fff;
}
.promo-main-div .promoCodeCreateParameter {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.promo-main-div .promoCodeCreateParameter > div:first-child {
  width: 100px;
  font-weight: 500;
}
.promo-main-div .promoCodeCreateParameter > div:last-child input {
  padding: 5px;
  width: 230px;
}
.promo-main-div .promoSliderDiv {
  height: 60px;
}
.promo-main-div .promoSliderDiv .promoNewCodePartnerPercent,
.promo-main-div .promoSliderDiv .promoNewCodeClientPercent {
  color: #f03f3c;
  font-weight: 500;
}
.promo-main-div .promoSliderDiv .promoNewCodePercentTitle {
  font-weight: 500;
  font-size: 16px;
  margin-left: 30px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.promo-main-div .promoOnePromoCodeFooter {
  cursor: pointer;
}
.promo-main-div .promoKeyVal {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 10px;
}
.promo-main-div .promoKeyVal > div:first-child {
  width: 300px;
}
.promo-main-div .promoKeyVal .promoButton {
  width: 200px;
  margin-left: 15px;
}
.promo-main-div .promoProjectTopUpDiv .promoProjectInput {
  width: 280px;
}
.promo-main-div .promoProjectTopUpDiv .projectAmountValueInput {
  width: 120px;
}
.promo-main-div .promoProjectTopUpDiv .projectAmountKey {
  width: 100px;
  text-align: right;
  padding-right: 15px;
  font-weight: 500;
}
.promo-main-div .withdrawMoneyDiv > * {
  color: #0089ff;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv {
  color: #333;
}
.promo-main-div .withdrawMoneyDiv .promoWithdrawalMoneyDiv .employeeInput {
  width: 200px;
}
.promo-main-div .withdrawMoneyDiv .actualPromoCode {
  margin-top: 25px;
}
.promo-main-div .withdrawMoneyDiv .promoDateFilterDiv {
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 30px;
  font-weight: 500;
}
.promo-main-div .withdrawMoneyDiv .promoDateFilterDiv .promoDatePickerInput {
  border: none;
  height: 100%;
  width: 227px;
  margin-left: 15px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .transactionHistoryDiv {
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 5px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(1) {
  min-width: 100px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(2) {
  flex-grow: 1;
  min-width: 300px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(3) {
  min-width: 100px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(4) {
  min-width: 100px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(5) {
  min-width: 100px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(6) {
  min-width: 100px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(7) {
  min-width: 150px;
}
.promo-main-div .withdrawMoneyDiv .promoTransactionsTablesDiv .promoOperationTableDiv .tableCol:nth-child(8) {
  min-width: 150px;
}
.promo-main-div .promoInvitesTable .f-table__head {
  background-color: #657689;
}
.promo-main-div .promoInvitesTable .f-table__head .f-table__col {
  padding: 18px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}
.promo-main-div .promoInvitesTable .f-table__col {
  flex-basis: 100%;
  padding: 28px 30px;
  font-size: 14px;
}
.promo-main-div .promoInvitesTable .f-table__body .f-table__row {
  border-top: none;
}
.promo-main-div .promoInvitesTable .f-table__body .f-table__row:nth-child(even) {
  background-color: #f7fafc;
}
.promo-main-div .promoInvitesTable .f-table__row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.show-all-payment-systems-block__wrap,
.pay_price_block_wrap {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 1199px) {
  .onePaySystem {
    width: 100%;
  }
  .paySystemCanSaveCard .payment_block {
    height: 355px;
  }
}
@media (min-width: 1200px) {
  .onePaySystem {
    width: 50%;
  }
  .paySystemCanSaveCard {
    width: 100%;
  }
}
@media (min-width: 1650px) {
  .onePaySystem {
    width: 33.33333333%;
  }
  .paySystemCanSaveCard {
    width: 66.66666666%;
  }
}
.pay_price_block {
  display: flex;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.3);
}
.pay_price_block .ico {
  background: #258ce2;
  height: 103px;
  width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay_price_block .num {
  padding: 0 40px;
  font-weight: 500;
  font-size: 30px;
  color: #f03f3c;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7fa;
}
.pay_price_block .order {
  padding: 0 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.pay_price_block .order .order_title {
  font-weight: 500;
  font-size: 21px;
  color: #292929;
  margin-bottom: 8px;
}
.payment_block {
  display: flex;
  height: 355px;
  margin-bottom: 30px;
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
.payment_block:hover {
  box-shadow: 12px 12px 25px -3px rgba(0, 0, 0, 0.4);
}
.payment_block .left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfcfd;
  border-right: 1px solid #ebedee;
}
.payment_block .left img {
  vertical-align: middle;
}
.payment_block .right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding: 0 35px;
  background-color: #fff;
}
.payment_block .right .onePaySystemDesc {
  font-size: 15px;
  line-height: 26px;
  color: #292929;
  margin-bottom: 20px;
}
.payment_block .right .paySystemUserName {
  width: 100%;
  max-height: 0;
  transition: max-height 3s ease-out;
  overflow: hidden;
}
.payment_block .right .payment-buttons-div {
  margin-top: 20px;
}
.payment_block .right .payment-buttons-div .payment-button,
.payment_block .right .payment-buttons-div .payment-in-progress-button {
  margin-right: 10px;
  margin-bottom: 10px;
}
.payment_block .right .payment-buttons-div .payment-in-progress-button-width {
  width: 155px;
}
@media (max-width: 1199px) {
  .payment_block .right .payment-buttons-div .payment-button,
  .payment_block .right .payment-buttons-div .payment-in-progress-button,
  .payment_block .right .payment-buttons-div .payment-button-save-card {
    margin-bottom: 10px;
  }
}
.payment_block:hover .paySystemUserName {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}
.pay-system-card-settings-div {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .pay-system-card-settings-div {
    margin: 10px 0;
  }
}
.pay-in-one-click-card-select {
  margin-bottom: 6px;
  min-height: 50px !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #4a8df1;
  padding: 0 0;
}
.pay-in-one-click-card-select .bootstrap-select {
  padding: 0 0;
  min-height: 50px !important;
}
.pay-in-one-click-card-select .bootstrap-select .btn-default {
  padding-left: 20px;
  min-height: 50px !important;
}
@media (max-width: 650px) {
  .pay-in-one-click-card-select {
    width: 170px;
  }
}
.show-all-payment-systems-block {
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 103px;
  margin-bottom: 10px;
  background-color: #fff;
  color: lightgrey;
  box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}
.show-all-payment-systems-block:hover {
  box-shadow: 12px 12px 25px -3px rgba(0, 0, 0, 0.4);
  color: #258ce2;
}
.show-all-payment-systems-block .show-all-payment-systems-text-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  width: 100%;
  font-weight: 500;
  font-size: 30px;
}
@media (max-width: 750px) {
  .show-all-payment-systems-block .show-all-payment-systems-text-div i {
    display: none;
  }
}
.paymentMethodSelectContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  width: 430px;
}
.paymentMethodSelectContainer .commission {
  color: #808080;
}
.paymentMethodSelectContainer .countrySelectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select {
  width: 100% !important;
  height: 50px !important;
  font-weight: normal;
  border-bottom: 1px solid #4a8df1;
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select .dropdown-toggle {
  padding-left: 24px;
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select .dropdown-toggle span {
  font-weight: normal;
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select button {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select .dropdown-menu {
  overflow-y: scroll;
  max-height: 250px;
  scrollbar-width: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.paymentMethodSelectContainer .countrySelectContainer .bootstrap-select .dropdown-menu.inner::-webkit-scrollbar {
  display: none;
}
.paymentMethodSelectContainer .invoiceDescription {
  border-bottom: 1px solid #4a8df1;
  padding: 5px 10px 5px 24px;
}
.paymentMethodSelectContainer ul {
  list-style-type: none;
}
.paymentMethodSelectContainer .paymentMethodOption {
  padding: 12px 12px 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
}
.paymentMethodSelectContainer .paymentMethodOption.bank {
  border-top: 1px solid #4a8df1;
  border-bottom: 1px solid #4a8df1;
  text-align: center;
}
.paymentMethodSelectContainer .paymentMethodList .paymentMethodOption:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}
.paymentMethodSelectContainer .paymentMethodDropdown {
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  min-height: 50px;
  background-color: white;
  border-bottom: 1px solid #4a8df1;
}
.paymentMethodSelectContainer .paymentMethodDropdown .paymentMethodOption {
  border: none;
}
.paymentMethodSelectContainer .paymentMethodDropdown:hover {
  background-color: #e7e7e7;
}
.paymentMethodSelectContainer .paymentMethodDropdown:not(:has(li)) {
  text-align: left;
  padding-left: 24px;
}
.paymentMethodSelectContainer .paymentMethodList {
  position: relative;
  display: none;
  overflow: hidden;
  left: 0;
  z-index: 1;
  background-color: #FFFFFF;
  border-top: 1px solid #ddd;
  padding: 0 0 5px 0;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: max-height 0.5s ease-out;
}
.paymentMethodSelectContainer .paymentMethodList.show {
  display: block;
}
.paymentMethodSelectContainer .hiddenOptionsContainer {
  display: none;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.paymentMethodSelectContainer .hiddenOptionsContainer.show {
  display: block;
}
.paymentMethodSelectContainer .hiddenOptionsContainer ul {
  padding: 0;
  margin: 0;
}
.paymentMethodSelectContainer .expandableSection {
  border-top: 1px solid #ddd;
}
.paymentMethodSelectContainer .expandableSection .expandButton {
  padding: 12px 24px;
  display: block;
  width: 100%;
  background-color: transparent;
  color: #2196F3;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s;
}
.paymentMethodSelectContainer .expandableSection .expandButton:hover {
  color: #0b7dda;
}
.paymentMethodSelectContainer .bankPaymentForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.paymentMethodSelectContainer .bankPaymentForm .bankPaymentFormHint {
  font-size: 14px;
  color: #777777;
}
.paymentMethodSelectContainer .bankPaymentForm .bankPaymentFormInput {
  padding-left: 12px;
  height: 40px;
  width: 100%;
  color: #777777;
  font-size: 14px;
  border: 1px solid #C0CBD5;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
.paymentMethodSelectContainer .bankPaymentForm .getInvoiceButton {
  white-space: nowrap;
  height: 40px;
  width: 45%;
  align-self: center;
  line-height: 47px;
  padding: 0 25px;
  font-weight: 500;
  color: #d51515;
  border: 1px solid #d51515;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.paymentMethodSelectContainer .bankPaymentForm .getInvoiceButton:hover {
  background-color: #d51515;
  color: #fff;
}
.paymentMethodSelectContainer .bankPaymentForm .getInvoiceButton.disabled {
  pointer-events: none;
  border: 1px solid #b95858;
  color: #b95858;
}
.paymentMethodSelectContainer .payButton,
.paymentMethodSelectContainer .addCardButton {
  height: 40px;
  width: 40%;
  align-self: center;
  line-height: 47px;
  padding: 0 25px;
  font-weight: 500;
  color: #d51515;
  border: 1px solid #d51515;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.paymentMethodSelectContainer .payButton:hover,
.paymentMethodSelectContainer .addCardButton:hover {
  background-color: #d51515;
  color: #fff;
}
.paymentMethodSelectContainer .returnToBalance {
  align-self: center;
  cursor: pointer;
  color: #0b7dda;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
}
.paymentMethodSelectContainer .returnToBalance:hover {
  border-color: #0b7dda;
}
.mainPay2Div {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 400px;
  gap: 8px;
}
.mainPay2Div .pay2Param {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.mainPay2Div .pay2Param .payParamValue {
  height: 50px;
}
.mainPay2Div .pay2Param .payParamValue .dropdown-toggle span {
  font-weight: normal;
}
.mainPay2Div .pay2Param .currencyAndAmountDiv {
  display: flex;
}
.mainPay2Div .pay2Param .currencyAndAmountDiv .currencySelect {
  width: 20%;
}
.mainPay2Div .pay2Param .currencyAndAmountDiv .topUpAmountDiv {
  background-color: white;
  width: 80%;
  border-bottom: 1px solid #4A8DF1;
}
.mainPay2Div .pay2Param .currencyAndAmountDiv .topUpAmountDiv .topUpAmountInput {
  height: 100%;
  font-size: 15px;
  font-weight: 200;
}
.mainPay2Div .pay2Param .bootstrap-select {
  border-bottom: 1px solid #4a8df1;
}
.mainPay2Div .payButton {
  height: 40px;
  width: 40%;
  align-self: center;
  line-height: 47px;
  padding: 0 25px;
  font-weight: 500;
  color: #d51515;
  border: 1px solid #d51515;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.mainPay2Div .payButton:hover {
  background-color: #d51515;
  color: #fff;
}
.mainPay2Div .hyperlinkStyle {
  align-self: center;
  cursor: pointer;
  color: #0b7dda;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
}
.mainPay2Div .hyperlinkStyle:hover {
  border-color: #0b7dda;
}
.pay2Modal {
  background-color: #EDF1F5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.pay2Modal .pay2Param {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.pay2Modal .pay2Param .payParamValue {
  height: 50px;
  border-bottom: 1px solid #4A8DF1;
}
.pay2Modal .pay2Param .payParamValue .dropdown-toggle span {
  font-weight: normal;
}
.pay2Modal .pay2Param .notesSubmissionInput {
  width: 100%;
  padding: 10px;
  min-height: 150px;
  line-height: 1.5;
  resize: vertical;
  border: none;
}
.amoMainDiv [readonly] {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}
.amoMainDiv .settingsSector .two-column-div-desc {
  width: 300px;
}
.amoMainDiv .settingsSector .two-column-div-input {
  width: 300px;
}
.amoMainDiv .responsibleSelectDiv {
  flex-wrap: wrap;
}
.amoMainDiv .responsibleSelectDiv .two-column-div-desc {
  padding-left: 20px;
  width: 150px;
}
.amoMainDiv .responsibleSelectDiv .two-column-div-input {
  width: 300px;
}
.amoMainDiv .innerPhonesSector {
  flex-wrap: wrap;
}
.amoMainDiv .innerPhonesSector .two-column-div-desc {
  padding-left: 20px;
  width: 250px;
}
.amoMainDiv .innerPhonesSector .two-column-div-input {
  width: 150px;
}
.amoMainDiv .outerLinesSector {
  flex-wrap: wrap;
}
.amoMainDiv .outerLinesSector .two-column-div-desc {
  padding-left: 20px;
  width: 250px;
}
.amoMainDiv .outerLinesSector .two-column-div-input {
  width: 400px;
}
.amoMainDiv .divForDealFields,
.amoMainDiv .divForContactFields {
  display: flex;
  flex-wrap: wrap;
}
.amoMainDiv .oneField {
  width: 400px;
  padding: 5px;
}
.amoMainDiv .oneField .fieldName {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #00AEEF;
}
.amoMainDiv .oneField .fieldSettingDiv {
  height: 50px;
}
.amoMainDiv .oneField .choisesSelect {
  height: 100%;
}
.amoMainDiv .oneField .typeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.amoMainDiv .oneField .typeAndValueDiv .inputValue,
.amoMainDiv .oneField .typeAndValueDiv .typeSelect {
  width: 100%;
  height: 100%;
}
.amoMainDiv .domainName {
  color: #1e88e5;
  text-decoration: underline;
}
.amoMainDiv .divForOutScenarioRules {
  margin-left: 10px;
}
.amoMainDiv .oneOutScenarioRule {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}
.amoMainDiv .oneOutScenarioRule .fieldDesc {
  font-weight: 500;
  width: 200px;
}
.amoMainDiv .oneOutScenarioRule .divForAndRules .oneOutScenarioAndRule {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.amoMainDiv .oneOutScenarioRule .addConditionButton {
  padding-left: 200px;
  height: 50px;
  display: flex;
  align-items: center;
}
.amoMainDiv .oneOutScenarioRule .addConditionButton .addOutScenarioConditionButton {
  margin-left: 45px;
}
.amoMainDiv .oneOutScenarioRule .pseudo-button-remove {
  height: 19px;
  margin-left: 44px;
}
.amoMainDiv .oneOutScenarioRule .outScenarioFieldNameSelect {
  width: 300px;
  margin-left: 20px;
  margin-right: 20px;
}
.amoMainDiv .oneOutScenarioRule .outScenarioValueSelect {
  margin-left: 20px;
}
.amoMainDiv .oneOutScenarioRule .outScenarioScenarioSelect {
  width: 300px;
  margin-left: 20px;
}
.amoMainDiv .oneOutScenarioRule .outScenarioRemoveButton {
  margin-left: 40px;
}
.eprojects-table {
  margin-top: 20px;
}
.eprojects-table-col {
  padding: 15px 8px;
  padding-bottom: 0px;
}
.eprojects-table-col:nth-child(1) {
  flex-basis: 150px;
  min-width: 200px;
}
.eprojects-table-col:nth-child(2) {
  flex-basis: 150px;
  min-width: 120px;
}
.eprojects-table-col:nth-child(3) {
  flex-basis: 100px;
  min-width: 120px;
}
.eprojects-table-col:nth-child(4) {
  flex-basis: 150px;
  min-width: 150px;
}
.eprojects-table-col:nth-child(5) {
  min-width: 250px;
}
.eprojects-table-col:nth-child(6) {
  flex-basis: 120px;
  min-width: 120px;
}
.eprojects-table-col:nth-child(7) {
  flex-basis: 140px;
  min-width: 140px;
}
.eprojects-table-col:nth-child(8) {
  flex-basis: 1000px;
}
.eprojects-table-counters {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.eprojects-table-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.eprojects-table-row-main-info {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.eprojects-table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
}
.eprojects-table-ordered-header {
  padding: 15px;
  background-color: #dfe6ed;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
}
.eprojects-table-row-utm-collapsed {
  display: none;
}
.eprojects-table-row-utm-expanded {
  display: none;
}
.eprojects-table-one-project {
  background-color: #fff;
}
.eprojectServiceLabel {
  font-size: 12px;
  font-weight: 500;
  height: 21px;
  border: 1px solid #000;
  display: inline-flex;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  margin-bottom: 5px;
}
.eprojectServiceLabel.eprojectServiceLabelC2c {
  color: #ec30d8;
  border-color: #ec30d8;
}
.eprojectServiceLabel.eprojectServiceLabelCallTracking {
  color: #308bec;
  border-color: #308bec;
}
.eprojectServiceLabel.eprojectServiceLabelTelephony {
  color: #02ce00;
  border-color: #02ce00;
}
.eprojectServiceLabel.eprojectServiceLabelAutodial {
  color: #ed9d12;
  border-color: #ed9d12;
}
.eprojectServiceLabel.eprojectServiceLabelGuaranteedDial {
  color: #8f00ff;
  border-color: #8f00ff;
}
.eprojectServiceLabel.eprojectServiceLabelRobodialer {
  color: #8b0000;
  border-color: #8b0000;
}
.eprojects-table-row-utm > * {
  margin-left: 5px;
  margin-bottom: 5px;
  float: left;
}
.ordersDivWithFilters .orders-filters-first-row {
  display: flex;
  flex-wrap: wrap;
}
.ordersDivWithFilters .orders-filters-first-row .filterItem {
  width: 220px;
}
.ordersDivWithFilters .eprojects-orders-filters-main-types {
  display: flex;
  background-color: #fff;
  width: 450px !important;
}
.ordersDivWithFilters .eprojects-orders-filters-main-types > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  width: 33.33333333%;
}
.ordersDivWithFilters .eprojects-orders-filters-main-types > div.active {
  background-color: #2cabe3;
  color: #fff;
}
.ordersDivWithFilters .ordersFilterInput {
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  background-color: #fff;
}
.ordersDivWithFilters .orders-filters-clear-button {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ordersDivWithFilters .orders-filters-one-type {
  cursor: pointer;
}
.eprojectsProjectId {
  color: #dbdbdb;
  min-width: 40px;
  cursor: pointer;
}
.eprojectsProjectId:hover {
  color: #000000;
  text-decoration: underline;
}
.eprojectsProjectName {
  font-weight: 500;
  cursor: pointer;
}
.eprojectsProjectName:hover {
  text-decoration: underline;
}
.eprojectsProjectRegistered {
  min-width: 80px;
  margin-left: 40px;
}
.eprojectsProjectWithUs {
  font-weight: 500;
  margin-left: 40px;
}
.eprojectsProjectPayDay {
  font-weight: 500;
}
.eprojectsProjectLeftToPayDay {
  color: #a8a500;
}
.eprojectServiceLabel {
  font-weight: 500;
}
.eprojectsProjectCurrency {
  color: #373737;
  margin-left: 10px;
}
.eprojectsProjectOwnerName {
  font-weight: 500;
}
.eprojectsProjectCallsIn,
.eprojectsProjectCallsInDesc {
  font-weight: 500;
  color: #00c907;
}
.eprojectsProjectCallsOut,
.eprojectsProjectCallsOutDesc {
  font-weight: 500;
  color: #00AEEF;
}
.eprojectsProjectCallsSize,
.eprojectsProjectCallsSizeDesc {
  font-weight: 500;
  color: #00c907;
}
.eprojectsProjectCallsSizeDesc,
.eprojectsProjectCallsOutDesc,
.eprojectsProjectCallsInDesc {
  width: 70px;
}
.eprojectsCommentArea {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  display: flex;
  align-items: center;
}
.commentAuthorNameSpan {
  position: absolute;
  top: -15px;
  left: 2px;
  font-weight: 500;
  font-size: 13px;
  color: #7F7F7F;
}
.sorted-tab {
  cursor: pointer;
}
.sorted-tab:hover {
  background-color: #8298b0;
}
.eprojectsNotVerifiedProject {
  position: absolute;
  top: 35px;
  left: 10px;
  color: #7b7778;
  font-size: 15px;
  cursor: pointer;
}
@media (max-width: 1380px) {
  .eprojects-filters-project-name,
  .eprojects-filters-owner-name,
  .eprojects-filter-service-type {
    width: 158px;
  }
}
@media (max-width: 1600px) {
  .eprojects-sort-created {
    padding-left: 10px !important;
  }
  .eprojects-table-col {
    padding: 15px 8px;
  }
  .eprojects-table-col:nth-child(1) {
    flex-basis: 150px;
    min-width: 150px;
  }
  .eprojects-table-col:nth-child(2) {
    min-width: 100px;
  }
  .eprojects-table-col:nth-child(3) {
    min-width: 100px;
  }
  .eprojects-table-col:nth-child(4) {
    min-width: 120px;
  }
  .eprojects-table-col:nth-child(5) {
    min-width: 250px;
  }
  .eprojects-table-col:nth-child(7) {
    flex-basis: 130px;
    min-width: 130px;
  }
  .eprojects-table-col:nth-child(8) {
    flex-basis: 1000px;
  }
}
.bannedProjectNotify {
  color: red;
  position: absolute;
  top: 0;
  left: 47px;
}
.kamailioProjectNotify {
  position: absolute;
  bottom: -5px;
  left: 50px;
}
.eprojectAdditionalButtons {
  position: absolute;
  top: 2px;
  right: 2px;
}
.eprojectAdditionalButtons > div {
  margin-left: 5px;
  cursor: pointer;
}
.eprojectsOneProjectTelegramDiv {
  display: flex;
}
.telegram-message-text {
  margin: 10px;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 12px;
}
.projectGoldSupport .eprojectsProjectName {
  color: #D4AF37;
  font-weight: 500;
  text-decoration: underline;
}
.projectSilverSupport .eprojectsProjectName {
  color: #6C7A86;
  font-weight: 500;
  text-decoration: underline;
}
.eprojectsAdditionalsActionsDiv {
  display: flex;
  align-items: center;
  z-index: 1;
}
.eprojectsAdditionalsActionsDiv .eprojectsCitySelect {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.eprojectsOccupationDiv {
  display: flex;
  justify-content: space-between;
}
.eprojectsOccupationDiv .eprojectsSaveBtnSpan {
  vertical-align: super;
}
.tariffAndMultiDiv {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.tariffAndMultiDiv .header {
  display: flex;
  position: relative;
  height: 55px;
  align-items: center;
}
.tariffAndMultiDiv .header .count {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #0a55c6;
}
.tariffAndMultiDiv .header .title {
  font-size: 25px;
  margin-left: 20px;
  font-weight: 500;
  line-height: 50px;
}
.oneTariffAndMulti {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
}
.oneTariffAndMulti.tariffSelected {
  box-shadow: 0 0 10px 0 rgba(0, 192, 3, 0.82);
}
.oneTariffAndMulti .header {
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
}
.oneTariffAndMulti .header:hover {
  background-color: #fbfbfb;
}
.oneTariffAndMulti .header .partOfHeader {
  display: flex;
}
.oneTariffAndMulti .header .divWithCheckbox {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.oneTariffAndMulti .header .divWithCheckbox .next-checkbox-square {
  height: 15px;
}
.oneTariffAndMulti .header .headerCenter {
  display: flex;
  flex-direction: column;
}
.oneTariffAndMulti .header .headerCenter .location {
  display: flex;
  height: 50%;
}
.oneTariffAndMulti .header .headerCenter .location .country,
.oneTariffAndMulti .header .headerCenter .location .type,
.oneTariffAndMulti .header .headerCenter .location .company,
.oneTariffAndMulti .header .headerCenter .location .name {
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 500;
}
.oneTariffAndMulti .header .headerCenter .location .country,
.oneTariffAndMulti .header .headerCenter .location .type,
.oneTariffAndMulti .header .headerCenter .location .company {
  color: #00AEEF;
}
.oneTariffAndMulti .header .headerCenter .location .type,
.oneTariffAndMulti .header .headerCenter .location .company,
.oneTariffAndMulti .header .headerCenter .location .name {
  margin-left: 15px;
}
.oneTariffAndMulti .header .headerCenter .location .country {
  width: 40px;
}
.oneTariffAndMulti .header .headerCenter .location .type {
  width: 150px;
}
.oneTariffAndMulti .header .headerCenter .location .company {
  min-width: 120px;
}
.oneTariffAndMulti .header .headerCenter .location .name {
  min-width: 120px;
}
.oneTariffAndMulti .header .headerCenter .codesDiv {
  display: flex;
  align-items: center;
  height: 50%;
}
.oneTariffAndMulti .header .indexes {
  font-size: 13px;
  font-weight: 500;
  min-width: 300px;
  border-bottom: 1px solid #dcdcdc;
  cursor: auto;
}
.oneTariffAndMulti .header .leasesOneCost {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: wrap;
  width: 300px;
}
.oneTariffAndMulti .header .leasesOneCost .oneCurrency {
  display: flex;
  justify-content: left;
  height: 50%;
  padding: 5px;
}
.oneTariffAndMulti .header .leasesOneCost .oneCurrency > div {
  display: flex;
  align-items: center;
  height: 100%;
}
.oneTariffAndMulti .header .leasesOneCost .oneCurrency > div img {
  width: 30px;
  margin: -3px;
}
.oneTariffAndMulti .header .leasesOneCost .oneCurrency > div:last-child {
  width: 50px;
  margin-left: 10px;
  font-weight: 500;
  margin-top: 1px;
}
.oneTariffAndMulti .header .notSavedWarning {
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 20px;
  font-weight: 500;
  margin-right: 20px;
  display: none;
}
.oneTariffAndMulti .header .linesCount {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.oneTariffAndMulti .header .icon {
  width: 70px;
  position: relative;
  overflow: hidden;
  height: 30px;
}
.oneTariffAndMulti .header .icon svg {
  width: 70px;
  transform: translate(0%, -30%);
}
.oneTariffAndMulti .header .icon .value {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-shadow: 0px 0px 6px #000000;
}
.oneTariffAndMulti .body {
  position: relative;
  border-top: 1px solid #ebebeb;
  padding: 10px;
  padding-top: 0;
}
.oneTariffAndMulti .body .idDiv {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #c5c5c5;
  font-size: 11px;
}
.oneTariffAndMulti .body .bodyItem {
  height: 25px;
}
.oneTariffAndMulti .body .bodyContent .tariffSection {
  background-color: rgba(0, 169, 255, 0.05);
  padding: 5px;
  padding-top: 25px;
  padding-left: 20px;
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidPriceSection {
  display: flex;
  margin-top: 5px;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidPriceSection .pseudo-button-add span:before {
  left: -32px;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidPriceSection .divForBuyOutPrepaidPrice {
  flex-grow: 1;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidPriceSection .oneBuyOutPrepaidPrice {
  display: flex;
  align-items: center;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidInPriceSection .defaultParameterInPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 157px;
  margin-right: 20px;
}
.oneTariffAndMulti .body .bodyContent .tariffSection .buyPrepaidInPriceSection .defaultParameterInPrice .paramNameInPrice {
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .moneyInputWithFlag {
  display: flex;
}
.oneTariffAndMulti .body .bodyContent .moneyInputWithFlag .divForIcon {
  height: 100%;
  width: 37px;
  border: 1px solid #e4e7ea;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  overflow: hidden;
}
.oneTariffAndMulti .body .bodyContent .moneyInputWithFlag .divForIcon img {
  height: 24px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.oneTariffAndMulti .body .bodyContent .moneyInputWithFlag input {
  width: 70px;
  padding-left: 1px;
  padding-right: 1px;
  border: 1px solid #e4e7ea;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: none;
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .sectionName {
  font-weight: 500;
  position: absolute;
  left: 5px;
  top: 5px;
  color: #0b7dda;
}
@media (max-width: 1620px) {
  .oneTariffAndMulti .body .bodyContent {
    flex-wrap: wrap;
  }
}
.oneTariffAndMulti .body .bodyContent .sectionHeader {
  height: 30px;
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .inputWithDescription {
  margin-bottom: 5px;
}
.oneTariffAndMulti .body .bodyContent .inputWithDescription span {
  padding: 0 5px 0;
  height: unset;
}
.oneTariffAndMulti .body .bodyContent .inputWithDescription input {
  font-size: 15px;
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .inputWithDescription.forDigit .input-group-addon {
  width: 70%;
}
.oneTariffAndMulti .body .bodyContent .onePrice .bodyItem {
  padding: 0;
}
.oneTariffAndMulti .body .bodyContent .tariffActivationDiv {
  width: 108px;
}
.oneTariffAndMulti .body .bodyContent .tariffChangeDiv {
  width: 108px;
}
.oneTariffAndMulti .body .bodyContent .tariffSettingsDiv {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.oneTariffAndMulti .body .bodyContent .tariffSettingsDiv .next-checkbox-square {
  margin-bottom: 0;
}
.oneTariffAndMulti .body .bodyContent .tariffSettingsDiv .tarifficationDiv {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.oneTariffAndMulti .body .bodyContent .tariffSettingsDiv .leasesTarifficationSelect .dropdown-toggle {
  padding-left: 5px;
}
.oneTariffAndMulti .body .bodyContent .contentMainVertical {
  max-width: 705px;
}
.oneTariffAndMulti .body .bodyContent .tariffPercentDiv {
  display: flex;
  align-items: center;
}
.oneTariffAndMulti .body .bodyContent .tariffPercentDiv .paramName {
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .tariffPercentDiv input {
  width: 50px;
}
.oneTariffAndMulti .body .bodyContent .tariffDirectionsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 720px;
}
.oneTariffAndMulti .body .bodyContent .rowIndexes {
  font-weight: 500;
  color: #565656;
}
.oneTariffAndMulti .body .bodyContent .divForNames {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.oneTariffAndMulti .body .bodyContent .divForCurrencyRates {
  display: flex;
  flex-direction: column;
}
.oneTariffAndMulti .body .bodyContent .divForManualChangedRatesDescription {
  padding-left: 10px;
  font-family: monospace;
  color: red;
}
.oneTariffAndMulti .body .bodyContent .divForAutoChangedRatesDescription {
  padding-left: 10px;
  font-family: monospace;
  color: #00bd0c;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup {
  display: flex;
  align-items: center;
  font-weight: 500;
  max-height: 25px;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup input {
  height: 25px;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup .priceDescAndField {
  margin-right: 10px;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup .priceDescAndField .desc {
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup .priceDescAndField .field {
  width: 100%;
}
.oneTariffAndMulti .body .bodyContent .callPricesSetup .priceDescAndField .field .button_default {
  height: 25px;
  line-height: 25px;
}
.oneTariffAndMulti .body .bodyContent .tarificationSettings {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.oneTariffAndMulti .body .bodyContent .tarificationSettings input {
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  height: 25px;
  width: 80px;
}
.oneTariffAndMulti .body .bodyContent .tarificationSettings .paramName {
  font-weight: 500;
}
.oneTariffAndMulti .body .bodyContent .leaseInput {
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  height: 25px;
  padding-left: 5px;
  width: 100%;
}
.oneTariffAndMulti .body .bodyContent .tariffsSelect {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  width: 100%;
}
.oneTariffAndMulti .body .bodyContent .tariffsSelect .dropdown-toggle {
  padding: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  padding-left: 5px;
}
.oneTariffAndMulti .body .bodyContent .tariffsSelect .dropdown-toggle .caret {
  right: 5px;
}
.oneTariffAndMulti .body .bodyContent .tariffsSelect .bootstrap-select {
  width: 100%;
}
.oneTariffAndMulti .body .bodyContent .defaultParameter {
  width: 280px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  align-items: center;
}
.oneTariffAndMulti .body .bodyContent .defaultParameter .paramName,
.oneTariffAndMulti .body .bodyContent .defaultParameter input {
  font-weight: 500;
}
.oneLeaseLang {
  display: flex;
  width: 400px;
  margin-bottom: 5px;
  justify-content: center;
  margin-right: 10px;
}
.oneLeaseLang .langCode {
  width: 40px;
  font-weight: 500;
  line-height: 25px;
}
.oneLeaseLang .langTranslate {
  width: 100%;
  height: 25px;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  padding-left: 5px;
}
.oneLeaseLang .langTranslate::placeholder {
  color: red;
}
.oneValueRate {
  display: flex;
  width: 290px;
  padding-left: 10px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}
.oneValueRate .currencyCode {
  width: 40px;
  font-weight: 500;
  line-height: 25px;
}
.oneValueRate .currencyRate {
  height: 25px;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  padding-left: 5px;
  width: 90px;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  text-align: right;
}
.oneValueRate .currencyDelta {
  height: 25px;
  padding-left: 5px;
  width: 75px;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
}
.oneValueRate .currencyUpButton {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
.oneValueRate .currencyUpButton path {
  fill: #4a8cc7;
}
.oneValueRate .rateByDay {
  height: 25px;
  padding-left: 5px;
  width: 64px;
  display: flex;
  align-items: center;
}
.oneValueRate .showPricesInCurrencyButton {
  background-color: #4a8cc7;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  max-width: 50px;
  margin-left: 5px;
  cursor: pointer;
  height: 100%;
}
.notSaved .notSavedWarning {
  display: flex !important;
}
.redShadowOnError {
  box-shadow: 0 0 10px 0 rgba(192, 0, 8, 0.82) !important;
}
.globalBulkMode .oneTariffAndMulti {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
}
.globalBulkMode .tariffSelected {
  box-shadow: 0 0 10px 0 rgba(0, 192, 3, 0.82);
}
.leasesLinesCountSquare {
  background-color: #00c907;
  border-radius: 5px;
  position: relative;
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.leasesLinesCountSquare span {
  color: #fff;
}
.leasesLinesCountSquare .leasesLinesCount {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}
.leasesLinesCountSquare .desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
}
.leasesMoneyInputs .input-group {
  margin-bottom: 5px;
  font-weight: 500;
}
.leasesMoneyInputs .input-group img {
  width: 37px;
  overflow: hidden;
  border-radius: 2px;
}
.leasesMoneyInputs .input-group .input-group-addon {
  padding: 0 0px 0;
  max-height: 25px;
}
.leasesMoneyInputs .input-group .form-control {
  width: 70px;
}
.tariffDirectionsDiv .quickPreview {
  font-weight: 500;
  color: #1b76c7;
  cursor: pointer;
}
.tariffDirectionsDiv .quickPreview:hover {
  text-decoration: underline;
}
.tariffDirectionsDiv .leasesTariffTable {
  width: 100%;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs {
  display: flex;
  width: 100%;
  height: 28px;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab {
  width: 33.3333333%;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab a {
  position: absolute;
  right: 4px;
  top: 4px;
  display: none;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab:first-child {
  border-top-left-radius: 3px;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab:last-child {
  border-top-right-radius: 3px;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab:hover {
  background-color: #fcfcfc;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab.active {
  cursor: default;
  border-bottom: none;
  background-color: #fbfbfb;
}
.tariffDirectionsDiv .leasesTariffTable .tableTabs .tableTab.active a {
  display: block;
}
.tariffDirectionsDiv .leasesTariffTable .sections {
  border: 1px solid #e3e3e3;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section {
  width: 100%;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow {
  height: 30px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow:nth-child(odd) {
  background-color: #fbfbfb;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow:nth-child(even) {
  background-color: #fff;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .outDirectionBlackListDescription {
  width: 260px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 400;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sellPrepaidInSecondsDescription {
  width: 227px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 400;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sellPrepaidInSecondsInput {
  width: 100px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .leasesMoneyInputs {
  display: flex;
  flex-direction: row-reverse;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .leasesMoneyInputs .input-group {
  margin-bottom: 0;
  margin-left: 5px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow {
  display: flex;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .form-control {
  width: 50px;
  padding: 0 3px 0;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .minutesInput {
  width: 75px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .tableLeft {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 5px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .tableLeft .input-group {
  margin-bottom: 0;
  margin-left: 5px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .tableRight {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .inputDiv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 30px;
  display: flex;
  align-items: center;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .inputDiv input {
  width: 100%;
  height: 25px;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  padding-left: 5px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .sectionRow .removeDiv {
  position: absolute;
  top: 0;
  right: 0;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .oneInPrice {
  display: flex;
  align-items: center;
  margin-top: 3px;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  height: 27px;
  background-color: #fff;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .oneInPrice .inDirection {
  font-weight: 500;
  width: 70px;
  padding-left: 10px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .oneInPrice .next-checkbox-square {
  top: -11px;
  left: 3px;
}
.tariffDirectionsDiv .leasesTariffTable .sections .section .oneInPrice .divForPrices .onePrice {
  display: flex;
  flex-direction: row;
}
.divWithControlButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}
.tariffControlButton {
  height: 25px !important;
  line-height: 25px !important;
  font-size: 14px !important;
  margin-left: 10px;
  width: 130px;
}
.leasesAddButton {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 40px;
  display: flex;
  align-items: center;
}
.leasesAddButton .newTypeChooser {
  display: flex;
  align-items: center;
}
.leasesAddButton .newTypeChooser input {
  width: 45px;
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  height: 37px;
  border: 2px solid #e8e8e8;
}
.leasesAddButton .newTypeChooser .newTypeSelect {
  width: 165px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #e8e8e8;
}
.leasesAddButton .newTypeChooser .newCompanyInputDiv {
  width: 165px;
  margin-top: 0px;
  margin-right: 20px;
}
.leasesAddButton .newTypeChooser .newCompanyInputDiv input {
  width: 100%;
  margin-left: 0px;
}
.leasesAddButton .button_default {
  height: 40px;
  line-height: 40px;
}
.leasesFiltersWrapper {
  display: flex;
  padding-bottom: 20px;
}
.leasesFiltersWrapper .leasesFilters {
  display: flex;
}
.leasesFiltersWrapper .leasesFilters .oneFilter {
  margin-right: 20px;
}
.leasesFiltersWrapper .nextel-autocomplete-list {
  top: 50px;
}
.leasesFiltersWrapper .changedCurrencyRateDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leasesFiltersWrapper .changedCurrencyRateDiv label {
  width: 400px;
}
.leasesFiltersWrapper .paginationRadio {
  display: flex;
  margin-top: 5px;
}
.leasesFiltersWrapper .paginationRadio .nextel-radio {
  width: 31px;
}
.leasesAddMultiButton {
  display: flex;
  margin-left: auto;
}
.leasesAddMultiButton .createButton {
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
}
.leasesAddMultiButton .countrySelect .bootstrap-select {
  width: 90px !important;
}
.leasesAddMultiButton .locationTypeSelect .bootstrap-select {
  width: 175px !important;
}
.leasesAddMultiButton .dropdown-toggle {
  height: 40px !important;
  padding: 11px !important;
}
.oneProjectWithNumber {
  display: flex;
  margin-bottom: 5px;
}
.oneProjectWithNumber .projectLogin {
  font-weight: 500;
  min-width: 150px;
}
.oneProjectWithNumber .currency {
  font-weight: 500;
  min-width: 35px;
}
.oneProjectWithNumber .number {
  width: 140px;
}
.oneTariffPrice {
  font-family: monospace;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.oneTariffPrice .priceColumn {
  margin-right: 10px;
}
.oneTariffPrice .selectedCbDiv {
  width: 20px;
  height: 20px;
}
.oneTariffPrice .selectedCbDiv input {
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.oneTariffPrice .errorField {
  animation: blink-animation 2s infinite;
  -webkit-animation: blink-animation 2s infinite;
}
@keyframes blink-animation {
  to {
    background-color: rgba(255, 0, 0, 0.38);
  }
}
@-webkit-keyframes blink-animation {
  to {
    background-color: red;
  }
}
.onePrefixDetails {
  font-family: monospace;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.onePrefixDetails .priceColumn {
  margin-right: 10px;
}
.onePrefixDetails .selectedCbDiv {
  width: 25px;
  height: 20px;
}
.onePrefixDetails .selectedCbDiv input {
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.prefixesEditor {
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 60px;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 0px 7px -2px #000000 !important;
  height: calc(100% - 60px);
}
.prefixesEditor .greyHeader {
  background-color: #dfe6ed;
  color: #000000;
  padding: 8px;
  padding-left: 15px;
  line-height: 30px;
  font-weight: 500;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.leasesTable {
  padding: 15px;
  padding-top: 0;
  transition: none;
  animation: none;
  position: relative;
}
.leasesTable .tableHeader .columnNames,
.leasesTable .tableHeader .filterControls {
  display: flex;
}
.leasesTable .tableHeader .columnNames .priceColumn {
  margin-right: 10px;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}
.leasesTable .tableHeader .columnNames .priceColumn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.leasesTable .tableHeader .columnNames .priceColumn.desc {
  background-color: rgba(0, 174, 239, 0.26);
}
.leasesTable .tableHeader .columnNames .priceColumn.asc {
  background-color: rgba(0, 104, 239, 0.25);
}
.leasesTable .tableHeader .filterControls .priceColumn {
  margin-right: 10px;
}
.leasesTable .tableHeader .filterControls .cbColumn {
  position: relative;
}
.leasesTable .tableHeader .filterControls .cbColumn input {
  position: absolute;
  bottom: 4px;
  left: 1px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.leasesTable .priceColumn:nth-child(1) {
  width: 25px;
}
.leasesTable .priceColumn:nth-child(2) {
  width: 100px;
}
.leasesTable .priceColumn:nth-child(3) {
  width: 150px;
}
.leasesTable .priceColumn:nth-child(4) {
  width: 95px;
}
.leasesTable .priceColumn:nth-child(5) {
  width: 400px;
}
.leasesTable .priceColumn:nth-child(6) {
  width: 250px;
}
.leasesTable .priceColumn:nth-child(7) {
  width: 250px;
}
.leasesTable .priceColumn:nth-child(8) {
  width: 150px;
}
.leasesTable .priceColumn:nth-child(9) {
  width: 130px;
}
.leasesTable .priceColumn:nth-child(10) {
  width: 150px;
}
.leasesTable .countBar,
.leasesTable .selectBar {
  height: 30px;
}
.leasesTable .countBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eae9ee;
}
.leasesTable .selectBar {
  display: flex;
  align-items: center;
  background-color: #aff7ff;
  padding-left: 20px;
}
.leasesTable .selectBar button {
  height: 25px;
  line-height: 25px;
  margin-left: 10px;
}
.leasesTable .divForCallPricesInTableWrap {
  position: relative;
}
.leasesTable .divForCallPricesInTableWrap .divForCallPricesInTable {
  overflow-y: scroll;
  border: 1px solid #E0E0E0;
  max-height: calc(100vh - 400px);
  min-height: calc(100vh - 400px);
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu {
  position: absolute;
  top: 20px;
  left: calc(50% - 70px);
  transform: translateX(-50%);
  background-color: #fffefd;
  border: 2px solid #1e98ff;
  padding: 5px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .paramWithValueBlock {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .paramWithValueBlock .paramWithValue {
  display: flex;
  margin-top: 5px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .paramWithValueBlock .paramWithValue > div:first-child {
  width: 200px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .paramWithValueBlock .paramWithValue > div:last-child {
  padding-left: 20px;
  width: 160px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .modalHeader {
  display: flex;
  justify-content: space-between;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .modalHeader .headerName {
  color: #1e98ff;
  font-size: 20px;
  margin-bottom: 10px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .modalHeader .cancelNewValueButton {
  font-size: 50px;
  line-height: 30px;
  transform: rotate(-45deg);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .modalHeader .cancelNewValueButton:hover {
  color: rgba(255, 0, 0, 0.78);
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .buttons {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-left: 10px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .buttons button {
  margin-top: 10px;
  height: 34px;
  line-height: 34px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .calculatedPrice {
  display: flex;
  font-family: 'JetBrains Mono', monospace;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .calculatedPrice .currency {
  width: 40px;
}
.leasesTable .divForCallPricesInTableWrap .editPopupMenu .calculatedPrice .date {
  width: 100px;
}
.leasesTable .filtersHidden {
  display: none;
}
.leasesTable .displayHidden {
  display: none;
}
.leasesTable .newRow {
  background-color: rgba(0, 255, 12, 0.3);
}
.leasesTable .removedRow {
  background-color: rgba(255, 9, 0, 0.22);
}
.leasesTable .changedRow {
  background-color: rgba(255, 251, 0, 0.29);
}
.leasesTable .inputWithDescription {
  margin-bottom: 5px;
  position: relative;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
}
.leasesTable .inputWithDescription span {
  padding: 0 5px 0;
  height: unset;
  position: absolute;
  top: 1px;
  left: 2px;
  font-size: 10px;
  color: #1e88e5;
}
.leasesTable .inputWithDescription input {
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  padding-top: 20px;
  height: 30px;
}
.leasesTable .inputWithDescription .priceFilterDateInput {
  padding: 0;
  padding-top: 20px;
  padding-left: 5px;
  font-weight: 600;
  background-color: #fff;
  border: none;
  font-family: monospace;
  width: 100%;
  cursor: pointer;
}
.leasesTable .inputWithDescription .dropDateFilter {
  position: absolute;
  top: 1px;
  right: 3px;
  font-weight: 600;
  color: #656565;
  font-size: 25px;
  line-height: 15px;
  transform: rotate(45deg);
  cursor: pointer;
}
.leasesTable .valueEditButton {
  height: 30px;
  line-height: 15px;
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leasesImportFileUpload .fileDropZone {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border: dotted 2px #8181a0;
}
.leasesImportFileUpload .fileDropZone:hover {
  background-color: #eff4f8;
}
.leasesImportFileUpload .fileDropZone .chooseFileButton {
  line-height: 30px;
  border: 2px #8181a0 solid;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 20px;
  color: #8181a0;
}
.leasesImportFileUpload .fileDropZone .uploadStatus {
  line-height: 30px;
  margin-left: 5px;
  font-size: 20px;
  color: #8181a0;
}
.callPricesEditor .priceColumn:nth-child(1) {
  width: 25px;
}
.callPricesEditor .priceColumn:nth-child(2) {
  width: 150px;
}
.callPricesEditor .priceColumn:nth-child(3) {
  width: 150px;
}
.callPricesEditor .priceColumn:nth-child(4) {
  width: 120px;
}
.callPricesEditor .priceColumn:nth-child(5) {
  width: 100px;
}
.callPricesEditor .priceColumn:nth-child(6) {
  width: 200px;
}
.callPricesEditor .priceColumn:nth-child(7) {
  width: 210px;
}
.callPricesEditor .priceColumn:nth-child(8) {
  width: 150px;
}
.callPricesEditor .priceColumn:nth-child(9) {
  width: 130px;
}
.callPricesEditor .priceColumn:nth-child(10) {
  width: 150px;
}
.prefixesEditor .priceColumn:nth-child(1) {
  width: 25px;
}
.prefixesEditor .priceColumn:nth-child(2) {
  width: 100px;
}
.prefixesEditor .priceColumn:nth-child(3) {
  width: 150px;
}
.prefixesEditor .priceColumn:nth-child(4) {
  width: 95px;
}
.prefixesEditor .priceColumn:nth-child(5) {
  width: 400px;
}
.prefixesEditor .priceColumn:nth-child(6) {
  width: 250px;
}
.prefixesEditor .priceColumn:nth-child(7) {
  width: 250px;
}
.prefixesEditor .priceColumn:nth-child(8) {
  width: 150px;
}
.prefixesEditor .priceColumn:nth-child(9) {
  width: 130px;
}
.prefixesEditor .priceColumn:nth-child(10) {
  width: 150px;
}
.migrateOldPricesModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.providersDiv {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 0 7px -2px #000 !important;
}
.callPrefixesEditor .priceColumn:nth-child(1) {
  width: 25px;
}
.callPrefixesEditor .priceColumn:nth-child(2) {
  width: 150px;
}
.callPrefixesEditor .priceColumn:nth-child(3) {
  width: 150px;
}
.callPrefixesEditor .priceColumn:nth-child(4) {
  width: 120px;
}
.callPrefixesEditor .priceColumn:nth-child(5) {
  width: 100px;
}
.callPrefixesEditor .priceColumn:nth-child(6) {
  width: 200px;
}
.callPrefixesEditor .priceColumn:nth-child(7) {
  width: 210px;
}
.callPrefixesEditor .priceColumn:nth-child(8) {
  display: none;
}
.callPrefixesEditor .priceColumn:nth-child(9) {
  display: none;
}
.callPrefixesEditor .priceColumn:nth-child(10) {
  width: 150px;
}
.providersDiv {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 0 7px -2px #000 !important;
}
.messageTariffsDiv {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.messageTariffsDiv .header {
  display: flex;
  position: relative;
  height: 55px;
}
.messageTariffsDiv .header .title {
  font-size: 25px;
  margin-left: 20px;
  font-weight: 500;
  line-height: 50px;
}
.messageTariffsDiv .header .messageTariffAddButton {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 40px;
  display: flex;
  align-items: center;
}
.messageTariffsDiv .header .messageTariffAddButton .button_default {
  height: 40px;
  line-height: 40px;
}
.messageTariffsDiv .header .messageTariffAddButton input {
  width: 45px;
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  height: 37px;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .header .messageTariffAddButton .newTypeSelect {
  width: 165px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader {
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  height: 55px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader:hover {
  background-color: #fbfbfb;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader .partOfMessageHeader {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader .partOfMessageHeader .divForProvider {
  position: relative;
  width: 140px;
  height: 100%;
  margin-left: 10px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader .partOfMessageHeader .divForProvider .headerProvider {
  position: absolute;
  top: 30%;
  color: #00AEEF;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader .partOfMessageHeader .difForName {
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: 10px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageHeader .partOfMessageHeader .difForName .headerName {
  position: absolute;
  top: 30%;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody {
  border-top: 1px solid #ebebeb;
  padding: 10px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .bodyItem {
  height: 25px;
  padding: 0;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData {
  width: 100%;
  display: block;
  flex-direction: column;
  min-width: 720px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageTariffHeadName {
  height: 40px;
  width: 300px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageTariffHeadName .tariffHeaderNames {
  display: flex;
  align-items: center;
  height: 40px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageTariffHeadName .tariffHeaderNames .messageTariffNameInput {
  height: 40px;
  width: 300px;
  padding: 5px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageTariffHeadName .button_default {
  height: 40px;
  line-height: 40px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageProvider .providersType {
  width: 200px;
  height: 40px;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageTarification .tarification {
  width: 200px;
  height: 40px;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageCurrency .currencyName {
  width: 200px;
  height: 40px;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageDeniedDirections {
  width: 600px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageDeniedDirections .messageDeniedDirectionsDiv {
  height: 40px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageDeniedDirections .messageDeniedDirectionsDiv .deniedDirectionsInput {
  height: 40px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .messageDeniedDirections .excludedOutDirectionsInput {
  width: 100%;
  height: 100%;
  border: 2px solid #e8e8e8;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffsDivData .messageTariffsData .editMessagePrice .editMessagePricesButton {
  height: 40px;
  line-height: 40px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .tariffSection {
  background-color: rgba(0, 169, 255, 0.05);
  padding: 5px;
  padding-top: 25px;
  padding-left: 20px;
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection .sectionName {
  font-weight: 500;
  position: absolute;
  left: 5px;
  top: 5px;
  color: #0b7dda;
}
@media (max-width: 1620px) {
  .messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection {
    flex-wrap: wrap;
  }
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection .contentMainVertical {
  max-width: 700px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection .contentMainVertical .divForCurrencyRates {
  display: flex;
  flex-direction: column;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection .contentMainVertical .divForManualChangedRatesDescription {
  padding-left: 10px;
  font-family: monospace;
  color: red;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .messageBodyContent .sellSection .contentMainVertical .divForAutoChangedRatesDescription {
  padding-left: 10px;
  font-family: monospace;
  color: #00bd0c;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .divWithControlButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}
.messageTariffsDiv .divForMessageTariffs .oneMessageTariff .messageTariffBody .divWithControlButtons .tariffControlButton {
  height: 25px !important;
  line-height: 25px !important;
  font-size: 14px !important;
  margin-left: 10px;
  width: 130px;
}
.messageTariffsDiv .divForMessageTariffs .generalTariff {
  background-color: #e0eaff;
}
.directionBody {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.alfaNameBody {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.showUnavailableMccMncCodes {
  width: 700px;
  position: absolute;
  top: 20px;
  left: calc(50% - 70px);
  transform: translateX(-50%);
  background-color: #fffefd;
  border: 2px solid #1e98ff;
  padding: 5px;
}
.showUnavailableMccMncCodes .modalHeader {
  display: flex;
  justify-content: space-between;
}
.showUnavailableMccMncCodes .modalHeader .headerName {
  color: #1e98ff;
  font-size: 20px;
  margin-bottom: 10px;
}
.showUnavailableMccMncCodes .modalHeader .cancelNewValueButton {
  margin-top: 10px;
  font-size: 50px;
  line-height: 30px;
  transform: rotate(-45deg);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.showUnavailableMccMncCodes .modalHeader .cancelNewValueButton:hover {
  color: rgba(255, 0, 0, 0.78);
}
.showUnavailableMccMncCodes .warning-message {
  width: 100%;
  height: 35px;
  background-color: #ddd109;
  /* Light red background for warning */
  border: 1px solid #ddd109;
  /* Border similar to the background */
  text-align: center;
  line-height: 25px;
  /* Vertically center text */
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.showUnavailableMccMncCodes .modalContent {
  display: flex;
  justify-content: space-between;
}
.showUnavailableMccMncCodes .modalContent .unavailableCodes {
  margin-top: 10px;
}
.messagePrefixesEditor .priceColumn:nth-child(1) {
  width: 25px;
}
.messagePrefixesEditor .priceColumn:nth-child(2) {
  width: 150px;
}
.messagePrefixesEditor .priceColumn:nth-child(3) {
  width: 150px;
}
.messagePrefixesEditor .priceColumn:nth-child(4) {
  width: 120px;
}
.messagePrefixesEditor .priceColumn:nth-child(5) {
  width: 100px;
}
.messagePrefixesEditor .priceColumn:nth-child(6) {
  width: 200px;
}
.messagePrefixesEditor .priceColumn:nth-child(7) {
  width: 210px;
}
.messagePrefixesEditor .priceColumn:nth-child(8) {
  width: 150px;
}
.messagePrefixesEditor .priceColumn:nth-child(9) {
  width: 220px;
}
.messagePrefixesEditor .priceColumn:nth-child(10) {
  width: 150px;
}
.billStatMainDiv .filtersBar {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.billStatMainDiv .rangeSelectorDiv {
  display: flex;
  background-color: #fff;
}
.billStatMainDiv .rangeSelectorDiv .desc {
  line-height: 30px;
  font-weight: 500;
  padding: 10px;
  cursor: default;
}
.billStatMainDiv .rangeSelectorDiv .selector {
  width: 200px;
  height: 100%;
}
.billStatMainDiv .rangeSelectorDiv .selector input {
  background-color: #fff;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  font-weight: 300;
}
.billStatMainDiv #paymentsByProjectChart {
  margin-top: 20px;
}
.billStatMainDiv .content-wrap .section {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.billStatMainDiv .content-wrap .section .chartSpoiler .header {
  background-color: #99BFE7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
}
.billStatProjPaymentWrap {
  display: flex;
}
.billStatProjPaymentWrap .divForCurrencyRates {
  position: absolute;
  top: 10px;
  right: 20px;
}
.billStatProjPaymentWrap .divForCurrencyRates .oneCurrencyRate {
  display: flex;
  font-family: "JetBrains Mono";
}
.billStatProjPaymentWrap .divForCurrencyRates .oneCurrencyRate .currencyCode {
  width: 35px;
  font-weight: 500;
}
.billStatProjPaymentWrap .divForCurrencyRates .oneCurrencyRate .currencyValue {
  display: flex;
  width: 80px;
  justify-content: flex-end;
  font-weight: 500;
}
/*************
  Blue Theme
 *************/
/* overall */
.tablesorter-blue {
  width: 100%;
  background-color: #fff;
  margin: 10px 0 15px;
  text-align: left;
  border-spacing: 0;
  border: #cdcdcd 1px solid;
  border-width: 1px 0 0 1px;
}
.tablesorter-blue th,
.tablesorter-blue td {
  border: #cdcdcd 1px solid;
  border-width: 0 1px 1px 0;
}
/* header */
.tablesorter-blue th,
.tablesorter-blue thead td {
  font: 12px/18px Arial, Sans-serif;
  font-weight: bold;
  color: #000;
  background-color: #99bfe6;
  border-collapse: collapse;
  padding: 4px;
  text-shadow: 0 1px 0 rgba(204, 204, 204, 0.7);
}
.tablesorter-blue tbody td,
.tablesorter-blue tfoot th,
.tablesorter-blue tfoot td {
  padding: 4px;
  vertical-align: top;
}
.tablesorter-blue .header,
.tablesorter-blue .tablesorter-header {
  /* black (unsorted) double arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  /* white (unsorted) double arrow */
  /* background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==); */
  /* image */
  /* background-image: url(images/black-unsorted.gif); */
  background-repeat: no-repeat;
  background-position: center right;
  padding: 4px 18px 4px 4px;
  white-space: normal;
  cursor: pointer;
}
.tablesorter-blue .headerSortUp,
.tablesorter-blue .tablesorter-headerSortUp,
.tablesorter-blue .tablesorter-headerAsc {
  background-color: #9fbfdf;
  /* black asc arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  /* white asc arrow */
  /* background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7); */
  /* image */
  /* background-image: url(images/black-asc.gif); */
}
.tablesorter-blue .headerSortDown,
.tablesorter-blue .tablesorter-headerSortDown,
.tablesorter-blue .tablesorter-headerDesc {
  background-color: #8cb3d9;
  /* black desc arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  /* white desc arrow */
  /* background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7); */
  /* image */
  /* background-image: url(images/black-desc.gif); */
}
.tablesorter-blue thead .sorter-false {
  background-image: none;
  cursor: default;
  padding: 4px;
}
/* tfoot */
.tablesorter-blue tfoot .tablesorter-headerSortUp,
.tablesorter-blue tfoot .tablesorter-headerSortDown,
.tablesorter-blue tfoot .tablesorter-headerAsc,
.tablesorter-blue tfoot .tablesorter-headerDesc {
  /* remove sort arrows from footer */
  background-image: none;
}
/* tbody */
.tablesorter-blue td {
  color: #3d3d3d;
  background-color: #fff;
  padding: 4px;
  vertical-align: top;
}
/* hovered row colors
 you'll need to add additional lines for
 rows with more than 2 child rows
 */
.tablesorter-blue tbody > tr.hover > td,
.tablesorter-blue tbody > tr:hover > td,
.tablesorter-blue tbody > tr:hover + tr.tablesorter-childRow > td,
.tablesorter-blue tbody > tr:hover + tr.tablesorter-childRow + tr.tablesorter-childRow > td,
.tablesorter-blue tbody > tr.even.hover > td,
.tablesorter-blue tbody > tr.even:hover > td,
.tablesorter-blue tbody > tr.even:hover + tr.tablesorter-childRow > td,
.tablesorter-blue tbody > tr.even:hover + tr.tablesorter-childRow + tr.tablesorter-childRow > td {
  background-color: #d9d9d9;
}
.tablesorter-blue tbody > tr.odd.hover > td,
.tablesorter-blue tbody > tr.odd:hover > td,
.tablesorter-blue tbody > tr.odd:hover + tr.tablesorter-childRow > td,
.tablesorter-blue tbody > tr.odd:hover + tr.tablesorter-childRow + tr.tablesorter-childRow > td {
  background-color: #bfbfbf;
}
/* table processing indicator */
.tablesorter-blue .tablesorter-processing {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  /* background-image: url(images/loading.gif) !important; */
  background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=') !important;
}
/* Zebra Widget - row alternating colors */
.tablesorter-blue tbody tr.odd > td {
  background-color: #ebf2fa;
}
.tablesorter-blue tbody tr.even > td {
  background-color: #fff;
}
/* Column Widget - column sort colors */
.tablesorter-blue td.primary,
.tablesorter-blue tr.odd td.primary {
  background-color: #99b3e6;
}
.tablesorter-blue tr.even td.primary {
  background-color: #c2d1f0;
}
.tablesorter-blue td.secondary,
.tablesorter-blue tr.odd td.secondary {
  background-color: #c2d1f0;
}
.tablesorter-blue tr.even td.secondary {
  background-color: #d6e0f5;
}
.tablesorter-blue td.tertiary,
.tablesorter-blue tr.odd td.tertiary {
  background-color: #d6e0f5;
}
.tablesorter-blue tr.even td.tertiary {
  background-color: #ebf0fa;
}
/* caption */
.tablesorter-blue > caption {
  background-color: #fff;
}
/* filter widget */
.tablesorter-blue .tablesorter-filter-row {
  background-color: #eee;
}
.tablesorter-blue .tablesorter-filter-row td {
  background-color: #eee;
  line-height: normal;
  text-align: center;
  /* center the input */
  -webkit-transition: line-height 0.1s ease;
  -moz-transition: line-height 0.1s ease;
  -o-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}
/* optional disabled input styling */
.tablesorter-blue .tablesorter-filter-row .disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
/* hidden filter row */
.tablesorter-blue .tablesorter-filter-row.hideme td {
  /*** *********************************************** ***/
  /*** change this padding to modify the thickness     ***/
  /*** of the closed filter row (height = padding x 2) ***/
  padding: 2px;
  /*** *********************************************** ***/
  margin: 0;
  line-height: 0;
  cursor: pointer;
}
.tablesorter-blue .tablesorter-filter-row.hideme * {
  height: 1px;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  /* don't use visibility: hidden because it disables tabbing */
  opacity: 0;
  filter: alpha(opacity=0);
}
/* filters */
.tablesorter-blue input.tablesorter-filter,
.tablesorter-blue select.tablesorter-filter {
  width: 98%;
  height: auto;
  margin: 0;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #bbb;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: height 0.1s ease;
  -moz-transition: height 0.1s ease;
  -o-transition: height 0.1s ease;
  transition: height 0.1s ease;
}
/* rows hidden by filtering (needed for child rows) */
.tablesorter .filtered {
  display: none;
}
/* ajax error row */
.tablesorter .tablesorter-errorRow td {
  text-align: center;
  cursor: pointer;
  background-color: #e6bf99;
}
.reducePaymentFromStatsModal {
  padding: 10px;
}
.reducePaymentFromStatsModal .oneParameter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.reducePaymentFromStatsModal .oneParameter .oneParameterName {
  font-weight: 500;
  margin-right: 15px;
  width: 180px;
}
.reducePaymentFromStatsModal .reducePaymentButton {
  margin-left: auto;
  height: 40px;
  line-height: 40px;
  width: 253px;
}
.cards-template .have-no-cards-div {
  margin-top: 20px;
}
.cards-template .have-no-cards-div > div {
  color: grey;
  margin-bottom: 5px;
}
.cards-template .have-no-active-card-div {
  margin-top: 20px;
}
.cards-template .have-no-active-card-div > div {
  color: red;
}
.cards-template .cards-div {
  margin-top: 20px;
}
.cards-template .add-new-card-button {
  margin-top: 5px;
}
.one-card-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 2px 5px;
  border-bottom: 0.1px;
  border-radius: 5px;
}
@media (max-width: 850px) {
  .one-card-item {
    flex-direction: column;
    align-items: normal;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0 5px;
    margin-bottom: 10px;
  }
  .one-card-item > div {
    margin-bottom: 5px;
  }
}
.one-card-item .button_default {
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  font-size: 14px;
}
.one-card-item > div {
  margin-right: 10px;
}
.one-card-item .card-pan-div {
  margin-right: 50px;
}
.one-card-item .card-active-div {
  margin-right: 13px;
}
.one-card-item .active-card-text-div {
  color: #00bc02;
}
.one-card-item .invalid-card-text-div {
  color: darkred;
}
.one-card-item .card-remove-div {
  margin-right: 0;
}
.one-card-item .card-active-div {
  margin-left: auto;
}
@media (max-width: 850px) {
  .one-card-item .card-active-div {
    margin-left: 0;
  }
}
.one-card-item .card-type-icon-div {
  padding-top: 2px;
}
.one-card-item i {
  font-size: 31px;
}
.one-card-item .fa-cc-mastercard {
  color: #EB001B;
}
.one-card-item .fa-cc-visa {
  color: #172274;
}
.one-card-item .fa-credit-card {
  color: #fcb70a;
}
.active-card-color {
  background-color: #effff0;
}
.invalid-card-color {
  background-color: #ffefef;
}
.nextel-settings-main-div .white-sector .next-checkbox-square {
  padding-left: 25px;
}
.nextel-settings-main-div .white-sector {
  padding: 20px;
  padding-top: 10px;
}
.nextel-settings-main-div .white-sector .sector-header-div {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.nextel-settings-main-div .webra-input-field {
  height: 30px;
}
.nextel-settings-main-div .oneSenderTitle,
.nextel-settings-main-div .webra-input-field {
  width: 200px;
  margin-right: 5px;
}
@media (max-width: 1400px) {
  .nextel-settings-main-div .webra-input-field {
    width: 150px;
    margin-right: 5px;
  }
}
.nextel-settings-main-div .general-line-direction-input {
  width: 100px;
}
.nextel-settings-main-div .generalLineProdNumberInput,
.nextel-settings-main-div .generalLineDevNumberInput {
  width: 150px;
}
.nextel-settings-main-div button {
  height: 30px;
  line-height: 30px;
}
.nextel-settings-main-div .checker-general-line-div {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.nextel-settings-main-div .one-general-line-item {
  display: flex;
  margin: 10px 0;
}
.nextel-settings-main-div .new-sms-allowed-direction-input,
.nextel-settings-main-div .new-sms-not-allowed-direction-input,
.nextel-settings-main-div .sms-direction-input {
  width: 100px;
}
.nextel-settings-main-div .sms-allowed-directions,
.nextel-settings-main-div .sms-not-allowed-directions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.nextel-settings-main-div .one-sms-direction {
  margin: 10px 100px 0 0;
}
.nextel-settings-main-div .one-sms-direction .sms-direction-input {
  background-color: lightgrey;
}
.nextel-settings-main-div .countryList {
  display: flex;
  flex-wrap: wrap;
}
.nextel-settings-main-div .oneRegistrationCountry {
  width: 200px;
  padding: 5px;
}
.nextel-settings-main-div .oneRegistrationCountry:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nextel-settings-main-div .countryListHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  border-bottom: solid 1px #dadada;
}
.nextel-settings-main-div .showListButton {
  color: #1e88e5;
}
.nextel-settings-main-div .showListButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nextel-settings-main-div .smallBlockWrapper {
  display: flex;
  justify-content: space-between;
}
.nextel-settings-main-div .smallBlockWrapper .divWithButtons > div button {
  width: 145px;
  margin-bottom: 10px;
}
.nextel-settings-main-div .autodialBadCodesWrapper .autodialBadCodesMainDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.nextel-settings-main-div .autodialBadCodesWrapper .autodialBadCodesSettingsDiv > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.nextel-settings-main-div .autodialBadCodesWrapper .autodialBadCodesSettingsDiv > div > div:first-child {
  width: 415px;
  font-weight: 500;
}
.nextel-settings-main-div .autodialBadCodesWrapper .autodialBadCodesSettingsDiv > div .autodialBadCodesInput {
  width: 530px;
  margin-right: 0;
}
.nextel-settings-main-div .listItem {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}
.nextel-settings-main-div .oneOccupationTypeItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.nextel-settings-main-div .oneOccupationTypeItem .divForOccupationTypeTranslations {
  display: flex;
  align-items: center;
}
.nextel-settings-main-div .oneOccupationTypeItem .divForOccupationTypeTranslations > div {
  width: 205px;
}
.nextel-settings-main-div .oneOccupationTypeItem .occupationId {
  width: 50px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
@media (min-width: 1800px) {
  .nextel-settings-main-div .fullHdOptimizationDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .nextel-settings-main-div .fullHdOptimizationDiv > .white-sector {
    width: 49.2%;
  }
  .nextel-settings-main-div .fullHdOptimizationDiv .fullHdVerticalDiv {
    width: 49.2%;
  }
}
.nextel-settings-main-div .pseudo-button {
  height: 25px;
  margin-top: 5px;
}
.nextel-settings-main-div .uiIntegerPrimitiveField {
  display: flex;
  align-items: center;
}
.nextel-settings-main-div .uiMapField {
  font-weight: 500;
}
.nextel-settings-main-div .uiMapField .mapName {
  border-bottom: 1px solid #e5e5e5;
}
.nextel-settings-main-div .uiMapField .uiMapFieldKeyName {
  width: 315px;
  padding-left: 10px;
}
.nextel-settings-main-div .uiMapField .mapKey,
.nextel-settings-main-div .uiMapField .mapValue {
  width: 300px;
}
.nextel-settings-main-div .uiMapField .itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.nextel-settings-main-div .uiMapField .oneDynamicMapItem {
  display: flex;
}
.one-project-with-telegram-div {
  width: 300px;
  height: 22px;
}
.one-project-with-telegram-div .project-with-telegram-selected {
  background-color: #c5e0fc;
}
.one-project-with-telegram-div .project-name {
  border-radius: 5px;
  cursor: pointer;
  padding: 1px 5px;
}
.one-project-with-telegram-div .project-name:hover {
  background-color: #dfe6ed;
}
.one-project-telegram-user-div {
  width: 300px;
  margin: 2px 0;
}
.one-project-telegram-user-div .telegram-username {
  margin-left: 25px;
}
.nextel-admin-main-div .white-sector {
  padding: 15px;
  margin-bottom: 20px !important;
}
.nextel-admin-main-div .white-sector .sector-header-div {
  padding-bottom: 5px;
}
.nextel-admin-main-div .white-sector .dropdown-single-button .bs-searchbox {
  max-height: 40px;
  padding-top: 2px;
}
.nextel-admin-main-div .white-sector .dropdown-single-button .bs-searchbox input[type="text"] {
  height: 30px;
  line-height: 30px;
}
.nextel-admin-main-div .adminPageInput {
  height: 40px;
  line-height: 40px;
  width: 300px;
}
.nextel-admin-main-div .inputWithDescription {
  display: inline-block;
  position: relative;
}
.nextel-admin-main-div .inputWithDescription span {
  position: absolute;
  color: #1e88e5;
  font-size: 10px;
  top: -13px;
  left: 1px;
}
.nextel-admin-main-div .nextAdminOneChangeNumTask {
  margin-top: 15px;
}
.nextel-admin-main-div .nextAdminOneChangeNumTask .adminPageInput {
  width: 185px;
}
.nextel-admin-main-div .sipClientsTable {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 600px;
  overflow-x: scroll;
}
.nextel-admin-main-div .sipClientsTable .oneAgent {
  display: flex;
  padding: 5px;
  margin-left: 1px;
  margin-top: 1px;
  width: 400px;
  background-color: #efefef;
}
.nextel-admin-main-div .sipClientsTable .oneAgent .count {
  width: 35px;
  font-weight: 500;
}
.nextel-admin-main-div .sipClientsTable .oneAgent .name {
  width: 380px;
  overflow: hidden;
}
.retail-settings-block .retail-contact-custom-fields-div,
.retail-settings-block .retail-deal-custom-fields-div {
  display: flex;
  flex-wrap: wrap;
}
.retail-settings-block .retail-responsible-schedule-one-day-div,
.retail-settings-block .one-retail-deal-status-div,
.retail-settings-block .retail-site-select-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.retail-settings-block .retail-responsible-schedule-one-day-div .retail-responsible-schedule-day-name,
.retail-settings-block .one-retail-deal-status-div .retail-responsible-schedule-day-name,
.retail-settings-block .retail-site-select-div .retail-responsible-schedule-day-name {
  font-weight: 500;
  font-size: 25px;
  width: 50px;
}
.retail-settings-block .retail-responsible-schedule-one-day-div .retail-deal-status-outer-number,
.retail-settings-block .one-retail-deal-status-div .retail-deal-status-outer-number,
.retail-settings-block .retail-site-select-div .retail-deal-status-outer-number {
  width: 300px;
}
.retail-settings-block .retail-responsible-schedule-one-day-div .retail-responsible-select,
.retail-settings-block .one-retail-deal-status-div .retail-responsible-select,
.retail-settings-block .retail-site-select-div .retail-responsible-select,
.retail-settings-block .retail-responsible-schedule-one-day-div .retail-deal-status-select,
.retail-settings-block .one-retail-deal-status-div .retail-deal-status-select,
.retail-settings-block .retail-site-select-div .retail-deal-status-select,
.retail-settings-block .retail-responsible-schedule-one-day-div .retail-site-select,
.retail-settings-block .one-retail-deal-status-div .retail-site-select,
.retail-settings-block .retail-site-select-div .retail-site-select {
  width: 250px;
  height: 50px;
}
#outScenarioGroupsTabList li {
  cursor: pointer !important;
}
.tabs-style-bar .tab-current a {
  background: #308bec !important;
  cursor: default !important;
  margin: 0 !important;
}
.outScenarioGroupsDiv section {
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
}
.outScenarioGroupsDiv .outScOneGroup {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.outScOneGroup {
  position: relative;
  width: 310px;
  min-height: 100px;
  box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e5e6;
  background-color: #fbfcfd;
}
.outScOneGroup .hiddenItem {
  display: none;
}
.outScOneGroup .outScOneGroupHeader {
  position: relative;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: #fff;
  line-height: 20px;
}
.outScOneGroup .outScOneGroupHeader .outScGroupName {
  font-weight: 500;
  color: #7F7F7F;
  font-size: 17px;
  min-width: 150px;
  max-width: 240px;
  border-bottom: 2px solid #ffffff;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  opacity: 0;
  transition: all 200ms;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton > div {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton > div > svg {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton .outScGroupEditButton {
  fill: #1e88e5;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton .outScGroupSaveToGroupsButton {
  fill: #1e88e5;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton .outScGroupSaveButton {
  fill: #00c900;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton .outScGroupRemoveButton {
  fill: red;
}
.outScOneGroup .outScOneGroupHeader .outScGroupActionButton .outScGroupCancelButton {
  fill: #1e88e5;
}
.outScOneGroup .outScGroupRedirectType {
  line-height: 20px;
  margin-bottom: -3px;
}
.outScOneGroup .outScGroupRedirectType .btn-default {
  background-color: unset !important;
}
.outScOneGroup .outScGroupRedirectType .btn-default {
  padding: 0;
}
.outScOneGroup .outScGroupRedirectType .caret {
  display: none;
}
.outScOneGroup .outScGroupRedirectType .btn[disabled] {
  cursor: default;
}
.outScOneGroup .outScGroupRedirectType .btn[disabled]:hover {
  color: #6e6e6e;
}
.outScOneGroup.outScGroupShowSpoiler .outScGroupRedirectType .btn-default {
  color: #fff;
  opacity: 1;
  text-shadow: 0px 0px 5px #9c9c9c;
}
.outScOneGroup.outScGroupEditMode .outScGroupRedirectType .caret {
  display: inline-block;
}
.outScOneGroup.projectGroup .outScOneGroupHeader .outScGroupName {
  color: #1e88e5;
}
.outScOneGroup .outScOneGroupBody {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #e3e5e6;
}
.outScOneGroup .outScGroupOutLineSettings {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
.outScOneGroup.outScGroupEditMode .outScGroupName {
  border-bottom: 2px solid #aeafb1;
}
.outScOneGroup:hover .outScGroupActionButton,
.outScOneGroup.outScGroupEditMode .outScGroupActionButton {
  opacity: 1;
}
.outScOneGroup .outScGroupDivForList {
  display: flex;
  flex-wrap: wrap;
}
.outScOneGroup .outScGroupSpoiler {
  position: absolute;
  top: 27px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #dcdcdc;
  color: #fff;
  text-shadow: 0px 0px 10px #626262;
  font-size: 30px;
  display: none;
  opacity: 1;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}
.outScOneGroup.outScGroupShowSpoiler:hover .outScGroupSpoiler {
  opacity: 0;
}
.outScOneGroup.outScGroupShowSpoiler:hover .outScGroupRedirectType .btn-default {
  color: #6e6e6e;
  text-shadow: none;
}
.outScOneGroup.outScGroupShowSpoiler .outScGroupSpoiler {
  display: flex;
}
.outScOneGroup.outScGroupShowSpoiler .outScItemOverLimit {
  display: none;
}
.outScOneGroup.outScGroupEditMode .outScGroupSpoiler {
  display: none;
}
.outScOneGroup.outScGroupEditMode .outScItemOverLimit {
  display: flex;
}
.outScOneGroup.outScGroupEditMode .outScGroupRedirectType .btn-default {
  color: #6e6e6e;
  text-shadow: none;
}
.outScOneGroup .outCsNextelNumbersImageWrap {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #ffffff;
}
.outScOneGroup .outCsNextelNumbersImageWrap .outCsNextelNumbersImage {
  display: flex;
  height: 100%;
  color: #ff0000;
  font-size: 35px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  background: url(../img/svg/nextel_logo_with_background.svg) no-repeat center center;
}
.outScOneGroup.outCsNextelNumbers .outCsNextelNumbersImageWrap {
  display: block;
}
.outScOneGroup.outCsNextelNumbers .outScGroupRemoveButton {
  z-index: 2;
}
.outScOneGroup .outCsGroupSelector {
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
}
.outScOneGroup .outCsGroupSelector .bootstrap-select {
  width: 100% !important;
}
.outScGroupDivForList > div {
  height: 20px;
}
.outScOneListItem {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 3px;
  line-height: 20px;
  border-bottom: 1px solid #fbfcfd;
}
.outScOneListItem .outScOneListItemName {
  display: flex;
  align-items: center;
  line-height: 19px;
}
.outScOneListItem .outScOneListItemRemoveButton {
  margin-left: 5px;
  min-width: 15px;
  width: 15px;
  height: 100%;
  cursor: pointer;
  display: none;
}
.outScOneListItem .outScOneListItemRemoveButton path {
  fill: #cbc7ca;
}
.outScOneListItem .outScOneListItemRemoveButton path:hover {
  fill: red;
}
.outScOneListItemAddButton {
  width: 15px;
  height: 100%;
  display: none;
}
.outScOneListItemAddButton .outScOneListItemAdd {
  margin-top: 4.5px;
  cursor: pointer;
}
.outScOneListItemAddButton path {
  fill: #00bb00;
}
.outScGroupEditMode .outScOneListItemRemoveButton,
.outScGroupEditMode .outScOneListItemAddButton {
  display: block;
}
.outScGroupEditMode.outScIndexGroup .outScOneListItem,
.outScGroupEditMode.outScGsmGroup .outScOneListItem,
.outScGroupEditMode.outScShfGroup .outScOneListItem {
  border-bottom: 1px solid #aeafb1;
}
.outScSipGroup .outScOneListItem {
  width: 60px;
}
.outScSipGroup .outScOneListItem .outScOneListItemName {
  width: 60px;
}
.outScIndexGroup .outScOneListItem {
  width: 75px;
}
.outScIndexGroup .outScOneListItem .outScOneListItemName {
  width: 75px;
}
.outScGsmGroup .outScOneListItem {
  width: 125px;
}
.outScGsmGroup .outScOneListItem .outScOneListItemName {
  width: 125px;
}
.outScShfGroup .outScOneListItem {
  width: 125px;
}
.outScShfGroup .outScOneListItem .outScOneListItemName {
  width: 125px;
}
.outScOutGroup .outScOneListItem {
  width: 125px;
}
.outScOutGroup .outScOneListItem .outScOneListItemName {
  width: 125px;
}
.outScOneScenario {
  margin-top: 15px;
}
.outScOneScenario .inScenarioActivationPrefixInput,
.outScOneScenario .inScenarioNameInput {
  width: 250px;
  height: 40px;
  padding-left: 14px;
  color: #5d5d5d;
  font-size: 17px;
}
.outScOneScenario .outScBody {
  padding: 20px;
}
.outScOneScenario .outScSettings {
  display: flex;
  margin-bottom: 20px;
}
.outScOneScenario .outScSettings .outScNameDesc {
  position: absolute;
  font-weight: 500;
  top: -5px;
  left: 15px;
  background-color: #fff;
  line-height: 13px;
  color: #5d5d5d;
}
.outScOneScenario .outScSettings .outScSettingCb {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.outScOneScenario .containsAutodialNumbersOnlyWarning {
  color: red;
  font-weight: 500;
  font-size: 12px;
  display: none;
  justify-content: flex-end;
}
.outScOneScenario .outScDivForScRules {
  background-color: #f4f6f8;
  border: 3px dashed #cbd2d9;
  padding: 20px;
}
.outScOneScenario .outScDivForScRules.dragInProgress .outScAddButtonUp {
  display: block !important;
}
.outScOneScenario .outScDivForScRules.dragInProgress .outScAddButtonDown {
  display: none !important;
}
.outScOneScenario .outScDivForScRules.dragInProgress .outScOneScRule:last-child .outScAddButtonDown {
  display: block !important;
}
.outScOneScenario .outScDivForScRules.dragInProgress.dragLast .outScOneScRule:nth-last-child(2) .outScAddButtonDown {
  display: block !important;
}
.outScOneScRule {
  position: relative;
}
.outScOneScRule .outScAddRuleButton {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 15px;
  background: #ffffff;
  width: 95px;
  cursor: pointer;
  border: 1px solid #ecf0f4;
}
.outScOneScRule .outScOneScRuleBody {
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(47, 68, 88, 0.05);
  border-radius: 2px;
  border: 1px solid #ecf0f4;
  border-bottom: none;
  padding: 17px 25px;
  position: relative;
  min-height: 136px;
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 10px;
}
.outScOneScRule .outScOneScRuleBody .outScBigAddButtonWrap {
  margin-left: 30px !important;
}
.outScOneScRule .outScOneScRuleBody .outScRuleRemoveButton {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 18px;
  width: 18px;
  fill: #bebebe;
  cursor: pointer;
}
.outScOneScRule .outScOneScRuleBody .outScRuleRemoveButton:hover {
  fill: red;
}
.outScOneScRule .outScOneScRuleBody .outScRuleResult {
  border-left: 1px solid #d5d5d5;
  padding-left: 30px;
  min-width: 341px;
  display: flex;
}
.outScOneScRule .outScOneScRuleBody .outScResultDescription,
.outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
  position: absolute;
  top: 0px;
  color: #7F7F7F;
  font-weight: 500;
}
.outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
  left: 68px;
}
.outScOneScRule .outScOneScRuleBody .outScResultDescription {
  right: 200px;
}
@keyframes blink {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 15px #c28786;
  }
  100% {
    box-shadow: none;
  }
}
@-webkit-keyframes blink {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 15px #c28786;
  }
  100% {
    box-shadow: none;
  }
}
.outScOneScRule .outScOneScRuleBody.blink {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}
.outScOneScRule .outCsMoveRuleWrap {
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}
.outScOneScRule .outCsMoveRuleWrap .outScDragToggle {
  height: 20px;
  width: 20px;
  cursor: move;
}
.outScOneScRule .outCsMoveRuleWrap .outScDragToggle span {
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background-color: #d6dee5;
}
.outScOneScRule .outCsMoveRuleWrap .outScDragToggle span:first-child {
  margin-top: 2px;
}
.outScOneScRule .outCsMoveRuleWrap .outScDragToggle:hover span {
  background-color: #adb2b9;
}
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleUpButton,
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleDownButton {
  fill: #d6dee5;
  cursor: pointer;
}
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleUpButton:hover,
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleDownButton:hover {
  fill: #adb2b9;
}
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleUpButton.disabled,
.outScOneScRule .outCsMoveRuleWrap .outCsMoveRuleDownButton.disabled {
  fill: #ffffff;
  cursor: default;
}
.outScOneScRule .outScConditionsWrap {
  display: flex;
}
.outScOneScRule .outScConditionsWrap .outScRuleConditions {
  padding-left: 40px;
  display: flex;
}
.outScOneScRule .outScConditionsWrap .outScRuleConditions > div {
  margin-left: 5px;
}
.outScOneScRule .outScConditionsWrap .outScRuleConditions > div:first-child {
  margin-left: 0;
}
.outScOneScRule .outCsArrowDown {
  position: absolute;
  font-weight: 500;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #7F7F7F;
}
.outScOneScRule:last-child .outCsArrowDown {
  display: none;
}
.outScBigAddButtonWrap {
  margin-left: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.outScBigAddButtonWrap .outScBigAddButton {
  height: 50px;
  width: 50px;
  color: #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
  box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e5e6;
  background-color: #fbfcfd;
}
.outScBigAddButtonWrap .outScDivForAddMenu {
  z-index: 2;
  display: none;
  position: absolute;
  box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.08);
  left: 49px;
}
.outScBigAddButtonWrap .outScDivForAddMenu.outScDivForAddMenuShow {
  display: block;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem {
  position: relative;
  border: 1px solid #e3e5e6;
  width: 180px;
  padding: 10px 15px;
  background-color: #fbfcfd;
  line-height: 28px;
  cursor: default;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem .outScAddMenuItemDivForSubItems {
  position: absolute;
  left: 179px;
  display: none;
  transform: translateY(-50%);
  top: 50%;
  max-height: 400px;
  overflow-y: auto;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem .outScAddMenuSubItem {
  border: 1px solid #e3e5e6;
  width: 170px;
  padding: 10px 15px;
  background-color: #fbfcfd;
  line-height: 28px;
  cursor: pointer;
  font-weight: 500;
  color: #7F7F7F;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem .outScAddMenuSubItem:hover {
  background-color: #e3e5e6;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem .outScAddMenuSubItem.outScSaved {
  color: #1e88e5;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem:hover {
  background-color: #e3e5e6;
}
.outScBigAddButtonWrap .outScDivForAddMenu .outScAddMenuItem:hover .outScAddMenuItemDivForSubItems {
  display: block;
}
.outScBigAddButtonWrap .outScDivForAddMenu.outScDivForAddMenuOutMode {
  display: block;
}
.outScBigAddButtonWrap .outScDivForAddMenu.outScDivForAddMenuOutMode .outScAddMenuItem {
  width: 0;
  padding: 0;
}
.outScBigAddButtonWrap .outScDivForAddMenu.outScDivForAddMenuOutMode .outScAddMenuItemDivForSubItems {
  display: block;
}
.outScBigAddButtonWrap .outScDivForAddMenu.outScDivForAddMenuOutMode .outScAddMenuItemDivForSubItems {
  left: 0;
}
.outCsAndRuleWrap {
  display: flex;
  align-items: center;
  position: relative;
}
.outCsAndRuleWrap .outCsAndRule {
  height: 20px;
  width: 20px;
  color: #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.outScAnyConditionWrap {
  display: flex;
  align-items: center;
  position: relative;
}
.outScAnyConditionWrap .outScAnyCondition {
  margin-left: 20px;
  height: 20px;
  color: #7F7F7F;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
@media (max-width: 1630px) {
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 290px;
    padding-left: 15px;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 55px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 140px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 15px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    padding-left: 25px;
  }
  .outScOneGroup {
    width: 295px;
  }
}
@media (max-width: 1550px) {
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 290px;
    padding-left: 15px;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 55px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 140px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 15px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    padding-left: 25px;
  }
  .outScOneGroup {
    width: 260px;
  }
}
@media (max-width: 1420px) {
  .outScOneScRule .outScOneScRuleBody {
    padding-left: 5px;
    padding-right: 5px;
  }
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 250px;
    padding-left: 10px;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 37px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 110px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 8px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    padding-left: 30px;
  }
  .outScOneGroup {
    width: 255px;
  }
  .outScOneScenario .outScBody {
    padding: 5px;
  }
  .outScOneScenario .outScBody .outScDivForScRules {
    padding: 5px;
  }
}
@media (max-width: 1300px) {
  .outScOneScRule .outScOneScRuleBody {
    padding-left: 5px;
    padding-right: 5px;
  }
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 250px;
    padding-left: 10px;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 37px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 110px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 8px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    padding-left: 30px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outCsAndRuleWrap {
    display: none;
  }
  .outScOneGroup {
    width: 230px;
  }
  .outScOneScenario .outScBody {
    padding: 5px;
  }
  .outScOneScenario .outScBody .outScDivForScRules {
    padding: 5px;
  }
}
@media (max-width: 1145px) {
  .outScOneScRule .outScOneScRuleBody {
    padding-left: 5px;
    padding-right: 5px;
  }
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 250px;
    padding-left: 10px;
    display: block;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 37px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 110px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 8px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    flex-wrap: wrap;
    padding-left: 30px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outScBigAddButtonWrap {
    margin-left: 10px !important;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outScOneGroup {
    width: 230px;
    margin-left: 0;
    margin-right: 5px;
    margin-top: 5px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outCsAndRuleWrap {
    display: none;
  }
  .outScOneScenario .outScBody {
    padding: 5px;
  }
  .outScOneScenario .outScBody .outScDivForScRules {
    padding: 5px;
  }
}
@media (max-width: 980px) {
  .outScOneScRule .outScOneScRuleBody {
    padding-left: 5px;
    padding-right: 5px;
  }
  .outScOneScRule .outScOneScRuleBody .outScRuleResult {
    min-width: 250px;
    padding-left: 10px;
    display: block;
  }
  .outScOneScRule .outScOneScRuleBody .outScConditionsDescription {
    left: 37px;
  }
  .outScOneScRule .outScOneScRuleBody .outScResultDescription {
    right: 110px;
  }
  .outScOneScRule .outCsMoveRuleWrap {
    left: 8px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions {
    flex-direction: column;
    padding-left: 30px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outScBigAddButtonWrap {
    margin-left: 85px !important;
    margin-top: 5px !important;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outScOneGroup {
    width: 230px;
    margin-left: 0;
    margin-right: 5px;
    margin-top: 5px;
  }
  .outScOneScRule .outScConditionsWrap .outScRuleConditions .outCsAndRuleWrap {
    display: none;
  }
  .outScOneScenario .outScBody {
    padding: 5px;
  }
  .outScOneScenario .outScBody .outScDivForScRules {
    padding: 5px;
  }
}
.showMoreScenariosButton,
.currentScenariosCountDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 32px;
  margin-bottom: 20px;
  color: #12171d;
  border: #d8d8d8 1px solid;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.showMoreScenariosButton > span,
.currentScenariosCountDiv > span {
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
}
.hidden-drag-ghost {
  display: none;
}
.tts-main-div .tts-settings-and-buttons-div {
  display: flex;
  padding: 20px 25px;
}
@media (max-width: 1230px) {
  .tts-main-div .tts-settings-and-buttons-div {
    flex-direction: column;
  }
}
.tts-main-div .tts-settings-and-buttons-div .tts-settings-div {
  margin-right: 10px;
}
.tts-main-div .tts-settings-and-buttons-div .tts-settings-div .tts-main-settings-div,
.tts-main-div .tts-settings-and-buttons-div .tts-settings-div .tts-advanced-settings-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div > div {
  margin-bottom: 10px;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-instruction-button-div {
  margin-bottom: auto;
  margin-top: 5px;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-settings-profile-id-div {
  width: 257px;
  padding: 0 5px;
  margin-top: 10px;
  display: flex;
  color: darkgrey;
  font-weight: 500;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-settings-profile-id-div .ttsCostSpan {
  overflow-wrap: break-word;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .btn,
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-settings-profile-name-field {
  width: 257px;
  height: 50px;
}
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-fake-button-div,
.tts-main-div .tts-settings-and-buttons-div .tts-buttons-div .tts-real-button-div {
  margin-bottom: 0;
}
.tts-main-div .tts-settings-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tts-main-div .tts-settings-row > div:first-child {
  width: 300px;
}
.tts-main-div .tts-settings-row .tts-slider-div {
  width: 450px;
}
.tts-main-div .tts-settings-row textarea {
  padding: 10px;
}
.tts-main-div .tts-settings-row .dropdown-single-button,
.tts-main-div .tts-settings-row .tts-audio-name-field {
  width: 300px;
  height: 45px;
}
.tts-main-div .tts-settings-row .microsoftSayAsFormatDiv,
.tts-main-div .tts-settings-row .ttsMicrosoftSilenceSettingsTemplate {
  display: flex;
  align-items: center;
}
.tts-main-div .tts-settings-row .microsoftSayAsFormatDiv > div,
.tts-main-div .tts-settings-row .ttsMicrosoftSilenceSettingsTemplate > div {
  margin-right: 10px;
}
.tts-main-div .tts-settings-row .microsoftSayAsFormatDiv {
  margin-left: 10px;
}
.tts-main-div .tts-settings-row .divForMicrosoftSilenceSettings .ttsMicrosoftSilenceSettingsTemplate:not(:last-child) {
  margin-bottom: 5px;
}
.tts-main-div .tts-settings-row .divForLanguagesSupportedByModel {
  display: grid;
  grid-template-columns: 170px 170px 170px;
  grid-auto-flow: row;
}
.tts-main-div .tts-new-audio-template {
  display: flex;
  align-items: center;
  height: 70px;
  margin-bottom: 10px;
}
.tts-main-div .tts-new-audio-template .tts-audio-player {
  width: 90%;
  margin-right: 10px;
}
.tts-main-div .tts-new-audio-template .tts-audio-save-button-div,
.tts-main-div .tts-new-audio-template .tts-audio-fake-save-button-div {
  margin-left: auto;
}
.tts-main-div .ttsTextAreaWrap {
  display: flex;
}
@media (max-width: 1365px) {
  .tts-main-div .ttsTextAreaWrap {
    flex-direction: column;
  }
}
.tts-main-div .ttsTextAreaWrap .tts-cost-div,
.tts-main-div .ttsTextAreaWrap .ttsSymbolsCountDiv {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #a1a1a1;
  font-weight: 500;
}
.tts-main-div .settingsHeaderDiv {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
}
.tts-settings-profiles-main-div .tts-settings-profile-template {
  display: flex;
  align-items: center;
  padding: 15px 30px 15px 25px;
}
.tts-settings-profiles-main-div .tts-settings-profile-template .tts-settings-profile-id-div {
  width: 80px;
  color: darkgrey;
}
.tts-settings-profiles-main-div .tts-settings-profile-template .tts-settings-profile-apply-button-div {
  margin-left: auto;
}
.tts-settings-profiles-main-div .tts-settings-profile-template .tts-settings-profile-remove-button-div {
  margin-left: 30px;
}
.messageServiceMainDiv .sectionDescription {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: red solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.messageServiceMainDiv .sectionDescription .sectionName {
  font-size: 25px;
  color: #000000;
}
.messageServiceMainDiv .sectionDescription .pseudo-button-add {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div {
  display: flex;
  margin-top: 20px;
}
@media (max-width: 1550px) {
  .messageServiceSettingsDiv .message-service-settings-and-buttons-div {
    flex-direction: column;
  }
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div > div {
  display: flex;
  margin-bottom: 20px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div > div > div {
  width: 350px;
  margin-right: 20px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div > div > div > div {
  margin-bottom: 10px;
}
@media (max-width: 800px) {
  .messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div > div {
    flex-direction: column;
  }
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div textarea {
  height: 200px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div textarea,
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-type-select,
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-api-key-input,
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-sms-sender-name-input,
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-viber-sender-name-input {
  width: 300px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-type-select {
  height: 50px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-settings-div .message-service-field-description {
  font-weight: 500;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-bottom: 10px;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div > div {
  margin-left: auto;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div .message-service-bottom-buttons-div {
  display: flex;
  margin-top: auto;
}
.messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div .message-service-bottom-buttons-div > div {
  margin-left: 20px;
}
@media (max-width: 800px) {
  .messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div .message-service-bottom-buttons-div {
    flex-direction: column;
  }
  .messageServiceSettingsDiv .message-service-settings-and-buttons-div .message-service-buttons-div .message-service-bottom-buttons-div > div {
    margin-left: auto;
    margin-bottom: 10px;
  }
}
.message-templates-div .message-service-message-template-template:not(:last-child) {
  margin-bottom: 25px;
}
.message-service-message-template-template {
  display: flex;
}
@media (max-width: 1050px) {
  .message-service-message-template-template {
    flex-direction: column;
  }
  .message-service-message-template-template > div {
    margin-bottom: 10px;
  }
}
.message-service-message-template-template > div {
  margin-right: 15px;
}
.message-service-message-template-template > div:last-child {
  margin-left: auto;
  margin-right: 0;
}
.message-service-message-template-template .message-template-name-input {
  width: 300px;
}
.message-service-message-template-template .message-template-text-area {
  resize: both;
  height: 70px;
  width: 1000px;
}
@media (max-width: 1650px) {
  .message-service-message-template-template .message-template-text-area {
    width: 700px;
  }
}
@media (max-width: 1350px) {
  .message-service-message-template-template .message-template-text-area {
    width: 400px;
  }
}
.message-service-send-message-div {
  display: flex;
  flex-direction: column;
  padding: 2px 11px;
}
.message-service-send-message-div > div {
  margin-bottom: 10px;
}
.message-service-send-message-div textarea {
  width: 665px;
}
.message-service-send-message-div .message-service-top-div {
  margin-bottom: 0;
}
.message-service-send-message-div .message-service-top-div > div {
  height: 50px;
  margin-bottom: 10px;
}
.message-service-send-message-div .message-service-top-div .message-service-message-phone-input {
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  width: 300px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 20px;
}
.message-service-send-message-div .message-service-top-div .message-service-message-template-select {
  width: 300px;
  height: 50px;
}
.message-service-send-message-div .message-service-top-div .message-service-message-type {
  display: flex;
  align-items: center;
}
.message-service-send-message-div .message-service-bottom-div {
  display: flex;
}
.message-service-send-message-div .message-service-bottom-div .message-service-send-button-div {
  margin-left: auto;
}
@media (max-width: 750px) {
  .message-service-send-message-div {
    width: 452px;
  }
  .message-service-send-message-div textarea {
    width: 430px;
  }
}
.messageDistributionsDiv .messageDistributionTemplate:last-child {
  margin-bottom: 30px;
}
.messageDistributionTemplate {
  margin-bottom: 20px;
}
.messageDistributionTemplate textarea {
  resize: vertical;
  padding: 10px;
}
.messageDistributionTemplate .messageDistributionMainDiv {
  padding: 15px 30px;
}
.messageDistributionTemplate .messageDistributionMainDiv > div {
  display: flex;
  margin-bottom: 15px;
}
.messageDistributionTemplate .messageDistributionMainDiv > div .parameterName {
  width: 200px;
  font-weight: 500;
}
.messageDistributionTemplate .messageDistributionMainDiv .messageDistributionButtonsDiv {
  margin-bottom: 5px;
}
.messageDistributionTemplate .messageDistributionMainDiv .messageDistributionButtonsDiv > div:not(:first-child) {
  margin-left: 20px;
}
.messageDistributionTemplate .messageDistributionMainDiv .messageDistributionButtonsDiv > div:first-child {
  margin-left: auto;
}
.messageDistributionTemplate .messageDistributionMainDiv .messageTypeSelect,
.messageDistributionTemplate .messageDistributionMainDiv .nameInput {
  width: 300px;
  height: 45px;
}
.messageDistributionTemplate .messageDistributionMainDiv .fileInputDiv {
  width: 400px;
}
@media (max-width: 1380px) {
  .message-history-main-div .orders-filters-clear-button {
    margin-left: 50px;
  }
}
.message-history-main-div .message-history-filters-error-types .bootstrap-select,
.message-history-main-div .message-history-filters-user .bootstrap-select {
  width: 210px !important;
}
.message-history-main-div .orders-filters-main-types > div {
  padding-left: 25.4px;
  padding-right: 25.4px;
}
.message-history-main-div .message-history-filters-message-text {
  width: 430px;
}
.message-history-main-div .message-history-filters-message-type,
.message-history-main-div .message-history-filters-service-type {
  width: 210px !important;
}
.message-history-main-div .messages-table {
  margin-top: 20px;
}
.message-history-main-div .messages-table .paginationTableHeader {
  position: relative;
}
.message-history-main-div .messages-table .paginationTableHeader:hover .callHistoryUpdateButton,
.message-history-main-div .messages-table .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.message-history-main-div .messages-table-col {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.message-history-main-div .messageStatusIconDiv [messageStatus="SENT"],
.message-history-main-div .messageStatusIconDiv [messageStatus="DELIVERED"] {
  color: #74e362;
}
.message-history-main-div .messageStatusIconDiv [messageStatus="NOT_DELIVERED"],
.message-history-main-div .messageStatusIconDiv [messageStatus="ERROR"] {
  color: #f44336;
}
.message-history-main-div .messageStatusIconDiv [messageStatus="INCOMING"],
.message-history-main-div .messageStatusIconDiv [messageStatus="PROCESSING"] {
  color: cornflowerblue;
}
.message-history-main-div .messages-table-col:nth-child(1) {
  flex-basis: 200px;
  min-width: 200px;
  font-weight: 500;
}
.message-history-main-div .messages-table-col:nth-child(1) div:nth-child(1) {
  width: 30px;
  display: flex;
  justify-content: center;
  margin-left: 15px;
  font-size: 30px;
}
.message-history-main-div .messages-table-col:nth-child(1) div:nth-child(2) {
  margin-left: 20px;
}
.message-history-main-div .messages-table-col:nth-child(2) {
  flex-basis: 500px;
  min-width: 500px;
  overflow-x: hidden;
}
.message-history-main-div .messages-table-col:nth-child(3) {
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  flex-basis: 250px;
  min-width: 250px;
  overflow-x: hidden;
  font-weight: 500;
}
.message-history-main-div .messages-table-col:nth-child(3) .message-source-name-span {
  color: #9400d3;
}
.message-history-main-div .messages-table-col:nth-child(4) {
  flex-basis: 120px;
  min-width: 120px;
  flex-direction: column;
  justify-content: center;
}
.message-history-main-div .messages-table-col:nth-child(4) > div {
  margin-right: auto;
}
.message-history-main-div .messages-table-col:nth-child(4) .message-date-span {
  font-weight: 500;
}
.message-history-main-div .messages-table-col:nth-child(5) {
  flex-basis: 150px;
  min-width: 150px;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  font-weight: 500;
}
.message-history-main-div .messages-table-col:nth-child(5) > div {
  margin-right: auto;
}
.message-history-main-div .messages-table-col:nth-child(5) .message-service-type-span {
  color: #9400d3;
}
.message-history-main-div .messages-table-col:nth-child(6) {
  flex-basis: 300px;
  min-width: 300px;
}
@media (max-width: 1600px) {
  .message-history-main-div .messages-table-col:nth-child(2) {
    flex-basis: 300px;
    min-width: 300px;
  }
  .message-history-main-div .messages-table-col:nth-child(3) {
    flex-basis: 150px;
    min-width: 150px;
  }
}
.message-history-main-div .messages-table-one-message-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.message-history-main-div .messages-table-one-message {
  background-color: #fff;
}
.clusterMainDiv {
  position: relative;
}
.clusterMainDiv .noConnectionWarning {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 10;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
  cursor: pointer;
}
.clusterMainDiv .noConnectionWarning > div {
  color: #ffffff;
  font-size: 36px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
.clusterMainDiv button {
  margin-left: 10px;
  height: 25px;
  line-height: 25px;
  padding: 0 15px;
}
.oneKamailioNode {
  background-color: #fff;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
}
.oneKamailioNode .kamailioHeader {
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.oneKamailioNode .kamailioHeader .kamailioName {
  font-weight: 500;
  font-size: 20px;
  padding-left: 10px;
}
.oneKamailioNode .kamailioSettingsSection .oneParameter {
  width: 150px;
  margin-right: 10px;
  padding-bottom: 10px;
}
.oneKamailioNode .kamailioSettingsSection .oneParameter .paramDesc {
  color: #2a8be1;
  font-size: 12px;
  display: flex;
}
.oneKamailioNode .kamailioSettingsSection .oneParameter .paramBody {
  width: 100%;
  height: 45px;
}
.oneKamailioNode .kamailioSettingsSection .oneParameter .paramBody .webra-input-field {
  height: 100%;
  border: solid 2px #c3c3c3;
}
.oneKamailioNode .nodeColumn0 {
  width: 30px;
}
.oneKamailioNode .nodeColumn1 {
  width: 120px;
}
.oneKamailioNode .nodeColumn2,
.oneKamailioNode .nodeColumn3,
.oneKamailioNode .nodeColumn4,
.oneKamailioNode .nodeColumn5 {
  margin-left: 20px;
  width: 15%;
}
.oneKamailioNode .nodeColumn2:first-child,
.oneKamailioNode .nodeColumn3:first-child,
.oneKamailioNode .nodeColumn4:first-child,
.oneKamailioNode .nodeColumn5:first-child {
  margin-left: 0;
}
.oneKamailioNode .nodeColumn6 {
  width: 200px;
  margin-left: 20px;
}
.oneKamailioNode .nodeColumn7 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
  margin-left: 20px;
}
.oneKamailioNode .controlButtons .controlButton {
  cursor: pointer;
}
.oneKamailioNode .controlButtons .controlButton:hover path {
  fill: red;
}
.oneKamailioNode .clusterNodesListDesc {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  padding-top: 20px;
  border-top: 2px solid #e1e1e1;
}
.oneKamailioNode .clusterNodesListDesc .nodeColumn2,
.oneKamailioNode .clusterNodesListDesc .nodeColumn3,
.oneKamailioNode .clusterNodesListDesc .nodeColumn4,
.oneKamailioNode .clusterNodesListDesc .nodeColumn5 {
  display: flex;
  align-items: center;
}
.oneKamailioNode .clusterNodesListDesc .nodeColumn2 > svg,
.oneKamailioNode .clusterNodesListDesc .nodeColumn3 > svg,
.oneKamailioNode .clusterNodesListDesc .nodeColumn4 > svg,
.oneKamailioNode .clusterNodesListDesc .nodeColumn5 > svg {
  fill: #a0a3a0;
}
.oneKamailioNode .clusterNodesListDesc .nodeColumn2 > svg:hover,
.oneKamailioNode .clusterNodesListDesc .nodeColumn3 > svg:hover,
.oneKamailioNode .clusterNodesListDesc .nodeColumn4 > svg:hover,
.oneKamailioNode .clusterNodesListDesc .nodeColumn5 > svg:hover {
  fill: #00bb00;
  cursor: pointer;
}
.oneKamailioNode .clusterNodesListDesc .nodeColumn2 > svg:last-child,
.oneKamailioNode .clusterNodesListDesc .nodeColumn3 > svg:last-child,
.oneKamailioNode .clusterNodesListDesc .nodeColumn4 > svg:last-child,
.oneKamailioNode .clusterNodesListDesc .nodeColumn5 > svg:last-child {
  margin-left: 5px;
}
.oneKamailioNode .kamailioFooter {
  display: flex;
  justify-content: space-between;
}
.oneKamailioNode .addNewAsteriskForm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.oneKamailioNode .addNewAsteriskForm .webra-input-field {
  height: 100%;
  border: solid 2px #c3c3c3;
  width: 150px;
}
.oneAsteriskNode {
  border-bottom: #7F7F7F;
  padding: 10px;
}
.oneAsteriskNode .nodeBody {
  width: 100%;
  display: flex;
  height: 70px;
}
.oneAsteriskNode .nodeBody .nodeInfo .nodeStatus {
  font-weight: 500;
  font-size: 18px;
  width: 30px;
  line-height: 18px;
}
.oneAsteriskNode .nodeBody .nodeInfo .nodeName {
  font-weight: 500;
  font-size: 14px;
}
.oneAsteriskNode .nodeBody .nodeInfo .nodeIp {
  width: 80px;
  font-size: 10px;
  color: #999c98;
}
.oneAsteriskNode .nodeBody .oneSlider {
  position: relative;
}
.oneAsteriskNode .nodeBody .oneSlider .irs-bar {
  z-index: 1;
}
.oneAsteriskNode .nodeBody .oneSlider .shadow-single {
  background-color: #ff0000;
  height: 5px;
  top: 25px;
}
.oneAsteriskNode .nodeBody .oneSlider .irs-single {
  top: 6px;
}
.oneAsteriskNode .nodeBody .oneSlider .irs-handle {
  display: none;
}
.oneAsteriskNode .nodeBody .oneSlider .irs-line {
  border: none;
  border-radius: 0;
  background: linear-gradient(to bottom, #c4c8ce 0%, #babfca 100%);
}
.oneAsteriskNode .nodeBody .oneSlider .coverLeft,
.oneAsteriskNode .nodeBody .oneSlider .coverRight {
  position: absolute;
  background-color: #fff;
  height: 5px;
  width: 6px;
  top: 25px;
  z-index: 1;
}
.oneAsteriskNode .nodeBody .oneSlider .coverRight {
  right: 0;
}
.oneAsteriskNode .nodeBody .oneSlider .sliderProgressDiv {
  position: absolute;
  display: flex;
  background-color: #babfca;
  align-items: center;
  justify-content: center;
  left: 6px;
  right: 6px;
  top: 30px;
  height: 25px;
  color: #ffffff;
  font-weight: 500;
}
.oneAsteriskNode .nodeBody .oneSlider .sliderProgressDiv .currentLoadSpan {
  z-index: 1;
}
.oneAsteriskNode .nodeBody .oneSlider .sliderProgressDiv .currentLoadBar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 25px;
  width: 0%;
  background-color: #20B426;
}
.oneAsteriskNode .nodeBody .oneSlider .irs-disabled .shadow-single {
  background-color: rgba(209, 214, 224, 0.5);
}
.oneAsteriskNode .nodeBody .nodeSettings .nodeDescription {
  color: #999c98;
  height: 50px;
}
.ivr-history-main-div .ivr-history-filters-action-source .bootstrap-select,
.ivr-history-main-div .ivr-history-filters-action-type .bootstrap-select,
.ivr-history-main-div .ivr-history-filters-pressed-digit .bootstrap-select,
.ivr-history-main-div .ivr-history-filters-ivr .bootstrap-select {
  width: 190px !important;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-show-div,
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-loading-in-progress-div,
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div {
  background-color: #fff;
  padding: 15px 25px;
  margin-top: 20px;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-show-div,
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-loading-in-progress-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-show-div {
  cursor: pointer;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-show-div:hover {
  background-color: #F9F9F9;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div .ivr-statistics-wrong-digits-info-div {
  margin-bottom: 10px;
  color: darkgrey;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div .ivr-statistics-total-count-div {
  font-weight: 500;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div .ivr-statistics-both-stat-divs-div {
  display: flex;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div .ivr-statistics-both-stat-divs-div span {
  font-weight: 500;
}
.ivr-history-main-div .ivr-statistics-main-div .ivr-statistics-div .ivr-statistics-both-stat-divs-div > div {
  width: 400px;
  margin-right: 10px;
}
.ivr-history-main-div .ivr-items-table {
  margin-top: 20px;
}
.ivr-history-main-div .ivr-items-table .paginationTableHeader {
  position: relative;
}
.ivr-history-main-div .ivr-items-table .paginationTableHeader:hover .callHistoryUpdateButton,
.ivr-history-main-div .ivr-items-table .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.ivr-history-main-div .ivr-items-table-col {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(1) {
  flex-basis: 220px;
  min-width: 220px;
  font-weight: 500;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(1) div:nth-child(1) {
  width: 30px;
  display: flex;
  justify-content: center;
  margin-left: 15px;
  font-size: 42px;
  color: darkgrey;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(1) div:nth-child(2) {
  margin-left: 20px;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(2) {
  flex-basis: 300px;
  min-width: 300px;
  overflow-x: hidden;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(3) {
  flex-basis: 130px;
  min-width: 130px;
  flex-direction: column;
  justify-content: center;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(3) > div {
  margin-right: auto;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(3) .ivr-item-date-span {
  font-weight: 500;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(4) {
  flex-basis: 500px;
  min-width: 500px;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  font-weight: 500;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(4) > div {
  margin-right: auto;
}
.ivr-history-main-div .ivr-items-table-col:nth-child(4) .ivr-item-action-name-span {
  color: #9400d3;
}
.ivr-history-main-div .ivr-table-one-ivr-item {
  background-color: #fff;
}
.ivr-history-main-div .ivr-table-one-ivr-item-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.ivr-history-main-div .ivr-table-one-ivr-item-row .ivr-item-one-call-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -1px;
  width: 5px;
  z-index: 1;
}
.trigger-action-header-template .trigger-action-header-error-title {
  color: #ffa4a2;
  margin-left: 20px;
}
.trigger-action-header-template .trigger-actions-div {
  padding: 5px 0 0 0;
}
.trigger-action-template {
  display: flex;
  border-bottom: #d8d8d8 1px solid;
  padding: 10px 15px 15px 20px;
}
@media (max-width: 1500px) {
  .trigger-action-template {
    flex-direction: column;
  }
}
.trigger-action-template .webra-input-field,
.trigger-action-template .dropdown-single-button {
  width: 300px;
  height: 40px;
}
.trigger-action-template .trigger-action-message-to-radio-div,
.trigger-action-template .trigger-action-cb-div {
  height: 40px;
}
.trigger-action-template .trigger-action-message-to-radio-div > div:last-child,
.trigger-action-template .trigger-action-cb-div > div:last-child {
  margin-top: 10px;
}
.trigger-action-template .trigger-action-template-settings-div {
  margin-right: 10px;
}
.trigger-action-template .trigger-action-template-settings-div > div {
  display: flex;
  margin-bottom: 5px;
}
.trigger-action-template .trigger-action-template-settings-div > div > div:first-child {
  margin-top: 10px;
  width: 270px;
  margin-right: 20px;
}
@media (max-width: 1100px) {
  .trigger-action-template .trigger-action-template-settings-div > div:not(.trigger-action-cb-div) {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 10px;
  }
}
.trigger-action-template .trigger-action-template-settings-div .trigger-action-telegram-contact-template {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.trigger-action-template .trigger-action-template-settings-div .trigger-action-telegram-contact-template > div {
  margin-right: 5px;
}
.trigger-action-template .trigger-action-template-settings-div .trigger-action-custom-json-area,
.trigger-action-template .trigger-action-template-settings-div .trigger-action-text-area {
  padding: 10px;
}
.trigger-action-template .trigger-action-buttons-div {
  margin-left: auto;
  margin-top: auto;
  margin-right: 10px;
}
@media (max-width: 1500px) {
  .trigger-action-template .trigger-action-buttons-div {
    display: flex;
  }
  .trigger-action-template .trigger-action-buttons-div > div {
    margin-left: 25px;
    margin-bottom: 0;
  }
}
.trigger-action-template .trigger-action-buttons-div > div:not(:last-child) {
  margin-bottom: 5px;
}
.trigger-action-template .trigger-action-text-area-div {
  display: flex;
}
.trigger-action-template .trigger-action-text-area-div .trigger-action-text-area-dynamic-values-button-div {
  margin-left: 5px;
  margin-top: 5px;
}
.trigger-action-template .executionTimeTypeRadioDiv {
  display: flex;
  align-items: center;
  height: 40px;
}
.trigger-action-template .trigger-action-body-type-radio-buttons-div {
  display: flex;
  align-items: center;
  height: 40px;
}
.trigger-action-template .trigger-action-body-type-radio-buttons-div > label {
  margin-right: 10px;
  width: 180px;
}
.trigger-action-template .triggerActionEmailOrPlaceholderTemplate,
.trigger-action-template .trigger-action-parameters-template {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.trigger-action-template .triggerActionEmailOrPlaceholderTemplate > div,
.trigger-action-template .trigger-action-parameters-template > div {
  margin-right: 5px;
}
@media (max-width: 800px) {
  .trigger-action-template .triggerActionEmailOrPlaceholderTemplate,
  .trigger-action-template .trigger-action-parameters-template {
    flex-direction: column;
    align-items: normal;
  }
  .trigger-action-template .triggerActionEmailOrPlaceholderTemplate > div,
  .trigger-action-template .trigger-action-parameters-template > div {
    margin-bottom: 5px;
  }
}
.triggerActionPlaceholdersPopup .placeholdersPopupShowVariablesDiv {
  margin-left: 10px;
  margin-bottom: 20px;
}
.triggerActionPlaceholdersPopup .divForPlaceholderSections {
  margin: 10px;
}
.triggerActionPlaceholdersPopup .divForPlaceholderSections .placeholderSectionTemplate {
  margin-bottom: 10px;
}
.triggerActionPlaceholdersPopup .divForPlaceholderSections .placeholderSectionTemplate .placeholderSectionTitle {
  color: darkgray;
}
.triggerActionPlaceholdersPopup .placeholdersPopupShowAllPlaceholdersButtonDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.triggerActionParamsPopup {
  margin: 5px 10px 10px 10px;
}
.triggerActionParamsPopup .divForParams {
  width: 100%;
  margin-bottom: 10px;
}
.triggerActionParamsPopup .divForParams .triggerActionPlaceholderParamValueSelectTemplate {
  display: flex;
  align-items: center;
}
.triggerActionParamsPopup .divForParams .triggerActionPlaceholderParamValueSelectTemplate > div:first-child {
  margin-right: 10px;
}
.triggerActionParamsPopup .divForParams .triggerActionPlaceholderParamValueSelectTemplate > div:last-child {
  width: 100%;
}
.triggerActionParamsPopup .buttonsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.triggerActionExecuteActionPopup,
.triggerActionCheckActionPopup {
  margin: 0 15px 15px 15px;
}
.triggerActionExecuteActionPopup .triggerActionSelectDiv,
.triggerActionCheckActionPopup .triggerActionSelectDiv,
.triggerActionExecuteActionPopup .triggerActionPhoneInputDiv,
.triggerActionCheckActionPopup .triggerActionPhoneInputDiv {
  display: flex;
  align-items: center;
}
.triggerActionExecuteActionPopup .triggerActionSelectDiv > div:first-child,
.triggerActionCheckActionPopup .triggerActionSelectDiv > div:first-child,
.triggerActionExecuteActionPopup .triggerActionPhoneInputDiv > div:first-child,
.triggerActionCheckActionPopup .triggerActionPhoneInputDiv > div:first-child {
  margin-right: 10px;
}
.triggerActionExecuteActionPopup input,
.triggerActionCheckActionPopup input {
  width: 200px;
}
.triggerActionExecuteActionPopup .dropdown-single-button,
.triggerActionCheckActionPopup .dropdown-single-button {
  width: 400px;
}
.triggerActionExecuteActionPopup .executeTriggerActionButtonDiv,
.triggerActionCheckActionPopup .executeTriggerActionButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.triggerActionGoToCreationButtonTemplate {
  display: flex;
  justify-content: center;
  margin: 0 15px 15px 15px;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionActionSelect .bootstrap-select,
.scheduledTriggerActionMainDiv .scheduledTriggerActionEventSelect .bootstrap-select,
.scheduledTriggerActionMainDiv .scheduledTriggerActionSourceTypeSelect .bootstrap-select,
.scheduledTriggerActionMainDiv .scheduledTriggerActionSourceSelect .bootstrap-select {
  width: 200px !important;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTable {
  margin-top: 20px;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTable .paginationTableHeader {
  position: relative;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTable .paginationTableHeader:hover .callHistoryUpdateButton,
.scheduledTriggerActionMainDiv .scheduledTriggerActionTable .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(1) {
  margin-left: 25px;
  flex-basis: 250px;
  min-width: 170px;
  overflow-x: hidden;
}
@media (max-width: 1366px) {
  .scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(1) {
    margin-left: 15px;
  }
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(2) {
  flex-basis: 170px;
  min-width: 120px;
  font-weight: 500;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(3),
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(4) {
  flex-direction: column;
  justify-content: center;
  flex-basis: 150px;
  min-width: 80px;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(5) {
  flex-direction: column;
  justify-content: center;
  flex-basis: 250px;
  min-width: 150px;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(6) {
  flex-basis: 500px;
  min-width: 230px;
  overflow-x: hidden;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionTableCol:nth-child(7) {
  margin-left: auto;
  margin-right: 10px;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionExecutionDateSpan,
.scheduledTriggerActionMainDiv .scheduledTriggerActionScheduledAtDateSpan {
  font-weight: 500;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionSourceTypeSpan {
  font-weight: 500;
  color: #9400d3;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionItemTemplate {
  background-color: #fff;
}
.scheduledTriggerActionMainDiv .scheduledTriggerActionItemRow {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.zendeskInnerPhonesSector {
  flex-direction: column;
}
.zendeskInnerPhonesSector .two-column-div-desc {
  padding-left: 20px;
  width: 250px;
}
.zendeskDomainName {
  color: #1e88e5;
  text-decoration: underline;
}
.autodial-history-main-div .autodial-history-filters-autodial-task .bootstrap-select,
.autodial-history-main-div .autodialHistoryFiltersMiaSelect .bootstrap-select,
.autodial-history-main-div .autodialHistoryFiltersMiaEndNoteTypeSelect .bootstrap-select,
.autodial-history-main-div .autodialHistoryFiltersMiaEndReasonSelect .bootstrap-select,
.autodial-history-main-div .autodial-history-filters-call-state .bootstrap-select {
  width: 200px !important;
}
.autodial-history-main-div .autodial-history-items-table {
  margin-top: 20px;
}
.autodial-history-main-div .autodial-history-items-table .paginationTableHeader {
  position: relative;
}
.autodial-history-main-div .autodial-history-items-table .paginationTableHeader:hover .callHistoryUpdateButton,
.autodial-history-main-div .autodial-history-items-table .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.autodial-history-main-div .autodial-history-items-table-col {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(1) {
  margin-left: 35px;
  flex-basis: 170px;
  min-width: 120px;
  font-weight: 500;
}
@media (max-width: 1366px) {
  .autodial-history-main-div .autodial-history-items-table-col:nth-child(1) {
    margin-left: 15px;
  }
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(2) {
  flex-basis: 300px;
  min-width: 110px;
  font-weight: 500;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(2) .autodial-history-item-call-state-span {
  color: #9400d3;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(3) {
  flex-basis: 130px;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(3) > div {
  margin-right: auto;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(3) .autodial-history-item-date-span {
  font-weight: 500;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(4) {
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  flex-basis: 180px;
  min-width: 80px;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(4) > div {
  margin-right: auto;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(4) .autodial-history-item-autodial-operator-number-span {
  font-weight: 500;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(4) .autodial-history-item-autodial-operator-name-span {
  color: #696969;
  font-size: 12px;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(5),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(6),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(7),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(8),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(9),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(10),
.autodial-history-main-div .autodial-history-items-table-col:nth-child(11) {
  flex-basis: 115px;
  min-width: 100px;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(12) {
  width: 100px;
  min-width: 100px;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(12) .autodialHistoryDivForButtons {
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  min-width: 100px;
}
.autodial-history-main-div .autodial-history-items-table-col:nth-child(12) .autodialHistoryDivForButtons .buttonRecord {
  margin: 3px;
}
.autodial-history-main-div .playRecordButton,
.autodial-history-main-div .playShazamButton {
  margin-right: 0;
}
.autodial-history-main-div .autodial-history-table-one-autodial-item {
  background-color: #fff;
}
.autodial-history-main-div .autodial-history-table-one-autodial-item-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.autodial-history-main-div .autodial-history-table-one-autodial-item-row .tableColumnTitle {
  display: inline-block;
  overflow-wrap: break-word;
}
.autodial-history-main-div .additionalInfoDiv {
  padding: 0 20px 10px 45px;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate {
  margin-bottom: 20px;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTitleAndValueTemplate {
  display: flex;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTitleAndValueTemplate div:first-child {
  width: 400px;
  font-weight: 500;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate {
  margin-top: 10px;
  margin-bottom: 10px;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate .triggerActionSourceSpan {
  font-weight: 500;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate .additionalInfoOneTriggerActionResultTemplate {
  display: flex;
  align-items: center;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate .additionalInfoOneTriggerActionResultTemplate .resultIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 3px;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate .additionalInfoOneTriggerActionResultTemplate .resultIconDiv .successIcon {
  font-size: 16px;
  color: #74e362;
}
.autodial-history-main-div .additionalInfoDiv .additionalInfoBlockTemplate .additionalInfoTriggerActionsDataTemplate .additionalInfoOneTriggerActionResultTemplate .resultIconDiv .failedIcon {
  font-size: 18px;
  color: #f44336;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-task-select {
  width: 400px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.autodial-statistics-and-monitoring-main-div .autodial-monitoring-main-div {
  margin-bottom: 30px;
}
.autodial-statistics-and-monitoring-main-div .autodial-monitoring-main-div .autodial-progress-bar-div {
  width: 500px;
  height: 50px;
  border: #d8d8d8 1px solid;
  border-radius: 5px;
  background-color: #B0C4DE;
  margin-bottom: 10px;
}
@media (max-width: 600px) {
  .autodial-statistics-and-monitoring-main-div .autodial-monitoring-main-div .autodial-progress-bar-div {
    width: 390px;
  }
}
.autodial-statistics-and-monitoring-main-div .autodial-monitoring-main-div .autodial-progress-bar-div .autodial-progress-bar {
  height: 48px;
  box-shadow: none;
  text-align: center;
  padding-top: 15px;
}
.autodial-statistics-and-monitoring-main-div .autodial-monitoring-main-div .autodial-progress-bar-div .autodial-progress-bar .autodial-progress-bar-text {
  font-weight: 500;
  color: #FFFFFF;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div {
  margin-bottom: 30px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 900px) {
  .autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div {
    flex-direction: column;
  }
  .autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div .autodial-statistics-refresh-button {
    margin-top: 20px;
  }
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div > div {
  height: 50px;
  border: #d8d8d8 1px solid;
  border-radius: 5px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div > div:first-child {
  margin-right: 25px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-main-div .autodial-statistics-control-div .autodial-statistics-datetimepicker-div {
  width: 390px;
  text-align: center;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-main-statistics-div {
  display: flex;
}
@media (max-width: 1365px) {
  .autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-main-statistics-div {
    flex-direction: column;
  }
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div {
  display: flex;
  align-items: center;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div > div {
  padding: 3px 5px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div > div:first-child {
  flex-basis: 375px;
  min-width: 150px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div > div:nth-child(2) {
  flex-basis: 150px;
  min-width: 130px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div > div:nth-child(n+3) {
  flex-basis: 150px;
  min-width: 110px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div .autodial-statistics-operator-statistics-sip-status-div {
  display: flex;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div > div .autodial-statistics-operator-statistics-sip-status-div > i {
  width: 25px;
  font-size: 20px;
  margin-right: 3px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div .autodial-statistics-operator-statistics-header-div {
  background: #edf1f5;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-template .autodial-statistics-operator-statistics-div .autodial-statistics-operator-statistics-row-template:nth-child(odd) {
  background: #f9fafc;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-history-div {
  display: flex;
  height: 50px;
  margin-top: 30px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-history-div > div:hover {
  filter: brightness(120%);
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-statistics-div {
  display: flex;
}
@media (max-width: 1350px) {
  .autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-statistics-div {
    flex-direction: column;
  }
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-statistics-div #autodial-statistics-autodial-statuses-chart {
  overflow: hidden;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-statistics-div .autodial-statistics-autodial-statuses-duration-div {
  margin-top: 40px;
}
.autodial-statistics-and-monitoring-main-div .autodial-statistics-autodial-statuses-div .autodial-statistics-autodial-statuses-statistics-div .autodial-statistics-autodial-statuses-duration-div > div {
  font-weight: 500;
  font-size: 16px;
}
/*<editor-fold desc="Блок Прогресс, в том числе на странице обзвонов">*/
.autodial-progress-block-rows-div {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-auto-flow: column;
  grid-template-rows: repeat(12, 30px);
}
@media (max-width: 1365px) {
  .autodial-progress-block-rows-div {
    grid-template-rows: repeat(23, 30px);
  }
}
@media (max-width: 650px) {
  .autodial-progress-block-rows-div {
    grid-template-rows: repeat(23, 50px);
  }
}
.autodial-statistics-one-row-template {
  display: flex;
  padding: 5px;
  width: 565px;
  border-radius: 5px;
  margin-right: 90px;
}
@media (max-width: 650px) {
  .autodial-statistics-one-row-template {
    width: 400px;
  }
}
@media (max-width: 1400px) {
  .autodial-statistics-one-row-template {
    margin-right: 70px;
  }
}
.autodial-statistics-one-row-template i {
  color: #f03f3c;
  margin-right: 3px;
  font-size: 15px;
}
.autodial-statistics-one-row-template .autodial-statistics-one-row-main-div {
  width: 490px;
  display: flex;
}
.autodial-statistics-one-row-template .autodial-statistics-one-row-main-div .autodial-statistics-row-count-span {
  margin-left: auto;
}
.autodial-statistics-one-row-template .autodial-statistics-one-row-percent-div {
  margin-left: 15px;
}
.autodial-statistics-one-row-template .proxima-tooltip {
  margin-left: 5px;
}
.autodial-statistics-row-hover:hover {
  background-color: #dfe6ed;
  cursor: pointer;
}
.autodialWithdrawalStatisticsBlockTemplate {
  display: flex;
}
@media (max-width: 1365px) {
  .autodialWithdrawalStatisticsBlockTemplate {
    flex-direction: column;
  }
}
/*</editor-fold>*/
.autodial-numbers-list-main-div .autodial-numbers-list-filters-autodial-task .bootstrap-select,
.autodial-numbers-list-main-div .autodial-numbers-list-filters-call-state .bootstrap-select {
  width: 200px !important;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table {
  margin-top: 20px;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table .paginationTableHeader {
  position: relative;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table .paginationTableHeader:hover .callHistoryUpdateButton,
.autodial-numbers-list-main-div .autodial-numbers-list-items-table .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(1) {
  margin-left: 35px;
  flex-basis: 170px;
  min-width: 120px;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(1) .autodial-numbers-list-item-client-number-span {
  font-weight: 500;
}
@media (max-width: 1366px) {
  .autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(1) {
    margin-left: 15px;
  }
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(2) {
  flex-direction: column;
  justify-content: center;
  flex-basis: 330px;
  min-width: 215px;
  overflow-x: hidden;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(2) > div {
  margin-right: auto;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(2) .autodial-numbers-list-item-call-state-span {
  color: #9400d3;
  font-weight: 500;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(3),
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(4),
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(5) {
  flex-basis: 100px;
  min-width: 90px;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(6) {
  flex-basis: 180px;
  min-width: 120px;
  display: block;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(6) span {
  overflow-wrap: break-word;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(7) {
  flex-basis: 185px;
  min-width: 150px;
  display: block;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(7) span {
  overflow-wrap: break-word;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(8) {
  flex-basis: 185px;
  min-width: 150px;
  display: block;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(8) span {
  overflow-wrap: break-word;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(9) {
  flex-basis: 120px;
  min-width: 120px;
}
.autodial-numbers-list-main-div .autodial-numbers-list-items-table-col:nth-child(10) {
  flex-basis: 100px;
  min-width: 100px;
}
.autodial-numbers-list-main-div .autodialNumberLabelsDiv > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.autodial-numbers-list-main-div .autodialNumberLabelsDiv i {
  color: white;
  font-size: 16px;
}
.autodial-numbers-list-main-div .autodialNumberLabelsDiv .paramsLabelDiv {
  background: #00d2ce;
}
.autodial-numbers-list-main-div .autodialNumberLabelsDiv .musicLabelDiv {
  background: orange;
}
.autodial-numbers-list-main-div .autodial-numbers-list-table-one-autodial-item {
  background-color: #fff;
}
.autodial-numbers-list-main-div .autodial-numbers-list-table-one-autodial-item-row {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.autodial-numbers-list-main-div .autodial-numbers-list-item-autodial-high-priority-div .fa-check {
  color: #74e362;
  font-size: 24px;
}
.autodial-numbers-list-main-div .filterItem {
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate {
  padding: 0 20px 10px 20px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate > div {
  margin-bottom: 10px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .autodialNumberTitleAndField {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 35px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .autodialNumberTitleAndField > div:first-child {
  width: 250px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .autodialNumberTitleAndField input,
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .autodialNumberTitleAndField .dropdown-single-button {
  width: 500px;
  height: 35px;
  margin-right: 5px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .autodialNumberEditDivTopBorder {
  border-top: 1px solid #e4e7ea;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .paramsBlock {
  display: flex;
}
@media (max-width: 1000px) {
  .autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .paramsBlock {
    flex-direction: column;
  }
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .paramsBlock .autodialNumberParamsSaveButton {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 5px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock {
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock {
    flex-direction: column;
  }
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .audiosSaveButton {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 5px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .addAudioButtonsDiv {
  display: flex;
  align-items: center;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .addAudioButtonsDiv > div {
  margin-right: 20px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneAudioTemplate,
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneTtsTemplate {
  margin-top: 15px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneAudioTemplate i,
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneTtsTemplate i {
  font-size: 18px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneAudioTemplate .audioTitleDiv,
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneTtsTemplate .audioTitleDiv {
  display: flex;
  align-items: center;
  width: 750px;
  margin-bottom: 5px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneAudioTemplate .audioTitleDiv .removeAudioButton,
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneTtsTemplate .audioTitleDiv .removeAudioButton {
  margin-left: auto;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberTtsTextDiv {
  display: flex;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberTtsTextDiv > div:first-child {
  width: 250px;
  margin-top: 5px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberTtsTextDiv .ttsTextAreaDiv {
  width: 500px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberTtsTextDiv .ttsTextAreaDiv .ttsTextArea {
  resize: vertical;
  min-height: 35px;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneAudioTemplate i {
  color: orange;
}
.autodial-numbers-list-main-div .autodialNumberEditVariablesDivTemplate .audiosBlock .autodialNumberOneTtsTemplate i {
  color: dodgerblue;
}
.creatioOneUserSelect .two-column-div-desc {
  width: 300px;
}
.creatioResponsibleSelectDiv {
  flex-wrap: wrap;
}
.creatioResponsibleSelectDiv .two-column-div-desc {
  padding-left: 20px;
  width: 150px;
}
.creatioResponsibleSelectDiv .two-column-div-input {
  width: 300px;
}
.remonlineMainDiv .remonlineOneUserSelect .two-column-div-desc {
  width: 300px;
}
.remonlineMainDiv .remonlineResponsibleSelectDiv {
  flex-wrap: wrap;
}
.remonlineMainDiv .remonlineResponsibleSelectDiv .two-column-div-desc {
  padding-left: 20px;
  width: 150px;
}
.remonlineMainDiv .remonlineResponsibleSelectDiv .two-column-div-input {
  width: 300px;
}
.remonlineMainDiv .oneParameterWithTooltip {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.remonlineMainDiv .oneParameterWithTooltip .parameterName {
  font-weight: 500;
  width: 150px;
}
.remonlineMainDiv .oneParameterWithTooltip .dropdown-single-button {
  width: 300px;
  margin-left: 20px;
}
.remonlineMainDiv .subSectionName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 170px;
  margin-top: 30px;
}
.pipeDriveOneUserSelect .two-column-div-desc {
  width: 300px;
}
.pipeDriveResponsibleSelectDiv {
  flex-wrap: wrap;
}
.pipeDriveResponsibleSelectDiv .two-column-div-desc {
  padding-left: 20px;
  width: 150px;
}
.pipeDriveResponsibleSelectDiv .two-column-div-input {
  width: 300px;
}
.pipeDriveDealAndLeadFieldsBlock,
.pipeContactFieldsBlock {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
}
.pipeDriveDealAndLeadFieldsBlock .pipeDriveDealAndLeadFieldsDiv,
.pipeContactFieldsBlock .pipeDriveDealAndLeadFieldsDiv,
.pipeDriveDealAndLeadFieldsBlock .pipeDriveContactFieldsDiv,
.pipeContactFieldsBlock .pipeDriveContactFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.dealsByStatusBlock > div:first-child {
  margin-bottom: 15px;
}
.dealsByStatusBlock .defaultPipelineAndStageDiv,
.dealsByStatusBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.dealsByStatusBlock .defaultPipelineAndStageDiv > div:first-child,
.dealsByStatusBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.dealsByStatusBlock .defaultPipelineAndStageDiv .dropdown-single-button,
.dealsByStatusBlock .oneRowSelectsTemplate .dropdown-single-button {
  width: 340px;
}
.dealsByStatusBlock .defaultPipelineAndStageDiv .pipelineSelect,
.dealsByStatusBlock .oneRowSelectsTemplate .pipelineSelect {
  margin-right: 10px;
}
.dealsByStatusBlock .defaultPipelineAndStageDiv span,
.dealsByStatusBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1087px) {
  .dealsByStatusBlock .defaultPipelineAndStageDiv,
  .dealsByStatusBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
}
.spamOneNumber {
  background-color: #fff;
  -webkit-box-shadow: 0 0 17px 0 #bebebe;
  box-shadow: 0 0 17px 0 #bebebe;
  padding: 20px;
  margin-bottom: 30px;
}
.spamOneNumber .header {
  border-bottom: #e1e1e1 1px solid;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.spamOneNumber .header .phoneNumber {
  font-size: 30px;
  font-weight: 500;
  width: 250px;
  color: #2b2b2b;
}
.spamOneNumber .header .phoneDescription {
  font-size: 22px;
  font-weight: 500;
  color: #7F7F7F;
}
.spamOneNumber .header .spamControlButtonsDiv {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.spamOneNumber .header .spamControlButtonsDiv .next-checkbox-square {
  width: 190px;
  max-height: 20px;
}
.spamOneNumber .header .spamControlButtonsDiv .pseudo-button {
  margin-left: 50px;
  font-weight: 500;
}
.spamOneNumber .divForNumberSettings {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.spamOneNumber .divForNumberSettings .badWordsSettings {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
}
.spamOneNumber .divForNumberSettings .badWordsSettings .badWordsTextArea {
  flex-grow: 1;
  border-color: #a1a1a1;
  width: 200px;
}
.spamOneNumber .divForNumberSettings .otherSettings {
  padding: 19px;
}
.spamOneNumber .divForNumberSettings .otherSettings .autoChangeCostNotifyDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spamOneNumber .divForNumberSettings .divForSpamCheckEmployeeSettings {
  color: #0089ff;
}
.spamOneNumber .divForNumberSettings .divForSpamCheckEmployeeSettings .pseudo-button-save {
  color: #0089ff;
}
.spamOneNumber .divForNumberSettings .developerOptions {
  color: #0089ff;
  width: 300px;
  margin-left: 20px;
}
.spamOneNumber .divForNumberSettings .developerOptions .pseudo-button-save {
  color: #0089ff;
}
.spamOneNumber .divForNumberSettings .developerOptions .emulatedTagsArea {
  width: 300px;
}
.spamOneNumber .divForChecks {
  margin-top: 5px;
}
.spamOneNumber .divForChecks .spamOneCheck .mainInfo {
  height: 40px;
  display: flex;
  align-items: center;
}
.spamOneNumber .divForChecks .spamOneCheck .mainInfo .checkmark {
  top: -9px;
}
.spamOneNumber .divForChecks .spamOneCheck .mainInfo .statusIcon > div,
.spamOneNumber .divForChecks .spamOneCheck .mainInfo .statusIcon svg {
  height: 20px;
  width: 20px;
}
.spamOneNumber .divForChecks .spamOneCheck .mainInfo .checkName {
  width: 120px;
  padding-top: 4px;
}
.spamOneNumber .divForChecks .spamOneCheck .mainInfo .statusDescription {
  margin-left: 5px;
  padding-top: 4px;
  width: 200px;
}
.spamOneNumber .divForChecks .spamOneCheck svg {
  height: 20px;
  width: 20px;
  color: #1eec00;
}
.spamOneNumber .divForChecks .spamOneCheck .lastCheckDate {
  margin-left: 10px;
  width: 160px;
  padding-top: 3px;
}
.spamOneNumber .divForChecks .spamOneCheck .checkFrequencyWrap {
  display: flex;
  align-items: center;
}
.spamOneNumber .divForChecks .spamOneCheck .checkFrequencyWrap .checkFrequencyInput {
  width: 60px;
  border: 1px solid #e3e3e3;
  height: 35px;
  padding: 6px;
  box-shadow: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
}
.spamOneNumber .divForChecks .spamOneCheck .checkNowButtonWrap {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.spamOneNumber .divForChecks .spamOneCheck .checkNowButtonWrap .checkNowButton {
  color: #06b000;
  cursor: pointer;
}
.spamOneNumber .divForChecks .spamOneCheck .checkNowButtonWrap .checkNowButton:hover {
  text-decoration: underline;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsListSpace {
  display: flex;
  align-items: center;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsListWrap {
  position: relative;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsListWrap:hover {
  text-decoration: underline;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsListWrap:hover .tagsList {
  display: flex;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsList {
  -webkit-box-shadow: 0 0 17px 0 #bebebe;
  box-shadow: 0 0 17px 0 #bebebe;
  display: none;
  background-color: #fff;
  position: absolute;
  top: -10px;
  left: -10px;
  border-radius: 2px;
  padding: 10px;
  z-index: 1;
  min-width: 150px;
  min-height: 80px;
  flex-direction: column;
  flex-grow: 1;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsList:hover {
  display: flex;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsList .oneTag {
  white-space: pre;
}
.spamOneNumber .divForChecks .spamOneCheck .tagsButton {
  color: #1e88e5;
}
.spamOneNumber .divForChecks:disabled {
  opacity: 0.5;
}
.addMailingForm {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
  padding: 30px;
}
.addMailingForm .mailingFilterDiv .sector-header-div {
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
  padding-bottom: 20px;
}
.addMailingForm .oneParam {
  font-size: 15px;
  display: flex;
  align-items: center;
}
.addMailingForm .oneParam .paramName {
  width: 150px;
}
.addMailingForm .oneParam .paramValue {
  width: 400px;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
}
.addMailingForm .saveButtonDiv {
  display: flex;
  justify-content: flex-end;
}
.mirandaMailingTable {
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
}
.mirandaMailingTable .mirandaOneMailing {
  background-color: #fff;
}
.mailingFilterSelectors {
  flex-wrap: wrap;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.mailingFilterSelectors .two-column-div {
  font-weight: 500;
  display: flex;
  margin-bottom: 10px;
  height: 52px;
}
.mailingFilterSelectors .two-column-div .divDescription {
  padding-left: 20px;
  width: 200px;
  display: flex;
  align-items: center;
}
.mailingFilterSelectors .two-column-div .filterSelector {
  width: 300px;
  border: 1px solid #c0cbd5;
}
.mailingFilterSelectors .two-column-div .filterSelector .bootstrap-select .dropdown-toggle {
  width: 298px;
}
.mirandaMailingTableRow {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
  font-weight: 500;
}
.mirandaMailingTableHeader {
  background-color: #657689;
  font-weight: 500;
  color: #fff;
}
.mirandaMailingTableRowMainInfo {
  display: flex;
  border-top: 1px solid #e4e7ea;
}
.mirandaMailingTableRowMainInfo .mailingsDescriptions {
  width: 0;
  word-wrap: break-word;
  flex-grow: 1;
}
.mirandaMailingTableCol {
  padding: 15px 8px;
  height: 100%;
}
.mirandaMailingTableCol .mailingCreateDescription {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.mirandaMailingTableCol .mailingCreateDescription .mailingContentTextArea {
  height: 100px;
  width: 100%;
  border-radius: 3px;
  border: 2px #c0cbd5 solid;
}
.mirandaMailingTableCol .mailingDescription {
  display: flex;
  margin-bottom: 25px;
}
.mirandaMailingTableCol .mailingDescription .oneDescription {
  overflow: hidden;
  margin-left: 10px;
  padding-top: 3px;
  width: 100%;
}
.mirandaMailingTableCol .langAndEditButtonDiv {
  display: flex;
}
.mirandaMailingTableCol .langAndEditButtonDiv .pictureDiv {
  width: 225px;
}
.mirandaMailingTableCol .langAndEditButtonDiv .pictureDiv .pictureName {
  font-size: 17px;
}
.mirandaMailingTableCol .langAndEditButtonDiv .pictureDiv .chatImagePreview {
  display: block;
  width: 200px;
  max-height: 500px;
}
.mirandaMailingTableCol .langAndEditButtonDiv .languageName {
  margin-right: 15px;
  font-size: 17px;
  width: 25px;
}
.mirandaMailingTableCol .sentSuccessCount,
.mirandaMailingTableCol .sentErrorCount,
.mirandaMailingTableCol .sentSourceName {
  display: flex;
}
.mirandaMailingTableCol .sentSuccessCount .successCount,
.mirandaMailingTableCol .sentErrorCount .successCount,
.mirandaMailingTableCol .sentSourceName .successCount {
  color: green;
}
.mirandaMailingTableCol .chooseImageFile {
  cursor: pointer;
  line-height: 30px;
  border-radius: 3px;
  font-size: 20px;
  color: #657689;
  border: 2px #c0cbd5 solid;
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
}
.mirandaMailingTableCol .dateInput {
  border-radius: 3px;
  width: 135px;
  border: 2px #c0cbd5 solid;
}
.mirandaMailingTableCol .date {
  border: none;
  border-radius: 3px;
  width: 200px;
  background: none;
}
.textAreaToEdit {
  width: 100%;
  resize: vertical;
  height: 150px;
}
.mailingInvoicesTableFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
}
.mailingTablesCounters {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.divForMailingStatistic {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.divForMailingStatistic .mailingStatisticTable {
  background: #fff;
  border: 2px #c0cbd5 solid;
  border-bottom: none;
  width: fit-content;
}
.divForMailingStatistic .mailingStatisticTable .statisticHeader {
  border-bottom: 2px #c0cbd5 solid;
  text-align: center;
  font-size: 20px;
  color: #657689;
  height: 40px;
}
.divForMailingStatistic .mailingStatisticTable .statisticTableSection {
  border-bottom: 2px #c0cbd5 solid;
  display: flex;
}
.divForMailingStatistic .mailingStatisticTable .statisticTableSection > div {
  width: 175px;
  border-right: 2px #c0cbd5 solid;
  text-align: center;
  color: #657689;
}
.divForMailingStatistic .mailingStatisticTable .statisticTableSection > div:last-child {
  border-right: none;
}
.addPictureForm .mirandaPictureTableHeader,
.mirandaPictures .mirandaPictureTableHeader {
  background-color: #657689;
  font-weight: 500;
  color: #fff;
}
.addPictureForm .mirandaPictureTableRowMainInfo,
.mirandaPictures .mirandaPictureTableRowMainInfo {
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid #e4e7ea;
  align-items: center;
}
.addPictureForm .mirandaOneChatPicture .itemHeader,
.mirandaPictures .mirandaOneChatPicture .itemHeader {
  display: flex;
  align-items: center;
}
.addPictureForm .mirandaOneChatPicture .itemHeader .collapseButton,
.mirandaPictures .mirandaOneChatPicture .itemHeader .collapseButton {
  color: #000;
  margin-left: auto;
  font-weight: 500;
}
.addPictureForm .mirandaOneChatPicture .itemHeader .itemHeaderName,
.mirandaPictures .mirandaOneChatPicture .itemHeader .itemHeaderName {
  font-size: 25px;
  font-weight: 500;
  color: #2b2b2b;
  padding-top: 0;
  padding-left: 20px;
}
.addPictureForm .mirandaOneChatPicture .mirandaPictureTableRowMainInfo,
.mirandaPictures .mirandaOneChatPicture .mirandaPictureTableRowMainInfo {
  margin-bottom: 10px;
  margin-top: 10px;
}
.addPictureForm .mirandaPictureTableCol,
.mirandaPictures .mirandaPictureTableCol {
  height: 100%;
  padding-left: 20px;
}
.addPictureForm .mirandaPictureTableCol .chatImage,
.mirandaPictures .mirandaPictureTableCol .chatImage,
.addPictureForm .mirandaPictureTableCol .chatImagePreview,
.mirandaPictures .mirandaPictureTableCol .chatImagePreview {
  display: block;
  width: 247px;
  max-height: 500px;
}
.addPictureForm .mirandaPictureTableCol .selectedChatImage,
.mirandaPictures .mirandaPictureTableCol .selectedChatImage {
  margin-top: 10px;
}
.addPictureForm .mirandaPictureTableCol .choosePictureFile,
.mirandaPictures .mirandaPictureTableCol .choosePictureFile {
  cursor: pointer;
  width: 247px;
  margin-bottom: 3px;
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
}
.addPictureForm .mirandaPictureTableCol .mirandaPictureSaveButton,
.mirandaPictures .mirandaPictureTableCol .mirandaPictureSaveButton {
  margin-left: 10px;
}
.addPictureForm .pictureInvoicesTableFooter,
.mirandaPictures .pictureInvoicesTableFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e4e7ea;
  cursor: pointer;
}
.addPictureForm .picturesTablesCounters,
.mirandaPictures .picturesTablesCounters {
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 500;
}
.sectionDescription {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: #657689 solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.sectionDescription .sectionName {
  font-size: 25px;
  color: #000000;
}
.addPictureForm {
  box-shadow: 0px 0px 7px -2px #7f7f7f !important;
  background-color: #fff;
}
.oneParam {
  padding-left: 20px;
  font-size: 15px;
  display: flex;
  margin-top: 35px;
  align-items: center;
}
.oneParam .paramName {
  width: 300px;
}
.oneParam .paramValue {
  width: 400px;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
}
.addPictureForm {
  margin-bottom: 20px;
}
.addPictureForm .chatImage {
  position: relative;
}
.addPictureForm .chatImage[alt]:after {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: attr(alt);
}
.addPictureForm .chatImage,
.addPictureForm .choosePictureFile {
  text-align: center;
  line-height: 30px;
  border: 2px #c0cbd5 solid;
  border-radius: 3px;
  font-size: 20px;
  color: #657689;
  font-weight: 500;
}
.outerPhoneDialHistoryTable {
  margin-top: 20px;
}
.outerPhoneDialHistoryTable .tableHeader {
  position: relative;
}
.outerPhoneDialHistoryTable .tableHeader:hover .callHistoryUpdateButton,
.outerPhoneDialHistoryTable .tableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.outerPhoneDialHistoryTable .tableCol {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(1) {
  flex-direction: column;
  justify-content: center;
  flex-basis: 170px;
  min-width: 120px;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(1) .outerPhoneDialItemOuterNumberSpan {
  font-weight: 500;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(1) .outerPhoneDialItemOuterNumberAliasSpan {
  font-size: 12px;
  color: #969696;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(2) {
  flex-basis: 170px;
  min-width: 120px;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(3) {
  flex-direction: column;
  justify-content: center;
  flex-basis: 130px;
  min-width: 100px;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(3) > div {
  margin-right: auto;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(3) .outerPhoneDialItemDialEndDateSpan {
  font-weight: 500;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(4) {
  flex-basis: 450px;
  min-width: 150px;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(5),
.outerPhoneDialHistoryTable .tableCol:nth-child(6) {
  flex-basis: 135px;
  min-width: 100px;
}
.outerPhoneDialHistoryTable .tableCol:nth-child(7) {
  flex-basis: 180px;
  min-width: 100px;
}
.outerPhoneDialHistoryFiltersOuterNumberSelect .bootstrap-select,
.outerPhoneDialHistoryFiltersHangupCodesSelect .bootstrap-select {
  width: 200px !important;
}
.outerPhoneDialStatisticsMainDiv .sectionDescription {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: red solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.outerPhoneDialStatisticsMainDiv .sectionDescription .sectionName {
  font-size: 25px;
  color: #000000;
}
.outerPhoneDialStatisticsMainDiv .sectionDescription .pseudo-button-add,
.outerPhoneDialStatisticsMainDiv .sectionDescription .pseudo-button-refresh {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable {
  margin-top: 10px;
  margin-bottom: 50px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableHeader {
  position: relative;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableHeader:hover .callHistoryUpdateButton,
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(1) {
  margin-left: 20px;
  flex-basis: 150px;
  min-width: 120px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(2) {
  flex-basis: 150px;
  min-width: 100px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(3),
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(4) {
  flex-basis: 105px;
  min-width: 80px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(5),
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(6) {
  flex-basis: 140px;
  min-width: 100px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(7) {
  flex-basis: 600px;
  min-width: 200px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(8) {
  flex-basis: 250px;
  min-width: 180px;
}
.outerPhoneDialStatisticsMainDiv .outerPhoneDialStatisticsTable .tableCol:nth-child(9) {
  flex-basis: 115px;
  min-width: 115px;
  margin-left: auto;
}
.outerPhoneDialStatisticsMainDiv .divForPhoneIndexGroups {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.outerPhoneDialStatisticsMainDiv .divForPhoneIndexGroups .outScOneGroup {
  margin-right: 20px;
  margin-bottom: 20px;
}
.outerPhoneDialStatisticsMainDiv .divForOuterPhoneDialStatisticsProfiles {
  margin-bottom: 50px;
}
.outerPhoneDialStatisticsMainDiv .OuterPhoneDialStatisticsProfileSegmentTemplate {
  display: flex;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e7ea;
}
.outerPhoneDialStatisticsMainDiv .OuterPhoneDialStatisticsProfileSegmentTemplate .OuterPhoneDialStatisticsProfileSegmentNumberSpan {
  color: darkgray;
  font-size: 24px;
}
.outerPhoneDialStatisticsMainDiv .OuterPhoneDialStatisticsProfileSegmentTemplate .OuterPhoneDialStatisticsProfileOuterNumberTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate {
  background-color: #fff;
  -webkit-box-shadow: 0 0 17px 0 #bebebe;
  box-shadow: 0 0 17px 0 #bebebe;
  padding: 20px;
  margin-bottom: 30px;
}
.OuterPhoneDialMonitoringTaskTemplate .header {
  border-bottom: #e1e1e1 1px solid;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.OuterPhoneDialMonitoringTaskTemplate .header .phoneNumber {
  font-size: 30px;
  font-weight: 500;
  width: 250px;
  color: #2b2b2b;
}
.OuterPhoneDialMonitoringTaskTemplate .header .phoneDescription {
  font-size: 22px;
  font-weight: 500;
  color: #7F7F7F;
}
.OuterPhoneDialMonitoringTaskTemplate .header .controlButtonsDiv {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.OuterPhoneDialMonitoringTaskTemplate .header .controlButtonsDiv .pseudo-button {
  margin-left: 50px;
  font-weight: 500;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings {
  margin-top: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings > div {
  padding-bottom: 10px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions {
  margin-top: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions .conditionAndActionsTemplate > div {
  padding-bottom: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions .conditionAndActionsTemplate .conditionAndActionsMainDiv {
  margin-left: 25px;
  margin-bottom: 15px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions .conditionAndActionsTemplate .conditionAndActionsMainDiv > div {
  display: flex;
  margin-bottom: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions .conditionAndActionsTemplate .conditionAndActionsMainDiv .pauseAutodialsSelect .bootstrap-select {
  width: 600px !important;
}
.OuterPhoneDialMonitoringTaskTemplate .divForMonitoringSettings .divForConditionsAndActions .conditionAndActionsTemplate .conditionAndActionsMainDiv .pauseAutodialsSelect .bootstrap-select .btn-default {
  padding: 6px 12px !important;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult {
  margin-top: 15px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsTableItem {
  border-top: 1px solid rgba(225, 225, 225, 0.4);
  padding-top: 5px;
  margin-top: 5px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow {
  display: flex;
  align-items: center;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol {
  display: flex;
  align-items: center;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(1) {
  flex-basis: 150px;
  min-width: 100px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(2) {
  flex-basis: 125px;
  min-width: 100px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(3) {
  flex-basis: 155px;
  min-width: 100px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(4) {
  flex-basis: 225px;
  min-width: 100px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(5) {
  flex-basis: 235px;
  min-width: 100px;
}
.OuterPhoneDialMonitoringTaskTemplate .divForLastMonitoringResult .monitoringStatisticsRow .monitoringStatisticsCol:nth-child(6) {
  margin-left: auto;
  flex-basis: 115px;
  min-width: 115px;
}
.mirandaSourceItem {
  background-color: #fff;
  box-shadow: 0 0 7px -2px #7f7f7f !important;
  padding: 20px;
  margin-bottom: 30px;
}
.mirandaSourceItem .mirandaSourceHeader {
  border-bottom: #e1e1e1 1px solid;
  font-size: 25px;
  font-weight: 500;
  color: #2b2b2b;
  padding: 15px;
  padding-top: 0;
  padding-left: 20px;
}
.mirandaSourceItem .mirandaSourceBody {
  padding-left: 20px;
}
.mirandaSourceItem .mirandaSourceBody .languagesMissedTranslationsTable {
  width: 100%;
  margin-left: 15px;
  border: 1px solid black;
  background-color: #f4f7f9;
}
.mirandaSourceItem .mirandaSourceBody .languagesMissedTranslationsTable .missedTranslationsTableHeader {
  background-color: #ffb732;
  padding: 10px;
  font-weight: 600;
  display: flex;
  font-size: 20px;
  justify-content: center;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px;
}
.mirandaSourceItem .mirandaSourceBody .languagesMissedTranslationsTable .missedTranslationsTableBody .oneEntity {
  align-items: center;
  margin-top: 30px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 500;
}
.mirandaSourceItem .mirandaSourceBody .languagesMissedTranslationsTable .missedTranslationsTableBody .oneEntity .entityName {
  font-weight: 500;
  font-size: 20px;
}
.mirandaSourceItem .oneParam {
  display: flex;
  margin-top: 15px;
  align-items: center;
  height: 50px;
}
.mirandaSourceItem .oneParam .paramName {
  width: 370px;
}
.mirandaSourceItem .oneParam .paramValue {
  width: 400px;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
}
.mirandaSourceItem .oneParam .botLanguageDropdown {
  width: 400px;
  border: 1px solid #c0cbd5;
}
.mirandaSourceItem .oneParam .botLanguageDropdown .bootstrap-select .dropdown-toggle {
  width: 398px;
}
.mirandaSourceItem .mirandaSourceButtonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.addSourceForm {
  margin-bottom: 20px;
}
.addSourceForm .oneParam .paramName {
  width: 150px;
}
.msBody .msRuleDragAndDropDiv {
  display: flex;
  padding: 20px;
  justify-content: center;
}
.msBody .msRulesLeftDiv {
  background-color: #f4f6f8;
  border: 3px dashed #cbd2d9;
  padding: 20px;
  min-height: 450px;
  position: relative;
  flex: 1;
}
.msBody .msRulesLeftDiv .msEmptyDropZoneDescription {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 21px;
  font-weight: 500;
  color: #647688;
}
.msBody .msRulesLeftDiv .dropdown-single-button,
.msBody .msRulesLeftDiv .webra-input-field {
  height: 50px;
}
.msBody .msRulesLeftDiv .webra-input-field {
  padding: 5px;
  padding-left: 20px;
}
.msBody .msRuleRightDivWrap {
  flex-basis: 400px;
  margin-left: 35px;
  padding: 0;
}
.msBody .msRuleRightDivWrap .msRuleRightDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.msBody .msRuleRightDivWrap .msTitle {
  font-size: 21px;
  font-weight: 500;
  line-height: 1;
  text-transform: inherit;
  margin-top: 30px;
  margin-bottom: 30px;
}
.msBody .msRuleRightDivWrap .msTitle:first-child {
  margin-top: 0;
}
.msBody .scenarioItemDrag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 18px 20px;
  border: 1px solid #d0d5da;
  cursor: pointer;
  margin-bottom: 4px;
  position: relative;
}
.msBody .scenarioItemDrag .scenarioItemIco {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.msBody .scenarioItemDrag .scenarioItemText {
  font-weight: 500;
  color: #30323e;
  font-size: 15px;
  margin-left: 17px;
}
.msBody .scenarioItemDrag .msRightIco {
  position: absolute;
  right: 20px;
  top: 13px;
  fill: #C8C8C8;
}
.msBody .scenarioItemDrag .msRightIco:hover {
  fill: #707070;
}
.msBody .gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.msBody .gu-hide {
  display: none !important;
}
.msBody .gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.msBody .gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.msBody .msRuleRightDiv .scenarioItemDrop {
  display: none;
}
.msBody .msRulesLeftDiv .scenarioItemDrag {
  display: none;
}
.msBody .gu-mirror .scenarioItemDrop {
  display: none;
}
.msBody .scenarioItemDropped .scenarioItemDrop {
  display: block;
}
.msBody .scenarioItemDropped .scenarioItemDrag {
  display: none;
}
.msBody .scenarioItemDrop {
  display: block;
  margin-bottom: 50px;
}
.msBody .msDropHeader {
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(47, 68, 88, 0.05);
  border-radius: 2px;
  border: 1px solid #ecf0f4;
  border-bottom: none;
  padding: 17px 25px;
  position: relative;
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.msBody .msDropHeader .scenarioToggle {
  height: 20px;
  width: 20px;
  margin-right: 30px;
  cursor: move;
}
.msBody .msDropHeader .scenarioToggle span {
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background-color: #cbd2d9;
}
.msBody .msDropHeader .scenarioItemHeaderTitle {
  font-size: 21px;
  font-weight: 500;
  line-height: 1;
  text-transform: inherit;
}
.msBody .msDropHeader .scenarioControlButtonsDiv {
  margin-left: auto;
  display: flex;
  font-weight: 500;
}
.msBody .msDropHeader .scenarioControlButtonsDiv .pseudo-button-settings {
  margin-right: 20px;
  padding-right: 25px;
  border-right: 1px solid #d7d7d7;
}
.msBody .msDropSettingsWrap {
  display: none;
  padding: 15px 50px 40px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(47, 68, 88, 0.05);
}
.msBody .msDropSettings {
  display: flex;
  flex-wrap: wrap;
}
.msBody .msDropSettings > div {
  margin-right: 20px;
}
.msBody .msDropSettings .msSection {
  display: flex;
}
.msBody .msDropSettings .msSection .msParameterName {
  font-weight: 500;
}
.msBody .msDropSettings .msSection .msDropTimeoutInput {
  width: 80px;
  padding: 5px;
  padding-left: 20px;
  height: 50px;
}
.msBody .msDropSettings .msSection .msAwaitTimeUnitSelect {
  width: 150px;
  height: 50px;
  margin-left: 10px;
}
.msBody .scenarioItemDropped {
  position: relative;
}
.msBody .scenarioItemDropped::before {
  content: "Если нет ответа";
  position: absolute;
  bottom: -40px;
  left: 0px;
  width: 140px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 15px;
  background: #ffffff;
}
.msBody .scenarioItemDropped:after {
  content: '';
  position: absolute;
  bottom: -33px;
  left: 115px;
  width: 18px;
  height: 14px;
  background: url(../img/svg/arrow-carret-down.svg) no-repeat center right;
  -webkit-background-size: 14px;
  background-size: 14px;
}
.msBody .gu-mirror::before,
.msBody .msRulesLeftDiv .scenarioItemDropped:last-child::before {
  content: "";
}
.msBody .gu-mirror:after,
.msBody .msRulesLeftDiv .scenarioItemDropped:last-child:after {
  content: '';
  background: none;
}
.msBody .scenarioItemDrop .bootstrap-select.btn-group .dropdown-menu {
  padding-bottom: 0px;
  padding-top: 0px;
}
.msBody .msRule {
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 7px -2px #717171 !important;
}
.msBody .msButtons {
  margin-left: auto;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.msBody .msButtons .pseudo-button-remove {
  margin-left: 20px;
  padding-left: 40px;
  border-left: #c3c3c3 solid 1px;
}
.msBody .msRuleName {
  font-weight: 500;
  font-size: 18px;
}
.msBody .msRuleTime {
  color: #aaaaaa;
  font-weight: 400;
}
.msBody .msRuleHeader {
  display: flex;
  cursor: pointer;
}
.msBody .msRuleContent {
  border-top: 1px solid #e4e3e2;
  margin-top: 10px;
}
.msBody .msRuleSettings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
}
.msBody .msRuleSettings .checkmark {
  top: 7px;
}
.msBody .msRuleTimeSelect {
  display: flex;
}
.msBody .msRuleTimeSelect .msInputTime {
  position: relative;
  width: 90px;
  border: 2px solid #e8e8e8 !important;
}
.msBody .msRuleTimeSelect .input-time2 {
  border: 2px solid #e8e8e8 !important;
}
.msBody .msRuleTimeSelect .input-time2:before {
  top: 9px;
  right: 6px;
}
.msBody .msRuleTimeSelect .input-time2 input {
  padding: 3px;
  padding-left: 10px;
}
.msBody .msDaysOfWeek {
  display: flex;
  align-items: center;
  justify-content: center;
}
.msBody .msDaysOfWeek > label {
  width: 135px;
  height: 20px;
  line-height: 35px;
}
.msBody .msDefaultRuleToolTip {
  display: none;
}
.msBody .msDefaultRule {
  position: relative;
}
.msBody .msDefaultRule .msDefaultRuleToolTip {
  display: block;
}
.msBody .msDefaultRule:before {
  content: 'В остальное время - переводить на';
  position: absolute;
  top: -50px;
  left: 0;
  width: 260px;
  padding: 5px 15px;
  font-weight: 500;
  background: #fff;
  font-size: 12px;
  box-shadow: 0px 0px 7px -2px #717171 !important;
}
.msBody .msDefaultRule:after {
  content: '';
  position: absolute;
  top: -35px;
  left: 230px;
  width: 18px;
  height: 14px;
  background: url(../img/svg/arrow-carret-down.svg) no-repeat center right;
  background-size: 14px;
}
.msBody .msDefaultRule .msRuleHeader .pseudo-button-remove {
  display: none;
}
.msBody .msDefaultRule .msRuleSettings {
  display: none;
}
.msBody .msDivForRules {
  padding: 20px;
}
.msBody .msDivForRules .msDivWithAddRuleButton {
  padding-bottom: 10px;
}
.msBody .msNameDesc {
  position: absolute;
  font-weight: 500;
  top: -5px;
  left: 15px;
  background-color: #fff;
  line-height: 13px;
  color: #5d5d5d;
}
.msBody .msItemNotAvailable {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 10;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
  cursor: pointer;
  display: none;
}
.msBody .msItemNotAvailable div {
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
@media (max-width: 1370px) {
  .msBody .msItemNotAvailable div {
    font-size: 20px;
  }
}
.msBody .msItemNotAvailable span {
  margin-left: 10px;
}
.msBody .msNotAvailable .msItemNotAvailable {
  display: block;
}
@media (max-width: 1380px) {
  .msBody .msDropSettingsWrap {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .msBody .msDropHeader {
    padding: 8px 20px;
  }
  .msBody .msRulesLeftDiv {
    padding: 12px;
  }
  .msBody .msVQRedirectSelect {
    width: 300px !important;
  }
  .msBody .scenarioItemDrag {
    padding: 10px 10px;
  }
  .msBody .scenarioItemDrag .msRightIco {
    right: 6px;
    top: 14px;
  }
  .msBody .scenarioItemDrag .msRightIco svg {
    width: 20px;
    height: 20px;
  }
  .msBody .scenarioItemDrag .scenarioItemText {
    margin-left: 8px;
    font-size: 14px;
  }
  .msBody .msRuleDragAndDropDiv {
    padding: 0;
    padding-top: 10px;
  }
  .msBody .msRuleRightDivWrap {
    margin-left: 10px;
    flex-basis: 320px;
  }
}
.mmMainDiv {
  background-color: #fff;
  display: flex;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  min-height: var(--vh);
  max-height: var(--vh);
  --headerHeight: 50px;
  --chatListWidth: 400px;
  --messages-text-size: 16px;
  --line-height: 1.3125;
  --max-message-width: 420px;
  --whisperColor: #ff8100;
  --messageInputAndMessagesWidth: 648px;
}
.mmMainDiv .avatar {
  --peer-avatar-violet-top: #82b1ff;
  --peer-avatar-violet-bottom: #665fff;
  --color-top: var(--peer-avatar-violet-top);
  --color-bottom: var(--peer-avatar-violet-bottom);
  --size: 54px;
  --multiplier: 0.5;
  color: #fff;
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);
  line-height: var(--size) !important;
  background: linear-gradient(var(--color-top), var(--color-bottom));
  text-align: center;
  font-size: 25px;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
}
.mmMainDiv .leftColumn {
  width: var(--chatListWidth);
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader,
.mmMainDiv .leftColumn .chatListWrap .chatListBody {
  width: 100%;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader {
  width: 100%;
  display: flex;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection {
  width: 33.3%;
  height: 100%;
  transition: all 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.closeAllChatsButton {
  --tab-color: #e54500;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.chatSectionTab {
  --tab-color: #1e88e5;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.historySectionTab {
  --tab-color: #00e52a;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.monitoringSectionTab {
  --tab-color: #e5db00;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.settingsSectionTab {
  --tab-color: #7700e5;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.blockListSectionTab {
  --tab-color: #e54500;
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection:hover,
.mmMainDiv .leftColumn .chatListWrap .chatListHeader .headerSection.active {
  color: #fff;
  background-color: var(--tab-color);
}
.mmMainDiv .leftColumn .chatListWrap .chatListHeader {
  height: calc(var(--headerHeight));
  border-bottom: #e9e9e9 1px solid;
}
.mmMainDiv .leftColumn .chatListWrap .chatListBody .chatList {
  overflow: hidden;
  min-height: calc(var(--vh) - var(--headerHeight)*2);
  max-height: calc(var(--vh) - var(--headerHeight)*2);
}
.mmMainDiv .chatItem {
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-top: 5px;
}
.mmMainDiv .chatItem:first-child {
  margin-top: 0;
}
.mmMainDiv .chatItem .chatItemDetails {
  padding-left: 5px;
  padding-right: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.mmMainDiv .chatItem .chatItemDetails .dialogTitle {
  display: flex;
  justify-content: space-between;
}
.mmMainDiv .chatItem .chatItemDetails .dialogTitle .chatName {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mmMainDiv .chatItem .chatItemDetails .dialogTitle .chatTime {
  color: #7F7F7F;
}
.mmMainDiv .chatItem .chatItemDetails .dialogSubtitle {
  width: 100%;
}
.mmMainDiv .chatItem .chatItemDetails .dialogSubtitle .lastMessage {
  display: flex;
  line-height: 27px;
  max-width: calc(var(--chatListWidth) - 100px);
}
.mmMainDiv .chatItem .chatItemDetails .dialogSubtitle .lastMessage .lastMessageName {
  font-weight: 500;
  white-space: nowrap;
}
.mmMainDiv .chatItem .chatItemDetails .dialogSubtitle .lastMessage .lastMessageContent {
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mmMainDiv .chatItem .chatItemDetails .dotsAnimDiv {
  position: absolute;
  bottom: 0;
  right: 22px;
}
.mmMainDiv .chatItem .chatItemDetails .unreadMessagesCircle {
  display: none;
  position: absolute;
  bottom: 2px;
  right: 5px;
}
.mmMainDiv .chatItem .chatItemDetails .unreadMessagesCircle .unreadMessagesCount {
  padding-top: 2px;
  width: 25px;
  height: 25px;
  background-color: #0ac630;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .chatItem.history {
  margin-top: 1px;
}
.mmMainDiv .chatItem.history .chatName {
  max-width: calc(var(--chatListWidth) - 155px);
}
.mmMainDiv .chatItem.history .avatar {
  display: none;
}
.mmMainDiv .chatItem.history .dialogSubtitle {
  display: none;
}
.mmMainDiv .chatItem.opened {
  background-color: #e0f1ff;
}
.mmMainDiv .chatItem.opened:hover {
  background-color: #99c8ff;
}
.mmMainDiv .chatItem.opened.active {
  background-color: #3390ec;
  color: #fff;
}
.mmMainDiv .chatItem.opened.active .chatTime {
  color: #fff !important;
}
.mmMainDiv .chatItem.closed {
  background-color: #b8f7b5;
}
.mmMainDiv .chatItem.closed:hover {
  background-color: #82ed74;
}
.mmMainDiv .chatItem.closed.active {
  background-color: #0cbf09;
  color: #fff;
}
.mmMainDiv .chatItem.closed.active .chatTime {
  color: #fff !important;
}
.mmMainDiv .chatItem.history {
  background-color: #eaeaea;
}
.mmMainDiv .chatItem.history:hover {
  background-color: #d9d9d9;
}
.mmMainDiv .chatItem.history.active {
  background-color: #bfbfbf;
  color: #fff;
}
.mmMainDiv .chatItem.history.active .chatTime {
  color: #fff !important;
}
.mmMainDiv .centerColumn {
  flex-grow: 1;
  background-color: #f0efee;
}
.mmMainDiv .centerColumn .historySection,
.mmMainDiv .centerColumn .chatsSection,
.mmMainDiv .centerColumn .monitoringSection {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mmMainDiv .centerColumn .chatWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mmMainDiv .centerColumn .chatWrap .chatHeader,
.mmMainDiv .centerColumn .chatWrap .chatBody {
  width: 100%;
}
.mmMainDiv .centerColumn .chatWrap .divForChatBodies {
  display: flex;
  flex-direction: column;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap {
  width: 648px;
  margin-left: 381px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields {
  border: #657689 solid 3px;
  background: white;
  margin-top: 15px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .questionForm {
  border-bottom: #657689 solid 3px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .questionForm .greetingMessage {
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .oneCustomField {
  margin: 30px;
  border-bottom: 1px solid #e7e7e7;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .oneCustomField .question {
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .dropdownDiv .answerSelector {
  width: 250px;
  margin-left: 168px;
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .dropdownMultiDiv .multiAnswerSelector {
  width: 222px;
  margin-left: 178px;
  margin-bottom: 20px;
  height: 100%;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .checkboxDiv .checkboxVariantsDiv {
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .checkboxDiv .checkboxVariantsDiv .oneCheckbox {
  margin-right: 20px;
  word-wrap: break-word;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .checkboxDiv .checkboxVariantsDiv .oneCheckbox .next-checkbox-square {
  padding-left: 40px;
  margin-bottom: 10px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .horizontalDiv .horizontalVariantsDiv {
  display: flex;
  justify-content: space-between;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .horizontalDiv .horizontalVariantsDiv .oneHorizontalText {
  cursor: pointer;
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .radioDiv .radioAnswerVariantsDiv {
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .radioDiv .radioAnswerVariantsDiv .oneRadioButton {
  margin-bottom: 15px;
  display: grid;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .textInputDiv,
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .numberInputDiv {
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .textInputDiv .textInput,
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .numberInputDiv .textInput,
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .textInputDiv .numberInput,
.mmMainDiv .centerColumn .chatWrap .surveyWrap .surveyFormAndFields .customFields .numberInputDiv .numberInput {
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
  padding: 5px;
}
.mmMainDiv .centerColumn .chatWrap .chatBody {
  padding-top: 5px;
  flex-grow: 1;
  background-image: url(https://web.telegram.org/k/assets/img/bg.jpeg);
  background-size: cover;
  background-position: center center;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(var(--vh) - var(--headerHeight) + 1px);
  max-height: calc(var(--vh) - var(--headerHeight) + 1px);
  justify-content: flex-end;
  position: relative;
}
.mmMainDiv .centerColumn .chatWrap .chatBody .fileUploadDragZone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.mmMainDiv .centerColumn .chatWrap .chatBody .fileUploadDragZone .anyTypeFileDropZone {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 300px;
  height: 300px;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: #08d500 5px dashed;
  font-weight: 500;
}
.mmMainDiv .centerColumn .chatWrap .chatBody .fileUploadDragZone .anyTypeFileDropZone .fewFilesDropZoneText {
  display: none;
}
.mmMainDiv .centerColumn .chatWrap .chatBody .fileUploadDragZone .anyTypeFileDropZone .defaultDropZoneText {
  color: #08d500;
}
.mmMainDiv .centerColumn .chatWrap.showNames .bubble:not(.is-out) .bubble-content .name {
  display: block !important;
}
.mmMainDiv .centerColumn .chatWrap.dropZoneActive .fileUploadDragZone {
  z-index: 3;
}
.mmMainDiv .centerColumn .chatWrap.dropZoneFewFilesWarning .fileUploadDragZone .anyTypeFileDropZone {
  border: none;
}
.mmMainDiv .centerColumn .chatWrap.dropZoneFewFilesWarning .fileUploadDragZone .defaultDropZoneText {
  display: none;
}
.mmMainDiv .centerColumn .chatWrap.dropZoneFewFilesWarning .fileUploadDragZone .fewFilesDropZoneText {
  display: block !important;
  color: #939291;
  width: 200px;
}
.mmMainDiv .centerColumn .settingsSection {
  display: flex;
  background-color: #fff;
  height: 100%;
  border-left: #e9e9e9 1px solid;
}
.mmMainDiv .centerColumn .settingsSection .subsectionHeader {
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: #e9e9e9 1px solid;
}
.mmMainDiv .centerColumn .settingsSection .subsectionBody {
  height: calc(100% - 50px);
  overflow-y: visible;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.mmMainDiv .centerColumn .settingsSection .subsectionBody .sectionDescription {
  font-size: 15px;
  font-weight: 500;
}
.mmMainDiv .centerColumn .settingsSection .quickActionsSubSection {
  width: 600px;
  height: 100%;
  border-right: #e9e9e9 1px solid;
}
.mmMainDiv .centerColumn .settingsSection .mainSettingsSubSection {
  flex-grow: 1;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction {
  display: flex;
  margin-top: 3px;
  margin-bottom: 20px;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction .actionKey {
  width: 60px;
  height: 25px;
  padding-top: 2px;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction .actionPhrase {
  width: 265px;
  max-width: 265px;
  height: 28px;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction .replaceActionButtons {
  display: flex;
  margin-top: 4px;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction .replaceActionButtons .pseudo-button {
  height: 20px;
  width: 0;
}
.mmMainDiv .centerColumn .settingsSection .textReplaceAction .replaceActionButtons .pseudo-button span {
  display: inline-block;
  vertical-align: top;
}
.mmMainDiv .centerColumn .settingsSection .autoReplaceType {
  display: flex;
  flex-direction: column;
}
.mmMainDiv .chatHeader {
  height: var(--headerHeight);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mmMainDiv .chatHeader .chatInfo {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.mmMainDiv .chatHeader .chatInfo .avatar {
  --size: 45px;
  min-width: var(--size);
  min-height: var(--size);
  max-width: var(--size);
  max-height: var(--size);
  line-height: var(--size) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .projectKamailioName {
  font-weight: 500;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .activeChatName {
  font-weight: 500;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .activeChatName.canEnter:hover {
  text-decoration: underline;
  color: #1e88e5;
  cursor: pointer;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .activeUserName {
  font-weight: 500;
  color: #1e88e5;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .activeChatParticipants {
  color: #707579;
  cursor: pointer;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .activeChatParticipants:hover {
  text-decoration: underline;
  color: #1e88e5;
}
.mmMainDiv .chatHeader .chatInfo .chatInfoDetails .archiveNotification {
  display: none;
}
.mmMainDiv .chatHeader .headerLeftPart {
  display: flex;
}
.mmMainDiv .chatHeader .headerLeftPart .divForTyping {
  display: flex;
  align-items: flex-end;
}
.mmMainDiv .chatHeader .headerLeftPart .divForTyping .userTyping {
  display: flex;
  margin-left: 20px;
  height: 25px;
}
.mmMainDiv .chatHeader .headerLeftPart .divForTyping .authorName {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .chatHeader .chatSettings {
  padding-right: 10px;
}
.mmMainDiv .chatHeader .banUserButton,
.mmMainDiv .chatHeader .closeChatButton {
  --headerButtonColor: red;
}
.mmMainDiv .chatHeader .leaveChatButton {
  --headerButtonColor: #0084ff;
}
.mmMainDiv .chatHeader .takeChatButton {
  --headerButtonColor: #08cc00;
}
.mmMainDiv .chatHeader .headerActionButton {
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  transition: all 150ms;
  color: var(--headerButtonColor);
  border: 2px solid var(--headerButtonColor);
  margin-left: 4px;
}
.mmMainDiv .chatHeader .headerActionButton:hover {
  background-color: var(--headerButtonColor);
  color: #fff;
}
.mmMainDiv .chatHeader,
.mmMainDiv .chatList {
  --dotsColor: #3390ec;
}
.mmMainDiv .dotsAnimDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -5%;
  overflow: hidden;
  width: 30px;
  height: 25px;
}
.mmMainDiv .dot-typing {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--dotsColor);
  color: var(--dotsColor);
  box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  animation: dotTyping 1s infinite linear;
}
@keyframes dotTyping {
  0% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
  16.667% {
    box-shadow: 9989px -8px 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
  33.333% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
  50% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px -8px 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
  66.667% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
  83.333% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px -8px 0 0 var(--dotsColor);
  }
  100% {
    box-shadow: 9989px 0 0 0 var(--dotsColor), 9999px 0 0 0 var(--dotsColor), 10009px 0 0 0 var(--dotsColor);
  }
}
.mmMainDiv .messagesWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}
.mmMainDiv .messagesWrap .bubbleCenterer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble {
  width: var(--messageInputAndMessagesWidth);
  --message-background-color: #fff;
  position: relative;
  z-index: 1;
  margin: 0 auto 3px;
  display: flex;
  flex-wrap: wrap;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .time {
  visibility: hidden;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1;
  vertical-align: middle;
  pointer-events: none;
  display: inline-flex;
  z-index: 1;
  height: 12px;
  direction: ltr;
  float: right;
  cursor: pointer;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .time .visibleTime {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: inherit;
  white-space: nowrap;
  height: 12px;
  visibility: visible;
  user-select: none;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.is-out {
  flex-direction: row-reverse;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.is-out .time {
  padding-right: 5px;
  margin-left: -4px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.is-out .time .visibleTime {
  color: #4fae4e;
  bottom: 4px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.operator-message {
  --message-background-color: #eeffde;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.is-in .time {
  margin-left: -3px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble.is-in .time .visibleTime {
  color: #707579;
  margin-bottom: 4px;
  padding-right: 8px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper {
  transform-origin: center;
  opacity: 1;
  display: flex;
  flex-direction: column;
  max-width: 85%;
  min-width: 80px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper .bubble-content {
  background-color: var(--message-background-color);
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper .bubble-content .name {
  display: none;
  padding: 5px 9px 0 6px;
  font-weight: 500 !important;
  color: #3390ec;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper .bubble-content .message {
  padding: 6px;
  word-break: break-word;
  position: relative;
  white-space: pre-wrap;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper .bubble-content .attachments {
  user-select: none;
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  max-height: none;
  max-width: var(--max-message-width);
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubble-content-wrapper .bubble-content .attachments .timeSpaceSpan {
  display: none;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubbleHistoryWrapper {
  background-color: #bfbfbf;
  width: 100%;
  display: flex;
  color: #ffffff;
  padding: 5px;
  cursor: pointer;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubbleHistoryWrapper .historyChatDate {
  width: 125px;
  min-width: 125px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubbleHistoryWrapper .historyChatName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  min-width: 200px;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubbleHistoryWrapper .historyChatFirstMessage {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mmMainDiv .messagesWrap .bubbleCenterer .bubble .bubbleHistoryWrapper:hover {
  background-color: #afafaf;
}
.mmMainDiv .messagesWrap .bubbleCenterer.photoOnly .visibleTime {
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff !important;
  margin-right: 10px;
  padding: 5px !important;
  border-radius: 3px;
}
.mmMainDiv .messagesWrap .bubbleCenterer.whisper .message {
  color: var(--whisperColor);
  font-style: italic;
}
.mmMainDiv .messagesWrap .bubbleCenterer.is-system .bubble-content-wrapper {
  min-width: 100%;
  max-width: 100%;
}
.mmMainDiv .messagesWrap .bubbleCenterer.is-system .bubble-content-wrapper .bubble-content {
  display: flex;
  justify-content: center;
  background: none;
}
.mmMainDiv .messagesWrap .bubbleCenterer.is-system .bubble-content-wrapper .bubble-content .message {
  display: inline-block;
  background-color: hsla(85.5319, 36.9171%, 40.402%, 0.5);
  font-weight: 500;
  color: #ffffff;
}
.mmMainDiv .messagesWrap .bubbleCenterer.is-system .bubble-content-wrapper .bubble-content .message .visibleTime {
  font-weight: 400;
  color: #f1f9ff;
}
.mmMainDiv .messagesWrap .album-item {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  max-width: 100%;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
}
.mmMainDiv .messagesWrap .album-item .album-item-media {
  width: 100%;
  height: 100%;
}
.mmMainDiv .messagesWrap .album-item .album-item-media .media-photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.mmMainDiv .messagesWrap .fileItem {
  display: flex;
  padding: 5px;
}
.mmMainDiv .messagesWrap .fileItem .fileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00AEEF;
  width: 50px;
  height: 50px;
}
.mmMainDiv .messagesWrap .fileItem .fileIcon svg {
  width: 25px;
  height: 25px;
}
.mmMainDiv .messagesWrap .fileItem .fileInfo {
  padding: 5px;
}
.mmMainDiv .messagesWrap .fileItem .fileInfo .fileName {
  font-weight: 500;
}
.mmMainDiv .messagesWrap .fileItem .fileInfo .fileSize {
  font-size: 12px;
  font-weight: 500;
  color: #7F7F7F;
}
.mmMainDiv .messagesWrap .audioItemPreview path {
  fill: #00AEEF;
}
.mmMainDiv .messagesWrap .audioItemPreview .metadata {
  display: flex;
}
.mmMainDiv .messagesWrap .audioItemPreview .metadata .fileDownloadLink,
.mmMainDiv .messagesWrap .audioItemPreview .metadata .showPlayerButton {
  color: #00AEEF;
}
.mmMainDiv .messagesWrap .audioItemPreview .metadata .fileDownloadLink:hover,
.mmMainDiv .messagesWrap .audioItemPreview .metadata .showPlayerButton:hover {
  text-decoration: underline;
}
.mmMainDiv .messagesWrap .audioItemPreview .metadata .showPlayerButton {
  margin-left: 20px;
}
.mmMainDiv .messagesWrap .audioItemPreview .audioItemHeader {
  display: flex;
  padding: 5px;
}
.mmMainDiv .messagesWrap .audioItemPreview .audioItemHeader .fileName {
  font-weight: 500;
}
.mmMainDiv .messagesWrap .audioItemPreview .audioItemHeader .fileSize {
  color: #7F7F7F;
  font-weight: 500;
  width: 70px;
}
.mmMainDiv .messagesWrap .audioItemPreview .audioDownloadButton {
  width: 20px;
  height: 20px;
}
.mmMainDiv .messageInputWrap {
  width: var(--messageInputAndMessagesWidth);
  padding-top: 5px;
  padding-bottom: 10px;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper {
  transition: opacity 0.1s 0s;
  align-items: flex-end;
  width: 100%;
  position: relative;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer {
  flex: 1 1 auto;
  display: flex;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .chatActionButton {
  position: absolute;
  bottom: 0;
  height: 36px;
  font-weight: 500;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .whisperButton {
  width: 70px;
  left: -70px;
  color: #c2c1c0;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .whisperButton:hover {
  border: var(--whisperColor) 2px solid;
  color: var(--whisperColor);
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .whisperButton.active {
  background-color: var(--whisperColor);
  color: #fff;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .sendMessageButton {
  width: 37px;
  right: -37px;
  background-color: #1b76c7;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .sendMessageButton:hover {
  background-color: #1e98ff;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .attachmentButton {
  width: 37px;
  right: -74px;
  background-color: #00c224;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .attachmentButton:hover {
  background-color: #00e52a;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .inputMessageInput {
  transition-duration: 181ms;
  transition: height 1s;
  padding: 8px;
  width: 100%;
  position: relative;
  margin-top: -1px;
  resize: none;
  border: none;
  outline: none;
  line-height: var(--line-height);
  font-size: var(--messages-text-size);
  white-space: pre-wrap;
  max-height: 150px;
  overflow-y: auto;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .inputMessageInput:empty:before {
  content: "Сообщение";
  color: #a2acb4;
  display: block;
  pointer-events: none;
}
.mmMainDiv .messageInputWrap .anotherMessageInputWrap .newMessageWrapper .inputMessageContainer .inputMessageInput.whisperMode {
  color: var(--whisperColor);
}
.mmMainDiv .messageInputWrap .chatClosedInfo {
  height: 37px;
  display: none;
  justify-content: center;
  align-items: center;
}
.mmMainDiv .messageInputWrap .chatClosedInfo .chatInfoDescription {
  color: #fffefd;
  font-weight: 500;
  margin-right: 20px;
}
.mmMainDiv .messageInputWrap .chatClosedInfo .chatCloseButton {
  font-weight: 500;
  border: 2px solid #27d007;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 500ms;
  color: #fff;
  background-color: #0fbd00;
  width: 200px;
  display: flex;
  justify-content: center;
}
.mmMainDiv .messageInputWrap .chatClosedInfo .chatCloseButton:hover {
  background-color: #11d000;
}
.mmMainDiv .sendFilePopup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: 0;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.3);
  transition-property: opacity, visibility;
  transition-duration: 0.15s, 0s;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .sendFilePopup.active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
  z-index: 4;
}
.mmMainDiv .sendFilePopup .popupContainer {
  background-color: #fff;
  padding: 20px;
  min-width: 300px;
  max-width: 500px;
}
.mmMainDiv .sendFilePopup .popupContainer .fileItemPreview {
  display: flex;
  align-items: center;
  position: relative;
}
.mmMainDiv .sendFilePopup .popupContainer .fileItemPreview .documentIcon {
  text-align: center;
  border-radius: 0.375rem;
  background-color: #3390ec;
  background-size: contain;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  left: 0;
  color: #fff;
  height: 48px;
  width: 48px;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0;
  padding: 1.5625rem 0.25rem 0 0.25rem;
}
.mmMainDiv .sendFilePopup .popupContainer .fileItemPreview .documentIcon:after {
  --size: 1.125rem;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  border-bottom-left-radius: 0.25rem;
  border-style: solid;
  border-width: calc(var(--size) / 2);
  border-left-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-top-color: #fff;
  border-right-color: #fff;
}
.mmMainDiv .sendFilePopup .popupContainer .fileItemPreview .fileName {
  font-weight: 500;
}
.mmMainDiv .sendFilePopup .popupContainer .fileItemPreview .fileSize {
  font-weight: 500;
  color: #7F7F7F;
}
.mmMainDiv .sendFilePopup .divForFilePreview {
  margin-top: 10px;
}
.mmMainDiv .sendFilePopup .fileDescriptionInputWrap {
  margin-top: 10px;
}
.mmMainDiv .sendFilePopup .fileDescriptionInput {
  width: 100%;
  box-shadow: inset 0.5px 0.866px 5px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  height: 50px;
  font-size: 16px;
  color: #535353;
  padding: 6px 12px;
  overflow-y: scroll;
}
.mmMainDiv .sendFilePopup .popupSendMessageButton {
  margin-top: 20px;
  width: 100%;
}
.mmMainDiv .contextMenu {
  position: fixed;
  z-index: 4;
  bottom: 100px;
}
.mmMainDiv .contextMenu .menu {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
}
.mmMainDiv .contextMenu .menu .menuItem {
  cursor: pointer;
  padding: 10px 30px 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
}
.mmMainDiv .contextMenu .menu .menuItem:hover {
  background: #e9ebef;
  color: #4b00ff;
}
.mmMainDiv .contextMenu .menu .menuItem > i {
  padding-right: 10px;
  width: 27px;
}
.mmMainDiv .contextMenu .menu .menuItem.trash {
  color: red;
}
.mmMainDiv .reply {
  --replyColor: #4fae4e;
}
.mmMainDiv .edit {
  --replyColor: #4b00ff;
}
.mmMainDiv .reply,
.mmMainDiv .edit {
  padding: 4px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  max-width: var(--max-message-width);
}
.mmMainDiv .reply .replyBorder,
.mmMainDiv .edit .replyBorder {
  width: 3px;
  background-color: var(--replyColor);
  position: absolute;
  top: 2px;
  bottom: 2px;
}
.mmMainDiv .reply .replyImage,
.mmMainDiv .edit .replyImage {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.mmMainDiv .reply .replyImage img,
.mmMainDiv .edit .replyImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mmMainDiv .reply .replyContent,
.mmMainDiv .edit .replyContent {
  position: relative;
  display: flex;
  max-width: 100%;
}
.mmMainDiv .reply .replyContent .replyDescription,
.mmMainDiv .edit .replyContent .replyDescription {
  padding-left: 10px;
}
.mmMainDiv .reply .replyContent .replyDescription .replyAuthor,
.mmMainDiv .edit .replyContent .replyDescription .replyAuthor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: var(--replyColor);
}
.mmMainDiv .reply .replyContent .replyDescription .replyText,
.mmMainDiv .edit .replyContent .replyDescription .replyText {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(var(--max-message-width) - 45px);
}
.mmMainDiv .reply.whisper .replyText,
.mmMainDiv .edit.whisper .replyText {
  color: var(--whisperColor);
  font-style: italic;
}
.mmMainDiv .newMessageReplyWrap {
  display: flex;
  align-items: center;
}
.mmMainDiv .newMessageReplyWrap svg {
  margin-left: 10px;
  margin-right: 5px;
  padding: 6px;
  cursor: pointer;
  border-radius: 100%;
  transition: 0.2s color, background-color, opacity 0.2s, 0.2s;
}
.mmMainDiv .newMessageReplyWrap svg:hover {
  background-color: #dfdfdf;
}
.mmMainDiv .imagePreviewDiv {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1022;
  background-color: rgba(0, 0, 0, 0.7);
  transition-property: opacity, visibility;
  transition-duration: 0.15s, 0s;
  display: none;
}
.mmMainDiv .imagePreviewDiv.active {
  display: flex;
}
.mmMainDiv .imagePreviewDiv .divForFullScreenImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
}
.mmMainDiv .imagePreviewDiv .divForFullScreenImage img {
  max-width: 100%;
  max-height: 100%;
}
.mmMainDiv .chatUsersShadowDiv {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: opacity, visibility;
  transition-duration: 0.15s, 0s;
  z-index: 5;
}
.mmMainDiv .chatUsersShadowDiv.active {
  display: flex;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer {
  background-color: #fff;
  overflow-y: auto;
  max-height: 90%;
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  width: fit-content;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .userSelectorColumn {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-right: 1px solid #e2e2e2;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .userSelectorColumn > div:last-child {
  padding: 0 5px 5px 5px;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .userTypeDescription {
  padding: 10px;
  padding-left: 20px;
  background-color: #e5e5e5;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails {
  display: flex;
  margin-top: 3px;
  transition: 0.3s;
  position: relative;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .avatar {
  --size: 40px;
  font-size: 25px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails.isGroup .avatar {
  background: linear-gradient(#ff89a6, #ff3e41);
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .name {
  font-weight: 500;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .details {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton {
  transition-property: opacity, visibility;
  transition-duration: 0.3s, 0s;
  height: 100%;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton svg {
  height: 30px;
  width: 30px;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton.addButton {
  background-color: #14cd00;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton.forwardButton {
  background-color: #2966ff;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton.kickButton {
  background-color: #ff2f28;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails .controlButtons .controlButton:hover {
  opacity: 1;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails:hover {
  background-color: #ededed;
}
.mmMainDiv .chatUsersShadowDiv .chatUsersContainer .oneUserForDetails:hover .controlButton {
  opacity: 0.7;
}
.mmMainDiv .historyBody .toolsHeader,
.mmMainDiv .blockListSection .toolsHeader {
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  text-align: center;
}
.mmMainDiv .historyBody .historyItemsTable .oneHistoryItem,
.mmMainDiv .blockListSection .historyItemsTable .oneHistoryItem,
.mmMainDiv .historyBody .blockListItemTable .oneHistoryItem,
.mmMainDiv .blockListSection .blockListItemTable .oneHistoryItem,
.mmMainDiv .historyBody .historyItemsTable .oneBlockItem,
.mmMainDiv .blockListSection .historyItemsTable .oneBlockItem,
.mmMainDiv .historyBody .blockListItemTable .oneBlockItem,
.mmMainDiv .blockListSection .blockListItemTable .oneBlockItem {
  max-height: 48.5px;
  cursor: pointer;
  position: relative;
}
.mmMainDiv .historyBody .historyItemsTable .leftBorder,
.mmMainDiv .blockListSection .historyItemsTable .leftBorder,
.mmMainDiv .historyBody .blockListItemTable .leftBorder,
.mmMainDiv .blockListSection .blockListItemTable .leftBorder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  width: 4px;
  background-color: #00b907;
}
.mmMainDiv .historyBody .historyItemsTable .tableCol:nth-child(2),
.mmMainDiv .blockListSection .historyItemsTable .tableCol:nth-child(2),
.mmMainDiv .historyBody .blockListItemTable .tableCol:nth-child(2),
.mmMainDiv .blockListSection .blockListItemTable .tableCol:nth-child(2) {
  padding-left: 20px;
  width: 150px;
  min-width: 245px;
  max-width: 150px;
}
.mmMainDiv .historyBody .historyItemsTable .tableCol:nth-child(3),
.mmMainDiv .blockListSection .historyItemsTable .tableCol:nth-child(3),
.mmMainDiv .historyBody .blockListItemTable .tableCol:nth-child(3),
.mmMainDiv .blockListSection .blockListItemTable .tableCol:nth-child(3) {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mmMainDiv .historyBody .historyItemsTable .tableCol:nth-child(4),
.mmMainDiv .blockListSection .historyItemsTable .tableCol:nth-child(4),
.mmMainDiv .historyBody .blockListItemTable .tableCol:nth-child(4),
.mmMainDiv .blockListSection .blockListItemTable .tableCol:nth-child(4) {
  max-width: 100px;
}
.mmMainDiv .historyBody .historyItemsTable .tableCol:nth-child(5),
.mmMainDiv .blockListSection .historyItemsTable .tableCol:nth-child(5),
.mmMainDiv .historyBody .blockListItemTable .tableCol:nth-child(5),
.mmMainDiv .blockListSection .blockListItemTable .tableCol:nth-child(5) {
  max-width: 200px;
  min-width: 200px;
  flex-basis: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mmMainDiv .historyBody .historyItemsTable .tableCol:nth-child(6),
.mmMainDiv .blockListSection .historyItemsTable .tableCol:nth-child(6),
.mmMainDiv .historyBody .blockListItemTable .tableCol:nth-child(6),
.mmMainDiv .blockListSection .blockListItemTable .tableCol:nth-child(6) {
  max-width: 350px;
  flex-basis: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mmMainDiv .monitoringBody .toolsHeader {
  font-size: 15px;
  font-weight: 500;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .oneMonitoringItem {
  max-height: 48.5px;
  cursor: pointer;
  position: relative;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol > div {
  flex-basis: unset;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(2) {
  padding-left: 20px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(3) {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(4) {
  width: 105px;
  flex-basis: 105px;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(5) {
  width: 100px;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(6) {
  flex-basis: 175px;
  max-width: 175px;
  min-width: 175px;
}
.mmMainDiv .monitoringBody .monitoringItemsTable .tableCol:nth-child(7) {
  width: 175px;
  flex-basis: 175px;
  max-width: 350px;
}
.mmMainDiv .historyBody.toolsBody,
.mmMainDiv .monitoringBody.toolsBody {
  display: flex;
  height: 100%;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel {
  width: 320px !important;
  border-right: #e9e9e9 1px solid;
  background-color: #ececec;
  height: 100%;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .toolsHeader,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .toolsHeader {
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .toolsLeftPanelScrollableDiv,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .toolsLeftPanelScrollableDiv {
  height: calc(100% - 50px);
  overflow-y: visible;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .oneToolItem,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .oneToolItem {
  height: 40px;
  width: 100%;
  margin-top: 5px;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .oneToolItem input,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .oneToolItem input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  background-color: #fff;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select {
  height: 40px;
  width: 100%;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select .dropdown-toggle,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select .dropdown-toggle {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mmMainDiv .historyBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select > .dropdown-menu,
.mmMainDiv .monitoringBody.toolsBody .toolsLeftPanel .oneToolItem .dropdown-multi-button .bootstrap-select > .dropdown-menu {
  border: #00AEEF solid 1px;
}
.mmMainDiv .historyBody .toolsItemsWrap,
.mmMainDiv .monitoringBody .toolsItemsWrap {
  flex-grow: 1;
  background-color: #ffffff;
  overflow-y: visible;
  overflow-x: hidden;
}
.mmMainDiv .historyBody .toolsItemsWrap .paginationTable .tableHeader,
.mmMainDiv .monitoringBody .toolsItemsWrap .paginationTable .tableHeader {
  height: 49px;
}
@media (max-width: 2000px) {
  .mmMainDiv {
    --chatListWidth: 400px;
    --max-message-width: 420px;
  }
}
@media (max-width: 1900px) {
  .mmMainDiv .chatUsersContainer {
    max-width: 620px !important;
  }
}
@media (max-width: 1440px) {
  .mmMainDiv {
    --chatListWidth: 300px;
  }
}
@media (max-width: 1280px) {
  .mmMainDiv {
    --chatListWidth: 250px;
  }
}
@media (max-width: 1000px) {
  .mmMainDiv {
    --chatListWidth: 250px;
    --messageInputAndMessagesWidth: 500px;
  }
  .mmMainDiv .chatUsersContainer {
    max-width: 320px !important;
  }
}
.mmMainDiv.fullScreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  min-height: 100%;
  max-height: 100%;
}
.mmMainDiv.fullScreen .leftColumn {
  background-color: #fff;
}
.mmMainDiv.fullScreen .leftColumn .chatListWrap .chatListBody .chatList {
  min-height: calc(var(--vh) - var(--headerHeight) + 1px + 246px);
  max-height: calc(var(--vh) - var(--headerHeight) + 1px + 246px);
}
.mmMainDiv.fullScreen .centerColumn .chatWrap .chatBody {
  min-height: calc(var(--vh) - var(--headerHeight) + 1px + 246px);
  max-height: calc(var(--vh) - var(--headerHeight) + 1px + 246px);
}
.mmMainDiv .customFieldAnswerSelector .dropdown-menu,
.mmMainDiv .languageSelect .dropdown-menu {
  height: 300px;
}
.mmMainDiv .oneLangPhrase .quickActionLangSelector .dropdown-menu {
  height: 300px;
}
.mirandaCustomFieldItem,
.mirandaCustomFieldDefaultItem,
.mirandaFormItem {
  background-color: #fff;
  box-shadow: 0 0 7px -2px #7f7f7f !important;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.mirandaCustomFieldItem .itemHeader,
.mirandaCustomFieldDefaultItem .itemHeader,
.mirandaFormItem .itemHeader {
  display: flex;
  align-items: center;
}
.mirandaCustomFieldItem .itemHeader .itemHeaderName,
.mirandaCustomFieldDefaultItem .itemHeader .itemHeaderName,
.mirandaFormItem .itemHeader .itemHeaderName {
  font-size: 25px;
  font-weight: 500;
  color: #2b2b2b;
  padding-top: 0;
  padding-left: 20px;
}
.mirandaCustomFieldItem .mirandaCustomFieldBody,
.mirandaCustomFieldDefaultItem .mirandaCustomFieldBody,
.mirandaFormItem .mirandaCustomFieldBody,
.mirandaCustomFieldItem .mirandaFormBody,
.mirandaCustomFieldDefaultItem .mirandaFormBody,
.mirandaFormItem .mirandaFormBody {
  padding-left: 20px;
}
.mirandaCustomFieldItem .answerVariantsDiv .answerVariantDiv,
.mirandaCustomFieldDefaultItem .answerVariantsDiv .answerVariantDiv,
.mirandaFormItem .answerVariantsDiv .answerVariantDiv {
  border-bottom: #e1e1e1 1px solid;
  align-items: normal;
}
.mirandaCustomFieldItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer,
.mirandaCustomFieldDefaultItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer,
.mirandaFormItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer {
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
}
.mirandaCustomFieldItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .questionLanguageSelector,
.mirandaCustomFieldDefaultItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .questionLanguageSelector,
.mirandaFormItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .questionLanguageSelector {
  width: 200px;
  margin-right: 20px;
  height: 50px;
}
.mirandaCustomFieldItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .paramName,
.mirandaCustomFieldDefaultItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .paramName,
.mirandaFormItem .answerVariantsDiv .answerVariantDiv .oneLangAnswer .paramName {
  width: 95px;
}
.mirandaCustomFieldItem .oneParam,
.mirandaCustomFieldDefaultItem .oneParam,
.mirandaFormItem .oneParam {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}
.mirandaCustomFieldItem .oneParam .paramName,
.mirandaCustomFieldDefaultItem .oneParam .paramName,
.mirandaFormItem .oneParam .paramName {
  width: 300px;
}
.mirandaCustomFieldItem .oneParam .paramValue,
.mirandaCustomFieldDefaultItem .oneParam .paramValue,
.mirandaFormItem .oneParam .paramValue {
  width: 400px;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
}
.mirandaCustomFieldItem .formButtonsDiv,
.mirandaCustomFieldDefaultItem .formButtonsDiv,
.mirandaFormItem .formButtonsDiv,
.mirandaCustomFieldItem .customFieldButtonsDiv,
.mirandaCustomFieldDefaultItem .customFieldButtonsDiv,
.mirandaFormItem .customFieldButtonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.mirandaCustomFieldItem .formButtonsDiv .formDeleteButton,
.mirandaCustomFieldDefaultItem .formButtonsDiv .formDeleteButton,
.mirandaFormItem .formButtonsDiv .formDeleteButton,
.mirandaCustomFieldItem .customFieldButtonsDiv .formDeleteButton,
.mirandaCustomFieldDefaultItem .customFieldButtonsDiv .formDeleteButton,
.mirandaFormItem .customFieldButtonsDiv .formDeleteButton,
.mirandaCustomFieldItem .formButtonsDiv .customFieldDeleteButton,
.mirandaCustomFieldDefaultItem .formButtonsDiv .customFieldDeleteButton,
.mirandaFormItem .formButtonsDiv .customFieldDeleteButton,
.mirandaCustomFieldItem .customFieldButtonsDiv .customFieldDeleteButton,
.mirandaCustomFieldDefaultItem .customFieldButtonsDiv .customFieldDeleteButton,
.mirandaFormItem .customFieldButtonsDiv .customFieldDeleteButton {
  margin-left: 10px;
}
.mirandaCustomFieldItem .collapseButton,
.mirandaCustomFieldDefaultItem .collapseButton,
.mirandaFormItem .collapseButton {
  color: #000;
  margin-left: auto;
  font-weight: 500;
}
.mirandaFormsAndCustomFields .customFieldsDropdown {
  margin-right: 5px;
  float: left;
  margin-top: 5px;
}
.mirandaFormsAndCustomFields .sectionDescription {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: #657689 solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.mirandaFormsAndCustomFields .sectionDescription .sectionName {
  font-size: 25px;
  color: #000000;
}
.mirandaFormsAndCustomFields .sectionDescription .pseudo-button-add {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}
.customButtonsAndSettings .defaultKeyboardSettings,
.customButtonsAndSettings .mirandaCustomButtonItem,
.customButtonsAndSettings .defaultButtonItem {
  background-color: #fff;
  box-shadow: 0 0 7px -2px #7f7f7f !important;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.customButtonsAndSettings .defaultKeyboardSettings .actionButtonsDiv,
.customButtonsAndSettings .mirandaCustomButtonItem .actionButtonsDiv,
.customButtonsAndSettings .defaultButtonItem .actionButtonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.customButtonsAndSettings .defaultKeyboardSettings .actionButtonsDiv .customButtonDeleteButton,
.customButtonsAndSettings .mirandaCustomButtonItem .actionButtonsDiv .customButtonDeleteButton,
.customButtonsAndSettings .defaultButtonItem .actionButtonsDiv .customButtonDeleteButton {
  margin-left: 10px;
}
.customButtonsAndSettings .defaultKeyboardSettings .itemHeader,
.customButtonsAndSettings .mirandaCustomButtonItem .itemHeader,
.customButtonsAndSettings .defaultButtonItem .itemHeader {
  display: flex;
  align-items: center;
}
.customButtonsAndSettings .defaultKeyboardSettings .itemHeader .collapseButton,
.customButtonsAndSettings .mirandaCustomButtonItem .itemHeader .collapseButton,
.customButtonsAndSettings .defaultButtonItem .itemHeader .collapseButton {
  color: #000;
  margin-left: auto;
  font-weight: 500;
}
.customButtonsAndSettings .defaultKeyboardSettings .itemHeader .itemHeaderName,
.customButtonsAndSettings .mirandaCustomButtonItem .itemHeader .itemHeaderName,
.customButtonsAndSettings .defaultButtonItem .itemHeader .itemHeaderName {
  font-size: 25px;
  font-weight: 500;
  color: #2b2b2b;
  padding-top: 0;
  padding-left: 20px;
}
.customButtonsAndSettings .defaultKeyboardSettings .defaultKeyboardHeaderName,
.customButtonsAndSettings .mirandaCustomButtonItem .defaultKeyboardHeaderName,
.customButtonsAndSettings .defaultButtonItem .defaultKeyboardHeaderName {
  font-size: 25px;
  font-weight: 500;
  color: #2b2b2b;
  padding-top: 0;
  padding-left: 20px;
}
.addCustomButtonsDiv,
.defaultButtonsHeader,
.systemMessagesDiv,
.uniTalkSystemMessagesDiv {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: #657689 solid 3px;
  box-shadow: 0px 0px 7px -2px #a5a5a5;
  position: relative;
}
.addCustomButtonsDiv .headerName,
.defaultButtonsHeader .headerName,
.systemMessagesDiv .headerName,
.uniTalkSystemMessagesDiv .headerName {
  font-size: 25px;
  color: #000000;
}
.addCustomButtonsDiv .addCustomButtonButton,
.defaultButtonsHeader .addCustomButtonButton,
.systemMessagesDiv .addCustomButtonButton,
.uniTalkSystemMessagesDiv .addCustomButtonButton {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}
.buttonLocalizationHeader,
.buttonRedirectHeader {
  width: 400px;
  text-align: center;
}
.buttonRedirectHeader {
  margin-left: 60px;
}
.mirandaKeybords .oneParam {
  padding-left: 20px;
  font-size: 15px;
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.mirandaKeybords .oneParam .paramName {
  width: 300px;
}
.mirandaKeybords .oneParam .paramValue {
  width: 400px;
  border: 1px solid #c0cbd5;
  border-radius: 2px;
  font-size: 16px;
  height: 50px;
  color: #535353;
}
.adminAudioMainDiv .agiAudiosDiv {
  margin-top: 15px;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioTitleDiv {
  font-weight: 500;
  margin-bottom: 15px;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv {
  display: flex;
  margin-top: 3px;
  align-items: center;
  padding-left: 10px;
  line-height: 1;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv .agiAudioButtonsTemplate {
  display: flex;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv .agiAudioButtonsTemplate .pseudo-button > span:before {
  top: 6px;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv > div {
  margin-right: 10px;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv > div:first-child {
  width: 650px;
}
.adminAudioMainDiv .agiAudiosDiv .agiAudioMainDiv > div:not(:first-child) {
  width: 100px;
}
.adminAudioMainDiv .agiAudiosDiv .audio-audio-div {
  margin-bottom: 10px;
}
.chat-stat-op-table-header {
  cursor: pointer;
}
.mondayAuthBlock .mondayEnabledToggleDiv {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.mondaySettingsBlock .mondayMainSection > div {
  display: flex;
  align-items: center;
}
.mondaySettingsBlock .mondayMainSection > div > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.mondaySettingsBlock .mondayMainSection > div > div > div:first-child {
  width: 250px;
}
.mondaySettingsBlock .mondayMainSection > div > div .dropdown-single-button {
  height: 50px;
  width: 300px;
  margin-right: 100px;
}
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv,
.mondaySettingsBlock .mondayEntityPipelineTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv .mondayResponsibleScheduleDayName,
.mondaySettingsBlock .mondayEntityPipelineTemplate .mondayResponsibleScheduleDayName {
  font-weight: 500;
  font-size: 25px;
  width: 50px;
  margin-right: 15px;
}
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv .mondayEntityPipelineOuterNumber,
.mondaySettingsBlock .mondayEntityPipelineTemplate .mondayEntityPipelineOuterNumber {
  width: 300px;
}
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv .mondayResponsibleSelect,
.mondaySettingsBlock .mondayEntityPipelineTemplate .mondayResponsibleSelect,
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv .mondayEntityPipelineGroupSelect,
.mondaySettingsBlock .mondayEntityPipelineTemplate .mondayEntityPipelineGroupSelect,
.mondaySettingsBlock .mondayResponsibleScheduleOneDayDiv .mondayEntityPipelineStatusSelect,
.mondaySettingsBlock .mondayEntityPipelineTemplate .mondayEntityPipelineStatusSelect {
  width: 250px;
  height: 50px;
  margin-right: 10px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv > div {
  margin-bottom: 15px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntitySettingsWithSelectDiv {
  display: flex;
  align-items: center;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntitySettingsWithSelectDiv > div:first-child {
  width: 300px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntitySettingsWithSelectDiv .dropdown-multi-button .bootstrap-select {
  width: 296px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntitySettingsWithSelectDiv .mondayEntityNamePrefixInput,
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntitySettingsWithSelectDiv .dropdown-single-button {
  height: 50px;
  width: 300px;
  margin-right: 100px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntityFieldsBlock {
  margin-top: 25px;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntityFieldsBlock .mondayEntityFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.mondaySettingsBlock .mondayEntitySettingsTemplateDiv .mondayEntityPipelinesBlock {
  margin-top: 25px;
}
.billingSettingsMainDiv .settingNameAndValue {
  width: 700px;
  display: flex;
  align-items: center;
}
.billingSettingsMainDiv .settingNameAndValue .name {
  width: 525px;
}
.billingSettingsMainDiv .settingNameAndValue .value {
  width: 150px;
}
.billingSettingsMainDiv .settingNameAndValue .value input {
  height: 40px;
}
.billingSettingsMainDiv .settingNameAndValue .pseudo-button {
  margin-top: 10px;
  margin-left: 35px;
}
.billingSettingsMainDiv .divForSmsPrices,
.billingSettingsMainDiv .divForCurrencyAndSum,
.billingSettingsMainDiv .divForTranslationPrices {
  margin-top: 10px;
}
.billingSettingsMainDiv .smsHeader {
  display: flex;
  justify-content: space-between;
}
.billingSettingsMainDiv .divForSmsPrices,
.billingSettingsMainDiv .divForTranslationPrices {
  display: flex;
  flex-wrap: wrap;
}
.billingSettingsMainDiv .divForSmsPrices .oneSmsPrice,
.billingSettingsMainDiv .divForTranslationPrices .oneSmsPrice,
.billingSettingsMainDiv .divForSmsPrices .oneLangCodePrice,
.billingSettingsMainDiv .divForTranslationPrices .oneLangCodePrice {
  padding-right: 30px;
  margin-bottom: 15px;
}
.billingSettingsMainDiv .divForSmsPrices .oneSmsPrice .countryCodeDiv,
.billingSettingsMainDiv .divForTranslationPrices .oneSmsPrice .countryCodeDiv,
.billingSettingsMainDiv .divForSmsPrices .oneLangCodePrice .countryCodeDiv,
.billingSettingsMainDiv .divForTranslationPrices .oneLangCodePrice .countryCodeDiv {
  display: flex;
  justify-content: space-between;
  height: 25px;
}
.billingSettingsMainDiv .divForSmsPrices .oneSmsPrice .countryCodeDiv .countryCode,
.billingSettingsMainDiv .divForTranslationPrices .oneSmsPrice .countryCodeDiv .countryCode,
.billingSettingsMainDiv .divForSmsPrices .oneLangCodePrice .countryCodeDiv .countryCode,
.billingSettingsMainDiv .divForTranslationPrices .oneLangCodePrice .countryCodeDiv .countryCode,
.billingSettingsMainDiv .divForSmsPrices .oneSmsPrice .countryCodeDiv .langCode,
.billingSettingsMainDiv .divForTranslationPrices .oneSmsPrice .countryCodeDiv .langCode,
.billingSettingsMainDiv .divForSmsPrices .oneLangCodePrice .countryCodeDiv .langCode,
.billingSettingsMainDiv .divForTranslationPrices .oneLangCodePrice .countryCodeDiv .langCode {
  font-weight: 500;
  color: #1e88e5;
}
.billingSettingsMainDiv .oneCurrencyAndCost {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.billingSettingsMainDiv .oneCurrencyAndCost .currency {
  width: 50px;
  font-weight: 500;
}
.billingSettingsMainDiv .oneCurrencyAndCost .cost {
  width: 90px;
  padding: 5px;
  height: 30px;
}
.billingSettingsMainDiv .controlButtonsDiv {
  display: flex;
  justify-content: flex-end;
}
.billingSettingsMainDiv .controlButtonsDiv button {
  line-height: 30px;
  height: 30px;
}
.billingSettingsMainDiv .divForGeneralPricesSection {
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
}
.billingSettingsMainDiv .divForGeneralPricesSection .defaultSection {
  flex-grow: 1;
}
.billingSettingsMainDiv .defaultTypedRowSection .typedRowTemplate,
.billingSettingsMainDiv .defaultTypedRowSection .currenciesHeader {
  display: flex;
  align-items: center;
}
.billingSettingsMainDiv .defaultTypedRowSection .typedRowTemplate > div:first-child,
.billingSettingsMainDiv .defaultTypedRowSection .currenciesHeader > div:first-child {
  width: 200px;
}
.billingSettingsMainDiv .defaultTypedRowSection .typedRowTemplate > div:not(:first-child),
.billingSettingsMainDiv .defaultTypedRowSection .currenciesHeader > div:not(:first-child) {
  width: 100px;
  margin-right: 5px;
}
.billingSettingsMainDiv .defaultTypedRowSection .currenciesHeader {
  font-weight: 500;
  margin-top: 10px;
}
.billingSettingsMainDiv .defaultTypedRowSection .divForPriceWithTypeRows .rowType {
  font-weight: 500;
  color: #1e88e5;
}
.billingSettingsMainDiv .defaultTypedRowSection .divForPriceWithTypeRows input {
  padding: 5px;
  height: 30px;
}
.billingSettingsMainDiv .defaultTypedRowSection .divForPriceWithTypeRows .typedRowTemplate:not(:first-child) {
  margin-top: 5px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-height: 400px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentHeader {
  display: flex;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentHeader .paySystemName {
  width: 150px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody {
  display: flex;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .oneParameter {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .oneParameter .commissionInput,
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .oneParameter .priorityInput {
  height: 30px;
  width: 50px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .oneParameter .commissionInput:disabled,
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .oneParameter .priorityInput:disabled {
  color: #777777;
  border-color: #c7c4c45c;
  cursor: not-allowed;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .currencySettingsDiv {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentBody .currencySettingsDiv .oneCurrencyAndCB {
  width: 60px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap {
  max-height: 400px;
  min-height: 400px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .countriesListDefinition {
  display: flex;
  align-items: center;
  height: 40px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .countriesListDefinition .listTypeName {
  font-weight: 500;
  margin-right: 20px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableHeader {
  font-weight: 500;
  background-color: rgba(162, 255, 148, 0.61);
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableHeader .spoilerColumn {
  cursor: pointer;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .activatedCountriesTable {
  max-height: 350px;
  overflow-y: scroll;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .oneCountryInTable:nth-child(odd) {
  background-color: #f9f9f9;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .oneCountryInTable:nth-child(even) {
  background-color: #f3f3f3;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow {
  display: flex;
  height: 30px;
  align-items: center;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow .next-checkbox-square .checkmark {
  top: -10px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow div:nth-child(1) {
  width: 20px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow div:nth-child(2) {
  width: 50px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow div:nth-child(3) {
  width: 200px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow div:nth-child(4) {
  width: 80px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap .tableRow div:nth-child(5) {
  width: 30px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap.denyMode .tableHeader {
  font-weight: 500;
  background-color: rgba(255, 0, 0, 0.35);
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap.denyMode .spoilerColumn {
  opacity: 0;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countriesWrap {
  width: 400px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .paymentMethodSettingsContainer {
  max-width: 250px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .allCountryCodesDiv {
  flex-grow: 1;
  max-height: 100%;
  display: none;
  margin-left: 20px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .allCountryCodesDiv .divForAllCountries {
  max-height: 380px;
  min-height: 380px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .oneAvailableCountry {
  display: flex;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  max-height: 20px;
  cursor: pointer;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .oneAvailableCountry:hover {
  background-color: #f1f1f1;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .oneAvailableCountry .code {
  font-weight: 500;
  width: 30px;
  min-width: 30px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .next-checkbox-square {
  padding-left: 30px;
}
.billingSettingsMainDiv .paymentMethodSettingsTable .paymentMethodRow .countrySettingsDiv {
  display: flex;
  column-gap: 10px;
  row-gap: 5px;
  width: 1000px;
  flex-wrap: wrap;
}
.salesforceSectionDiv {
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}
.salesforceSectionDiv .salesforceRemoveButton {
  margin-left: 15px;
}
.salesforceSectionDiv .currentConnectedDomain {
  margin: 0 0 15px 0;
  display: flex;
}
.salesforceSectionDiv .currentConnectedDomain .domainUrl {
  margin-left: 10px;
  font-weight: bold;
}
.salesforceSectionDiv .salesforceHeader {
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
}
.salesforceSectionDiv .salesforce-settings__item {
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .salesforceSectionDiv .salesforce-settings__item {
    display: block;
  }
}
@media (max-width: 991px) {
  .salesforceSectionDiv .salesforce-settings__item .nextel-radio {
    margin-top: 10px;
  }
}
.salesforceSectionDiv .salesforce-settings__item div:first-child {
  width: 300px;
}
.salesforceSectionDiv .salesforce-settings__item .nextel-radio {
  width: 200px;
}
.salesforceSectionDiv .daysBeforeClosingOpportunity {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.salesforceSectionDiv .daysBeforeClosingOpportunity .salesforceDaysBeforeClosingOpportunityInput {
  width: 300px;
  font-weight: 500;
}
.salesforceSectionDiv .oneResponsibleUserByDayElement {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.salesforceSectionDiv .oneResponsibleUserByDayElement .dayName {
  width: 50px;
  font-weight: 500;
  font-size: 25px;
  margin-right: 15px;
}
.salesforceSectionDiv .oneResponsibleUserByDayElement .daySelect {
  width: 300px;
  height: 45px;
}
.salesforceSectionDiv .oneResponsibleUserByDayElement:first-child {
  margin-top: 0;
}
.salesforceSectionDiv .divForInnerNumber {
  display: flex;
  flex-wrap: wrap;
}
.salesforceSectionDiv .divForInnerNumber .oneOperatorAndPhone {
  display: flex;
  align-items: center;
  width: 450px;
  margin-top: 15px;
  margin-right: 65px;
}
.salesforceSectionDiv .divForInnerNumber .oneOperatorAndPhone .operatorName {
  width: 250px;
  font-weight: 500;
}
.salesforceSectionDiv .divForInnerNumber .oneOperatorAndPhone .phoneNumber {
  width: 150px;
}
.salesforceLoadingScreen {
  font-weight: 500;
  font-size: 20px;
}
.salesforceDivForOpportunityFields,
.salesforceDivForContactFields,
.salesforceDivForLeadFields {
  display: flex;
  flex-wrap: wrap;
}
.salesforceOneField {
  width: 400px;
  padding: 5px;
}
.salesforceOneField .salesforceFieldName {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #00AEEF;
}
.salesforceOneField .salesforceFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.salesforceOneField .salesforceFieldSettingDiv {
  height: 50px;
}
.salesforceOneField .salesforceChoisesSelect {
  height: 100%;
}
.salesforceOneField .salesforceTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.salesforceOneField .salesforceTypeAndValueDiv .salesforceInputValue,
.salesforceOneField .salesforceTypeAndValueDiv .salesforceTypeSelect {
  width: 100%;
  height: 100%;
}
.bigCircle,
.smallCircle {
  position: fixed;
  opacity: 0.1;
}
.speech-recognition-main-div .speechRecognitionSettingsDiv {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 45px;
}
.speech-recognition-main-div .speechRecognitionSettingsDiv > div:first-child {
  width: 200px;
}
.speech-recognition-main-div .speechRecognitionSettingsDiv .webra-input-field,
.speech-recognition-main-div .speechRecognitionSettingsDiv .dropdown-single-button {
  width: 300px;
  height: 45px;
}
.speech-recognition-main-div .speechRecognitionHeaderDiv {
  margin-top: 20px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
}
.speech-recognition-main-div .speech-recognition-buttons-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
}
.speech-recognition-main-div .speech-recognition-buttons-div .pseudo-button {
  font-weight: 500;
}
.speech-recognition-main-div .oneRecognizer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.speech-recognition-main-div .oneRecognizer .leftPart {
  display: flex;
  align-items: center;
}
.speech-recognition-main-div .oneRecognizer .rightPart {
  align-items: center;
}
.speech-recognition-main-div .oneRecognizer .rightPart .pseudo-button > span:before {
  top: -6px;
}
.speech-recognition-main-div .oneRecognizer .engineSelect,
.speech-recognition-main-div .oneRecognizer .localeSelect {
  border: 2px solid #e8e8e8;
  width: 150px;
  margin-right: 20px;
}
.speech-recognition-main-div .oneRecognizer .oneParameter {
  padding-bottom: 10px;
}
.speech-recognition-main-div .oneRecognizer .oneParameter .paramDesc {
  color: #2a8be1;
  font-size: 12px;
  display: flex;
}
.speech-recognition-main-div .oneRecognizer .oneParameter .paramBody {
  width: 100%;
  display: flex;
  align-items: center;
}
.speech-recognition-main-div .oneRecognizer .oneParameter .paramBody .webra-input-field,
.speech-recognition-main-div .oneRecognizer .oneParameter .paramBody .dropdown-single-button {
  height: 100%;
  border: solid 2px #c3c3c3;
}
.speech-recognition-main-div .oneRecognizer .oneParameter .paramBody .commentaryInputDiv {
  height: 100%;
  width: 100%;
  border: solid 2px #c3c3c3;
  overflow-y: scroll;
}
.speech-recognition-main-div .oneRecognizer .warnDiv {
  font-size: 10px;
  color: #c59a00;
  max-width: 550px;
}
.miaListBlockTemplate .miaLineTemplate {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
  padding: 0 10px;
  border-radius: 5px;
  border-bottom: 1px solid white;
}
.miaListBlockTemplate .miaLineTemplate:hover {
  background-color: #ecf0f4;
  cursor: pointer;
}
.miaListBlockTemplate .miaLineTemplate .miaIdDiv {
  color: darkgrey;
}
.miaListBlockTemplate .miaLineTemplate .draftUpdatedAtDiv {
  margin-left: 10px;
}
.miaListBlockTemplate .miaLineTemplate .draftUpdatedAtDiv .draftUpdatedAtSpan {
  color: forestgreen;
}
.miaListBlockTemplate .miaLineTemplate .miaDivWithButtons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.miaListBlockTemplate .miaLineTemplate .miaDivWithButtons > div {
  margin-left: 60px;
}
.miaCopyToOtherProjectPopup {
  margin: 0 15px;
}
.miaCopyToOtherProjectPopup > div {
  margin-bottom: 15px;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect {
  display: flex;
  align-items: center;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect > div:first-child {
  width: 120px;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect > div:last-child {
  width: 650px;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect .copyToProjectSelect {
  width: 300px;
  height: 40px;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect .miasSelect .bootstrap-select {
  width: 100% !important;
}
.miaCopyToOtherProjectPopup .divWithTitleAndSelect .miasSelect .btn-default {
  padding: 8px 12px;
}
.miaCopyToOtherProjectPopup .copyToOtherProjectButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*<editor-fold desc="Профіль умов">*/
.miaConditionsProfileTemplate .publicProfileLabel {
  color: aqua;
}
.miaConditionsProfileTemplate .publicProfileLanguageCode {
  color: #EE82EE;
}
.miaConditionsProfileTemplate .publicProfileIsVisible {
  color: #80ff00;
}
.miaConditionsProfileTemplate .publicProfileIsHidden {
  color: #FF9F9F;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .divWithTitleAndInput {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .divWithTitleAndInput > div:first-child {
  width: 270px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .divWithButtons {
  display: flex;
  margin-top: 10px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .divWithButtons > div:first-child {
  margin-left: auto;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .divWithButtons > div:not(:first-child) {
  margin-left: 50px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .conditionGroupsTemplateContainer {
  margin-top: 15px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .conditionsProfileNameInput {
  width: 300px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .publicConditionsProfileLanguageSelect {
  height: 45px;
  width: 300px;
}
.miaConditionsProfileTemplate .miaConditionsProfileMainDiv .conditionProfilePublicCheckboxDiv {
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
}
/*</editor-fold>*/
/*<editor-fold desc="Попап з публічними профілями">*/
.publicConditionsProfilePopupTemplate {
  padding: 0 10px 10px 10px;
}
.publicConditionsProfilePopupTemplate .publicConditionsProfilePopupLanguageDiv {
  display: flex;
  align-items: center;
}
.publicConditionsProfilePopupTemplate .publicConditionsProfilePopupLanguageDiv .publicConditionsProfilePopupLanguageSelect {
  width: 200px;
  height: 45px;
  margin-left: 20px;
}
.publicConditionsProfilePopupTemplate .publicConditionsProfileCopyButtonTemplate {
  margin-left: 5px;
  margin-top: 5px;
}
/*</editor-fold>*/
.miaHistoryMainDiv .miaHistoryMiaSelect .bootstrap-select,
.miaHistoryMainDiv .miaHistoryEndReasonSelect .bootstrap-select,
.miaHistoryMainDiv .miaHistoryEndNodeTypesSelect .bootstrap-select {
  width: 200px !important;
}
.miaHistoryMainDiv .miaHistoryRouteDiv {
  border-top: #e9e9e9 1px solid;
  padding: 10px 20px 10px 45px;
}
.miaHistoryMainDiv .miaHistoryTable {
  margin-top: 20px;
}
.miaHistoryMainDiv .miaHistoryTable .paginationTableHeader {
  position: relative;
}
.miaHistoryMainDiv .miaHistoryTable .paginationTableHeader:hover .callHistoryUpdateButton,
.miaHistoryMainDiv .miaHistoryTable .paginationTableHeader:hover .callHistoryUpdateButtonBackground {
  opacity: 0.8;
  transition: all 300ms linear 0.4s;
}
.miaHistoryMainDiv .miaHistoryTableCol {
  padding: 15px 8px;
  display: flex;
  align-items: center;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(1) {
  margin-left: 35px;
  flex-basis: 170px;
  min-width: 120px;
  font-weight: 500;
}
@media (max-width: 1366px) {
  .miaHistoryMainDiv .miaHistoryTableCol:nth-child(1) {
    margin-left: 15px;
  }
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(2) {
  flex-basis: 180px;
  min-width: 110px;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(3) {
  flex-basis: 180px;
  min-width: 110px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(3) > div {
  margin-right: auto;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(3) .miaHistorySourceTypeSpan {
  color: #9400d3;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(4) {
  flex-basis: 130px;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(4) > div {
  margin-right: auto;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(4) .miaHistoryDateSpan {
  font-weight: 500;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(5) {
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  flex-basis: 130px;
  min-width: 80px;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(5) > div {
  margin-right: auto;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(6) {
  flex-basis: 400px;
  min-width: 230px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(6) .miaHistoryEndNodeTypeSpan {
  color: #9400d3;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(6) > div {
  margin-right: auto;
}
.miaHistoryMainDiv .miaHistoryTableCol:nth-child(7) {
  flex-basis: 215px;
  min-width: 100px;
}
.miaHistoryMainDiv .playRecordButton {
  margin-right: 0;
}
.miaHistoryMainDiv .miaHistoryItemTemplate {
  background-color: #fff;
}
.miaHistoryMainDiv .miaHistoryItemRow {
  display: flex;
  border-top: 1px solid #e4e7ea;
  position: relative;
}
.treeControlsDiv {
  display: flex;
  align-items: center;
}
.treeControlsDiv i {
  font-size: 20px;
  cursor: pointer;
}
.treeControlsDiv button {
  line-height: 30px;
  height: 30px;
  padding: 0 15px;
  margin-right: 20px;
}
.treeControlsDiv .divWithButton {
  margin-top: 5px;
  width: 21px;
  margin-right: 20px;
}
.treeControlsDiv .zoomPlusButtonDiv {
  margin-right: 0;
}
.treeControlsDiv .treeZoomPercentDiv {
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#treeCanvasContainerDiv {
  position: relative;
  background-color: red;
  overflow: hidden;
  border: solid black 1px;
  cursor: grab;
  margin: -25px -26px -25px -24px;
}
#treeCanvasContainerDiv:active {
  cursor: grabbing;
}
.playAudiosDiv {
  position: absolute;
  width: 415px;
  height: 300px;
  top: 20px;
  left: 50px;
  z-index: 90;
  background-color: #ffffff;
  cursor: default;
  border-radius: 10px;
  opacity: 0.7;
  padding: 10px 15px;
  overflow: auto;
}
.playAudiosDiv:hover {
  opacity: 1;
}
.playAudiosDiv .audiosDiv > div:not(:last-child) {
  margin-bottom: 10px;
}
.playAudiosDiv .playAudiosHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-bottom: 10px;
}
.playAudiosDiv .playAudiosHeaderDiv i {
  font-size: 25px;
}
.playAudiosDiv .playAudiosHeaderDiv .playAudiosCloseButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.playAudiosDiv .playAudiosHeaderDiv .playAudiosCloseButtonDiv .playAudiosCloseButton:hover,
.playAudiosDiv .playAudiosHeaderDiv .playAudiosCloseButtonDiv .playAudiosCloseButton:focus {
  color: #ababab;
  cursor: pointer;
}
.treeSettingsSidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #ffffff;
  cursor: default;
}
.treeSettingsSidebar .settingsHeaderDiv {
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsHeaderTitleDiv {
  margin-left: 20px;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsHeaderTitleDiv .settingsHeaderTitleSpan {
  font-weight: 500;
  font-size: 18px;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsSidebarSaveButtonDiv {
  margin-left: auto;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsSidebarCloseButtonDiv {
  margin-left: 25px;
  margin-right: 20px;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsSidebarCloseButtonDiv .settingsSidebarCloseButton {
  color: #c2c2c2;
  font-size: 40px;
}
.treeSettingsSidebar .settingsHeaderDiv .settingsSidebarCloseButtonDiv .settingsSidebarCloseButton:hover,
.treeSettingsSidebar .settingsHeaderDiv .settingsSidebarCloseButtonDiv .settingsSidebarCloseButton:focus {
  color: #ababab;
  cursor: pointer;
}
.treeSettingsSidebar .divForSettings {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px 15px 20px;
  overflow: auto;
}
.treeSettingsSidebar .divForSettings .selectWithRemoveButtonTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.treeCanvasDiv {
  position: relative;
  height: 1360px;
  width: 3600px;
  transform-origin: 0 0;
  background-image: url(../img/tile.png);
  background-repeat: repeat;
  background-size: 30px 30px;
  background-color: #e0e0e0;
}
.treeCanvasDiv .treeNodeDiv {
  height: 104px;
  width: 150px;
  position: absolute;
  z-index: 9;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}
.treeCanvasDiv .treeNodeDiv .treeNodeActionDiv {
  cursor: pointer;
  height: 104px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.treeCanvasDiv .treeNodeDiv .treeNodeActionDiv .treeNodeActionTitleSpan {
  overflow: hidden;
  margin: 5px 10px 0 10px;
}
.treeCanvasDiv .treeNodeDiv .treeNodeConditionDiv {
  position: absolute;
  top: -35px;
  height: 20px;
  width: 150px;
  background-color: lightyellow;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  cursor: pointer;
}
.treeCanvasDiv .treeNodeDiv .treeNodeConditionDiv .treeNodeConditionsTitleSpan {
  overflow: hidden;
}
.treeCanvasDiv .treeNodeDiv .treeNodeColorLine {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  z-index: 10;
}
.treeCanvasDiv .treeNodeDiv .treeNodeRemoveNodeButtonDiv {
  position: absolute;
  top: 0;
  right: 0;
}
.treeCanvasDiv .treeNodeDiv .treeNodeRemoveNodeButtonDiv .removeNodeButton {
  color: red;
}
.treeCanvasDiv .treeNodeDiv .treeNodePlayAudiosButtonDiv {
  position: absolute;
  bottom: 0;
  right: 0;
}
.treeCanvasDiv .treeNodeDiv .treeNodePlayAudiosButtonDiv .playAudiosButton {
  color: yellowgreen;
}
.treeCanvasDiv .treeNodeDiv .treeNodeAddNodeButtonDiv {
  position: absolute;
  bottom: -13px;
}
.treeCanvasDiv .treeNodeDiv .treeNodeAddNodeButtonDiv .addNodeButton {
  color: green;
}
.treeCanvasDiv .treeNodeDiv:hover .treeNodeButtonsDiv {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv {
  position: absolute;
  right: -52px;
  top: 0;
  bottom: 0;
  width: 52px;
  z-index: 10;
  background-color: white;
  display: none;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .moveLeftButton,
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .moveRightButton {
  color: darkblue;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .moveLeftFake,
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .moveRightFake {
  color: dimgrey;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .copyNodeButton {
  color: dodgerblue;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .copyBranchButton {
  color: mediumslateblue;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .cutNodeButton {
  color: darkred;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .pasteToNodeButton {
  color: forestgreen;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .changeNodeColorButton {
  color: darkorange;
}
.treeCanvasDiv .treeNodeDiv .treeNodeButtonsDiv .changeBranchColorButton {
  color: #9b5192;
}
.treeCanvasDiv .treeNodeDiv .nodeButtonDiv {
  cursor: pointer;
  background-color: white;
  border: solid #ededed 1px;
  border-radius: 5px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
}
.treeCanvasDiv .treeNodeDiv .nodeButtonDiv:hover i {
  filter: brightness(150%);
}
.treeCanvasDiv .jumpToNodeArrowDiv,
.treeCanvasDiv .treeArrowDiv {
  position: absolute;
  width: 100%;
  overflow: visible;
  pointer-events: none;
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.treeCanvasDiv .jumpToNodeArrowDiv svg,
.treeCanvasDiv .treeArrowDiv svg {
  overflow: visible;
}
.treeCanvasDiv .jumpToNodeArrowDiv svg polyline,
.treeCanvasDiv .treeArrowDiv svg polyline {
  fill: none;
  stroke-width: 2;
}
.treeCanvasDiv .jumpToNodeArrowDiv.miaArrowBlinker,
.treeCanvasDiv .treeArrowDiv.miaArrowBlinker {
  -webkit-animation: 2s blink linear infinite;
  -moz-animation: 2s blink linear infinite;
  -ms-animation: 2s blink linear infinite;
  -o-animation: 2s blink linear infinite;
  animation: 2s blink linear infinite;
}
.treeCanvasDiv .jumpToNodeArrowDiv.miaArrowBlinker svg polyline,
.treeCanvasDiv .treeArrowDiv.miaArrowBlinker svg polyline {
  stroke: red;
}
.treeCanvasDiv .jumpToNodeArrowDiv.miaArrowBlinker svg polygon,
.treeCanvasDiv .treeArrowDiv.miaArrowBlinker svg polygon {
  fill: red;
}
.treeCanvasDiv .treeArrowDiv svg polyline {
  stroke: cornflowerblue;
}
.treeCanvasDiv .treeArrowDiv svg polygon {
  fill: cornflowerblue;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}
@-moz-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}
@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}
@-ms-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}
@-o-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}
.treeCanvasDiv .jumpToNodeArrowDiv svg polyline {
  stroke: blueviolet;
  stroke-dasharray: 5;
  -webkit-animation: 60s dash linear infinite;
  -moz-animation: 60s dash linear infinite;
  -ms-animation: 60s dash linear infinite;
  -o-animation: 60s dash linear infinite;
  animation: 60s dash linear infinite;
}
.treeCanvasDiv .jumpToNodeArrowDiv svg polygon {
  fill: blueviolet;
}
.miaMainSettingsTemplate .dropdown-single-button,
.miaActionSettingsTemplate .dropdown-single-button,
.miaMainSettingsTemplate .webra-input-field,
.miaActionSettingsTemplate .webra-input-field {
  width: 400px;
  height: 40px;
}
.miaMainSettingsTemplate > div,
.miaActionSettingsTemplate > div {
  display: flex;
  margin-bottom: 10px;
}
.miaMainSettingsTemplate > div > div:first-child,
.miaActionSettingsTemplate > div > div:first-child {
  width: 400px;
}
.miaActionTitleEmojiPickerButton,
.miaConditionsTitleEmojiPickerButton {
  margin-left: 10px;
}
.miaActionTitleEmojiPickerButton i,
.miaConditionsTitleEmojiPickerButton i {
  font-size: 30px;
  margin-top: 3px;
  color: dimgrey;
}
.miaActionTitleEmojiPickerButton i:hover,
.miaConditionsTitleEmojiPickerButton i:hover {
  color: darkgrey;
  cursor: pointer;
}
.miaMainSettingsTemplate .miaMakeBackgroundDiv,
.miaMainSettingsTemplate .miaBackgroundIsDeferCallDiv,
.miaMainSettingsTemplate .publicMiaCheckboxDiv {
  margin: 20px 0;
}
.miaMainSettingsTemplate .miaNameDiv,
.miaMainSettingsTemplate .publicMiaLanguageSelectDiv,
.miaMainSettingsTemplate .speechToTextSettingsDiv,
.miaMainSettingsTemplate .skipAudioIfSynthesisFailDiv,
.miaMainSettingsTemplate .textToSpeechSettingsSelectDiv {
  align-items: center;
}
.miaMainSettingsTemplate .noSpeechToTextSettingsMessageDiv {
  color: indianred;
}
.miaActionSettingsTemplate .endNodeFailDiv,
.miaActionSettingsTemplate .endGreetingIfHelloDiv,
.miaActionSettingsTemplate .speechRecognitionNodeTypeDiv {
  margin: 20px 0;
}
.miaActionSettingsTemplate .mainAudioDiv > div:last-child,
.miaActionSettingsTemplate .alternativeAudiosDiv > div:last-child {
  min-width: 500px;
  width: 1000px;
}
.miaActionSettingsTemplate .jumpToNodeDiv .jumpToNodeIdInput {
  width: 120px;
}
.miaActionSettingsTemplate .jumpToNodeDiv .cantJumpToSameNodeWarning {
  margin-left: 10px;
  color: orangered;
}
.miaActionSettingsTemplate .actionTitleDiv,
.miaActionSettingsTemplate .actionTypeDiv,
.miaActionSettingsTemplate .maxWaitSecondsDiv,
.miaActionSettingsTemplate .maxListenSecondsDiv,
.miaActionSettingsTemplate .redirectToDiv,
.miaActionSettingsTemplate .jumpToNodeDiv,
.miaActionSettingsTemplate .endNodeFailRadio,
.miaActionSettingsTemplate .awaitingTimeDiv,
.miaActionSettingsTemplate .actionMohSelectDiv,
.miaActionSettingsTemplate .forwardTypeDiv,
.miaActionSettingsTemplate .speechRecognitionNodeTypeDiv,
.miaActionSettingsTemplate .endGreetingIfHelloDiv,
.miaActionSettingsTemplate .systemPrompt,
.miaActionSettingsTemplate .miaPrompt,
.miaActionSettingsTemplate .recognitionResultPrefix {
  align-items: center;
}
.miaActionSettingsTemplate .whisperPromptsContainer .whisperPromptDiv {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.miaActionSettingsTemplate .whisperPromptsContainer .whisperPromptDiv .localeSelect {
  width: 150px;
  min-width: 100px;
  margin-right: 5px;
}
.miaConditionsSettingsTemplate .dropdown-single-button,
.miaConditionsSettingsTemplate .webra-input-field {
  width: 400px;
  height: 40px;
}
.miaConditionsSettingsTemplate .conditionsPriorityInput {
  width: 50px;
}
.miaConditionsSettingsTemplate .conditionsTitleDiv,
.miaConditionsSettingsTemplate .conditionsPriorityDiv,
.miaConditionsSettingsTemplate .ifConditionsMatchWriteRecognizedTextToDiv,
.miaConditionsSettingsTemplate .wayToSetConditionsDiv,
.miaConditionsSettingsTemplate .openChatClassKey,
.miaConditionsSettingsTemplate .conditionProfileSelectDiv,
.miaConditionsSettingsTemplate .showConditionProfileSettingsButtonDiv,
.miaConditionsSettingsTemplate .publicConditionProfileSelectDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.miaConditionsSettingsTemplate .conditionsTitleDiv > div:first-child,
.miaConditionsSettingsTemplate .conditionsPriorityDiv > div:first-child,
.miaConditionsSettingsTemplate .ifConditionsMatchWriteRecognizedTextToDiv > div:first-child,
.miaConditionsSettingsTemplate .wayToSetConditionsDiv > div:first-child,
.miaConditionsSettingsTemplate .openChatClassKey > div:first-child,
.miaConditionsSettingsTemplate .conditionProfileSelectDiv > div:first-child,
.miaConditionsSettingsTemplate .showConditionProfileSettingsButtonDiv > div:first-child,
.miaConditionsSettingsTemplate .publicConditionProfileSelectDiv > div:first-child {
  width: 400px;
}
.miaConditionsSettingsTemplate .profilesConditionGroupsContainer,
.miaConditionsSettingsTemplate .conditionGroupsContainer {
  margin: 20px 0;
}
.voiceAnalyticsSettingsDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.voiceAnalyticsSettingsDiv .generalSettings {
  display: flex;
  align-items: center;
  gap: 10px;
}
.voiceAnalyticsSettingsDiv .filtersDiv {
  display: flex;
  flex-direction: column;
}
.voiceAnalyticsSettingsDiv .filtersDiv .limitCallDuration {
  margin-bottom: 15px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .callDurationLimitContainer {
  display: flex;
  gap: 5px;
  margin-left: 20px;
  align-items: center;
  margin-bottom: 15px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .callDurationLimitContainer input {
  width: 100px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .voiceAnalyticsSubheader {
  font-weight: 500;
}
.voiceAnalyticsSettingsDiv .filtersDiv .allCallsCB {
  height: 50px;
  display: flex;
  align-items: center;
}
.voiceAnalyticsSettingsDiv .filtersDiv .filters {
  display: flex;
  align-items: center;
  gap: 10px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .incomingCallsFiltersContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  padding: 10px 20px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .outgoingCallsFiltersContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  padding: 10px 20px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .sitesFiltersContainer,
.voiceAnalyticsSettingsDiv .filtersDiv .miasFiltersContainer {
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  padding: 10px 20px;
}
.voiceAnalyticsSettingsDiv .filtersDiv .sitesFiltersContainer .dropdown-toggle,
.voiceAnalyticsSettingsDiv .filtersDiv .miasFiltersContainer .dropdown-toggle {
  padding-left: 0;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  padding: 10px 20px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv div {
  margin-bottom: 10px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .next-checkbox-square {
  display: inline-block;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .stopWordsContainer,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .requiredPhrasesContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .stopWordsContainer .oneStopWord,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .requiredPhrasesContainer .oneStopWord,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .stopWordsContainer .oneRequiredPhrase,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .requiredPhrasesContainer .oneRequiredPhrase {
  display: flex;
  width: 550px;
  gap: 5px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .stopWords .pseudo-button,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .requiredPhrases .pseudo-button {
  line-height: unset;
  height: unset;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .translationPromptInput,
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .translationPromptHelperInput {
  height: 200px;
  width: 735px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .systemMessageInput .operatorSystemMessageInput .phraseMonitoringSystemMessageInput {
  height: 200px;
  width: 735px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .langNameContainer {
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .webra-input-field {
  height: 35px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .languageConfigBody {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 15px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .languageConfigBody .metricSettings {
  display: flex;
  flex-direction: column;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .languageConfigBody .metricSettings .oneMetric {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.voiceAnalyticsSettingsDiv .analyticsSettingsDiv .languageConfig .languageConfigBody .metricSettings .oneMetric .metricPrompt {
  width: 500px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .addNotificationConditionButton {
  width: 100px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow {
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.23);
  padding: 10px 20px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow .pseudo-button {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  height: 20px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow .triggerActionMultiSelect .bootstrap-select {
  width: unset;
  max-width: 600px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow .conditionContainer {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow .conditionContainer .thresholdValue {
  border: 2px solid #e8e8e8;
  height: 40px;
  width: 60px;
}
.voiceAnalyticsSettingsDiv .notificationSettingsDiv .conditions .configurationRow .conditionContainer .dropdown-single-button.thresholdValue {
  width: 120px;
}
.voiceAnalyticsSettingsDiv .voiceAnalyticsHeaderDiv {
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
}
.voiceAnalyticsButtonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
}
.voiceAnalyticsButtonsDiv .pseudo-button {
  font-weight: 500;
}
.voiceAnalyticsGlobalSettings {
  display: flex;
  flex-direction: column;
}
.voiceAnalyticsGlobalSettings .buttonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
}
.voiceAnalyticsGlobalSettings .buttonsDiv .pseudo-button {
  font-weight: 500;
}
.voiceAnalyticsGlobalSettings .voiceAnalyticsHeaderDiv {
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e7e7e7;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 15px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .systemMessageInput,
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .operatorSystemMessageInput,
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .phraseMonitoringSystemMessageInput {
  height: 200px;
  width: 735px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .langNameContainer {
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .webra-input-field {
  height: 35px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .languageConfigBody {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 15px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .languageConfigBody .metricSettings {
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .languageConfigBody .metricSettings .oneMetric {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfig .languageConfigBody .metricSettings .oneMetric .metricPrompt {
  width: 500px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfigButtons {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
}
.voiceAnalyticsGlobalSettings .languageConfigsContainer .languageConfigButtons .pseudo-button {
  font-weight: 500;
}
.customScenarioRulesSection {
  padding: 20px;
}
.customScenarioRulesSection .pseudo-button {
  font-weight: 500;
}
.customScenarioRulesSection .inScenarioNameInputContainer {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.customScenarioRulesSection .inScenarioNameInputContainer .inScenarioNameInputLabel {
  font-weight: 500;
  margin-right: 15px;
}
.customScenarioRulesSection .inScenarioNameInputContainer .inScenarioNameInput {
  width: 278px;
  height: 32px;
}
.customScenarioRulesSection .addConditionContainer {
  display: flex;
  margin-top: 5px;
  margin-left: 5px;
}
.customScenarioRulesSection .ruleFieldDescriptionWrap {
  display: flex;
  cursor: move;
  gap: 5px;
}
.customScenarioRulesSection .oneScenarioRule {
  margin-bottom: 15px;
}
.customScenarioRulesSection .oneScenarioRule .scenario-toggle {
  height: 20px;
  width: 20px;
  cursor: move;
  margin-top: 2px;
}
.customScenarioRulesSection .oneScenarioRule .scenario-toggle span {
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background-color: #cbd2d9;
}
.customScenarioRulesSection .ruleAddContainer {
  margin-left: 205px;
}
.customScenarioRulesSection .ruleAddContainer .ruleAddBtn {
  width: 200px;
  margin-bottom: 20px;
  height: 32px;
  font-size: small;
}
.customScenarioRulesSection .ruleTypeSelect {
  width: 200px;
  margin-right: 15px;
  margin-left: 5px;
}
.customScenarioRulesSection .customScenarioSelect,
.customScenarioRulesSection .ruleTypeSelect {
  width: 200px;
  margin-right: 15px;
}
.customScenarioRulesSection .ruleSubTypeSelect,
.customScenarioRulesSection .successStatusesSelect,
.customScenarioRulesSection .callResultStatusSelect,
.customScenarioRulesSection .directionsSelect,
.customScenarioRulesSection .additionalSubTypeSelect {
  width: 200px;
  margin-right: 15px;
}
.customScenarioRulesSection .ruleSelectorDiv {
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 3px;
}
.customScenarioRulesSection .oneCondition {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.customScenarioRulesSection .ruleFieldDescription {
  display: flex;
  width: 200px;
  text-align: right;
  padding-right: 15px;
  font-weight: 500;
  justify-content: space-around;
  gap: 5px;
}
.customScenarioRulesSection .ruleInput {
  height: 36px;
  width: unset;
  flex-grow: 1;
  margin-right: 15px;
}
.customScenarioRulesSection .conditionRemoveBtn,
.customScenarioRulesSection .ruleRemoveBtn {
  margin-left: 25px;
}
.customScenarioRulesSection .ruleButtons {
  display: flex;
  margin-top: 25px;
  flex-direction: row-reverse;
}
.customScenarioRulesSection .ruleButtons .ruleSaveDiv {
  margin-left: 25px;
}
.customScenarioRulesSection .ruleButtons .ruleRemoveDiv {
  margin-left: 25px;
}
.customScenarioRulesSection .ruleButtons button {
  width: 150px;
  height: 32px;
}
.customScenarioRulesSection .dropdown-single-button,
.customScenarioRulesSection .webra-input-field {
  border: 2px solid #e8e8e8;
}
.customScenarioRulesSection .timeSpan {
  padding-right: 10px;
  font-weight: 500;
  line-height: 40px;
}
.asterilAuthBlock .asterilCrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.asterilAuthBlock .asterilCrmAuthField > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.asterilAuthBlock .asterilCrmAuthField > div:last-child {
  width: 600px;
}
.asterilAuthBlock .asterilCrmAuthField input {
  height: 35px;
}
.asterilAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .asterilAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.asterilAuthBlock .asterilEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.asterilAuthBlock .asterilEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.asterilSettingsBlock > div {
  margin-bottom: 12px;
}
.asterilSettingsBlock .dropdown-single-button,
.asterilSettingsBlock .webra-input-field {
  height: 35px;
}
.asterilSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.asterilSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.asterilSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.asterilSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.asterilSettingsBlock .mainSettings .settingsField {
  display: flex;
  align-items: center;
}
.asterilSettingsBlock .mainSettings .settingsField > div:first-child {
  width: 250px;
  margin-right: 10px;
}
.asterilSettingsBlock .mainSettings .settingsField .dropdown-single-button,
.asterilSettingsBlock .mainSettings .settingsField .webra-input-field {
  width: 500px;
}
.asterilSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldsHeader a {
  font-weight: 300;
  font-size: 14px;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldTemplate > div {
  margin-right: 5px;
  width: 310px;
}
.asterilSettingsBlock .customFieldsBlockTemplate .customFieldTemplate input {
  height: 35px;
}
.asterilSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.asterilSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.asterilSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.asterilSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.asterilSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.asterilSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.asterilSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.asterilSettingsBlock .ordersByStatusBlock .orderByStatusTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.asterilSettingsBlock .ordersByStatusBlock .orderByStatusTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.asterilSettingsBlock .ordersByStatusBlock .orderByStatusTemplate > div:last-child {
  width: 255px;
}
.eunioMainDiv .specialButtons {
  display: flex;
  margin-bottom: 5px;
  margin-top: -15px;
}
.eunioMainDiv .specialButtons .filterSpecialAction {
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: 30px;
}
.eunioMainDiv .specialButtons .filterSpecialAction input:checked ~ .checkmark {
  background-color: rgba(255, 0, 0, 0.65);
}
.eunioMainDiv .specialButtons .pseudo-button {
  font-weight: 500;
}
.eunioMainDiv .specialButtons .pseudo-button > span:before {
  top: -3px;
}
.eunioMainDiv .tagsFilters .divForTags,
.eunioMainDiv .tagsFilters .divForExceptTags {
  display: flex;
  align-items: center;
  height: 35px;
  max-width: calc(100% - 550px);
  flex-wrap: wrap;
}
.eunioMainDiv .tagsFilters .divForExceptTags .oneTag {
  margin-right: 10px;
}
.eunioMainDiv .tagsFilters .oneTag {
  height: 35px;
  background-color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.eunioMainDiv .tagsFilters .tagCondition {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 17px;
  color: #7F7F7F;
}
.eunioMainDiv .tagsFilters .tagsFilterAlgo {
  height: 35px;
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
}
.eunioMainDiv .tagsFilters .tagsFilterAlgo .nextel-radio span {
  font-weight: 500;
}
.eunioMainDiv .tagsFilters .manyTags .oneTag {
  height: 16px;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
}
.eunioMainDiv .tagsFilters .manyTags .tagCondition {
  font-size: 13px;
  height: 16px;
  width: 30px;
}
.eunioMainDiv .eunioFilters {
  display: flex;
  flex-wrap: wrap;
}
.eunioMainDiv .eunioFilters .oneFilter {
  height: 35px;
  width: 220px;
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
  margin-right: 10px;
  font-weight: 500;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
  color: #333;
}
.eunioMainDiv .eunioFilters .oneFilter[type=text] {
  padding-left: 10px;
}
.eunioMainDiv .eunioFilters .oneFilter.dropdown-multi-button .btn-default {
  padding: 0;
  height: 35px;
  padding-left: 10px;
}
.eunioMainDiv .eunioFilters .oneFilter .dropdown-menu.open {
  padding: 0;
  border: none;
  border-top: 1px solid #ddd;
}
.eunioMainDiv .eunioFilters .oneFilter.changed {
  color: red;
}
.eunioMainDiv .eunioFilters .oneFilter.changed .filter-option {
  color: red;
}
.eunioMainDiv .eunioFilters .createdDatePickerDiv {
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.eunioMainDiv .eunioFilters .createdDatePickerDiv .createdDatePicker {
  cursor: pointer;
  border: none;
  width: 260px;
  font-family: 'Droid Sans Mono', arial;
  font-weight: 600;
  font-size: 15px;
}
.eunioMainDiv .tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 100%;
}
.eunioMainDiv .tabs .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  margin-right: 2px;
  font-weight: 500;
  color: #7F7F7F;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  user-select: none;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
}
.eunioMainDiv .tabs .tab .activeLine {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2.5px;
  background-color: red;
  opacity: 0;
  transition: all 0.1s ease;
}
.eunioMainDiv .tabs .tab .tabName {
  display: flex;
  padding: 5px;
}
.eunioMainDiv .tabs .tab .tabName .typeLetter {
  color: red;
  width: 15px;
}
.eunioMainDiv .tabs .tab .closeButton {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 15px;
  background-color: red;
  transition: all 0.3s ease 0.1s;
}
.eunioMainDiv .tabs .tab:hover {
  background-color: #fdfdfd;
}
.eunioMainDiv .tabs .tab:hover .closeButton {
  opacity: 1;
}
.eunioMainDiv .tabs .tab.active {
  border-bottom: none;
  background-color: #fff;
  color: red;
  height: 30px;
}
.eunioMainDiv .tabs .tab.active .activeLine {
  opacity: 1;
}
.eunioMainDiv .sections {
  background-color: #fff;
  z-index: 1;
  position: relative;
}
.eunioMainDiv .sections .section {
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
}
.eunioMainDiv .sections .section .emptyResult {
  height: 300px;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7F7F7F;
}
.eunioMainDiv .sections .section .emptyResult img {
  margin-left: 50px;
  height: 200px;
}
.eunioMainDiv .sections .section .showMoreDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-weight: 500;
  cursor: pointer;
}
.eunioMainDiv .sections .section .showMoreDiv:hover {
  background-color: #d3d3d3;
}
.eunioMainDiv .sections .urow {
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
  transition: opacity 0.1s ease;
  position: relative;
  line-height: 14px;
  font-size: 15px;
  font-family: 'Droid Sans Mono', arial;
  font-weight: 600;
}
.eunioMainDiv .sections .urow .leftSide {
  display: flex;
  align-items: center;
}
.eunioMainDiv .sections .urow .numberQuarantineDiv {
  opacity: 0;
  width: 45px;
  position: relative;
}
.eunioMainDiv .sections .urow .numberQuarantineDiv .quarantineDays {
  position: absolute;
  bottom: 0;
  right: 8px;
}
.eunioMainDiv .sections .urow .numberCommentDiv {
  position: relative;
  display: flex;
  width: 300px;
}
.eunioMainDiv .sections .urow .numberCommentDiv .commentIcon {
  min-width: 30px;
}
.eunioMainDiv .sections .urow .numberCommentDiv .commentText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.eunioMainDiv .sections .urow .numberCommentDiv .commentPopup {
  display: none;
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
  top: -10px;
  z-index: 1;
}
.eunioMainDiv .sections .urow .numberCommentDiv.hasPopup:hover .commentPopup {
  display: flex;
}
.eunioMainDiv .sections .urow .numberCommentDiv.noComment {
  opacity: 0;
}
.eunioMainDiv .sections .urow .rightSide {
  display: flex;
  align-items: center;
}
.eunioMainDiv .sections .urow:nth-child(odd) {
  background-color: #fff;
}
.eunioMainDiv .sections .urow:nth-child(even) {
  background-color: #f9f9f9;
}
.eunioMainDiv .sections .urow .env {
  color: #0ac630;
}
.eunioMainDiv .sections .entityIdentifier {
  display: flex;
  align-items: center;
}
.eunioMainDiv .sections .entityIdentifier .typeLetter {
  width: 12px;
  font-weight: 600;
  color: red;
  margin-right: 3px;
}
.eunioMainDiv .sections .entityIdentifier .name {
  min-width: 130px;
  max-width: 130px;
}
.eunioMainDiv .sections .entityIdentifier .ownIcon {
  min-width: 30px;
  max-width: 30px;
}
.eunioMainDiv .sections .entityIdentifier .projectName {
  min-width: 200px;
  max-width: 200px;
  font-weight: 500;
}
.eunioMainDiv .sections .entityIdentifier .entityCounter {
  display: flex;
  font-weight: 600;
}
.eunioMainDiv .sections .entityIdentifier .entityCounter .typeCount {
  width: 30px;
}
.eunioMainDiv .sections.contextMenuOpened .rowsList .urow {
  background-color: #fff;
  opacity: 0.4;
}
.eunioMainDiv .sections.contextMenuOpened .rowsList .urow.contextMenuSource {
  opacity: 1;
  background-color: #f1f1f1;
}
.eunioMainDiv .sections .oneRequestedPhone:nth-child(odd) {
  background-color: #fff;
}
.eunioMainDiv .sections .oneRequestedPhone:nth-child(even) {
  background-color: #f9f9f9;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader {
  padding: 10px;
  display: flex;
  position: relative;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .numberOfRequest {
  width: 40px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .projectColumn {
  width: 170px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .projectColumn .project {
  display: flex;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .projectColumn .project .projectLogin {
  font-weight: 500;
  margin-left: 0;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .projectColumn .date {
  min-width: 80px;
  font-weight: 500;
  font-family: 'Droid Sans Mono', arial;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .fieldDesc {
  font-weight: 500;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .orderRequestInfo {
  width: 300px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .tariffAndMultiInfo {
  width: 350px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .tariffAndMultiInfo .tariffInfo,
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .tariffAndMultiInfo .multiInfo {
  display: flex;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .tariffAndMultiInfo .tariffInfo > .fieldDesc,
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .tariffAndMultiInfo .multiInfo > .fieldDesc {
  width: 95px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .userMessageDiv {
  margin-left: 20px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .controlButtonsDiv {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body {
  padding: 10px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .divForAvailablePhones {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .divForAvailablePhones .oneAvailablePhone {
  box-shadow: 0px 0px 4px 0px #404040 !important;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .divForAvailablePhones .oneAvailablePhone:hover {
  box-shadow: 0px 0px 10px 0px #404040 !important;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .divWithControlButtons {
  display: flex;
  justify-content: flex-start;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .divWithControlButtons button {
  margin-left: 10px;
}
.eunioMainDiv .sections .oneRequestedPhone .onePhoneHeader .body .noAvailablePhones {
  color: red;
}
.eunioMainDiv .unioEntityEditor {
  padding-right: 0;
  border-top: #dedede 1px solid;
}
.eunioMainDiv .unioEntityEditor .unioMainInfoDiv {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.eunioMainDiv .unioEntityEditor .leftSide {
  display: flex;
  padding-left: 5px;
}
.eunioMainDiv .unioEntityEditor .unioControlButtons {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-weight: 500;
}
.eunioMainDiv .unioEntityEditor .column {
  margin-right: 10px;
  min-width: 300px;
}
.eunioMainDiv .unioEntityEditor .column .oneParameter {
  width: 365px;
  margin-right: 10px;
  margin-top: 5px;
}
.eunioMainDiv .unioEntityEditor .column .oneParameter .paramDesc {
  color: #2a8be1;
  font-size: 12px;
  display: flex;
}
.eunioMainDiv .unioEntityEditor .column .oneParameter .paramBody {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}
.eunioMainDiv .unioEntityEditor .column .oneParameter .paramBody .webra-input-field,
.eunioMainDiv .unioEntityEditor .column .oneParameter .paramBody .dropdown-single-button {
  height: 100%;
  border: solid 2px #c3c3c3;
}
.eunioMainDiv .unioEntityEditor .column .oneParameter .paramBody .commentaryInputDiv {
  height: 100%;
  width: 100%;
  border: solid 2px #c3c3c3;
  overflow-y: scroll;
}
.eunioMainDiv .unioEntityEditor .column .twoParams,
.eunioMainDiv .unioEntityEditor .column .threeParams {
  display: flex;
  justify-content: space-between;
}
.eunioMainDiv .unioEntityEditor .column .twoParams .oneParameter {
  width: 175px;
}
.eunioMainDiv .unioEntityEditor .column .threeParams .oneParameter {
  width: 110px;
}
.eunioMainDiv .unioEntityEditor .column .billDropdowns {
  display: flex;
  justify-content: space-between;
}
.eunioMainDiv .unioEntityEditor .column .billDropdowns .dropdown-single-button {
  width: 100%;
}
.eunioMainDiv .unioEntityEditor .column .billDropdowns .dropdown-single-button .btn-default {
  padding-left: 5px;
  padding-right: 5px !important;
}
.eunioMainDiv .unioEntityEditor .column .unioEditorOperator {
  width: 100%;
}
.eunioMainDiv .unioEntityEditor .column .oneDataParameter {
  width: 365px;
  margin-right: 10px;
  margin-top: 5px;
}
.eunioMainDiv .unioEntityEditor .column .oneDataParameter .paramDesc {
  color: #2a8be1;
  font-size: 12px;
  display: flex;
}
.eunioMainDiv .unioEntityEditor .column .oneDataParameter .paramValue {
  width: 100%;
  font-weight: 500;
}
.eunioMainDiv .unioEntityEditor .unioFirstColumn {
  width: 250px;
  min-width: 250px;
}
.eunioMainDiv .unioEntityEditor .unioFirstColumn .oneParameter {
  width: unset;
}
.eunioMainDiv .unioEntityEditor .nextel-autocomplete-list {
  max-height: 200px;
  overflow-y: scroll;
}
.eunioMainDiv .unioEntityEditor .tagsParameter .paramBody {
  align-items: self-start;
  flex-wrap: wrap;
}
.eunioMainDiv .unioEntityEditor .tagsParameter .oneTag {
  margin-bottom: 1px;
  margin-right: 10px;
  background-color: #f5f5f5;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  font-weight: 500;
  box-shadow: 3px 3px 7px -2px rgba(0, 0, 0, 0.2);
}
.eunioMainDiv .unioEntityEditor .tariffSelector {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 375px;
}
.eunioMainDiv .unioEntityEditor .tariffSelector .fieldName {
  color: #2a8be1;
  font-size: 12px;
  display: flex;
}
.eunioMainDiv .unioEntityEditor .tariffSelector .divForTariffChoice,
.eunioMainDiv .unioEntityEditor .tariffSelector .divForMultiChoice {
  max-height: 300px;
  overflow-y: scroll;
  flex-grow: 1;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem {
  margin: 1px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  padding-right: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #c7c7c7;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem .oneItemLeft {
  display: flex;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem .oneItemLeft .tariffLines {
  align-items: center;
  background-color: #c7c7c7;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 33px;
  display: flex;
  justify-content: center;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem .oneItemLeft .space {
  width: 1px;
  background-color: #fff;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem .oneItemLeft .tariffName {
  padding-left: 5px;
  padding-right: 5px;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem.selected {
  background-color: #00AEEF;
}
.eunioMainDiv .unioEntityEditor .oneTariffItem.selected .tariffLines {
  background-color: #06c100;
}
.eunioMainDiv .unioEntityEditor .creatingPhonePairDiv {
  padding-top: 10px;
}
.eunioMainDiv .unioEntityEditor .creatingPhonePairDiv .creatingPhonePairHeader {
  color: #0a55c6;
  font-weight: 500;
}
.eunioMainDiv .unioEntityEditor .creatingPhonePairDiv .creatingPhonePairBody {
  display: flex;
  position: relative;
}
.eunioMainDiv .unioEntityEditor .divForEntities {
  margin-top: 5px;
  border-top: #dedede 1px solid;
}
.eunioMainDiv .unioEntityEditor .giveForLeaseDiv .buttonLeaseDiv {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.eunioMainDiv .unioEntityEditor .giveForLeaseDiv .buttonLeaseDiv .projectLogin {
  display: flex;
  align-content: center;
  font-weight: 500;
}
.eunioMainDiv .unioEntityEditor .giveForLeaseDiv .buttonLeaseDiv .projectCurrency {
  display: flex;
  align-content: center;
  font-weight: 500;
  color: #0a55c6;
}
.eunioMainDiv .unioEntityEditor .giveForLeaseDiv .buttonLeaseDiv button {
  width: 200px;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .batchAddingDiv {
  display: flex;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .batchAddingDiv .batchList {
  display: flex;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .batchAddingDiv .batchAddingInputDiv {
  height: 40px;
  border: #d0d0d0 solid 2px;
  min-width: 200px;
  overflow-y: scroll;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .batchAddingParamsDiv {
  display: flex;
  gap: 20px;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .queueList {
  margin-left: 20px;
  display: flex;
  min-height: 30px;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .queueList > div:first-child {
  min-width: 100px;
  line-height: 30px;
  font-weight: 500;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .queueList .parsedNumbersDiv,
.eunioMainDiv .unioOrTrunkOrNumberDiv .queueList .batchAddedInputDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .oneBatchNumber {
  padding: 3px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.eunioMainDiv .unioOrTrunkOrNumberDiv .buttonsDiv {
  display: flex;
  justify-content: flex-end;
}
.eunioMainDiv .batchActionsRow {
  display: none;
  background-color: #f9f9f9;
}
.eunioMainDiv .batchActionsRow .batchActionsControls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: relative;
}
.eunioMainDiv .batchActionsRow .batchActionsActions {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}
.eunioMainDiv .batchActionsRow .batchActionsActions .batchActionsMenu {
  opacity: 0;
  color: #1e88e5;
  font-weight: 500;
}
.eunioMainDiv .batchActionsRow .batchActionsActions .batchActionsMenu:hover {
  text-decoration: underline;
  cursor: pointer;
}
.eunioMainDiv .batchActionsRow .leftSide {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
}
.eunioMainDiv .batchActionsRow .leftSide .batchActionsCheckmarkDiv .next-checkbox-square .checkmark {
  left: 4px;
  top: -14px;
}
.eunioMainDiv .batchActionsRow .leftSide .batchActionsCheckmarkDiv input:checked ~ .checkmark {
  background-color: rgba(255, 0, 0, 0.65);
}
.eunioMainDiv .urow .batchActionsCheckmarkDiv {
  position: absolute;
  left: 4px;
  top: -6px;
  display: none;
}
.eunioMainDiv .urow .batchActionsCheckmarkDiv input:checked ~ .checkmark {
  background-color: rgba(255, 0, 0, 0.65);
}
.eunioMainDiv .urow .usesDiv {
  display: flex;
  align-items: center;
  width: 130px;
}
.eunioMainDiv .urow .usesDiv i {
  color: #3CC400;
}
.eunioMainDiv .urow .registrationInfo {
  display: flex;
  align-items: center;
  width: 90px;
  color: #3CC400;
}
.eunioMainDiv .urow .typeInfo {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.eunioMainDiv .batchActionsActivated .batchActionsRow {
  display: flex;
  flex-direction: column;
}
.eunioMainDiv .batchActionsActivated .urow .leftSide {
  padding-left: 25px;
}
.eunioMainDiv .batchActionsActivated .urow .batchActionsCheckmarkDiv {
  display: block;
}
.eunioMainDiv .selectAllEntitiesButton {
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
}
.eunioMainDiv .selectAllEntitiesButton:hover {
  text-decoration: underline;
  color: red;
}
.eunioMainDiv .globalSelectActivated .batchActionsControls .centerSide {
  color: red;
  font-weight: 500;
}
@keyframes flash_tabs {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  60% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.eunioMainDiv .animate_flash_tabs {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: flash_tabs;
}
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv {
  margin-top: 15px;
  display: flex;
}
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv .giveForLeaseDesc,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv .giveForLeaseDesc {
  font-weight: 500;
}
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv .loginInputWrap,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv .loginInputWrap {
  height: 100%;
  border: solid 2px #c3c3c3;
}
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv .addToProjTariffs,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv .addToProjTariffs,
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv .addToProjMultis,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv .addToProjMultis {
  min-height: 30px;
  min-width: 367px;
  margin-left: 10px;
  border: solid 2px #c3c3c3;
}
.eunioMainDiv .divForGiveForLease .giveForLeaseDiv .ephonesSmallButton,
.eunioMainDiv .divForGiveNumberToProject .giveForLeaseDiv .ephonesSmallButton {
  height: 30px !important;
  line-height: 20px !important;
  padding: 5px !important;
}
.unioContextMenu {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.unioContextMenu .contextItem {
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  font-weight: 500;
}
.unioContextMenu .contextItem:hover {
  color: #1e88e5;
  cursor: pointer;
}
.unioContextMenu .contextItem .contextItemIcon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unioContextMenu .contextItem .contextItemName {
  width: 160px;
  display: flex;
  align-items: center;
}
.ctrlPressed .eunioMainDiv .entityIdentifier .name,
.shiftPressed .eunioMainDiv .entityIdentifier .name {
  text-decoration: underline;
  cursor: pointer;
}
.eunioBatchActionsNumbersModal {
  padding-left: 20px;
  padding-right: 20px;
}
.eunioBatchActionsNumbersModal .oneSection {
  padding-bottom: 20px;
}
.eunioBatchActionsNumbersModal .subSectionName {
  font-weight: 500;
  font-size: 25px;
  color: #7F7F7F;
}
.eunioBatchActionsNumbersModal .horizontalButtons {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.eunioBatchActionsNumbersModal .horizontalButtons button {
  line-height: 30px;
  height: 30px;
}
.eunioBatchActionsNumbersModal .modalInput {
  height: 30px;
  font-weight: 500;
  color: #3d3d3d;
}
.outerNumberHistory {
  margin-top: 15px;
}
.outerNumberHistory .historyHeader {
  font-weight: 500;
  color: #595959;
  font-size: 20px;
}
.outerNumberHistory .oneNumberHistory {
  display: flex;
  margin-top: 3px;
}
.outerNumberHistory .oneNumberHistory .oneNumberDate {
  font-weight: 700;
  color: #7F7F7F;
  font-family: 'Droid Sans Mono', arial;
  width: 160px;
}
.trunkCustomHeaders .divForHeaders {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .headerInputs {
  display: flex;
  align-items: center;
  padding: 2px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .pseudo-button {
  padding-top: 5px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .typeLetter {
  width: 12px;
  font-weight: 600;
  color: red;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader input {
  padding: 2px;
  width: 191px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .directionSelect {
  padding-right: 30px;
  width: 191px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .unioName {
  margin-left: 2px;
  width: 140px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .header {
  margin-left: 2px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .value {
  margin-left: 2px;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .value input {
  color: #06b000;
}
.trunkCustomHeaders .divForHeaders .oneCustomHeader .value ::placeholder {
  color: red;
}
.trunkCustomHeaders .customHeadersButtons {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-right: 10px;
}
.eunioBatchActionsUniosModal .divWithButton {
  display: flex;
  align-items: center;
  gap: 20px;
}
.eunioBatchActionsUniosModal .divWithButton .button_small {
  height: 35px;
  line-height: 20px;
  padding: 3px;
  width: 100px;
}
.hubspotAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .hubspotAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.hubspotAuthBlock .accountUrlDiv {
  display: flex;
  align-items: center;
}
.hubspotAuthBlock .hubspotEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.hubspotAuthBlock .hubspotEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.hubspotSettingsBlock > div {
  margin-bottom: 12px;
}
.hubspotSettingsBlock .dropdown-single-button,
.hubspotSettingsBlock .webra-input-field {
  height: 35px;
}
.hubspotSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.hubspotSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.hubspotSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.hubspotSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv > div:first-child {
  margin-right: 10px;
}
.hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv label {
  margin-left: 30px;
}
@media (max-width: 952px) {
  .hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv > div,
  .hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv label {
    margin-bottom: 5px;
  }
  .hubspotSettingsBlock .mainSettings .passOperatorNumberRadioDiv label {
    margin-left: 0px;
  }
}
.hubspotSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.hubspotSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.hubspotSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.hubspotSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.hubspotSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.hubspotSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.hubspotSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.hubspotSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.hubspotSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.hubspotSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.hubspotSettingsBlock .dealsByStatusBlock .oneRowSelectTemplate,
.hubspotSettingsBlock .callStatusMatchingBlock .oneRowSelectTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.hubspotSettingsBlock .dealsByStatusBlock .oneRowSelectTemplate > div:first-child,
.hubspotSettingsBlock .callStatusMatchingBlock .oneRowSelectTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.hubspotSettingsBlock .dealsByStatusBlock .oneRowSelectTemplate > div:last-child,
.hubspotSettingsBlock .callStatusMatchingBlock .oneRowSelectTemplate > div:last-child {
  width: 340px;
}
.oneLocaleDiv .modelHeader {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.oneLocaleDiv .modelHeader .localeName {
  font-weight: 500;
  font-size: 20px;
  width: 80px;
}
.oneLocaleDiv .modelHeader .localeCountries {
  font-weight: 500;
  color: #696969;
}
.oneLocaleDiv .pseudo-button {
  font-weight: 500;
}
.oneLocaleDiv .oneModelDiv {
  padding: 10px;
  padding-left: 0;
  margin-top: 10px;
  box-shadow: 0px 0px 7px -2px #717171;
  position: relative;
}
.oneLocaleDiv .oneModelDiv .header {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding-left: 15px;
}
.oneLocaleDiv .oneModelDiv .header .headerInfo {
  display: flex;
}
.oneLocaleDiv .oneModelDiv .header .controlButtons {
  display: flex;
}
.oneLocaleDiv .oneModelDiv .header .controlButtons .pseudo-button {
  height: unset;
}
.oneLocaleDiv .oneModelDiv .activatedModes {
  width: 50px;
  display: flex;
  position: relative;
}
.oneLocaleDiv .oneModelDiv .activatedModes .shazamActiveIndicator {
  display: none;
  position: absolute;
  left: 0;
}
.oneLocaleDiv .oneModelDiv .activatedModes .recognitionActiveIndicator {
  display: none;
  position: absolute;
  right: 0;
}
.oneLocaleDiv .oneModelDiv .shazamActivateButton,
.oneLocaleDiv .oneModelDiv .recognitionActivateButton {
  cursor: pointer;
  max-height: 20px;
}
.oneLocaleDiv .oneModelDiv.shazamActive .shazamActiveIndicator {
  display: flex;
}
.oneLocaleDiv .oneModelDiv.shazamActive .shazamActivateButton {
  display: none;
}
.oneLocaleDiv .oneModelDiv.recognitionActive .recognitionActiveIndicator {
  display: flex;
}
.oneLocaleDiv .oneModelDiv.recognitionActive .recognitionActivateButton {
  display: none;
}
.oneLocaleDiv .localeSettingsDiv .countryListHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  border-bottom: solid 1px #dadada;
}
.oneLocaleDiv .localeSettingsDiv .showAvailableListButton,
.oneLocaleDiv .localeSettingsDiv .showActiveListButton {
  color: #1e88e5;
  cursor: pointer;
}
.oneLocaleDiv .localeSettingsDiv .divForUsedCountries {
  display: flex;
  flex-wrap: wrap;
}
.oneLocaleDiv .localeSettingsDiv .divForUsedCountries .oneCountry {
  width: 200px;
}
.oneLocaleDiv .localeSettingsDiv .divForUsedCountries .oneCountry:hover {
  text-decoration: underline;
  color: red;
  cursor: pointer;
}
.oneLocaleDiv .localeSettingsDiv .divForAvailableCountries {
  display: flex;
  flex-wrap: wrap;
}
.oneLocaleDiv .localeSettingsDiv .divForAvailableCountries .oneCountry {
  width: 200px;
}
.oneLocaleDiv .localeSettingsDiv .divForAvailableCountries .oneCountry:hover {
  text-decoration: underline;
  color: #0066ff;
  cursor: pointer;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType {
  margin-bottom: 15px;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType input {
  height: 30px;
  box-shadow: none;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .typeName {
  font-weight: 500;
  width: 110px;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .divForDescriptions {
  flex-grow: 1;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .divForDescriptions .pseudo-button {
  margin-left: 35px;
  margin-right: 10px;
  margin-top: 5px;
  height: 20px;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .oneAnsweringMachineDescription {
  display: flex;
  flex-grow: 1;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .oneAnsweringMachineDescription .desc {
  width: 700px;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .oneAnsweringMachineDescription .divForPhrases {
  flex-grow: 1;
}
.oneLocaleDiv .localeSettingsDiv .oneAnsweringMachineType .oneAnsweringMachineDescription .oneAnsweringMachinePhrase {
  flex-grow: 1;
  margin-bottom: 3px;
  display: flex;
}
.oneLocaleDiv .controlButtonsDiv {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.oneLocaleDiv .controlButtonsDiv .miaSettings {
  display: flex;
  align-items: center;
}
.oneLocaleDiv .controlButtonsDiv .miaSettings .miaLocaleNameDescription {
  width: 250px;
  font-weight: 500;
}
.oneLocaleDiv .controlButtonsDiv .miaSettings .miaLocaleNameInput {
  height: 30px;
}
.oneLocaleDiv .controlButtonsDiv .controlButtons {
  display: flex;
}
.oneLocaleDiv .controlButtonsDiv .controlButtons .smallButton {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin-left: 10px;
}
.addModelToLocalePopupBody .voskModelUploadDiv {
  border: 2px dotted #8181a0;
}
.addModelToLocalePopupBody .voskModelUploadDiv .fileDropZone {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;
  height: 49px;
}
.addModelToLocalePopupBody .voskModelUploadDiv .fileDropZone:hover {
  background-color: #eff4f8;
}
.addModelToLocalePopupBody .voskModelUploadDiv .fileDropZone .chooseFileButton {
  line-height: 30px;
  border: 2px #8181a0 solid;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 20px;
  color: #8181a0;
}
.addModelToLocalePopupBody .voskModelUploadDiv .fileDropZone .uploadStatus {
  line-height: 30px;
  margin-left: 5px;
  font-size: 20px;
  color: #8181a0;
}
.white-sector .smallButton {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin-left: 10px;
}
.oneTaurusNode {
  box-shadow: 0 0 7px -2px rgba(113, 113, 113, 0.43);
  background-color: #fff;
  margin-bottom: 20px;
  padding-left: 23px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.oneTaurusNode .leftMark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  color: #fff;
  font-weight: 500;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(180deg);
}
.oneTaurusNode .nodeBody {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 5px;
  padding-left: 30px;
}
.oneTaurusNode .leftSide,
.oneTaurusNode .rightSide {
  display: flex;
}
.oneTaurusNode .enableRadios {
  display: flex;
  gap: 10px;
  position: relative;
}
.oneTaurusNode .oneEnablingButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
}
.oneTaurusNode .oneEnablingButton svg {
  width: 20px;
  height: 20px;
}
.oneTaurusNode .oneEnablingButton .buttonName {
  margin-left: 5px;
  font-weight: 500;
  height: 15px;
}
.oneTaurusNode .oneEnablingButton.modeAuto {
  color: #1e88e5;
}
.oneTaurusNode .oneEnablingButton.modeAuto svg path {
  fill: #1e88e5;
}
.oneTaurusNode .oneEnablingButton.modeOn {
  color: #43c900;
}
.oneTaurusNode .oneEnablingButton.modeOn svg path {
  fill: #43c900;
}
.oneTaurusNode .oneEnablingButton.modeOff {
  color: #d20000;
}
.oneTaurusNode .oneEnablingButton.modeOff svg path {
  fill: #d20000;
}
.oneTaurusNode .oneEnablingButton.notSelected {
  color: rgba(0, 0, 0, 0.25);
}
.oneTaurusNode .oneEnablingButton.notSelected svg path {
  fill: rgba(0, 0, 0, 0.25);
}
.oneTaurusNode .oneStatParameter {
  display: flex;
  height: 20px;
}
.oneTaurusNode .oneStatParameter .paramName {
  font-weight: 500;
}
.oneTaurusNode .oneStatParameter .paramValue {
  color: #1e88e5;
  font-weight: 500;
}
.oneTaurusNode .statColumn {
  margin-right: 20px;
}
.oneTaurusNode .statsDiv {
  display: flex;
  position: relative;
  gap: 20px;
  margin-left: 20px;
  min-width: 200px;
}
.oneTaurusNode .statsDiv .stat1 {
  width: 120px;
}
.oneTaurusNode .statsDiv .stat1 .paramName {
  width: 85px;
}
.oneTaurusNode .statsDiv .stat3 {
  width: 110px;
}
.oneTaurusNode .statsDiv .stat3 .paramName {
  width: 85px;
}
.oneTaurusNode .statsDiv .noConnectionWarning {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  backdrop-filter: blur(1px);
}
.oneTaurusNode .statsDiv .noConnectionWarning .stateDesc {
  color: #ffffff;
  font-size: 25px;
  position: absolute;
  width: 100%;
  top: 50%;
  font-family: monospace;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 600;
}
.oneTaurusNode .gpuStatColumn .oneStatParameter,
.oneTaurusNode .stat2 .oneStatParameter {
  display: flex;
  justify-content: space-between;
  width: 85px;
}
.oneTaurusNode .stat2 {
  width: 100px;
}
.oneTaurusNode .stat2 .paramName {
  width: 70px;
}
.oneTaurusNode .leftLines {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: end;
}
.oneTaurusNode .leftLines > div {
  width: 5px;
  margin-right: 1px;
}
.oneTaurusNode .readyLine {
  background-color: #a8a8a8;
  height: 100%;
}
.oneTaurusNode .cpuLine {
  background-color: #ff0000;
  height: 0;
}
.oneTaurusNode .ramLine {
  background-color: #0a55c6;
  height: 0;
}
.oneTaurusNode .nodeCounters {
  display: flex;
  font-weight: 500;
}
.oneTaurusNode .nodeCounters .nodeCalls {
  width: 40px;
  display: flex;
  margin-left: 10px;
  color: #43a2ff;
}
.oneTaurusNode .textButton {
  font-size: 12px;
}
.oneTaurusNode .textButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.oneTaurusNode .interceptionSettingsDiv {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 5px;
}
.oneTaurusNode .interceptionSettingsDiv .interceptTitle {
  width: 630px;
  font-weight: 500;
}
.oneTaurusNode .interceptionSettingsDiv .interceptNumbersInput {
  max-height: 30px;
}
.oneTaurusNode .nodeControlButton {
  background-color: #5e83ff;
  font-weight: 500;
  color: #fff;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.oneTaurusNode .nodeControlButton:hover {
  background-color: #3565ff;
}
.oneTaurusNode .coreButton {
  background-color: #b4b4b4;
  color: #fff;
  height: 24px;
  display: flex;
  align-items: center;
  font-family: 'JetBrainsMono', monospace;
  font-weight: 600;
  font-size: 16px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel {
  margin-top: 5px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelBody {
  display: flex;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelDiv {
  width: 375px;
  padding-left: 3px;
  display: flex;
  font-size: 16px;
  font-family: 'JetBrainsMono', monospace;
  font-weight: 600;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelName {
  padding-left: 5px;
  background-color: #b4b4b4;
  color: #fff;
  width: 300px;
  height: 24px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .activeCore {
  background-color: rgba(23, 162, 0, 0.82);
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .addGpuButton,
.oneTaurusNode .nodeConfiguration .oneNodeModel .addCpuButton {
  min-width: 50px;
  width: 50px;
  padding-left: 3px;
  margin-left: 3px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .divForGpuCores {
  display: flex;
  flex-direction: column;
  width: 350px;
  min-width: 350px;
  margin-left: 3px;
  gap: 3px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .divForGpuCores .oneGpuCore {
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .corePort {
  font-size: 14px;
  color: #efefef;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .removableCore {
  position: relative;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .removableCore .loadBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: red;
  width: 0;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .divForCpuCores {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-left: 3px;
  max-width: 900px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .divForCpuCores .oneCpuCore {
  width: 150px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic5secondsBar {
  background-color: red;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic1minuteBar {
  background-color: #dd00ff;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic10minuteBar {
  background-color: #0033ff;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic5seconds {
  color: red;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic1minutes {
  color: #dd00ff;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .traffic10minutes {
  color: #0033ff;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelMetrics {
  display: flex;
  height: 15px;
  padding-left: 5px;
  margin-top: 3px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelMetrics .bars {
  padding-left: 3px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .trafficNumbersDiv {
  display: flex;
  justify-content: space-between;
  width: 370px;
  font-weight: 500;
  padding-top: 2px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .trafficNumbersDiv > div {
  display: flex;
  align-items: center;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .trafficNumbersDiv .trafficNum {
  margin-left: 2px;
  line-height: 10px;
  width: 45px;
  display: flex;
  align-items: center;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .trafficBar {
  margin-top: 1px;
  height: 4px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelPerformanceInfo {
  display: flex;
  justify-content: space-between;
  width: 370px;
  font-weight: 500;
  padding-top: 2px;
  padding-left: 5px;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelPerformanceInfo > div {
  display: flex;
  align-items: center;
}
.oneTaurusNode .nodeConfiguration .oneNodeModel .modelPerformanceInfo .performanceBlock {
  margin-left: 2px;
  line-height: 10px;
  width: 45px;
  display: flex;
  align-items: center;
  color: #0dad00;
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore .whisperName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  height: 24px;
  background-color: #1cb2a0;
  width: 75px;
  color: #fff;
  font-size: 14px;
  margin-left: 3px;
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore .model {
  padding-left: 5px;
  background-color: #17d2bc;
  color: #fff;
  width: 350px;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore .divForCores {
  margin-left: 3px;
  width: 350px;
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore .activeCore {
  background-color: rgba(23, 162, 0, 0.82);
}
.oneTaurusNode .nodeWhisperConfiguration .oneWhisperCore .reservedCore {
  background-color: rgba(23, 162, 0, 0.44);
}
.oneTaurusNode .nodeWhisperConfiguration .oneGpuCore {
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oneTaurusNode .nodeWhisperConfiguration .corePort {
  font-size: 14px;
  color: #efefef;
}
.oneTaurusNode .nodeWhisperConfiguration .removableCore {
  position: relative;
}
.oneTaurusNode .nodeWhisperConfiguration .removableCore .loadBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #2c9b2a;
  width: 0;
}
.oneTaurusNode .nodeConfigButtonsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  font-weight: 400;
  align-items: end;
}
.oneProjectAndLocalesToRecord {
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}
.oneProjectAndLocalesToRecord input[type="text"] {
  max-height: 40px;
  margin-right: 15px;
  width: 300px;
}
.oneProjectAndLocalesToRecord .pseudo-button-remove {
  margin-top: 10px;
}
.oneModelHeader {
  display: flex;
  font-weight: 500;
}
.oneModelHeader .name {
  margin-left: 20px;
  width: 300px;
}
.oneModelHeader .defaultLocale {
  width: 100px;
}
.oneModelHeader .sizeInMb {
  width: 100px;
}
.taurusModelsWrap .oneModel {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  box-shadow: #717171 0px 0px 7px -2px;
  padding: 10px;
}
.taurusModelsWrap .oneModel .removeButton {
  height: 20px;
}
.addModelToLocalePopupBody .oneModelHeader {
  margin-top: 10px;
  box-shadow: #717171 0px 0px 7px -2px;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
}
.gpuInfoDiv {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.oneGpuInfoDiv {
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  height: 20px;
}
.oneGpuInfoDiv .gpuNumDiv {
  margin-left: 5px;
  width: 60px;
  display: flex;
  align-items: center;
}
.oneGpuInfoDiv .gpuName {
  width: 220px;
}
.oneGpuInfoDiv .divForFanSpeedBar {
  margin-left: 10px;
  width: 100px;
}
.oneGpuInfoDiv .divForTempBar {
  margin-left: 10px;
  width: 100px;
}
.oneGpuInfoDiv .divForVramBar {
  margin-left: 10px;
  width: 200px;
}
.oneGpuInfoDiv .divForPowerBar {
  margin-left: 10px;
  width: 200px;
}
.oneGpuInfoDiv .divForGpuBar {
  margin-left: 10px;
  width: 200px;
}
.oneGpuInfoDiv .oneGpuProgressBar {
  height: 18px;
  position: relative;
  width: 100%;
  background-color: #b4b4b4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.oneGpuInfoDiv .oneGpuProgressBar .progressBarText {
  color: #fff;
  z-index: 1;
  white-space: pre;
}
.oneGpuInfoDiv .oneGpuProgressBar .progressBarBackground {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 0, 0, 0.58);
}
.oneModelLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  height: 24px;
  background-color: #5e83ff;
  width: 75px;
  color: #fff;
  font-size: 14px;
}
.taurusModelModalBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
}
.taurusModelModalBody .smallButton {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  width: 340px;
}
.taurusModelModalBody .offsetDiv {
  display: flex;
  gap: 10px;
}
.taurusModelModalBody .offsetDiv input {
  height: 30px;
  width: 90px;
}
.taurusModelModalBody .offsetDiv .smallButton {
  width: 240px;
}
.sendpulseCrmAuthBlock .sendpulseCrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.sendpulseCrmAuthBlock .sendpulseCrmAuthField > div:first-child {
  width: 40px;
  margin-right: 10px;
}
.sendpulseCrmAuthBlock .sendpulseCrmAuthField > div:last-child {
  width: 200px;
}
.sendpulseCrmAuthBlock .sendpulseCrmAuthField input {
  height: 35px;
}
.sendpulseCrmAuthBlock .sendpulseCrmAuthField span {
  margin-right: 2px;
}
.sendpulseCrmAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .sendpulseCrmAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.sendpulseCrmAuthBlock .sendpulseCrmEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.sendpulseCrmAuthBlock .sendpulseCrmEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.sendpulseCrmSettingsBlock > div {
  margin-bottom: 12px;
}
.sendpulseCrmSettingsBlock .dropdown-single-button,
.sendpulseCrmSettingsBlock .webra-input-field {
  height: 35px;
}
.sendpulseCrmSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.sendpulseCrmSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.sendpulseCrmSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.sendpulseCrmSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.sendpulseCrmSettingsBlock .mainSettings .defaultBoardSelectsDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.sendpulseCrmSettingsBlock .mainSettings .defaultBoardSelectsDiv .dropdown-single-button {
  width: 200px;
}
.sendpulseCrmSettingsBlock .mainSettings .defaultBoardSelectsDiv .defaultBoardSelect {
  margin-right: 10px;
}
.sendpulseCrmSettingsBlock .mainSettings .defaultBoardSelectsDiv .defaultBoardSelectsSpanDiv {
  width: 50px;
  margin-right: 10px;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.sendpulseCrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.sendpulseCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.sendpulseCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.sendpulseCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.sendpulseCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.sendpulseCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.sendpulseCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.sendpulseCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.sendpulseCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv,
.sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv > div:first-child,
.sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .dropdown-single-button,
.sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .dropdown-single-button {
  width: 340px;
}
.sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .pipelineSelect,
.sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .pipelineSelect {
  margin-right: 10px;
}
.sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv span,
.sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1087px) {
  .sendpulseCrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv,
  .sendpulseCrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .webhookKeyInputDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv > div:first-child,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate > div:first-child,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv > div:first-child,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .webhookKeyInputDiv > div:first-child {
  margin-right: 10px;
  width: 315px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv .dropdown-single-button,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate .dropdown-single-button,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv .dropdown-single-button,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .webhookKeyInputDiv .dropdown-single-button,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv .webhookKeyInput,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate .webhookKeyInput,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv .webhookKeyInput,
.sendpulseCrmSettingsBlock .webhookSettingsBlock .webhookKeyInputDiv .webhookKeyInput {
  width: 370px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonsDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonsDiv button {
  margin-bottom: 10px;
  width: 500px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonsDiv .dealCreateWebhookUrlCopyButton {
  margin-bottom: 10px;
}
.sendpulseCrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonsDiv .dealStatusChangeWebhookUrlCopyButton {
  margin-bottom: 20px;
}
@media (max-width: 882px) {
  .sendpulseCrmSettingsBlock .webhookSettingsBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  .sendpulseCrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv {
    display: none;
  }
}
.workWithBalanceColumnsContainer {
  display: flex;
}
.workWithBalanceColumnsContainer .inputField {
  height: 35px;
  width: 170px;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn {
  margin: 5px 20px 15px 20px;
  width: 200px;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv .next-checkbox-square {
  margin-bottom: 18px;
  margin-left: 10px;
  padding-left: 0px;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv .statusIndicator {
  cursor: default;
  pointer-events: none;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .buttonsDiv {
  display: table-row;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .buttonsDiv > * {
  margin-top: 5px;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .descriptionPropertyDiv {
  position: relative;
}
.workWithBalanceColumnsContainer .workWithBalanceColumn .descriptionPropertyDiv .inputPlaceholder {
  position: absolute;
  top: 8px;
  right: 40px;
}
.creditsDiv {
  padding: 0;
  margin-top: 15px;
}
.creditsDiv .workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv {
  position: relative;
}
.creditsDiv .workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv .alreadyGaveDiv {
  display: flex;
  font-size: 12px;
  color: #6c6c6c;
  position: absolute;
  top: 27px;
}
.creditsDiv .workWithBalanceColumnsContainer .workWithBalanceColumn .headerDiv .alreadyGaveDiv .alreadyGaveMoneyStyle {
  margin-left: 5px;
}
.workWithBalanceButton {
  height: 35px;
  width: 170px;
  line-height: 35px;
  font-size: 17px;
  color: #fff;
  border: none;
  padding: 0 25px;
  font-weight: 500;
  background-color: #36af38;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.balanceOperationContent {
  overflow-x: auto;
}
.balanceOperationContent .balanceOperationTable {
  min-width: fit-content;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(1) {
  min-width: 200px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(2) {
  min-width: 200px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(3) {
  min-width: 100px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(4) {
  min-width: 100px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(5) {
  min-width: 100px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(6) {
  min-width: 100px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(7) {
  min-width: 100px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(8) {
  min-width: 200px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(9) {
  min-width: 200px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(10) {
  max-width: 300px;
  min-width: 300px;
  word-wrap: break-word;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(11) {
  min-width: 240px;
}
.balanceOperationContent .balanceOperationTable .tableCol:nth-child(12) {
  min-width: 200px;
}
.balanceOperationSettingsBlock {
  background-color: white;
  box-shadow: 0px 0px 7px -2px #7F7F7F !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.balanceOperationSettingsBlock .oneOperation {
  margin-top: 15px;
  margin-bottom: 15px;
}
.balanceOperationSettingsBlock .realMoneyOperation,
.balanceOperationSettingsBlock .fakeMoneyOperation,
.balanceOperationSettingsBlock .withdrawalOperation {
  display: flex;
  align-items: center;
}
.balanceOperationSettingsBlock .realMoneyOperation .oneOperationRadio,
.balanceOperationSettingsBlock .fakeMoneyOperation .oneOperationRadio,
.balanceOperationSettingsBlock .withdrawalOperation .oneOperationRadio {
  width: 250px;
}
.balanceOperationSettingsBlock .realMoneyOperation .commentaryTextArea,
.balanceOperationSettingsBlock .fakeMoneyOperation .commentaryTextArea,
.balanceOperationSettingsBlock .withdrawalOperation .commentaryTextArea,
.balanceOperationSettingsBlock .realMoneyOperation .withdrawalCommentaryTextArea,
.balanceOperationSettingsBlock .fakeMoneyOperation .withdrawalCommentaryTextArea,
.balanceOperationSettingsBlock .withdrawalOperation .withdrawalCommentaryTextArea,
.balanceOperationSettingsBlock .realMoneyOperation .notRealCommentaryTextArea,
.balanceOperationSettingsBlock .fakeMoneyOperation .notRealCommentaryTextArea,
.balanceOperationSettingsBlock .withdrawalOperation .notRealCommentaryTextArea {
  height: 45px;
  max-height: 300px;
  max-width: 300px;
}
.balanceOperationSettingsBlock .balanceOperationDate > div:first-child {
  float: left;
  line-height: 30px;
  font-weight: 500;
  padding: 5px;
  margin-left: 8px;
  cursor: default;
}
.balanceOperationSettingsBlock .balanceOperationDate > div:last-child {
  float: right;
}
.balanceOperationSettingsBlock .balanceOperationDate > div:last-child .transactionDatePicker {
  height: 40px;
  width: 140px;
  padding: 5px;
  border: none;
  cursor: pointer;
}
.balanceOperationSettingsBlock .oneInputOperationSetting,
.balanceOperationSettingsBlock .oneDropdownOperationSetting {
  margin-left: 50px;
  margin-bottom: 10px;
  width: 300px;
}
.balanceOperationSettingsBlock .balanceOperationsDiv {
  display: flex;
  flex-wrap: wrap;
}
.balanceOperationSettingsBlock .balanceOperationsDiv .oneDropdownOperationSetting {
  width: 300px;
  height: 45px;
}
.balanceOperationSettingsBlock .workWithBalanceButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 30px;
}
.balanceOperationSettingsBlock .workWithBalanceButtons .sumInputBox {
  position: relative;
  height: 35px;
  width: 200px;
  display: flex;
  align-items: center;
  border: 2px solid #e8e8e8;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}
.balanceOperationSettingsBlock .workWithBalanceButtons .sumInputBox .operationProjectCurrency {
  position: absolute;
  right: 5px;
}
.balanceOperationSettingsBlock .workWithBalanceButtons .sumInputBox .operationSumInput {
  border: none;
  color: #777777;
}
.balanceOperationSettingsBlock .workWithBalanceButtons .transactionActionButton {
  margin-left: 10px;
}
.workWithBalanceFilters {
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 5px;
}
.workWithBalanceFilters .workWithBalanceDateFilter {
  background-color: #fff;
  text-align: center;
}
.workWithBalanceFilters .workWithBalanceDateFilter .workWithBalanceDatePeriod {
  float: left;
  line-height: 30px;
  font-weight: 500;
  padding: 10px;
  cursor: default;
}
.workWithBalanceFilters .workWithBalanceDateFilter .workWithBalanceDateInput {
  float: left;
  width: 270px;
  height: 100%;
}
.workWithBalanceFilters .workWithBalanceDateFilter .workWithBalanceDateInput .workWithBalanceDatePicker {
  border: none;
  height: 100%;
  width: 100%;
}
.workWithBalanceFilters .workWithBalanceEmployeeTransactionsFilter {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.kerujAuthBlock .kerujIntegrationKeyDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.kerujAuthBlock .kerujIntegrationKeyDiv .kerujIntegrationKey:hover {
  cursor: pointer;
}
.kerujAuthBlock .kerujIntegrationKeyDiv > div:first-child {
  width: 180px;
  margin-right: 10px;
}
.kerujAuthBlock .kerujIntegrationKeyDiv > div:last-child {
  width: 320px;
}
.kerujAuthBlock .kerujIntegrationKeyDiv input {
  height: 35px;
}
.kerujAuthBlock .kerujIntegrationKeyDiv span {
  margin-right: 2px;
}
.kerujAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .kerujAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.kerujAuthBlock .kerujEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.kerujAuthBlock .kerujEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.kerujSettingsBlock > div {
  margin-bottom: 12px;
}
.kerujSettingsBlock .dropdown-single-button,
.kerujSettingsBlock .webra-input-field {
  height: 35px;
}
.kerujSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.kerujSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.kerujSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.kerujSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.kerujSettingsBlock .mainSettings .defaultProjectSelectDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.kerujSettingsBlock .mainSettings .defaultProjectSelectDiv .dropdown-single-button {
  width: 330px;
}
.kerujSettingsBlock .mainSettings .defaultProjectSelectDiv .defaultProjectSelect {
  margin-right: 10px;
}
.kerujSettingsBlock .mainSettings .defaultProjectSelectDiv .defaultProjectSelectSpanDiv {
  width: 70px;
  margin-right: 10px;
}
.kerujSettingsBlock .mainSettings .defaultProjectSelectDiv .defaultProjectSelectSpanDiv span {
  margin-right: 2px;
}
.kerujSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.kerujSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.kerujSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.kerujSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.kerujSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.kerujSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.kerujSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.kerujSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 1017px) {
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate,
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .opportunitiesColumnNameDiv,
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .opportunitiesColumnNameDiv {
    display: none;
  }
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
  .kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .dropdown-single-button {
    margin-bottom: 5px;
  }
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate > div:first-child,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv > div:first-child {
  margin-right: 10px;
  width: 170px;
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .dropdown-single-button,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .opportunitiesColumnNameDiv,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .opportunitiesColumnNameDiv {
  width: 200px;
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .pipelineSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .pipelineSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .phaseSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .phaseSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .sourceSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .sourceSelect,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .opportunitiesColumnNameDiv,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .opportunitiesColumnNameDiv {
  margin-right: 10px;
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate span,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv span {
  margin-right: 2px;
}
.kerujSettingsBlock .opportunitiesByPipelinesBlock .oneRowSelectsTemplate .opportunitiesColumnNameDiv,
.kerujSettingsBlock .opportunitiesByPipelinesBlock .opportunitiesColumnsNamesDiv .opportunitiesColumnNameDiv {
  text-align: center;
}
.generateDataLakeTokenTemplate h3,
.generateDataLakeTokenTemplate .tokenSpan {
  color: #0089ff;
  font-weight: 500;
}
.notConnectedDataLakeTemplateMainDiv > div {
  margin-bottom: 10px;
}
.notConnectedDataLakeTemplateMainDiv input {
  width: 300px;
}
.notConnectedDataLakeTemplateMainDiv span {
  font-weight: 500;
}
.abillsAuthBlock .abillsAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.abillsAuthBlock .abillsAuthField > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.abillsAuthBlock .abillsAuthField > div:last-child {
  width: 600px;
}
.abillsAuthBlock .abillsAuthField input {
  height: 35px;
}
.abillsAuthBlock .abillsAuthField span {
  margin-right: 2px;
}
.abillsAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .abillsAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.abillsAuthBlock .abillsEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.abillsAuthBlock .abillsEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.abillsSettingsBlock > div {
  margin-bottom: 12px;
}
.abillsSettingsBlock .dropdown-single-button,
.abillsSettingsBlock .webra-input-field {
  height: 35px;
}
.abillsSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.abillsSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.abillsSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.abillsSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.abillsSettingsBlock .mainSettings .usersIdsDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media (max-width: 1025px) {
  .abillsSettingsBlock .mainSettings .usersIdsDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .abillsSettingsBlock .mainSettings .usersIdsDiv > div {
    margin-bottom: 5px;
  }
  .abillsSettingsBlock .mainSettings .usersIdsDiv .usersIdsInput {
    width: 65%;
  }
}
.abillsSettingsBlock .mainSettings .usersIdsDiv > div:first-child {
  width: 255px;
  margin-right: 10px;
}
.abillsSettingsBlock .mainSettings .usersIdsDiv > div:last-child {
  width: 600px;
}
.abillsSettingsBlock .mainSettings .usersIdsDiv input {
  height: 35px;
}
.abillsSettingsBlock .mainSettings .usersIdsDiv span {
  margin-right: 2px;
}
.abillsSettingsBlock .mainSettings .closedLeadStepsDiv {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 607px) {
  .abillsSettingsBlock .mainSettings .closedLeadStepsDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .abillsSettingsBlock .mainSettings .closedLeadStepsDiv > div {
    margin-bottom: 5px;
  }
}
.abillsSettingsBlock .mainSettings .closedLeadStepsDiv .closedLeadStepsSelectSpanDiv {
  width: 160px;
  margin-right: 10px;
}
.abillsSettingsBlock .mainSettings .closedLeadStepsDiv .dropdown-single-button {
  width: 320px;
}
.abillsSettingsBlock .mainSettings .closedLeadStepsDiv .dropdown-single-button select {
  height: 35px;
}
.abillsSettingsBlock .mainSettings .commentSelectsDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 807px) {
  .abillsSettingsBlock .mainSettings .commentSelectsDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .abillsSettingsBlock .mainSettings .commentSelectsDiv > div {
    margin-bottom: 5px;
  }
}
.abillsSettingsBlock .mainSettings .commentSelectsDiv .commentPrioritySelect {
  width: 150px;
}
.abillsSettingsBlock .mainSettings .commentSelectsDiv .commentChapterSelect {
  width: 220px;
  margin-right: 10px;
}
.abillsSettingsBlock .mainSettings .commentSelectsDiv .commentChapterSpanDiv {
  width: 140px;
  margin-right: 10px;
}
.abillsSettingsBlock .mainSettings .commentSelectsDiv .commentPrioritySpanDiv {
  width: 90px;
}
.abillsSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldsHeader a {
  font-weight: 300;
  font-size: 14px;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldTemplate > div {
  margin-right: 5px;
  width: 310px;
}
.abillsSettingsBlock .customFieldsBlockTemplate .customFieldTemplate input {
  height: 35px;
}
.abillsSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.abillsSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.abillsSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.abillsSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.abillsSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.abillsSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.abillsSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.abillsSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv > div:first-child,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv > div:first-child,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadPriorityColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadPriorityColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadPriorityColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadPrioritySelect,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadPrioritySelect,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadPrioritySelect {
  width: 170px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .contactGroupColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .contactGroupColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .contactGroupColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .contactGroupSelect,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .contactGroupSelect,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .contactGroupSelect {
  width: 230px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadStepColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadStepColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadStepColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadStepSelect,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadStepSelect,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadStepSelect {
  width: 320px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
  margin-right: 10px;
}
.abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv span,
.abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv span,
.abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1147px) {
  .abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
  .abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
  .abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
  }
  .abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
  .abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
  .abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
    display: none;
  }
  .abillsSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
  .abillsSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
  .abillsSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button {
    margin-bottom: 5px;
    width: 320px;
  }
}
.buyerIdMainDiv {
  width: 800px;
}
.buyerIdMainDiv .privateOrLegalPersonRadio {
  display: flex;
  align-items: center;
}
.buyerIdMainDiv .privateOrLegalPersonRadio .payTypeDiv {
  display: flex;
  align-items: center;
}
.buyerIdMainDiv .privateOrLegalPersonRadio .payTypeDiv .paramValue {
  margin-left: 10px;
}
.buyerIdMainDiv .privateOrLegalPersonRadio .payTypeDiv .paramValue .dropdownBuyerIdPageSize {
  height: 40px;
  width: 150px;
  border: 2px solid #e8e8e8;
  border-radius: 8px !important;
}
.buyerIdMainDiv .privateOrLegalPersonRadio .payTypeDiv .paramValue .dropdownBuyerIdPageSize button {
  border-radius: 8px !important;
}
.buyerIdMainDiv .privateOrLegalPersonRadio .payTypeDiv .paramValue .dropdownBuyerIdPageSize span {
  font-weight: 100;
}
.buyerIdMainDiv .buyerGeneralForm {
  display: flex;
  flex-direction: column;
}
.buyerIdMainDiv .buyerGeneralForm.legalPersonForm > * .legalPersonComponent {
  display: flex;
  flex-direction: column;
}
.buyerIdMainDiv .buyerGeneralForm.legalPersonForm > * .privatePersonComponent {
  display: none;
}
.buyerIdMainDiv .buyerGeneralForm.privatePersonForm > * .privatePersonComponent {
  display: flex;
  flex-direction: column;
}
.buyerIdMainDiv .buyerGeneralForm.privatePersonForm .legalPersonComponent {
  display: none;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine {
  display: flex;
  gap: 5px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine button {
  border-radius: 8px !important;
  padding: 8px 16px !important;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine button:focus {
  outline: none !important;
  border-color: #78b4f8 !important;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6) !important;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .buyerButtonDiv {
  flex-direction: column;
  padding-bottom: 6px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .buyerButtonDiv .buyerButton {
  height: 40px;
  line-height: 25px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .buyerButtonDiv .sendVatPayerFormButton {
  margin-top: 10px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .buyerButtonDiv .continueButton {
  margin-top: 10px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion {
  flex-grow: 1;
  margin-top: 15px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion.companyOrPersonNameDiv {
  flex-grow: 2;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion.additionalVatNumber1Div {
  width: 80px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion.notVisible {
  display: none;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramName {
  text-align: left;
  margin-left: 7px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue {
  width: 100%;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue .dropdownBuyerIdPageSize {
  height: 40px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue span {
  font-weight: 100;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue input[type="text"] {
  font-weight: normal;
  font-size: 17px;
  border: 2px solid #e8e8e8;
  height: 40px;
  border-radius: 8px;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue input[type="text"]:focus {
  outline: none;
  border-color: #78b4f8;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}
.buyerIdMainDiv .buyerGeneralForm .buyerLine .oneQuestion .paramValue input[type="text"]:disabled {
  background-color: #f0f0f0;
  opacity: 0.8;
}
.odooCrmAuthBlock .odooCrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.odooCrmAuthBlock .odooCrmAuthField > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.odooCrmAuthBlock .odooCrmAuthField > div:last-child {
  width: 600px;
}
.odooCrmAuthBlock .odooCrmAuthField input {
  height: 35px;
}
.odooCrmAuthBlock .odooCrmAuthField span {
  margin-right: 2px;
}
.odooCrmAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .odooCrmAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.odooCrmAuthBlock .odooCrmEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.odooCrmAuthBlock .odooCrmEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.odooCrmSettingsBlock > div {
  margin-bottom: 12px;
}
.odooCrmSettingsBlock .dropdown-single-button,
.odooCrmSettingsBlock .webra-input-field {
  height: 35px;
}
.odooCrmSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.odooCrmSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.odooCrmSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.odooCrmSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.odooCrmSettingsBlock .mainSettings .dropdown-single-button {
  width: 200px;
}
.odooCrmSettingsBlock .mainSettings .createLeadsOrOpportunitiesDiv {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 3px;
}
.odooCrmSettingsBlock .mainSettings .createLeadsOrOpportunitiesDiv > div:first-child {
  width: 300px;
  margin-right: 10px;
}
.odooCrmSettingsBlock .mainSettings .leadPrioritySelectDiv,
.odooCrmSettingsBlock .mainSettings .createOpportunitySettings {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.odooCrmSettingsBlock .mainSettings .leadPrioritySelectDiv > div:first-child,
.odooCrmSettingsBlock .mainSettings .createOpportunitySettings > div:first-child {
  width: 300px;
  margin-right: 10px;
}
@media (max-width: 532px) {
  .odooCrmSettingsBlock .mainSettings .leadPrioritySelectDiv,
  .odooCrmSettingsBlock .mainSettings .createOpportunitySettings {
    flex-direction: column;
    align-items: flex-start;
  }
  .odooCrmSettingsBlock .mainSettings .leadPrioritySelectDiv > div,
  .odooCrmSettingsBlock .mainSettings .createOpportunitySettings > div {
    margin-bottom: 5px;
  }
}
.odooCrmSettingsBlock .mainSettings .next-checkbox-square {
  display: inline-block;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock {
  margin-bottom: 12px;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsHeader {
  display: flex;
  align-items: center;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.odooCrmSettingsBlock .leadCustomFieldsBlock .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.odooCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.odooCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.odooCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.odooCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.odooCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.odooCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.odooCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.odooCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv > div:first-child,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv > div:first-child,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineSelect,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineSelect,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineSelect {
  width: 170px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadSourceColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadSourceColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadSourceColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .leadSourceSelect,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .leadSourceSelect,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .leadSourceSelect {
  width: 230px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
  margin-right: 10px;
}
.odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv span,
.odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv span,
.odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 816px) {
  .odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
  }
  .odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
    display: none;
  }
  .odooCrmSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
  .odooCrmSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button {
    margin-bottom: 5px;
    width: 230px;
  }
}
.mainEnvsDiv {
  display: flex;
  flex-wrap: wrap;
}
.mainEnvsDiv .oneEnv {
  background-color: #fff;
  box-shadow: 0px 0px 7px -2px #7F7F7F !important;
  min-width: 100%;
  max-width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (min-width: 1500px) {
  .mainEnvsDiv .oneEnv {
    max-width: 48.5%;
    min-width: 48.5%;
  }
}
.mainEnvsDiv .oneEnv .envHeader {
  display: flex;
  border-bottom: solid 1px #ececec;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.mainEnvsDiv .oneEnv .envHeader .controls {
  display: none;
  height: 100%;
}
.mainEnvsDiv .oneEnv .envHeader .controls .controlButton {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.mainEnvsDiv .oneEnv .envHeader .controls .takeButton {
  background-color: rgba(11, 125, 218, 0.35);
}
.mainEnvsDiv .oneEnv .envHeader .controls .takeButton:hover {
  background-color: #0b7dda;
  color: #fff;
}
.mainEnvsDiv .oneEnv .envHeader .controls .releaseButton {
  background-color: rgba(10, 198, 48, 0.35);
}
.mainEnvsDiv .oneEnv .envHeader .controls .releaseButton:hover {
  color: #fff;
  background-color: #0ac630;
}
.mainEnvsDiv .oneEnv .envHeader .controls .descriptionInput {
  border: none;
  width: 300px;
}
.mainEnvsDiv .oneEnv .envHeader .controls .descriptionInput::placeholder {
  color: #fff;
}
.mainEnvsDiv .oneEnv .envHeader .controls .takeWrap {
  display: flex;
}
.mainEnvsDiv .oneEnv .envHeader .controls .takeWrap:hover .descriptionInput {
  border: none;
  width: 300px;
}
.mainEnvsDiv .oneEnv .envHeader .controls .takeWrap:hover .descriptionInput::placeholder {
  color: rgba(0, 0, 0, 0.29);
}
.mainEnvsDiv .oneEnv.changed .envHeader .controls {
  display: flex;
}
.mainEnvsDiv .oneEnv .envName {
  font-size: 20px;
  font-weight: 500;
  padding: 5px;
  padding-left: 10px;
}
.mainEnvsDiv .oneEnv .oneService {
  padding: 3px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  transition: 0.15s all ease;
  cursor: pointer;
}
.mainEnvsDiv .oneEnv .oneService:hover {
  background-color: #f8f8f8;
  padding-left: 15px;
}
.mainEnvsDiv .oneEnv .oneService.free {
  color: #0ac630;
}
.mainEnvsDiv .oneEnv .oneService .serviceName {
  width: 80px;
}
.mainEnvsDiv .oneEnv .oneService .busyBy {
  width: 180px;
}
.mainEnvsDiv .oneEnv .oneService .busyFrom {
  width: 150px;
}
.mainEnvsDiv .oneEnv .oneService.selected {
  padding-left: 30px;
  background-color: #f8f8f8;
}
.atlasTrunksMainScreen .rowsHeader {
  display: flex;
  padding-left: 150px;
}
.atlasTrunksMainScreen .rowsHeader .envTabs {
  display: flex;
}
.atlasTrunksMainScreen .rowsHeader .envTabs .oneEnvTab {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  margin-right: 2px;
  font-weight: 500;
  color: #7F7F7F;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  user-select: none;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
}
.atlasTrunksMainScreen .oneTrunk {
  margin-top: 1px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
  transition: opacity 0.1s ease;
  position: relative;
  background-color: #fff;
  line-height: 14px;
  font-size: 15px;
  font-family: 'Droid Sans Mono', arial;
  font-weight: 600;
}
.atlasTrunksMainScreen .oneTrunk:hover {
  background-color: #f8f8f8;
}
.atlasTrunksMainScreen .oneTrunk .trunkHeader {
  display: flex;
  height: 100%;
}
.atlasTrunksMainScreen .oneTrunk .trunkHeader .trunkName {
  display: flex;
  width: 150px;
  align-items: center;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData {
  display: flex;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData {
  align-items: center;
  position: relative;
  width: 250px;
  display: flex;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData:hover {
  background-color: #f1f1f1;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData .registerStatus {
  color: #0ac630;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData.inactiveKam .projectName {
  color: #a2a2a2;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData.registered .projectName {
  color: #0ac630;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData .ownIconWrap,
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData .registeredIconWrap {
  width: 30px;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .oneEnvData.contextMenuSource {
  background-color: #e7e7e7;
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .noPhoneBackground {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 10;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-image: repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 40px, rgba(255, 255, 255, 0.15) 60px, rgba(255, 255, 255, 0.05) 60px, rgba(255, 255, 255, 0.05) 80px);
}
.atlasTrunksMainScreen .oneTrunk .divForEnvsData .noPhoneBackground > div {
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
  text-wrap: avoid;
  text-align: center;
}
.leelooAuthBlock .leelooAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.leelooAuthBlock .leelooAuthField > div:first-child {
  width: 160px;
  margin-right: 10px;
}
.leelooAuthBlock .leelooAuthField input {
  height: 35px;
  width: 910px;
}
.leelooAuthBlock .leelooAuthField span {
  margin-right: 2px;
}
@media (max-width: 1268px) {
  .leelooAuthBlock .leelooAuthField {
    flex-direction: column;
    align-items: flex-start;
  }
  .leelooAuthBlock .leelooAuthField input {
    width: 70vw;
    box-sizing: border-box;
  }
}
.leelooAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .leelooAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.leelooAuthBlock .leelooEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.leelooAuthBlock .leelooEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.leelooSettingsBlock > div {
  margin-bottom: 12px;
}
.leelooSettingsBlock .dropdown-single-button,
.leelooSettingsBlock .webra-input-field {
  height: 35px;
}
.leelooSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.leelooSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.leelooSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.leelooSettingsBlock .mainSettings > div:not(:first-child):not(.paymentSystemIdDiv):not(.offerIdDiv) {
  margin-bottom: 5px;
}
.leelooSettingsBlock .mainSettings .paymentSystemIdDiv,
.leelooSettingsBlock .mainSettings .offerIdDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media (max-width: 690px) {
  .leelooSettingsBlock .mainSettings .paymentSystemIdDiv,
  .leelooSettingsBlock .mainSettings .offerIdDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .leelooSettingsBlock .mainSettings .paymentSystemIdDiv > div,
  .leelooSettingsBlock .mainSettings .offerIdDiv > div {
    margin-bottom: 5px;
  }
}
.leelooSettingsBlock .mainSettings .paymentSystemIdDiv > div:first-child,
.leelooSettingsBlock .mainSettings .offerIdDiv > div:first-child {
  width: 280px;
  margin-right: 10px;
}
.leelooSettingsBlock .mainSettings .paymentSystemIdDiv > div:last-child,
.leelooSettingsBlock .mainSettings .offerIdDiv > div:last-child {
  width: 270px;
}
.leelooSettingsBlock .mainSettings .paymentSystemIdDiv input,
.leelooSettingsBlock .mainSettings .offerIdDiv input {
  height: 35px;
}
.leelooSettingsBlock .mainSettings .paymentSystemIdDiv span,
.leelooSettingsBlock .mainSettings .offerIdDiv span {
  margin-right: 2px;
}
.leelooSettingsBlock .contactCustomFieldsBlock {
  margin-bottom: 12px;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldsHeader {
  display: flex;
  align-items: center;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldsHeader a {
  font-weight: 300;
  font-size: 14px;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldTemplate > div {
  margin-right: 5px;
  width: 310px;
}
.leelooSettingsBlock .contactCustomFieldsBlock .customFieldTemplate input {
  height: 35px;
}
.leelooSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.leelooSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.leelooSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.leelooSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.leelooSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.leelooSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.leelooSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.leelooSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv > div:first-child,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv > div:first-child,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineBlockIdInputDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineBlockIdInputDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineBlockIdInputDiv {
  width: 250px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
  margin-right: 10px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv span,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv span,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
.leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineBlockIdInputDiv ::placeholder,
.leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineBlockIdInputDiv ::placeholder,
.leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineBlockIdInputDiv ::placeholder {
  opacity: 0;
}
@media (max-width: 1437px) {
  .leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
  }
  .leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
    display: none;
  }
  .leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
  .leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
  .leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
  .leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineBlockIdInputDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineBlockIdInputDiv,
  .leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineBlockIdInputDiv {
    margin-bottom: 5px;
    width: 320px;
  }
  .leelooSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .pipelineBlockIdInputDiv ::placeholder,
  .leelooSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .pipelineBlockIdInputDiv ::placeholder,
  .leelooSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .pipelineBlockIdInputDiv ::placeholder {
    opacity: 1;
  }
}
.voipTimeCrmAuthBlock .voipTimeCrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.voipTimeCrmAuthBlock .voipTimeCrmAuthField > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.voipTimeCrmAuthBlock .voipTimeCrmAuthField > div:last-child {
  width: 600px;
}
.voipTimeCrmAuthBlock .voipTimeCrmAuthField input {
  height: 35px;
}
.voipTimeCrmAuthBlock .voipTimeCrmAuthField span {
  margin-right: 2px;
}
.voipTimeCrmAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .voipTimeCrmAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.voipTimeCrmAuthBlock .voipTimeCrmEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.voipTimeCrmAuthBlock .voipTimeCrmEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.voipTimeCrmSettingsBlock > div {
  margin-bottom: 12px;
}
.voipTimeCrmSettingsBlock .dropdown-single-button,
.voipTimeCrmSettingsBlock .webra-input-field {
  height: 35px;
}
.voipTimeCrmSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.voipTimeCrmSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.voipTimeCrmSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.voipTimeCrmSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.voipTimeCrmSettingsBlock .mainSettings .divWithSelect {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 660px) {
  .voipTimeCrmSettingsBlock .mainSettings .divWithSelect {
    flex-direction: column;
    align-items: flex-start;
  }
  .voipTimeCrmSettingsBlock .mainSettings .divWithSelect > div {
    margin-bottom: 5px;
  }
}
.voipTimeCrmSettingsBlock .mainSettings .divWithSelect > div:first-child {
  width: 200px;
  margin-right: 10px;
}
.voipTimeCrmSettingsBlock .mainSettings .divWithSelect > div:last-child {
  width: 320px;
}
.voipTimeCrmSettingsBlock .mainSettings .divWithSelect > div:last-child select {
  height: 35px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader a {
  font-weight: 300;
  font-size: 14px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldTemplate > div {
  margin-right: 5px;
  width: 310px;
}
.voipTimeCrmSettingsBlock .customFieldsBlockTemplate .customFieldTemplate input {
  height: 35px;
}
.voipTimeCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.voipTimeCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.voipTimeCrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.voipTimeCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.voipTimeCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 160px;
}
.voipTimeCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 160px;
}
.voipTimeCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.voipTimeCrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.financesettingsMainDiv .financeTabsDiv {
  height: 35px;
  display: flex;
  border-bottom: 1px solid #7B91A7;
}
.financesettingsMainDiv .financeTabsDiv .unitab {
  border: 1px solid #7B91A7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 4px;
  background-color: #e4e6ec;
  white-space: nowrap;
  overflow: hidden;
}
.financesettingsMainDiv .financeTabsDiv .unitab:hover {
  background-color: #EDF1F5;
}
.financesettingsMainDiv .financeTabsDiv .unitab.active {
  color: #F04259;
  background-color: #EDF1F5;
  border-bottom: none;
}
.ncrmAuthBlock .ncrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ncrmAuthBlock .ncrmAuthField > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.ncrmAuthBlock .ncrmAuthField > div:last-child {
  width: 600px;
}
.ncrmAuthBlock .ncrmAuthField input {
  height: 35px;
}
.ncrmAuthBlock .ncrmAuthField span {
  margin-right: 2px;
}
.ncrmAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .ncrmAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ncrmAuthBlock .ncrmEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.ncrmAuthBlock .ncrmEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.ncrmSettingsBlock > div {
  margin-bottom: 12px;
}
.ncrmSettingsBlock .dropdown-single-button,
.ncrmSettingsBlock .webra-input-field {
  height: 35px;
}
.ncrmSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.ncrmSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.ncrmSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.ncrmSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.ncrmSettingsBlock .mainSettings .divWithSelect {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 635px) {
  .ncrmSettingsBlock .mainSettings .divWithSelect {
    flex-direction: column;
    align-items: flex-start;
  }
  .ncrmSettingsBlock .mainSettings .divWithSelect > div {
    margin-bottom: 5px;
  }
}
.ncrmSettingsBlock .mainSettings .divWithSelect > div:first-child {
  width: 170px;
  margin-right: 20px;
}
.ncrmSettingsBlock .mainSettings .divWithSelect > div:last-child {
  width: 320px;
}
.ncrmSettingsBlock .mainSettings .divWithSelect > div:last-child select {
  height: 35px;
}
.ncrmSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.ncrmSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.ncrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.ncrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.ncrmSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.ncrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.ncrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.ncrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.ncrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.ncrmSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv,
.ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv > div:first-child,
.ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv .dropdown-single-button,
.ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .dropdown-single-button {
  width: 340px;
}
.ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv .pipelineSelect,
.ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .pipelineSelect {
  margin-right: 10px;
}
.ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv span,
.ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1087px) {
  .ncrmSettingsBlock .dealsByStatusBlock .defaultPipelineAndStageDiv,
  .ncrmSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
}
.ncrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv,
.ncrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate,
.ncrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.ncrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv > div:first-child,
.ncrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate > div:first-child,
.ncrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv > div:first-child {
  margin-right: 10px;
  width: 350px;
}
.ncrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv .dropdown-single-button,
.ncrmSettingsBlock .webhookSettingsBlock .oneRowSelectTemplate .dropdown-single-button,
.ncrmSettingsBlock .webhookSettingsBlock .dealCreateActionSelectDiv .dropdown-single-button {
  width: 370px;
}
.ncrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ncrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ncrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonDiv button {
  margin-bottom: 10px;
  width: 350px;
}
.ncrmSettingsBlock .webhookSettingsBlock .WebhookUrlCopyButtonDiv .webhookUrlCopyButton {
  margin-bottom: 20px;
}
@media (max-width: 917px) {
  .ncrmSettingsBlock .webhookSettingsBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  .ncrmSettingsBlock .webhookSettingsBlock .columnsNamesDiv {
    display: none;
  }
}
.firmaoAuthBlock .firmaoAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.firmaoAuthBlock .firmaoAuthField > div:first-child {
  width: 200px;
  margin-right: 10px;
}
.firmaoAuthBlock .firmaoAuthField > div:last-child {
  width: 600px;
}
.firmaoAuthBlock .firmaoAuthField input {
  height: 35px;
}
.firmaoAuthBlock .firmaoAuthField span {
  margin-right: 2px;
}
.firmaoAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .firmaoAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.firmaoAuthBlock .firmaoEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.firmaoAuthBlock .firmaoEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.firmaoSettingsBlock > div {
  margin-bottom: 12px;
}
.firmaoSettingsBlock .dropdown-single-button,
.firmaoSettingsBlock .webra-input-field {
  height: 35px;
}
.firmaoSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.firmaoSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.firmaoSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.firmaoSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.firmaoSettingsBlock .mainSettings .divWithSelect {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
@media (max-width: 490px) {
  .firmaoSettingsBlock .mainSettings .divWithSelect {
    flex-direction: column;
    align-items: flex-start;
  }
  .firmaoSettingsBlock .mainSettings .divWithSelect > div {
    margin-bottom: 5px;
  }
}
.firmaoSettingsBlock .mainSettings .divWithSelect > div:first-child {
  width: 130px;
  margin-right: 20px;
}
.firmaoSettingsBlock .mainSettings .divWithSelect > div:last-child {
  width: 160px;
}
.firmaoSettingsBlock .mainSettings .divWithSelect > div:last-child select {
  height: 35px;
}
.firmaoSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.firmaoSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.firmaoSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.firmaoSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.firmaoSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.firmaoSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.firmaoSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.firmaoSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.firmaoSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.firmaoSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:last-child {
  width: 250px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv > div:first-child,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv > div:first-child,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .contactStatusColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .contactStatusColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .contactStatusColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .contactStatusSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .contactStatusSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .contactStatusSelect {
  width: 250px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dealStageColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dealStageColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dealStageColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dealStageSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dealStageSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dealStageSelect {
  width: 260px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .organizationColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .organizationColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .organizationColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .organizationSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .organizationSelect,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .organizationSelect {
  width: 240px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button,
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
  margin-right: 10px;
}
.firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv span,
.firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv span,
.firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1177px) {
  .firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv,
  .firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv,
  .firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
  }
  .firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
  .firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .entitiesColumnNameDiv,
  .firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
    display: none;
  }
  .firmaoSettingsBlock .entitiesPipelinesBlock .entitiesColumnsNamesDiv .dropdown-single-button,
  .firmaoSettingsBlock .entitiesPipelinesBlock .defaultPipelineDiv .dropdown-single-button,
  .firmaoSettingsBlock .entitiesPipelinesBlock .oneRowSelectsTemplate .dropdown-single-button {
    margin-bottom: 5px;
    width: 260px;
  }
}
.profitMainDiv .profitTabsDiv {
  margin-top: 20px;
  height: 30px;
  display: flex;
  gap: 10px;
  position: relative;
  border-bottom: 1px solid #007598;
}
.profitMainDiv .profitTabsDiv .unitab {
  border: #007598;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 30px;
  border: 1px solid #007598;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.profitMainDiv .profitTabsDiv .unitab.active {
  background-color: #eeeeee;
  height: 30px;
  border-bottom: none;
}
.profitMainDiv .sections {
  margin-top: 10px;
}
.profitMainDiv .filtersBar {
  display: flex;
  position: relative;
}
.profitMainDiv .filtersBar .webra-input-field::placeholder {
  color: #000;
}
.profitMainDiv .filtersBar .filtersDeactivator {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(237, 241, 245, 0.62);
}
.profitMainDiv .filtersBar .callHistoryFiltersDateWrap {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-right: 5px;
  width: 305px;
}
.profitMainDiv .filtersBar .callHistoryFiltersDateWrap .callHistoryFiltersDate {
  background-color: #fff;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 10px;
}
.profitMainDiv .filtersBar .callHistoryFiltersDateWrap .callHistoryFiltersDate input {
  min-width: 192px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  line-height: 20px;
  padding-top: 2px;
  height: 100%;
}
.profitMainDiv .filtersBar .refreshButton {
  background: #fff;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profitMainDiv .filtersBar .filterItem {
  height: 50px;
  background-color: #fff;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
}
.profitMainDiv .profitTable {
  --headerHeight: 112px;
  position: relative;
  min-height: calc(var(--vh) - var(--headerHeight));
  max-height: calc(var(--vh) - var(--headerHeight));
  overflow: scroll;
  background-color: #fff;
}
.profitMainDiv .profitTable .headerWrapper {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #fff;
  border-bottom: #dcdcdc 1px solid;
}
.profitMainDiv .profitTable .tableHeader {
  display: flex;
}
.profitMainDiv .profitTable .tableColumn {
  padding-left: 2px;
  padding-right: 2px;
  cursor: pointer;
  background-color: #fff;
}
.profitMainDiv .profitTable .tableColumn:hover {
  background-color: rgba(255, 139, 0, 0.15);
}
.profitMainDiv .profitTable .tableColumn:first-child {
  border-left: none;
}
.profitMainDiv .profitTable .tableHeaderSections {
  display: flex;
}
.profitMainDiv .profitTable .tableHeaderSections .tableColumn {
  display: flex;
  justify-content: center;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper .tableRow {
  height: 24px;
  display: flex;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper .tableRow .tablCell {
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  background-color: #fff;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper .tableRow .tablCell:first-child {
  border-left: none;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper .tableRow:hover {
  background-color: #f3f3f3;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper .mainRow:hover .tablCell {
  background-color: #cfcfcf;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper.opened .mainRow {
  background-color: #dddddd;
}
.profitMainDiv .profitTable .tableBody .tableRowWrapper.opened .mainRow .tablCell {
  background-color: #d8d8d8;
}
.gplusCrmBlock .gplusCrmAuthField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.gplusCrmBlock .gplusCrmAuthField > div:first-child {
  width: 200px;
  margin-right: 10px;
}
.gplusCrmBlock .gplusCrmAuthField > div:last-child {
  width: 600px;
}
.gplusCrmBlock .gplusCrmAuthField input {
  height: 35px;
}
.gplusCrmBlock .gplusCrmAuthField span {
  margin-right: 2px;
}
.gplusCrmBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .gplusCrmBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.gplusCrmBlock .gplusCrmEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.gplusCrmBlock .gplusCrmEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.gplusCrmSettingsBlock > div {
  margin-bottom: 12px;
}
.gplusCrmSettingsBlock .dropdown-single-button,
.gplusCrmSettingsBlock .webra-input-field {
  height: 35px;
}
.gplusCrmSettingsBlock .proxima-tooltip {
  margin-left: 0;
}
.gplusCrmSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.gplusCrmSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.gplusCrmSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.gplusCrmSettingsBlock .mainSettings .next-checkbox-square {
  display: inline-block;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock {
  margin-bottom: 12px;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdHeader {
  display: flex;
  align-items: center;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdHeader > div:first-child {
  margin-right: 15px;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdHeader a {
  font-weight: 300;
  font-size: 14px;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdTemplate > div {
  margin-right: 5px;
  width: 310px;
}
.gplusCrmSettingsBlock .operatorEmailAndIdBlock .operatorEmailAndIdTemplate input {
  height: 35px;
}
.unitalkZohoIntegrationMainDiv .unitalkZohoIntegrationContentDiv > div {
  margin-top: 20px;
}
.unitalkZohoIntegrationMainDiv .unitalkZohoButtonsDiv > div:not(:first-child) {
  margin-top: 15px;
}
.unitalkZohoIntegrationMainDiv .unitalkIntegrationNotConnectedWarning {
  color: red;
  font-weight: 500;
  font-size: 20px;
}
.unitalkZohoIntegrationMainDiv .paymentsAndSpendingsDateDiv {
  display: flex;
}
.unitalkZohoIntegrationMainDiv .paymentsAndSpendingsDateDiv > div {
  margin-right: 10px;
}
.unitalkZohoOneResponsibleTemplate {
  display: flex;
  align-items: center;
}
.unitalkZohoOneResponsibleTemplate > div:first-child {
  width: 250px;
  margin-right: 10px;
  font-weight: 500;
}
.biginAuthBlock .authBlockButtons {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .biginAuthBlock .authBlockButtons .button_default {
    width: 100%;
    margin-bottom: 10px;
  }
}
.biginAuthBlock .biginEnabledToggleDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.biginAuthBlock .biginEnabledToggleDiv > div:first-child {
  width: 120px;
  margin-right: 10px;
}
.biginSettingsBlock > div {
  margin-bottom: 12px;
}
.biginSettingsBlock .dropdown-single-button,
.biginSettingsBlock .webra-input-field {
  height: 35px;
}
.biginSettingsBlock .proxima-tooltip {
  margin-left: 0px;
}
.biginSettingsBlock .sector-header-div {
  margin-bottom: 20px;
}
.biginSettingsBlock .sectorHeaderTooltip {
  margin-left: 0;
}
.biginSettingsBlock .mainSettings > div:not(:first-child) {
  margin-bottom: 5px;
}
.biginSettingsBlock .mainSettings .taskPrioritySelectDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.biginSettingsBlock .mainSettings .taskPrioritySelectDiv .dropdown-single-button {
  width: 200px;
}
.biginSettingsBlock .mainSettings .taskPrioritySelectDiv .taskPrioritySelect {
  margin-right: 10px;
}
.biginSettingsBlock .mainSettings .taskPrioritySelectDiv .taskPrioritySelectSpanDiv {
  width: 140px;
  margin-right: 10px;
}
.biginSettingsBlock .customFieldsBlockTemplate {
  margin-bottom: 12px;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsHeader {
  display: flex;
  align-items: center;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsHeader > div:first-child {
  margin-right: 15px;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv {
  display: flex;
  flex-wrap: wrap;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField {
  width: 400px;
  padding: 5px;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldName {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  color: #00AEEF;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSetButton {
  margin-left: auto;
  color: #00AEEF;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixFieldSettingDiv {
  height: 35px;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixChoisesSelect {
  height: 100%;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixInputValue,
.biginSettingsBlock .customFieldsBlockTemplate .customFieldsDiv .bitrixOneField .bitrixTypeAndValueDiv .bitrixTypeSelect {
  width: 100%;
  height: 100%;
}
.biginSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.biginSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate > div:first-child {
  margin-right: 30px;
  width: 30px;
  font-size: 25px;
}
.biginSettingsBlock .responsibleScheduleBlock .responsibleScheduleRowTemplate .responsibleSelect {
  width: 270px;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock {
  display: flex;
  flex-wrap: wrap;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 150px;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child {
  margin-right: 20px;
  width: 150px;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate > div:first-child .nameSpan {
  overflow-wrap: break-word;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate .phoneNumbersInputDiv {
  margin-right: 50px;
  width: 250px;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate .biginClickToDialDiv {
  display: flex;
  align-items: center;
}
.biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate .biginClickToDialDiv > div:first-child {
  width: 90px;
  margin-right: 5px;
}
@media (max-width: 780px) {
  .biginSettingsBlock .operatorPhoneNumbersBlock .operatorsDataBlock .operatorPhoneNumbersTemplate {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
}
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv > div:first-child,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv > div:first-child,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate > div:first-child {
  margin-right: 10px;
  width: 200px;
}
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv .dropdown-single-button,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .dropdown-single-button,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .dropdown-single-button,
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .entitiesColumnNameDiv,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
  width: 340px;
}
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv .pipelineSelect,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .pipelineSelect,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .pipelineSelect,
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv .pipelineColumnNameDiv,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .pipelineColumnNameDiv,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .pipelineColumnNameDiv {
  margin-right: 10px;
}
.biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv span,
.biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv span,
.biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate span {
  margin-right: 2px;
}
@media (max-width: 1087px) {
  .biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv,
  .biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv,
  .biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  .biginSettingsBlock .dealsByStatusBlock .entitiesColumnsNamesDiv .entitiesColumnNameDiv,
  .biginSettingsBlock .dealsByStatusBlock .defaultPipelineAndStepDiv .entitiesColumnNameDiv,
  .biginSettingsBlock .dealsByStatusBlock .oneRowSelectsTemplate .entitiesColumnNameDiv {
    display: none;
  }
}
.emailMainDiv .ordersDivWithFilters .emailFilterInput {
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 10px 10px;
  background-color: #fff;
}
.emailMainDiv .countBar {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eae9ee;
}
.emailMainDiv .userEmailTable {
  background-color: #fff;
  overflow-y: scroll;
  border: 1px solid #E0E0E0;
  max-height: calc(100vh - 400px);
  min-height: calc(100vh - 400px);
}
.oneEmailRow {
  font-family: monospace;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.oneEmailRow .emailName {
  margin-left: 5px;
}
/*<editor-fold desc="табы переключалки в статистике, с2с">*/
.sttabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
}
.sticon::before {
  display: inline-block;
  margin: 0 0.4em 0 0;
  vertical-align: middle;
  font-size: 20px;
  speak: none;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sttabs nav {
  text-align: center;
}
.sttabs nav ul {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.sttabs nav ul li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.sttabs nav a {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.5;
}
.sttabs nav a span {
  vertical-align: middle;
  font-wight: 500;
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
}
.sttabs nav a:focus {
  outline: none;
}
/*.sttabs nav li.tab-current a {*/
/*color: #f44336;*/
/*}*/
.content-wrap {
  background: #ffffff;
}
.content-wrap {
  position: relative;
}
.content-wrap section {
  display: none;
  margin: 0 auto;
  padding: 25px;
  min-height: 150px;
}
.content-wrap section p {
  margin: 0;
  padding: 0.75em 0;
}
.content-wrap section.content-current {
  display: block;
}
/*</editor-fold>*/
/*<editor-fold desc="еще вкладки в статистике по операторам и балансе">*/
.tabs-style-bar nav ul li a {
  margin: 0 2px;
  background-color: #f7fafc;
  color: #686868;
  padding: 5px 0;
  transition: background-color 0.2s, color 0.2s;
}
.tabs-style-bar nav ul li a:hover,
.tabs-style-bar nav ul li a:focus {
  color: #f44336;
}
.tabs-style-bar nav ul li a span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.tabs-style-bar nav ul li.tab-current a {
  background: #fb9678;
  color: #fff;
}
.sttabs {
  overflow: inherit;
}
/*</editor-fold>*/
/*<editor-fold desc="Progressbars на странице трекинга и таймер уведомлений">*/
.progress {
  -webkit-box-shadow: none !important;
  background-color: rgba(120, 130, 140, 0.13);
  box-shadow: none !important;
  height: 4px;
  border-radius: 0px;
  margin-bottom: 18px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}
.progress.progress-sm {
  height: 8px !important;
}
.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress.progress-md {
  height: 15px !important;
}
.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress.progress-lg {
  height: 20px !important;
}
.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
.progress-bar-primary {
  background-color: #707cd2;
}
.progress-bar-success {
  background-color: #4caf50;
}
.progress-bar-info {
  background-color: #1e88e5;
}
.progress-bar-megna {
  background-color: #01c0c8;
}
.progress-bar-warning {
  background-color: #ff9800;
}
.progress-bar-danger {
  background-color: #f44336;
}
.progress-bar-inverse {
  background-color: #4c5667;
}
.progress-bar-purple {
  background-color: #707cd2;
}
.progress-bar-custom {
  background-color: #1e88e5;
}
.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  transition: 5s all;
}
/* Progressbar Animated */
@-webkit-keyframes myanimation {
  from {
    width: 0;
  }
}
@keyframes myanimation {
  from {
    width: 0;
  }
}
/*</editor-fold>*/
/*<editor-fold desc="Label. Метки юзаются на странице истории и зендеска">*/
.label {
  letter-spacing: 0.05em;
  border-radius: 60px;
  padding: 4px 12px 3px;
  font-weight: 500;
}
.label-rounded,
.label-rouded {
  border-radius: 60px;
  padding: 4px 12px 3px;
  font-weight: 500;
}
.label-custom {
  background-color: #01c0c8;
}
.label-success {
  background-color: #4caf50;
}
.label-info {
  background-color: #1e88e5;
}
.label-warning {
  background-color: #ff9800;
}
.label-danger {
  background-color: #f44336;
}
.label-megna {
  background-color: #01c0c8;
}
.label-primary {
  background-color: #707cd2;
}
.label-purple {
  background-color: #707cd2;
}
.label-red {
  background-color: #fb3a3a;
}
.label-inverse {
  background-color: #4c5667;
}
.label-white {
  background-color: #ffffff;
}
.label-default {
  background-color: #e4e7ea;
}
/*</editor-fold>*/
.nextel-click-to-call-full-screen-shadow {
  display: none;
}
.divForNextelButton .nextel-click-to-call-general-background-div {
  display: none !important;
}
.role-hide {
  display: none !important;
}
.dropdownNotInitialized {
  height: 45px;
  font-weight: 500;
  color: #333;
  padding: 10px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding-right: 18px;
  position: relative;
  line-height: 1.52857143;
  font-size: 14px;
}
.dropdownNotInitialized span {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
  margin-left: 0;
  content: "";
  border-top: 0;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px dashed;
}
.supportPhonesDiv {
  display: none;
}
.goldSupport .supportPhonesDiv,
.silverSupport .supportPhonesDiv {
  display: block;
}
.swal-icon--warning__dot {
  margin-left: -3.5px;
}
.swal-overlay--show-modal .swal-modal {
  will-change: unset;
}
.horizontal {
  display: flex;
}
.disabled {
  opacity: 0.5;
  position: relative;
}
.disabled:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}
@keyframes animationHorizontalShakeKf {
  10%,
  90% {
    transform: translate3d(-3px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(3px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}
.animationHorizontalShake {
  animation: animationHorizontalShakeKf 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.timeRangesSettings .oneRangeRow,
.timeRangesSettings .oneRangeRowDesc {
  display: flex;
  margin-bottom: 1px;
}
.timeRangesSettings .oneRangeRow .rangeHourDesc,
.timeRangesSettings .oneRangeRowDesc .rangeHourDesc {
  width: 21px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.timeRangesSettings .oneRangeRow .rangeDay,
.timeRangesSettings .oneRangeRowDesc .rangeDay {
  width: 100px;
  cursor: pointer;
}
.timeRangesSettings .oneRangeRow .rangeCheckboxes,
.timeRangesSettings .oneRangeRowDesc .rangeCheckboxes {
  display: flex;
}
.timeRangesSettings .oneRangeRow .rangeCheckboxes .next-checkbox-square,
.timeRangesSettings .oneRangeRowDesc .rangeCheckboxes .next-checkbox-square {
  padding-left: 21px;
}
.timeRangesSettings .oneRangeRow .rangeCheckboxes .next-checkbox-square .checkmark,
.timeRangesSettings .oneRangeRowDesc .rangeCheckboxes .next-checkbox-square .checkmark,
.timeRangesSettings .oneRangeRow .rangeCheckboxes .next-checkbox-square .checkmark:after,
.timeRangesSettings .oneRangeRowDesc .rangeCheckboxes .next-checkbox-square .checkmark:after {
  transition: 0.05s ease-in-out;
}
.timeRangesSettings .oneRangeRow label.highlight .checkmark,
.timeRangesSettings .oneRangeRowDesc label.highlight .checkmark {
  border: 2px solid #0010b0;
}
.timeRangesSettings .oneRangeRow label.highlight input:checked ~ .checkmark,
.timeRangesSettings .oneRangeRowDesc label.highlight input:checked ~ .checkmark {
  background-color: #0010b0;
  border: none;
}
.numberAutoChangeEmployeeSettings {
  width: 300px;
}
.numberAutoChangeEmployeeSettings .changeNumberCalculations > div {
  display: flex;
  justify-content: space-between;
}
.numberAutoChangeEmployeeSettings .changeNumberCalculations > div > div:last-child {
  font-weight: 500;
}
.callPricesPreviewDiv {
  --modalHeigth: 560px;
  --headerHeigth: 50px;
  --tabsHeigth: 50px;
  --sectionHeaderHeigth: 30px;
  --tablesHeaderHeigth: 40px;
  --tablesBodyHeigth: calc(var(--modalHeigth) - var(--headerHeigth) - var(--tabsHeigth) - var(--sectionHeaderHeigth) - var(--tablesHeaderHeigth) - 68px);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: var(--modalHeigth);
  background-color: #fff;
  border: 3px solid #e8e8e8;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.callPricesPreviewDiv .header {
  display: flex;
  height: var(--headerHeigth);
  align-items: center;
  font-size: 25px;
  padding: 10px;
}
.callPricesPreviewDiv .header .tariffText {
  font-size: 25px;
}
.callPricesPreviewDiv .header .tariffName {
  color: #e33351;
  font-size: 25px;
  margin-left: 20px;
  font-weight: 500;
}
.callPricesPreviewDiv .header .headerDesc {
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
}
.callPricesPreviewDiv .header .downloadXlsxButton {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  color: #1e88e5;
}
.callPricesPreviewDiv .header .downloadXlsxButton:hover {
  text-decoration: underline;
}
.callPricesPreviewDiv .header .closeButton {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 40px;
  height: 40px;
  background-color: #ff6060;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callPricesPreviewDiv .header .closeButton:hover {
  background-color: #f50101;
}
.callPricesPreviewDiv .bodyWithTabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
  height: 100%;
}
.callPricesPreviewDiv .bodyWithTabs .summaryDiv {
  width: 100%;
  padding-left: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.callPricesPreviewDiv .bodyWithTabs .summaryDiv .oneSummaryRow {
  display: flex;
  height: 15px;
}
.callPricesPreviewDiv .bodyWithTabs .summaryDiv .oneSummaryRow .desc {
  font-weight: 500;
  width: 250px;
}
.callPricesPreviewDiv .bodyWithTabs .tariffTabDiv {
  width: 100%;
  display: flex;
  height: var(--tabsHeigth);
  justify-content: space-around;
}
.callPricesPreviewDiv .bodyWithTabs .tariffTabDiv .tariffTab {
  width: 30%;
  border: 2px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #fff;
  z-index: 1;
  box-sizing: content-box;
  cursor: pointer;
  border-bottom: none;
}
.callPricesPreviewDiv .bodyWithTabs .tariffTabDiv .tariffTab:hover {
  background-color: #f8f8f8;
}
.callPricesPreviewDiv .bodyWithTabs .tariffTabDiv .tariffTab.active {
  color: red;
  border-bottom: 2px solid #ffffff;
  cursor: default;
}
.callPricesPreviewDiv .bodyWithTabs .tariffTabDiv .tariffTab.active:hover {
  background-color: #fff;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody {
  width: 100%;
  border-top: 2px solid #e8e8e8;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .bodySection {
  width: 100%;
  height: 100%;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .sectionHeader {
  height: var(--sectionHeaderHeigth);
  display: flex;
  align-items: center;
  justify-content: center;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .sectionHeader .goBackButton {
  color: #00AEEF;
  cursor: pointer;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .sectionHeader .goBackButton:hover {
  text-decoration: underline;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .sectionHeader .sectionCenter {
  display: flex;
  font-size: 18px;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .sectionHeader .sectionCenter .countryName {
  margin-left: 10px;
  font-weight: 500;
  color: red;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .localTariffBody .callPricesTable .callPricesBody {
  max-height: var(--tablesBodyHeigth);
  min-height: var(--tablesBodyHeigth);
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .internationalTariffBody .callPricesTable .callPricesRow {
  cursor: pointer;
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .internationalTariffBody .callPricesTable .callPricesBody {
  max-height: var(--tablesBodyHeigth);
  min-height: var(--tablesBodyHeigth);
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .numberTariffBody {
  display: flex;
  align-items: center;
  justify-content: center;
  --numberBodyHeigth: calc(var(--modalHeigth) - var(--headerHeigth) - var(--tabsHeigth) - 68px);
  max-height: var(--numberBodyHeigth);
  min-height: var(--numberBodyHeigth);
}
.callPricesPreviewDiv .bodyWithTabs .tariffPricesBody .searchByNumberWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.callPricesPreviewDiv.noLocal .bodyWithTabs .tariffTabDiv .tariffTab {
  width: 45%;
}
.callPricesPreviewDiv.noLocal .bodyWithTabs .tariffTabDiv .tariffTab.localTab {
  display: none;
}
.callPricesPreviewDiv .searchByNumberWrap .numberSearchInput {
  text-align: center;
  width: 240px;
  padding: 10px;
  font-size: 18px;
}
.callPricesPreviewDiv .searchByNumberWrap .paramAndVal {
  display: flex;
  padding: 8px;
}
.callPricesPreviewDiv .searchByNumberWrap .paramAndVal .paramName {
  width: 250px;
  display: flex;
  justify-content: flex-end;
}
.callPricesPreviewDiv .searchByNumberWrap .paramAndVal .paramName > div {
  width: 100px;
}
.callPricesPreviewDiv .searchByNumberWrap .paramAndVal .paramValue {
  font-weight: 500;
  width: 250px;
}
.callPricesPreviewDiv .callPricesTable .callPricesHeader {
  height: var(--tablesHeaderHeigth);
  font-weight: 500;
  border-bottom: 2px solid #ababab;
  border-top: 2px solid #ababab;
  display: flex;
  align-items: center;
}
.callPricesPreviewDiv .callPricesTable .callPricesBody .callPricesRow {
  border-bottom: 1px solid #cecece;
}
.callPricesPreviewDiv .callPricesTable .callPricesBody .callPricesRow:hover {
  background-color: #f6f5f5;
}
.callPricesPreviewDiv .callPricesTable .callPricesBody .callPricesRow .prefixColumn {
  font-family: monospace;
}
.callPricesPreviewDiv .callPricesTable .callPricesBody .callPricesRow .priceColumn {
  font-family: monospace;
}
.callPricesPreviewDiv .callPricesTable .callPricesRow {
  height: 40px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.callPricesPreviewDiv .callPricesTable .callPricesRow:last-child {
  border-bottom: none;
}
.callPricesPreviewDiv .callPricesTable .callPricesRow .prefixColumn {
  width: 20%;
}
.callPricesPreviewDiv .callPricesTable .callPricesRow .directionColumn {
  width: 60%;
}
.callPricesPreviewDiv .callPricesTable .callPricesRow .priceColumn {
  width: 20%;
}
.kamailioNodesConfigDiv {
  user-select: none;
  width: 365px;
}
.kamailioNodesConfigDiv .kamNodesHeader {
  color: #2a8be1;
  font-size: 12px;
}
.kamailioNodesConfigDiv .kamNodesBorder {
  border: solid 2px #c3c3c3;
  background-color: #fff;
  padding: 5px;
}
.kamailioNodesConfigDiv .oneKamailioRow {
  display: flex;
  align-items: center;
  height: 26px;
}
.kamailioNodesConfigDiv .paramDesc {
  width: 100px;
  font-weight: 500;
}
.kamailioNodesConfigDiv .divForKamailiosInUse {
  display: flex;
}
.kamailioNodesConfigDiv .divForKamailiosInUse .oneKamailioSelectableNode {
  background-color: #0ac630;
}
.kamailioNodesConfigDiv .divForKamailiosAvailable {
  margin-top: 5px;
  display: flex;
}
.kamailioNodesConfigDiv .divForKamailiosAvailable .oneKamailioSelectableNode {
  background-color: #a8a8a8;
}
.kamailioNodesConfigDiv .oneKamailioSelectableNode {
  background-color: #0ac630;
  padding: 3px;
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.emojiPickerPopupDivForEmojis {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
.emojiPickerPopupDivForEmojis > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  margin: 5px;
  font-size: 30px;
  cursor: pointer;
}
.colorPickerPopupDivForColors {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
}
.colorPickerPopupDivForColors .removeColorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px darkgrey;
}
.colorPickerPopupDivForColors > div {
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin: 10px;
  border-radius: 50%;
}
.miaHistoryRouteStepsDiv {
  width: 600px;
  display: flex;
  flex-direction: column;
}
.miaHistoryRouteStepsDiv > div {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 3px 10px;
  margin: 3px;
}
.miaHistoryRouteStepsDiv > div .miaHistoryStepSvgDiv {
  margin-right: 10px;
  font-size: 25px;
}
.miaHistoryRouteStepsDiv .miaHistoryStepRecognizedText {
  display: block;
  background-color: #e9e9e9;
  margin-right: 150px;
}
.miaHistoryRouteStepsDiv .miaHistoryStepRecognizedText > div {
  display: flex;
  align-items: center;
}
.miaHistoryRouteStepsDiv .miaHistoryStepRecognizedText .miaHistoryStepRecognizedTextDiv i {
  color: cornflowerblue;
}
.miaHistoryRouteStepsDiv .miaHistoryStepRecognizedText .miaHistoryStepDatesDiv {
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid darkgrey;
}
.miaHistoryRouteStepsDiv .miaHistoryStepRecognizedText .miaHistoryStepDatesDiv i {
  color: darkblue;
}
.miaHistoryRouteStepsDiv .miaHistoryStepNode {
  background-color: #e9e9e9;
  margin-left: 150px;
}
.miaHistoryRouteStepsDiv .miaHistoryStepNode i {
  color: lightgreen;
}
.miaHistoryRouteStepsDiv .miaHistoryStepStartBackgroundMia {
  margin-right: auto;
  margin-left: auto;
}
.miaHistoryRouteStepsDiv .miaHistoryStepStartBackgroundMia i {
  color: darkviolet;
}
.miaHistoryRouteStepsDiv .miaHistoryStepEndBackgroundMia {
  margin-right: auto;
  margin-left: auto;
}
.miaHistoryRouteStepsDiv .miaHistoryStepEndBackgroundMia i {
  color: orange;
}
.miaHistoryRouteStepsDiv .miaHistoryStepJumpToNode {
  margin-right: auto;
  margin-left: auto;
}
.miaHistoryRouteStepsDiv .miaHistoryStepJumpToNode i {
  color: #40E0D0;
}
/*<editor-fold desc="Група умов і все що в ній лежить">*/
.miaConditionGroupsTemplate {
  background-color: #dfe6ed;
}
.miaConditionGroupsTemplate .divWithAddConditionGroupButton {
  background-color: #ffb732;
  width: 100%;
  padding: 5px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate {
  /*<editor-fold desc="Умова">*/
  /*</editor-fold>*/
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionGroupTitleDiv {
  display: flex;
  align-items: center;
  background-color: #ffb732;
  width: 100%;
  height: 40px;
  padding: 5px 15px 5px 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionGroupTitleDiv > div:last-child {
  margin-left: auto;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionGroupMainDiv {
  margin: 10px;
  display: flex;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionGroupMainDiv .conditionGroupIndexDiv {
  width: 80px;
  margin-right: 50px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate {
  background-color: #ffffff;
  margin: 10px;
  padding: 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate input,
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .dropdown-single-button {
  height: 35px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .numberInput {
  width: 125px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionSimilarityPercentageSliderDiv {
  width: 250px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate textarea {
  padding: 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionFieldDiv {
  margin-top: 5px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .miaConditionMainDiv {
  display: flex;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .miaConditionMainDiv > div:first-child {
  margin-right: 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .miaConditionMainDiv .conditionRemoveButton {
  margin-left: auto;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionFromAndToNumberDiv {
  display: flex;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionFromAndToNumberDiv > div {
  margin-right: 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionFieldWithNameAndInput {
  display: flex;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionFieldWithNameAndInput > div:first-child {
  width: 250px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionTimeTypeDiv {
  margin-bottom: 10px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionTimeSelectionMethodSelect,
.miaConditionGroupsTemplate .miaConditionGroupTemplate .miaConditionTemplate .conditionNumberSelectionMethodSelect {
  width: 250px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate {
  display: flex;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate > div:first-child {
  width: 250px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionTimeDatePicker {
  cursor: pointer;
  width: 110px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionTimeDateTimePicker {
  cursor: pointer;
  width: 150px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionOffsetSettingsDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionOffsetSettingsDiv > div:not(:last-child) {
  margin-right: 5px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionOffsetSettingsDiv .conditionTimeOffsetInput {
  width: 80px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionOffsetSettingsDiv .conditionTimeOffsetUnitSelect,
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionTimeTemplate .conditionOffsetSettingsDiv .conditionTimeOffsetDirectionSelect {
  width: 120px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionStringTemplate {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionStringTemplate > div:first-child {
  width: 100%;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionStringTemplate > div:not(:first-child) {
  margin-left: 40px;
}
.miaConditionGroupsTemplate .miaConditionGroupTemplate .conditionStringTemplate .conditionStringInput {
  width: 100%;
}
/*</editor-fold>*/
/*<editor-fold desc="Попап з умовами">*/
.miaConditionsPopupSectionsDiv {
  margin: 10px;
}
.miaConditionsPopupSectionsDiv .conditionsSectionTemplate {
  margin-bottom: 10px;
}
.miaConditionsPopupSectionsDiv .conditionsSectionTemplate .conditionSectionTitle {
  color: darkgray;
}
.miaConditionsPopupShowAllConditionsButtonTemplate {
  display: flex;
  justify-content: center;
  margin: 15px;
}
/*</editor-fold>*/
.compositeAudioTemplate .dropdown-single-button,
.compositeAudioTemplate .webra-input-field {
  height: 35px;
}
.compositeAudioTemplate .dropdown-single-button {
  width: 300px;
  min-width: 200px;
}
.compositeAudioTemplate .compositeAudioHeaderDiv {
  display: flex;
  align-items: center;
  background-color: orange;
  padding: 5px 10px;
}
.compositeAudioTemplate .compositeAudioHeaderDiv .audioTitleDiv {
  font-weight: 500;
  margin-right: 20px;
}
.compositeAudioTemplate .compositeAudioHeaderDiv .compositeAudioAddPartButtonsDiv {
  display: flex;
  align-items: center;
}
.compositeAudioTemplate .compositeAudioHeaderDiv .compositeAudioAddPartButtonsDiv > div {
  margin-right: 10px;
}
.compositeAudioTemplate .compositeAudioHeaderDiv .compositeAudioRemoveButton {
  margin-left: auto;
}
.compositeAudioTemplate .divForAudioParts {
  background-color: #dfe6ed;
  padding: 1px;
}
.compositeAudioTemplate .audioPartWrapperTemplate {
  display: flex;
  align-items: center;
  margin: 5px 9px;
}
.compositeAudioTemplate .audioPartWrapperTemplate > div:first-child {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.compositeAudioTemplate .audioPartWrapperTemplate .audioPartRemoveButton {
  margin-left: auto;
  min-width: 125px;
}
.compositeAudioTemplate .audioPartWrapperTemplate .audioPartNumberDiv {
  width: 15px;
}
.compositeAudioTemplate .audioPartWrapperTemplate .audioPartContainer {
  width: 100%;
  min-width: 300px;
  margin-right: 50px;
}
.compositeAudioTemplate .audioPartSilenceTemplate,
.compositeAudioTemplate .audioPartAudioTemplate,
.compositeAudioTemplate .audioPartSynthesisTemplate {
  background-color: #ffffff;
  padding: 5px 10px;
}
.compositeAudioTemplate .audioPartSilenceTemplate .openPageButtonDiv,
.compositeAudioTemplate .audioPartAudioTemplate .openPageButtonDiv,
.compositeAudioTemplate .audioPartSynthesisTemplate .openPageButtonDiv {
  margin-left: 5px;
}
.compositeAudioTemplate .audioPartSilenceTemplate,
.compositeAudioTemplate .audioPartAudioTemplate {
  display: flex;
  align-items: center;
}
.compositeAudioTemplate .audioPartAudioTemplate > div:first-child {
  width: 210px;
}
.compositeAudioTemplate .audioPartSilenceTemplate > div {
  margin-right: 10px;
}
.compositeAudioTemplate .audioPartSynthesisTemplate > div {
  display: flex;
  align-items: center;
}
.compositeAudioTemplate .audioPartSynthesisTemplate > div > div:first-child {
  width: 210px;
}
.compositeAudioTemplate .audioPartSynthesisTemplate > div:not(:last-child) {
  padding-bottom: 5px;
}
.unitalkTooltipZone {
  position: relative;
}
.unitalkTooltipZone .unitalkTooltip {
  transition: all 0.2s linear 0.5s;
  max-height: 0;
  position: absolute;
}
.divForKamailios {
  display: flex;
  flex-wrap: wrap;
}
.divForKamailios .oneKamailio {
  margin-bottom: 1px;
  margin-right: 10px;
  background-color: #ffffff;
  font-weight: 500;
  box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.4);
  padding-left: 25px;
  padding-right: 10px;
  cursor: move;
  width: 110px;
}
.divForKamailios .oneKamailio .next-checkbox-square .checkmark {
  left: -25px;
  top: 0;
}
.unitalkDatePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
}
.unitalkDatePicker .dateDesc {
  font-weight: 500;
  cursor: default;
}
.unitalkDatePicker input {
  border: none;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 175px;
}
.notAvailableSectionWarning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffcb00;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin-bottom: 20px;
}
.universalTableContainer {
  margin: 5px 5px 20px;
  display: none;
}
.universalTableContainer .tableActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.universalTableContainer .tableActions .entityHeaderName {
  font-size: 25px;
}
.universalTableContainer .tableActions .entityButtonsDiv {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}
.universalTableContainer .tableActions .entityButtonsDiv button {
  height: 30px;
  line-height: 30px;
  border-radius: 25px;
}
.universalTableContainer.active {
  display: block;
}
.universalTableContainer .tableSettings .universalTable {
  min-width: 100%;
  background-color: #ebf5ff;
  border-radius: 15px;
  border-collapse: collapse;
  table-layout: fixed;
}
.universalTableContainer .tableSettings .universalTable tr th,
.universalTableContainer .tableSettings .universalTable tr td {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #cae0ed;
  height: 40px;
}
.universalTableContainer .tableSettings .universalTable tr:first-of-type th:first-of-type {
  border-top-left-radius: 5px;
}
.universalTableContainer .tableSettings .universalTable thead tr th {
  border-top: none;
}
.universalTableContainer .tableSettings .universalTable thead tr th:first-child {
  border-top-left-radius: 5px;
  border-left: none;
}
.universalTableContainer .tableSettings .universalTable thead tr th:last-child {
  border-right: none;
}
.universalTableContainer .tableSettings .universalTable thead tr th.sortHeader {
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}
.universalTableContainer .tableSettings .universalTable thead tr th.sortHeader.sortUp {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
}
.universalTableContainer .tableSettings .universalTable thead tr th.sortHeader.sortDown {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
}
.universalTableContainer .tableSettings .universalTable tbody .filterRow {
  background-color: #d5d8f1;
}
.universalTableContainer .tableSettings .universalTable tbody tr:not(.filterRow):hover {
  background-color: #e2effa;
}
.universalTableContainer .tableSettings .universalTable tbody tr td {
  word-wrap: break-word;
}
.universalTableContainer .tableSettings .universalTable tbody tr td:first-child {
  border-left: none;
}
.universalTableContainer .tableSettings .universalTable tbody tr td:nth-last-child(2) {
  border-right: none;
}
.universalTableContainer .tableSettings .universalTable tbody tr td:last-child {
  border-left: none;
  border-right: none;
}
.universalTableContainer .tableSettings .universalTable tbody tr.changed {
  background-color: rgba(255, 245, 0, 0.25) !important;
}
.universalTableContainer .tableSettings .universalTable tbody tr.changed .actions .saveRow {
  pointer-events: auto;
  opacity: 1;
}
.universalTableContainer .tableSettings .universalTable tbody tr .actions {
  width: 115px;
  min-width: 115px;
  text-align: right;
}
.universalTableContainer .tableSettings .universalTable tbody tr .actions .saveRow {
  pointer-events: none;
  opacity: 0;
}
.universalTableContainer .tableSettings .universalTable tbody tr .actions svg:hover {
  cursor: pointer;
}
.universalTableContainer .tableSettings .universalTable tbody tr:last-child td {
  border-bottom: none;
}
.universalTableContainer .tableSettings .universalTable .btn.dropdown-toggle {
  box-shadow: none;
  border: none;
  padding: 0;
}
.universalTableContainer .tableSettings .universalTable .btn-group.bootstrap-select {
  width: 100% !important;
}
.universalTableContainer .tableSettings .universalTable .btn-default {
  background: none;
}
.universalTableContainer .tableSettings .universalTable input[type="text"] {
  background: none;
  border: none;
  width: 90%;
}
.universalTableContainer .pagination {
  display: flex;
  justify-content: center;
  border-radius: 15px;
}
.universalTableContainer .pagination > div {
  border: 1px solid #cae0ed;
  background-color: #ebf5ff;
  font-size: 15px;
  font-weight: 500;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.universalTableContainer .pagination > div.disabled {
  color: grey;
  cursor: default;
  pointer-events: none;
}
.universalTableContainer .pagination .backward {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  width: 22px;
}
.universalTableContainer .pagination .pagesToDisplay {
  width: 165px;
}
.universalTableContainer .pagination .forward {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  width: 22px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringControlsDiv {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringControlsDiv .operatorsMonitoringSipGroupSelect {
  height: 35px;
  width: 250px;
  margin-left: 10px;
  margin-right: 30px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringControlsDiv .operatorsMonitoringRefreshButton {
  height: 35px;
  line-height: 35px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div {
  display: flex;
  align-items: center;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div > div {
  padding: 3px 5px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div > div:first-child {
  flex-basis: 375px;
  min-width: 250px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div > div:nth-child(2) {
  flex-basis: 150px;
  min-width: 130px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div > div:nth-child(n+3) {
  flex-basis: 125px;
  min-width: 100px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div .operatorsMonitoringSipStatus {
  display: flex;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv > div .operatorsMonitoringSipStatus > i {
  width: 25px;
  font-size: 20px;
  margin-right: 3px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv .operatorsMonitoringOperatorsHeaderDiv {
  background: #edf1f5;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv .operatorsMonitoringOperatorsRow:nth-child(odd) {
  background: #f9fafc;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv .divForUserPauses {
  height: 35px;
}
.operatorsMonitoringMainBlock .operatorsMonitoringOperatorsDiv .divForUserPauses .userPausePopup {
  top: 29px;
}
.editorView {
  background-color: #fff;
  position: fixed;
  top: 60px;
  left: 60px;
  right: 0;
  box-shadow: 0px 0px 7px -2px #000000 !important;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.editorView .greyHeader {
  background-color: #dfe6ed;
  color: #000000;
  padding: 8px;
  padding-left: 15px;
  line-height: 30px;
  font-weight: 500;
  height: 40px;
  align-items: center;
  font-size: 20px;
  display: flex;
}
.editorView .toolsPane {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.editorView .toolsPane .topPanelButton {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.editorView .importAndConfigsMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.editorView .importAndConfigsMenu .importSection {
  display: flex;
  align-items: center;
  position: relative;
}
.editorView .importAndConfigsMenu .importSection .importFileUploadBlocker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.editorView .importAndConfigsMenu .importSection .configName {
  color: #00AEEF;
  font-weight: 500;
}
.editorView .importAndConfigsMenu .importSection .configWarningMessage {
  position: absolute;
  color: red;
  left: 62px;
  bottom: -20px;
  font-weight: 500;
}
.editorView .importAndConfigsMenu .importSection .link {
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.editorView .importAndConfigsMenu .importSection .editLinkButton {
  margin-left: 10px;
  cursor: pointer;
}
.editorView .importAndConfigsMenu .importHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.editorView .importAndConfigsMenu .importHeader .closeImportButton {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.editorView .importAndConfigsMenu .configColumns {
  display: flex;
  max-height: calc(100vh - 170px);
}
.editorView .importAndConfigsMenu .configColumns .configsListColumn {
  width: 300px;
}
.editorView .importAndConfigsMenu .configColumns .parametersColumn {
  flex-grow: 1;
}
.editorView .importAndConfigsMenu .configColumns .usesColumn {
  width: 600px;
}
.editorView .importAndConfigsMenu .configColumns .usedByTariffsList {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.editorView .importAndConfigsMenu .configColumns .configSettingsOption {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.editorView .importAndConfigsMenu .configColumns .configsList {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.editorView .importAndConfigsMenu .oneColumn {
  padding: 15px;
}
.editorView .importAndConfigsMenu .oneColumn .columnHeader {
  display: flex;
  align-items: end;
}
.editorView .importAndConfigsMenu .oneColumn .columnHeader .columnName {
  font-size: 20px;
  font-weight: 500;
}
.editorView .importAndConfigsMenu .oneColumn .columnHeader .functionButton {
  color: #0cbf09;
  font-weight: 500;
  font-size: 14px;
  margin-left: 15px;
  line-height: 24px;
}
.editorView .importAndConfigsMenu .oneColumn .columnHeader .functionButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.editorView .importAndConfigsMenu .oneConfigParameter {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.editorView .importAndConfigsMenu .oneConfigParameter .parameterName {
  width: 200px;
}
.editorView .importAndConfigsMenu .oneConfig {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editorView .importAndConfigsMenu .oneConfig .removeButton {
  height: 20px;
  display: none;
}
.editorView .importAndConfigsMenu .oneConfig:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.09);
}
.editorView .importAndConfigsMenu .oneConfig:hover {
  background-color: rgba(0, 148, 255, 0.13);
}
.editorView .importAndConfigsMenu .oneConfig:hover .removeButton {
  display: block;
}
.editorView .importAndConfigsMenu .oneConfig.selected {
  background-color: rgba(0, 148, 255, 0.78);
  color: #fff;
}
.editorView .importAndConfigsMenu .usesColumn .oneTariffUse {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.editorView .importAndConfigsMenu .usesColumn .oneTariffUse:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.09);
}
.editorView .importAndConfigsMenu .usesColumn .oneTariffUse.currentTariff {
  background-color: rgba(0, 141, 141, 0.35);
}
.leaseEditorView {
  background-color: #fff;
  position: fixed;
  top: 60px;
  left: 60px;
  right: 0;
  box-shadow: 0px 0px 7px -2px #000000 !important;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.leaseEditorView .greyHeader {
  background-color: #dfe6ed;
  color: #000000;
  padding: 8px;
  padding-left: 15px;
  line-height: 30px;
  font-weight: 500;
  height: 40px;
  align-items: center;
  font-size: 20px;
  display: flex;
}
.leaseEditorView .toolsPane {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.leaseEditorView .toolsPane .topPanelButton {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.leaseEditorView .priceColumn:nth-child(1) {
  width: 25px;
}
.leaseEditorView .priceColumn:nth-child(2) {
  width: 120px;
}
.leaseEditorView .priceColumn:nth-child(3) {
  width: 300px;
}
.leaseEditorView .priceColumn:nth-child(4) {
  width: 120px;
}
.leaseEditorView .priceColumn:nth-child(5) {
  width: 190px;
}
.leaseEditorView .priceColumn:nth-child(6) {
  width: 150px;
}
.leaseEditorView .priceColumn:nth-child(7) {
  width: 190px;
}
.leaseEditorView .priceColumn:nth-child(8) {
  width: 100px;
}
.leaseEditorView .priceColumn:nth-child(9) {
  width: 200px;
}
.leaseEditorView .priceColumn:nth-child(10) {
  width: 100px;
}
.leaseEditorView .priceColumn:nth-child(11) {
  width: 130px;
}
.leaseEditorView .priceColumn:nth-child(12) {
  width: 150px;
}
.leaseEditorView.specialPricesEditor .priceColumn:nth-child(4) {
  width: 80px;
}
.leaseEditorView.specialPricesEditor .priceColumn:nth-child(5) {
  width: 110px;
}
.leaseEditorView.specialPricesEditor .priceColumn:nth-child(6) {
  width: 120px;
}
.leaseEditorView.specialPricesEditor .priceColumn:nth-child(11) {
  width: 90px;
}
.leaseEditorView.publicPricesEditor .priceColumn:nth-child(7) {
  display: none;
}
.leaseEditorView.publicPricesEditor .priceColumn:nth-child(8) {
  display: none;
}
.leaseEditorView .oneTariffPrice {
  font-family: "JetBrains Mono";
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
}
.leaseEditorView .oneTariffPrice .priceColumn {
  margin-right: 10px;
}
.leaseEditorView .oneTariffPrice .selectedCbDiv {
  width: 20px;
  height: 20px;
}
.leaseEditorView .oneTariffPrice .selectedCbDiv input {
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.leaseEditorView .oneTariffPrice .errorField {
  animation: blink-animation 2s infinite;
  -webkit-animation: blink-animation 2s infinite;
}
@keyframes blink-animation {
  to {
    background-color: rgba(255, 0, 0, 0.38);
  }
}
@-webkit-keyframes blink-animation {
  to {
    background-color: red;
  }
}
.leaseEditorView .importAndConfigsMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.leaseEditorView .importAndConfigsMenu .importSection {
  display: flex;
  align-items: center;
  position: relative;
}
.leaseEditorView .importAndConfigsMenu .importSection .importFileUploadBlocker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.leaseEditorView .importAndConfigsMenu .importSection .configName {
  color: #00AEEF;
  font-weight: 500;
}
.leaseEditorView .importAndConfigsMenu .importSection .configWarningMessage {
  position: absolute;
  color: red;
  left: 62px;
  bottom: -20px;
  font-weight: 500;
}
.leaseEditorView .importAndConfigsMenu .importSection .link {
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.leaseEditorView .importAndConfigsMenu .importSection .editLinkButton {
  margin-left: 10px;
  cursor: pointer;
}
.leaseEditorView .importAndConfigsMenu .importHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.leaseEditorView .importAndConfigsMenu .importHeader .closeImportButton {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.leaseEditorView .importAndConfigsMenu .configColumns {
  display: flex;
  max-height: calc(100vh - 170px);
}
.leaseEditorView .importAndConfigsMenu .configColumns .configsListColumn {
  width: 300px;
}
.leaseEditorView .importAndConfigsMenu .configColumns .parametersColumn {
  flex-grow: 1;
}
.leaseEditorView .importAndConfigsMenu .configColumns .usesColumn {
  width: 600px;
}
.leaseEditorView .importAndConfigsMenu .configColumns .usedByTariffsList {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.leaseEditorView .importAndConfigsMenu .configColumns .configSettingsOption {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.leaseEditorView .importAndConfigsMenu .configColumns .configsList {
  max-height: calc(100% - 28px);
  overflow: auto;
}
.leaseEditorView .importAndConfigsMenu .oneColumn {
  padding: 15px;
}
.leaseEditorView .importAndConfigsMenu .oneColumn .columnHeader {
  display: flex;
  align-items: end;
}
.leaseEditorView .importAndConfigsMenu .oneColumn .columnHeader .columnName {
  font-size: 20px;
  font-weight: 500;
}
.leaseEditorView .importAndConfigsMenu .oneColumn .columnHeader .functionButton {
  color: #0cbf09;
  font-weight: 500;
  font-size: 14px;
  margin-left: 15px;
  line-height: 24px;
}
.leaseEditorView .importAndConfigsMenu .oneColumn .columnHeader .functionButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.leaseEditorView .importAndConfigsMenu .oneConfigParameter {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.leaseEditorView .importAndConfigsMenu .oneConfigParameter .parameterName {
  width: 200px;
}
.leaseEditorView .importAndConfigsMenu .oneConfig {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leaseEditorView .importAndConfigsMenu .oneConfig .removeButton {
  height: 20px;
  display: none;
}
.leaseEditorView .importAndConfigsMenu .oneConfig:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.09);
}
.leaseEditorView .importAndConfigsMenu .oneConfig:hover {
  background-color: rgba(0, 148, 255, 0.13);
}
.leaseEditorView .importAndConfigsMenu .oneConfig:hover .removeButton {
  display: block;
}
.leaseEditorView .importAndConfigsMenu .oneConfig.selected {
  background-color: rgba(0, 148, 255, 0.78);
  color: #fff;
}
.leaseEditorView .importAndConfigsMenu .usesColumn .oneTariffUse {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.leaseEditorView .importAndConfigsMenu .usesColumn .oneTariffUse:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.09);
}
.leaseEditorView .importAndConfigsMenu .usesColumn .oneTariffUse.currentTariff {
  background-color: rgba(0, 141, 141, 0.35);
}
.leaseEditorView .valueEditMenu .valueEditMenuBody {
  display: flex;
  height: 200px;
  width: 400px;
}
.sweetContextMenu {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  box-shadow: 5px 5px 17px -2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.sweetContextMenu .contextItem {
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  font-weight: 500;
}
.sweetContextMenu .contextItem:hover {
  color: #1e88e5;
  cursor: pointer;
}
.sweetContextMenu .contextItem .contextItemIcon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sweetContextMenu .contextItem .contextItemName {
  width: 160px;
  display: flex;
  align-items: center;
}
.specialConditionsEditor {
  --headerHeigth: 50px;
  --modalHeigth: 500px;
  --mainWidth: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  height: var(--modalHeigth);
  background-color: #fff;
  border: 3px solid #e8e8e8;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.specialConditionsEditor .header {
  display: flex;
  height: var(--headerHeigth);
  align-items: center;
  font-size: 25px;
  padding: 10px;
}
.specialConditionsEditor .header .tariffText {
  font-size: 25px;
}
.specialConditionsEditor .header .tariffName {
  color: #e33351;
  font-size: 25px;
  margin-left: 20px;
  font-weight: 500;
}
.specialConditionsEditor .header .headerDesc {
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
}
.specialConditionsEditor .header .downloadXlsxButton {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  color: #1e88e5;
}
.specialConditionsEditor .header .downloadXlsxButton:hover {
  text-decoration: underline;
}
.specialConditionsEditor .header .closeButton {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 40px;
  height: 40px;
  background-color: #ff6060;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.specialConditionsEditor .header .closeButton:hover {
  background-color: #f50101;
}
.specialConditionsEditor .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--headerHeigth);
  height: calc(var(--modalHeigth) - var(--headerHeigth));
}
.specialConditionsEditor .body .keyval {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: var(--mainWidth);
  margin-bottom: 5px;
}
.specialConditionsEditor .body .commentDiv {
  border: 2px solid #e8e8e8;
  min-width: var(--mainWidth);
  max-width: var(--mainWidth);
  margin-bottom: 5px;
  padding-left: 5px;
}
.specialConditionsEditor .body .globalCharge {
  width: var(--mainWidth);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}
.specialConditionsEditor .body .globalCharge .webra-input-field {
  height: 25px;
}
.specialConditionsEditor .body button {
  width: var(--mainWidth);
}
.specialConditionsEditor .buttonLeaseDiv {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
